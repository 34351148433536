.custom_toast {
  /* position: absolute; */
  bottom: 0;
  left: 0;
  background-color: #333; /* 토스트 배경 색상 */
  color: #fff; /* 토스트 텍스트 색상 */
  font-size: 16px; /* 토스트 폰트 크기 */
  border-radius: 8px; /* 토스트 경계선 둥글기 */
  padding: 10px; /* 토스트 패딩 */
  width: 100%; /* 토스트 너비 */
  max-width: 80%; /* 최대 너비 */
  margin: 0 auto; /* 중앙 정렬 */
  bottom: 0;
}
/* 서포트 화면 */
.support_header {
position: absolute;
width: 100%;
top: 0;
background-color: white;
height: 50px;
z-index: 100;
box-shadow: 0 0 4px 1px rgba(169, 173, 178, 0.2);
}
.support_header>div:nth-of-type(1) {
position: absolute;
top: 50%;
left: 30px;
transform: translate(-50%, -50%);
}
.support_header>div:nth-of-type(2) {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
font-size: 1.2em;
}
.venue_header {
position: absolute;
display: flex;
justify-content: space-between;
width: 100%;
background-color: white;
height: 50px;
box-shadow: 0 0 4px 1px rgba(169, 173, 178, 0.2);
z-index: 150;
}
.venue_header button {
border: none;
background-color: inherit;
}
.venue_header>div:nth-of-type(1) {
position: absolute;
top: 50%;
left: 30px;
transform: translate(-50%, -50%);
}
.venue_header>div:nth-of-type(2) {
position: absolute;
display: flex;
top: 50%;
right: 0;
transform: translate(-40%, -50%);
font-size: 1.2em;
text-align: right;
}
.support_favority_heart_box {
position: absolute;
bottom: 40px;
right: 20px;
z-index: 150;
} 
.support_favority_heart_box button {
border: none;
background-color: inherit;
}
.support_main_my_swiper :global(.swiper-button-prev),
.support_main_my_swiper :global(.swiper-button-next) {
display: none;
}
.support_login_check_popup {

}
.support_login_check_popup_title {
  font-weight: 500;
  padding: 5px 0;
}
.support_login_check_popup_sub_title {
  font-size: 0.9em;
  padding-bottom: 10px;
}
.support_login_check_popup_btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.support_login_check_popup_btn>button:nth-of-type(1) {
  width: 45%;
  height: 45px;
  border: 1px solid rgba(238, 225, 255, 1);
  background-color: inherit;
  font-size: 0.9em;
  border-radius: 4px;
}
.support_login_check_popup_btn>button:nth-of-type(2) {
  width: 45%;
  height: 45px;
  border: none;
  background-color: rgba(255, 3, 102, 1);
  color: white;
  font-size: 0.9em;
  border-radius: 4px;
}
.my_support_detail_favority_popup {
  
}
.my_support_detail_favority_title {
  font-weight: 500;
  padding: 20px 0;
}
.my_support_detail_favority_confirm_btn_box {
  width: 100%;
}
.my_support_detail_favority_confirm_btn_box button {
  width: 100%;
  height: 50px;
  border: none;
  background-color: rgba(255, 3, 102, 1);
  color: white;
  font-size: 0.9em;
  border-radius: 6px;
}
.detail_contents {
  top: -25px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 0 4px 1px rgba(169, 173, 178, 0.2);
  z-index: 100;
}
.message_btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
.shop_title_box {
  display: flex;
  align-items: center;
}
.shop_title_box>div:nth-of-type(1) {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
}
.shop_title_box>div:nth-of-type(1) img {
  width: 100%;
  height: 30px;
  object-fit: cover;
}
.shop_title_box>div:nth-of-type(2){
  padding-top: 2px;
}
.shop_title_box>div:nth-of-type(2) span{
  height: 30px;
  font-size: 0.9em;
}
.shop_hashtag_list {
  color: rgba(169, 173, 178, 1);
  font-size: 0.9em;
  padding: 5px 0;
}
.shop_product_name h5 {
  margin: 0 !important;
}

.reservation_review_filter_modal_container {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 200;
}
.reservation_review_filter_modal_box {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50%;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.reservation_review_filter_modal_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 20px;
}
.reservation_review_filter_modal_header button {
  border: none;
  background-color: inherit;
}
.reservation_review_filter_modal_content {
  width: 100%;
  padding: 20px;
}
.reservation_review_filter_modal_content>div {
  width: 100%;
  margin-bottom: 5px;
}
.reservation_review_filter_modal_content>div button {
  width: 100%;
  height: 50px;
  border: 1px solid rgba(241, 242, 243, 1);
  background-color: inherit;
  border-radius: 4px;
}
.reservation_filter_active {
  background-color: rgba(255, 3, 102, 1) !important;
  color: white;
}

/* 옵션 선택 */
.option_type_short_answer {
  padding: 15px 20px;
  border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.option_type_short_answer>div:nth-of-type(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.option_type_short_answer>div:nth-of-type(1)>div:nth-of-type(1) {
  font-size: 1.1em;
}
.option_type_short_answer>div:nth-of-type(1)>div:nth-of-type(2) {
  color: white;
  border-radius: 30px;
  padding: 5px 10px;
  font-size: 0.8em;
}
.option_type_request {
  background-color: rgb(0, 115, 255);
}
.option_type_no_request {
  background-color: rgb(167, 167, 167);
}
.option_type_short_answer>div:nth-of-type(2) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}
.option_type_short_answer>div:nth-of-type(2)>div:nth-of-type(1){width: 70%;}
.option_type_short_answer>div:nth-of-type(2)>div:nth-of-type(2){width: 30%; text-align: right;}
.option_type_short_answer>div:nth-of-type(2) input {
  width: 100%;
  height: 40px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgba(169, 173, 178, 1);
  padding-left: 10px;
  font-size: 0.8em;
}
.option_type_short_answer>div:nth-of-type(2) input:focus {
  outline: none;
  border-bottom: 1px solid rgba(255, 3, 102, 1);
}

.option_type_single_selection {
  padding: 15px 20px;
  border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.option_type_single_selection>div:nth-of-type(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.option_type_single_selection>div:nth-of-type(1)>div:nth-of-type(1) {
  font-size: 1.1em;
}
.option_type_single_selection>div:nth-of-type(1)>div:nth-of-type(2) {
  /* background-color: rgb(0, 115, 255); */
  color: white;
  border-radius: 30px;
  padding: 5px 10px;
  font-size: 0.8em;
}
.option_type_single_selection>div:nth-of-type(2)>div {
  position: relative;
}
.option_type_single_selection input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  border-radius: 50%;
  background: #fff;
  position: relative;
}

.option_type_single_selection input[type="radio"]::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba(255, 3, 102, 1);
  transition: width 0.2s ease, height 0.2s ease;
}

.option_type_single_selection input[type="radio"]:checked {
  border-color: rgba(255, 3, 102, 1);
}

.option_type_single_selection input[type="radio"]:checked::before {
  width: 10px;
  height: 10px;
}

.option_type_single_selection>div:nth-of-type(2) {
  padding: 20px 0;
}
.option_type_single_selection>div:nth-of-type(2)>div {
  display: flex;
  align-items: center;
  padding: 5px 0;
  justify-content: space-between;
}
.option_type_single_selection>div:nth-of-type(2)>div>div:nth-of-type(1) {
  font-size: 0.9em;
}
.option_quantity_control {
  position: absolute;
  top: 50%;
  right: 100px;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
}
.option_quantity_control>div:nth-of-type(1) {
  width: 30px;
  height: auto;
  padding: 0 5px;
}
.option_quantity_control>div:nth-of-type(2) {
  width: 25px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.option_quantity_control>div:nth-of-type(3) {
  width: 30px;
  height: auto;
  padding: 0 5px;
}
.option_quantity_control>div:nth-of-type(1) button,
.option_quantity_control>div:nth-of-type(3) button {
  width: 100%;
  height: 25px;
  border: none;
  box-shadow: 0 0 4px 1px rgba(238, 225, 255, 1);
  border-radius: 4px;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}
.option_quantity_control>div:nth-of-type(1) button>span,
.option_quantity_control>div:nth-of-type(3) button>span {
  font-size: 1.1em;
}
.option_quantity_input {
  width: 30px;
  border: none;
  /* box-shadow: 0 0 4px 1px rgba(238, 225, 255, 1); */
  font-size: 0.9em;
  padding: 2px;
  text-align: center;
  border-radius: 4px;
}
.option_quantity_input:focus {
  outline: none;
  border: 1px solid rgba(255, 3, 102, 1);
}
.option_type_multiple_selection {
padding: 15px 20px;
border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.option_type_multiple_selection>div:nth-of-type(1) {
display: flex;
justify-content: space-between;
align-items: center;
}
.option_type_multiple_selection>div:nth-of-type(1)>div:nth-of-type(1) {
font-size: 1.1em;
}
.option_type_multiple_selection>div:nth-of-type(1)>div:nth-of-type(2) {
/* background-color: rgb(0, 115, 255); */
color: white;
border-radius: 30px;
padding: 5px 10px;
font-size: 0.8em;
}
.option_type_multiple_selection>div:nth-of-type(2)>div {
position: relative;
}
.option_type_multiple_selection input[type="checkbox"] {
appearance: none;
width: 20px;
height: 20px;
border: 1px solid #999;
border-radius: 4px;
background: white;
position: relative;
}

.option_type_multiple_selection input[type="checkbox"]:after {
content: '';
position: absolute;
top: 35%;
left: 50%;
transform: translate(-50%, -50%) rotate(45deg);
width: 0;
height: 0;
border: solid white;
border-width: 0 2px 2px 0;
transition: all 0.3s;
opacity: 0;
}

.option_type_multiple_selection input[type="checkbox"]:checked {
border-color: rgba(255, 3, 102, 1);
background-color: rgba(255, 3, 102, 1);
}

.option_type_multiple_selection input[type="checkbox"]:checked:after {
width: 7px;
height: 12px;
opacity: 1;
}
.option_type_multiple_selection>div:nth-of-type(2) {
padding: 20px 0;
}
.option_type_multiple_selection>div:nth-of-type(2)>div {
display: flex;
align-items: center;
padding: 5px 0;
justify-content: space-between;
}
.option_type_multiple_selection>div:nth-of-type(2)>div>div:nth-of-type(1) {
font-size: 0.9em;
}

.option_type_date_selection {
padding: 15px 20px;
border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.option_type_date_selection>div:nth-of-type(1) {
display: flex;
justify-content: space-between;
align-items: center;
}
.option_type_date_selection>div:nth-of-type(1)>div:nth-of-type(1) {
font-size: 1.1em;
}
.option_type_date_selection>div:nth-of-type(1)>div:nth-of-type(2) {
/* background-color: rgb(0, 115, 255); */
color: white;
border-radius: 30px;
padding: 5px 10px;
font-size: 0.8em;
}
.option_type_date_selection>div:nth-of-type(2) {
padding: 20px 0;
}
.option_type_date_selection>div:nth-of-type(2) input {
width: 100%;
height: 40px;
border: 1px solid rgba(241, 242, 243, 1);
border-radius: 4px;
font-size: 0.8em;
padding-left: 10px;
padding-right: 10px;
}
.option_type_date_selection>div:nth-of-type(2) input:focus {
outline: none;
border: 1px solid rgba(255, 3, 102, 1);
}

.option_type_file_upload {
padding: 15px 20px;
border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.option_type_file_upload>div:nth-of-type(1) {
display: flex;
justify-content: space-between;
align-items: center;
}
.option_type_file_upload>div:nth-of-type(1)>div:nth-of-type(1) {
font-size: 1.1em;
}
.option_type_file_upload>div:nth-of-type(1)>div:nth-of-type(2) {
/* background-color: rgb(0, 115, 255); */
color: white;
border-radius: 30px;
padding: 5px 10px;
font-size: 0.8em;
}
.option_type_file_upload>div:nth-of-type(2) {
padding: 20px 0;
}
.option_type_file_upload_btn {
width: 100%;
height: 40px;
border: 1px solid rgba(255, 114, 153, 1);
border-radius: 4px;
font-size: 0.8em;
background-color: inherit;
transition: all 0.2s;
}
.option_type_file_upload_btn:hover {
background-color: rgba(255, 114, 153, 1);
color: white;
}
.option_type_file_name_list {
width: 100%;
}
/* .option_type_file_name_list :global(.swiper-slide) {
width: 20% !important;
} */
.option_type_file_upload_list {
padding: 10px 3px;
display: flex;
align-items: center;
}
.option_type_file_upload_list>span {
font-size: 0.8em;
color: rgba(255, 114, 153, 1);
padding: 0 5px;
width: 100px;
overflow: hidden; 
text-overflow: ellipsis;
white-space: nowrap
}
.option_type_file_upload_list>button {
border: none;
background-color: inherit;
}
.option_type_file_upload_list>button span {
color: rgba(255, 114, 153, 1);
}
.option_type_file_upload_txt {
font-size: 0.8em;
color: rgba(169, 173, 178, 1);
padding-top: 10px;
}

/* 예약 신청 일자 */
.support_date_range_piceker_container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 500;
  background-color: white;
}
.support_date_range_piceker_box {
  width: 100%;
  height: 100%;
}
.support_date_range_piceker_header {
  width: 100%;
  height: 50px;
  box-shadow: 0 0 4px 1px rgba(241, 242, 243, 1);
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.support_date_range_piceker_header button {
  border: none;
  background-color: inherit;
}
.support_date_range_piceker_header>div:nth-of-type(1) {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translate(0, -50%);
}
.support_date_range_piceker_header>div:nth-of-type(2) {
  font-size: 1.1em;
  font-weight: 500;
}
.support_date_range_piceker_header>div:nth-of-type(3) {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
}
.support_date_range_piceker_text {
  width: 100%;
  padding: 10px 10px 0 10px;
  font-weight: 500;
  color: rgba(255, 3, 102, 1);
}

/* 운영 시간 */
.venue_schedule_box {
width: 100%;
display: flex;
padding: 15px 0;
border-bottom: 1px solid rgb(247, 247, 247);
color: rgba(72, 76, 81, 1);
cursor: pointer;
}
.venue_schedule_box>div:nth-of-type(1) {width: 15%;}
.venue_schedule_box>div:nth-of-type(2) {width: 65%;}
.venue_schedule_box>div:nth-of-type(3) {width: 20%; text-align: right;}

/* 주소 */
.venue_address_box{
width: 100%;
display: flex;
padding: 15px 0;
border-bottom: 1px solid rgb(247, 247, 247);
color: rgba(72, 76, 81, 1);
}
.venue_address_box>div:nth-of-type(1) {width: 15%;}
.venue_address_box>div:nth-of-type(2) {width: 65%;}
.venue_address_box>div:nth-of-type(3) {width: 20%; text-align: right;}

/* 일정 달력 */
.venue_date {
/* 기존 venue_date 스타일들 */
height: auto !important;
}
.venue_date :global(.react-calendar__tile.react-calendar__month-view__days__day) {
height: 70px;
}
.venue_date :global(.react-calendar__navigation) {
width: 100% !important;
}

.highlight {
background: #f1f1f1 !important;
color: black !important;
}
.temporary {
background: rgba(255, 224, 232, 1) !important;
color: black;
}
.weekend {
color: blue;
}
.sunday {
color: red;
}
.venue_date :global(.react-calendar__month-view__weekdays__weekday) {
text-align: center;
font-weight: bold;
}

.venue_date :global(.react-calendar__month-view__weekdays__weekday abbr) {
text-decoration: none;
}

.venue_date :global(.react-calendar__month-view__days__day--weekend) {
/* color: blue; */
}

.venue_date :global(.react-calendar__month-view__days__day--weekend.react-calendar__tile--active) {
background: #ffeb3b;
color: black;
}

.venue_date :global(.react-calendar__month-view__days__day--sunday) {
color: red;
}
.modal {
position: absolute;
/* inset: 40px; */
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 80vh;
height: 80vh;
background: rgb(255, 255, 255);
overflow: auto;
border-radius: 4px;
outline: none;
padding: 20px;
box-shadow: 0 0 4px 1px rgb(188, 188, 189);
}
.overlay {
position: fixed;
inset: 0px;
background-color: rgba(255, 255, 255, 0.75);
z-index: 999;
}
.temporary_modal_popup {
position: relative;
}
.temporary_modal_close {
position: absolute;
width: 100%;
}
.temporary_modal_popup button {
position: absolute;
top: 0;
right: 0;
border: none;
background-color: inherit;
}
.temporary_modal_popup button span {
font-size: 1.7em;
}
.temporary_modal_title {
text-align: center;
padding: 30px 0;
font-size: 1.2em;
}
.temporary_modal_date {
display: flex;
align-items: center;
font-size: 0.9em;
color: rgba(255, 114, 153, 1);
}
.temporary_modal_content {
padding: 30px 0;
}
.temporary_modal_content>div:nth-of-type(1) {
font-size: 1.1em;
padding: 5px 0;
}
.temporary_modal_content>div:nth-of-type(2) {
font-size: 0.9em;
}
.temporary_modal_img {
width: 100%;
overflow: hidden;
}
.temporary_modal_img img {
width: 100%;
object-fit: cover;
}
.temporaryBar {
background-color: rgba(255, 114, 153, 1);
color: white;
cursor: pointer;
width: 100%;
height: 30px;
display: flex;
align-items: center; /* 수직 중앙 정렬 */
justify-content: center; /* 수평 중앙 정렬 */
white-space: nowrap; /* 텍스트 줄바꿈 방지 */
overflow: hidden; /* 넘치는 텍스트 숨김 */
text-overflow: ellipsis; /* 넘치는 텍스트 생략 부호 처리 */
padding: 2px 5px; /* 패딩을 조정하여 텍스트가 더 많이 보이도록 */
box-sizing: border-box; /* 패딩 포함 너비 계산 */
font-size: 0.7em;
}

.temporaryBarStart {
border-top-left-radius: 5px;
border-bottom-left-radius: 5px;
}

.temporaryBarEnd {
border-top-right-radius: 5px;
border-bottom-right-radius: 5px;
}

.temporaryBarMiddle {
border-radius: 0;
}

.temporary_title {
width: 100%;
text-align: center;
}

.react-calendar__tile--hover,
.react-calendar__tile--active {
background: none;
color: inherit;
border: none;
box-shadow: none;
}



.react-calendar__month-view__days__day--sunday.react-calendar__tile--active {
background: #ffeb3b;
color: black;
}
.reservation_option_select_box {
  width: 100%;
  height: 80vh;
  overflow-y: auto;
  padding-bottom: 50px;
}

.venue_date_calendar_box {
width: 100%;
display: flex;
padding: 15px 0;
border-bottom: 1px solid rgb(247, 247, 247);
color: rgba(72, 76, 81, 1);
cursor: pointer;
}
:global(.rbc-row-content) {
position: relative;
}

.venue_exchange_return_content {
  border-top: 1px solid rgba(238, 225, 255, 1);
  border-bottom: 1px solid rgba(238, 225, 255, 1);
  padding: 10px 0;
}
.venue_cancel_exchange_return_content {
  border-bottom: 1px solid rgba(238, 225, 255, 1);
  padding: 10px 0;
}

.holidayEvent {
background-color: grey !important;
width: auto !important;
color: white;
border-radius: 5px;
border: none;
display: block;
cursor: pointer;
text-align: center;
}

.birthdayEvent {
background-color: rgba(255, 114, 153, 1) !important;
color: white;
border-radius: 5px;
border: none;
display: block;
cursor: pointer;
text-align: center;
}
:global(.rbc-month-row) {
font-size: 0.7em;
}
:global(.rbc-event-content) {
font-size: 0.85em;
}

.calendar_header {
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
padding: 20px 5px;
}
.calendar_header>div:nth-of-type(1), .calendar_header>div:nth-of-type(3) {
padding: 10px;
border: 1px solid rgba(241, 242, 243, 1);
border-radius: 4px;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
color: rgb(188, 188, 189);
cursor: pointer;
}
.calendar_header>div:nth-of-type(1):hover, .calendar_header>div:nth-of-type(3):hover {
background-color: rgba(255, 224, 232, 1);
}

.venue_date_calendar_box>div:nth-of-type(1) {width: 15%;}
.venue_date_calendar_box>div:nth-of-type(2) {width: 65%;}
.venue_date_calendar_box>div:nth-of-type(3) {width: 20%; text-align: right;}

/* 예약하기 기본 옵션 */
.reserve_backgorund {
position: absolute;
top: 0;
left: 0;
width: 100%;
background-color: rgba(72, 76, 81, 0.4);
height: 100%;
z-index: 150;
}
.reserve_container {
position: absolute;
background-color: white;
width: 100%;
height: 50%;
bottom: 0;
border-top-left-radius: 20px;
border-top-right-radius: 20px;
}
.reserve_container>div {
position: relative;
height: 100%;
width: 100%;
}
.reserve_container_footer {
position: absolute;
bottom: 0;
width: 100%;
}
.reserve_container_footer span {
font-size: 1.3em;
color: rgba(255, 3, 102, 1);
font-weight: bold;
}
.reserve_container_footer button {
border: none;
width: 100%;
height: 50px;
border-radius: 8px;
font-size: 0.9em;
}
.reserve_container_footer_btn_active {
background-color: rgba(255, 3, 102, 1);
color: white;
}
.reserve_container_header {
width: 100%;
display: flex;
justify-content: space-between;
padding: 20px;
align-items: center;
}
.reserve_container_header>div:nth-of-type(1) {
font-size: 1.1em;
}
.reserve_container_header button {
border: none;
background-color: inherit;
}
.reserve_container_select_box {
width: 100%;
padding: 20px;
}
.reserve_container_select {
width: 100%;
height: 50px;
}
.reserve_container_option_img {
width: 40px;
height: 40px;
border-radius: 6px;
object-fit: cover;
}

/* .show_date_active {
overflow-y: hidden;
} */
.cart_reserve_btn {
display: flex;
width: 100%;
justify-content: space-around;
}
.cart_reserve_btn button {
width: 100%;
height: 50px;
border: none;
border-radius: 8px;
margin: 0 10px;
}

.cart_reserve_btn button:nth-of-type(2) {
background-color: rgba(255, 3, 102, 1);
color: white;
}
/* 달력 */
:global(.rdrDateDisplayWrapper) {
display: none !important;
}
:global(.rdrCalendarWrapper) {
width: 100% !important;
}
:global(.rdrMonth) {
width: 100% !important;
}
:global(.rdrDay) {
height: 6em !important;
}

/* 결제 진행 화면 */
.menual_payment_card_input {
font-size: 0.9em;
}
.menual_payment_card_input>div {
display: flex;
align-items: center;
margin-top: 5px;
}
.menual_payment_card_input>div>div:nth-of-type(1) {width: 40%;}
.menual_payment_card_input>div>div:nth-of-type(2) {width: 60%;}
.menual_payment_card_input>div input {
width: 100%; 
height: 40px;
border: 1px solid rgba(169, 173, 178, 1);
border-radius: 4px;
padding-left: 10px;
}
.menual_payment_card_input>div input:focus {
outline: none;
border: 1px solid rgba(255, 3, 102, 1);
}
.easy_payment_select {
width: 100%;
display: flex;
align-items: center;
flex-direction: column;
}
.easy_payment_select>div:nth-of-type(1), .easy_payment_select>div:nth-of-type(2) {
width: 100%;
border: 1px solid rgba(169, 173, 178, 1);
border-radius: 4px;
margin: 10px 0;
}
.easy_payment_select>div:nth-of-type(1) button, .easy_payment_select>div:nth-of-type(2) button {
width: 100%;
background-color: inherit;
border: none;
padding: 20px;
font-size: 0.9em;
}
.easy_payment_select_active {
border : 1px solid rgba(255, 3, 102, 1) !important;
}
.easy_payment_select_active_btn {
color: rgba(255, 3, 102, 1) !important;
}
.deposit_bank_select {
width: 80px;
height: 80px;
border-radius: 4px;
overflow: hidden;
border: 1px solid rgba(169, 173, 178, 1);
cursor: pointer;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.deposit_bank_select>div:nth-of-type(1) {
display: flex;
justify-content: center;
align-items: center;
}
.deposit_bank_select>div:nth-of-type(1) img {
width: 30px;
height: 30px;
}
.deposit_bank_select>div:nth-of-type(2) {
text-align: center;
font-size: 0.9em;
}
.deposit_bank_select_active {
border: 1px solid rgba(255, 3, 102, 1);
}
.depositor_name_input_box {
display: flex;
width: 100%;
align-items: center;
padding: 10px 0;
font-size: 0.9em;
}
.depositor_name_input_box>div:nth-of-type(1) {
width: 30%;
}
.depositor_name_input_box>div:nth-of-type(2) {
width: 70%;
}
.depositor_name_input_box>div:nth-of-type(2) input {
width: 100%;
border: 1px solid rgba(169, 173, 178, 1);
height: 40px;
border-radius: 4px;
padding-left: 10px;
font-size: 0.9em;
}
.depositor_name_input_box>div:nth-of-type(2) input:focus {
outline: none;
border: 1px solid rgba(255, 3, 102, 1);
}
.deposit_cash_receipt_box {
width: 100%;
font-size: 0.9em;
}
.deposit_cash_receipt_box>div:nth-of-type(2) {
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
}
.deposit_cash_receipt_box>div:nth-of-type(2)>div {
width: 100%;
margin: 0 5px;
}
.deposit_cash_receipt_box>div:nth-of-type(2)>div button {
width: 100%;
height: 40px;
border: 1px solid rgba(169, 173, 178, 1);
background-color: inherit;
border-radius: 4px;
}
.deposit_cash_receipt_active {
border: 1px solid rgba(255, 3, 102, 1) !important;
color: rgba(255, 3, 102, 1);
}
.deposit_cash_receipt_type {
width: 100%;
font-size: 0.9em;
margin-top: 1em;
}
.deposit_cash_receipt_type>div:nth-of-type(2) {
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
}
.deposit_cash_receipt_type>div:nth-of-type(2)>div {
width: 100%;
margin: 0 5px;
}
.deposit_cash_receipt_type>div:nth-of-type(2)>div button {
width: 100%;
height: 40px;
border: 1px solid rgba(169, 173, 178, 1);
background-color: inherit;
border-radius: 4px;
}
.deposit_cash_receipt_type_active {
border: 1px solid rgba(255, 3, 102, 1) !important;
color: rgba(255, 3, 102, 1);
}
.deposit_cash_receipt_type_box {
font-size: 0.9em;
margin-top: 1em;
}
.deposit_cash_receipt_type_box>div:nth-of-type(2) {
display: flex;
justify-content: space-between;
align-items: center;
}
.deposit_cash_receipt_type_box>div:nth-of-type(2) input {
width: 100%;
height: 40px;
border: 1px solid rgba(169, 173, 178, 1);
border-radius: 4px;
}
.deposit_cash_receipt_type_box>div:nth-of-type(2) input:focus {
outline: none;
border: 1px solid rgba(255, 3, 102, 1);
}

/* 예약 완료 화면 */
.reserve_payment_success_header {
position: absolute;
width: 100%;
height: 50px;
background-color: white;
top: 0;
box-shadow: 0 0 4px 1px rgba(169, 173, 178, 0.2);
}
.reserve_payment_success_header>button:nth-of-type(1) {
position: absolute;
top: 50%;
left: 30px;
transform: translate(-50%, -50%);

}
.reserve_payment_success_header>button:nth-of-type(2) {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
}
.reserve_payment_success_header button {
border: none;
background-color: inherit;
}
.reserve_payment_success_header button>span {
font-size: 1.7em;
}
.reserve_payment_success_content {
width: 100%;
margin-top: 50px;
display: flex;
align-items: center;
flex-direction: column;
justify-content: center;
border-bottom: 10px solid rgba(241, 242, 243, 1);
padding: 20px 0;
}
.reserve_payment_success_content>div:nth-of-type(1) {
text-align: center;
font-size: 1.3em;
}
.reserve_payment_success_content>div:nth-of-type(1) img {
width: 60px;
}
.reserve_payment_success_content>div:nth-of-type(2) {
width: 80%;
box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
border-radius: 4px;
padding: 20px;
margin: 20px 0
}
.reserve_payment_success_content>div:nth-of-type(2)>div {
display: flex;
font-size: 0.9em;
padding: 10px 0;
}
.reserve_payment_success_content>div:nth-of-type(2)>div>div:nth-of-type(1) {
color: rgba(169, 173, 178, 1);
}
.reserve_payment_success_content>div:nth-of-type(2)>div>div:nth-of-type(2) {
margin-left: 10px;
}
.reserve_payment_success_content>div:nth-of-type(3) {
display: flex;
align-items: center;
}
.reserve_payment_success_content>div:nth-of-type(3) button {
border: none;
padding: 15px 40px;
border-radius: 30px;
margin: 10px;
font-size: 0.9em;
}
.reserve_payment_success_content>div:nth-of-type(3)>div:nth-of-type(2) button {
background-color: rgba(255, 114, 153, 1);
color: white;
}
.reserve_payment_suggest {
cursor: pointer;
padding: 10px;
border-radius: 4px;
transition: all 0.2s;
}
.reserve_payment_suggest:hover {
background-color: rgba(241, 242, 243, 1);
}
.reserve_payment_suggest_box {
width: 100%;
overflow: hidden;
border-radius: 8px;
}
.reserve_payment_suggest_box>div:nth-of-type(1) {
width: 100%;
overflow: hidden;
height: 210px;
border-radius: 8px;
}
.reserve_payment_suggest_box>div:nth-of-type(1) img {
width: 100%;
height: 210px;
object-fit: cover;
}
.reserve_payment_suggest_box>div:nth-of-type(2) {
font-size: 0.9em;
color: rgba(169, 173, 178, 1);
padding: 5px 0 0 0;
}
.reserve_payment_suggest_box>div:nth-of-type(3) {
line-height: 17px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
.reserve_payment_suggest_box>div:nth-of-type(4) {
display: flex;
font-size: 1.2em;
padding: 10px 0 0 0;
align-items: center;
}
.reserve_payment_suggest_box>div:nth-of-type(4)>div:nth-of-type(1) {
color: rgba(255, 3, 102, 1);
font-weight: bold;
}
.reserve_payment_suggest_box>div:nth-of-type(4)>div:nth-of-type(2) {
font-size: 0.9em;
color: rgba(169, 173, 178, 1);
text-decoration: line-through;
}
.reserve_payment_suggest_box>div:nth-of-type(6) {
margin-bottom: 10px;
}
.reserve_payment_suggest_box>div:nth-of-type(6) span {
font-size: 0.8em;
background-color: rgba(241, 242, 243, 1);
padding: 5px 10px;
border-radius: 4px;
color: rgba(169, 173, 178, 1);
}

/* 메세지 결제 완료 화면 */
.message_payment_success_content {
width: 100%;
margin-top: 50px;
display: flex;
align-items: center;
flex-direction: column;
justify-content: center;
border-bottom: 10px solid rgba(241, 242, 243, 1);
padding: 20px 0;
}
.message_payment_success_content_header {
text-align: center;
}
.message_payment_success_content_header>div:nth-of-type(1) img {
width: 120px;
}
.message_payment_success_content_header>div:nth-of-type(2) {
text-align: center;
font-size: 1.3em;
}
.message_payment_success_content_box {
width: 80%;
box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
border-radius: 4px;
padding: 20px;
margin: 20px 0
}
.message_payment_success_content_style {
display: flex;
font-size: 0.9em;
padding: 10px 0;
}
.message_payment_success_content_style>div:nth-of-type(1) {width: 30%; color: rgba(169, 173, 178, 1);}
.message_payment_success_content_style>div:nth-of-type(2) {width: 70%;}
.message_payment_success_content_vbank_box {
box-shadow: 0 0 4px 1px rgba(255, 224, 232, 1);
padding: 10px;
border-radius: 4px;
}
.message_payment_account {
}
.message_payment_account button {
border: none;
background-color: inherit;
color: rgb(0, 115, 255);
transition: all 0.2s;
}
.message_payment_account button:hover {
color: rgba(255, 114, 153, 1);
}
.message_payment_btn {
display: flex;
}
.message_payment_btn button {
border: none;
padding: 15px 40px;
border-radius: 30px;
margin: 10px;
font-size: 0.9em;
}
.message_payment_btn>div:nth-of-type(2) button {
background-color: rgba(255, 114, 153, 1);
color: white;
}
.reserve_payment_suggest {
cursor: pointer;
padding: 10px;
border-radius: 4px;
transition: all 0.2s;
}

.support_content_info {
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
  border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.support_content_info>div:nth-of-type(1) {
  display: flex;
  align-items: center;
}
.support_content_info>div:nth-of-type(1)>div:nth-of-type(1) {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  border: 50%;
}
.support_content_info>div:nth-of-type(1)>div:nth-of-type(1) img {
  width: 100%;
  height: 30px;
  object-fit: cover;
  aspect-ratio: 500 / 500;
}
.support_content_info>div:nth-of-type(1)>div:nth-of-type(2) {
  font-size: 1.1em;
  padding-left: 8px;
  width: calc(100% - 30px);
}
.support_content_info>div:nth-of-type(2) {
  padding: 3px 0;
  font-size: 0.9em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: rgba(169, 173, 178, 1);
  font-weight: 200;
}
.support_content_info>div:nth-of-type(3) {
  font-size: 0.8em;
  padding: 3px 0;
}
.support_content_price_rate_star_box {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.support_content_price_rate_star_box>div:nth-of-type(1) {
  display: flex;
  align-items: center;
}
.support_content_price_rate_star_box>div:nth-of-type(1)>div:nth-of-type(1) {
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.support_content_price_rate_star_box>div:nth-of-type(1)>div:nth-of-type(1) img {
  width: 100%;
  height: 20px;
  object-fit: contain;
}
.support_content_price_rate_star_box>div:nth-of-type(1)>div:nth-of-type(2) {
  font-size: 0.9em;
  color: rgba(169, 173, 178, 1);
}
.support_content_price_rate_star_box>div:nth-of-type(2) {
  padding-left: 10px;
  font-size: 0.9em;
  color: rgba(169, 173, 178, 1);
}
.support_content_price_rate_star_box>div:nth-of-type(3) {
  padding-left: 10px;
  display: flex;
  align-items: center;
  color: rgba(255, 3, 102, 1);
  font-size: 1.1em;
  font-weight: 700;
}
.support_content_image_preview_content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.support_content_image_preview_content img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
}
.support_content_image_preview_close {
  position: absolute;
  top: 20px;
  right: 20px;
}
.support_content_image_preview_close button {
  padding: 10px;
  border-radius: 4px;
  background-color: rgba(169, 173, 178, 0.2);
  border: none;
}
.support_content_image_preview_close button span {
  color: white;
  
}

.review_my_slide img {
width: 100%;
aspect-ratio: 500 / 500;
height: auto;
object-fit: cover;
}


/* 메인 카테고리 */
.support_sub_category_swiper :global(.swiper-slide) {
width: auto !important; /* 버튼의 내용에 따라 크기가 조정되도록 설정 */
flex: 0 0 auto; /* 플렉스박스 아이템이 줄어들지 않도록 설정 */
}
.support_sub_category_box {
padding: 0 5px;
margin-top: 5.5px;
}

/* 서포트 맵 버튼 */
.support_rental_map_search_btn_box {
  position: absolute;
  width: 100%;
  bottom: 80px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.support_rental_map_search_btn_box button {
  padding: 5px 10px;
  border-radius: 30px;
  font-size: 0.8em;
  border: none;
  box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
  background-color: rgba(255, 114, 153, 0.8);
  color: white;
  display: flex;
  align-items: center;
}
.support_rental_map_search_btn_box button > span {
  padding-right: 5px;
}
.support_rental_map_search_btn_box button:hover,
.support_rental_map_search_btn_box button:active {
  animation: floatUpDown 0.8s infinite ease-in-out; /* 애니메이션 추가 */
  background-color: rgba(255, 114, 153, 1);
}

@keyframes floatUpDown {
  0%, 100% {
    transform: translateY(0); /* 초기 위치 */
  }
  50% {
    transform: translateY(-5px); /* 위로 이동 */
  }
}

/* 버튼의 스타일 */
.support_sub_category {
width: 100%;
height: 40px;
text-align: center;
display: flex;
justify-content: center; /* 버튼을 중앙 정렬 */
align-items: center; /* 수직 중앙 정렬 */
}

.support_sub_category button {
border: none;
background-color: rgba(241, 241, 241, 1);
font-size: 0.95em;
border-radius: 30px;
padding: 5px 10px;
white-space: nowrap; /* 텍스트가 한 줄에 유지되도록 설정 */
overflow: hidden; /* 넘치는 부분을 숨김 */
text-overflow: ellipsis; /* 넘치는 텍스트를 말줄임표로 표시 */
max-width: 120px; /* 최대 너비 설정으로 인해 너무 긴 버튼을 줄임 */
margin: 0 2px; /* 버튼 간 간격 설정 */
}

/* 활성화된 버튼의 스타일 */
.support_sub_category_active {
background-color: rgba(0, 0, 0, 0.8) !important;
color: white;
}

/* 조합 옵션 reservation */
.support_reservation_first_box {
  width: 100%;
  padding: 0 20px;
}
.support_reservation_first_box_title {
  font-size: 1.1em;
  font-weight: 500;
  padding: 10px 0;
}
.support_reservation_first_box_content {
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
}
.support_reservation_first_box_content>label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.support_reservation_first_box_content input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  border-radius: 50%;
  background: #fff;
  position: relative;
}

.support_reservation_first_box_content input[type="radio"]::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba(255, 3, 102, 1);
  transition: width 0.2s ease, height 0.2s ease;
}

.support_reservation_first_box_content input[type="radio"]:checked {
  border-color: rgba(255, 3, 102, 1);
}

.support_reservation_first_box_content input[type="radio"]:checked::before {
  width: 10px;
  height: 10px;
}
.support_reservation_combination_btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.support_reservation_combination_btn button:nth-of-type(1) {
  width: 48% !important;
  padding: 15px 0;
  border: none;
  border-radius: 8px;
}
.support_reservation_combination_btn button:nth-of-type(2) {
  width: 48% !important;
  
}

/* 상품 수량 박스 */
.reservation_product_quantity_box {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 100px;
  height: 30px;
  transform: translate(0, -50%);
  display: flex;
}
.reservation_no_option_product_quantity_box {
  position: absolute;
  top: 50%;
  left: 20px;
  width: 100px;
  height: 30px;
  transform: translate(0, -50%);
  display: flex;
}
.reservation_product_quantity_box  button,
.reservation_no_option_product_quantity_box  button{
  width: 30px;
  height: auto;
  border: 1px solid rgba(169, 173, 178, 1);
  border-radius: 4px;
  background-color: inherit;
}
.reservation_product_quantity_box input,
.reservation_no_option_product_quantity_box input {
  width: 40px;
  height: auto;
  border: none;
  text-align: center;
  border-radius: 4px;
  font-size: 0.9em;
  padding: 5px;
}
.reservation_product_quantity_box input:focus,
.reservation_no_option_product_quantity_box input:focus {
  outline: none;
  border: 1px solid rgba(255, 3, 102, 1);
}

.support_payment_suceess_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}
.support_payment_suceess_box>div {
  width: 100px;
  height: auto;
}
.support_payment_suceess_box>div img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.support_payment_fail_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}
.support_payment_fail_box > div {
  font-size: 0.9em;
}

/* 서포트 맵 검색 */
.support_location_container {
  padding: 20px;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  height: auto;
  margin-bottom: 100px;
  margin-top: 20px;
}
.support_no_location {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  color: rgba(169, 173, 178, 1);
}
.support_map_search_container {
  width: 100%;
  height: 100%;
}
.support_map_search_box {
  width: 100%;
  height: auto;
}
.support_map_search_header {
  width: 100%;
  height: 50px;
  box-shadow: 0 0 4px 1px rgba(241, 241, 241, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.support_map_search_header>div:nth-of-type(1) {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translate(0, -50%);
}
.support_map_search_header>div:nth-of-type(1) button {
  border: none;
  background-color: inherit;
}
.support_map_search_header>div:nth-of-type(2) {
  font-size: 1.1em;
  font-weight: 500;
}
.support_map_search_content {
  width: 100%;
  height: calc(100svh - 50px);
}
.support_map_search_content > div {
  width: 100%;
  height: 100%;
}

/* 이메일 인증 모달 */
.support_email_cetification_container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.support_email_cetification_box {
  width: 70%;
  height: 200px;
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
}
.support_email_cetification_header {
  width: 100%;
  height: 50px;
  box-shadow: 0 0 4px 1px rgba(238, 225, 255, 1);
  display: flex;
  align-items: center;
  justify-content: end;
}
.support_email_cetification_header button {
  margin-right: 15px;
  border: none;
  background-color: inherit;
}
.support_email_cetification_content {
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.support_email_cetification_content > div:nth-of-type(1) {
  font-weight: 500;
}
.support_email_cetification_content > div:nth-of-type(2) {
  font-size: 0.9em;
  font-weight: 200;
  color: rgba(255, 3, 102, 1);
}
.support_email_cetification_content > div:nth-of-type(3) {
  width: 100%;
  padding: 10px;
}
.support_email_cetification_content > div:nth-of-type(3) button {
  width: 100%;
  height: 40px;
  font-size: 0.9em;
  border: none;
  background-color: rgba(255, 3, 102, 1);
  color: white;
  border-radius: 4px;
}

.support_email_modal_container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.support_email_modal_box {
  width: 70%;
  height: 250px;
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
}
.support_email_modal_header {
  width: 100%;
  height: 40px;
  box-shadow: 0 0 4px 1px rgba(238, 225, 255, 1);
  display: flex;
  align-items: center;
  justify-content: end;
}
.support_email_modal_header button {
  margin-right: 15px;
  border: none;
  background-color: inherit;
}
.support_email_modal_content {
  padding: 10px;
}
.support_email_modal_content_email {
  width: 100%;
}
.support_email_modal_content_email > div:nth-of-type(1) {
  font-size: 0.9em;
}
.support_email_modal_content_email > div:nth-of-type(2) {
  width: 100%;
  position: relative;
}
.support_email_modal_content_email > div:nth-of-type(2) input {
  width: 100%;
  height: 35px;
  border: 1px solid rgba(169, 173, 178, 1);
  border-radius: 4px;
  padding: 10px;
  font-size: 0.9em;
}
.support_email_modal_content_email > div:nth-of-type(2) input:focus {
  outline: none;
  border: 1px solid rgba(255, 3, 102, 1);
}
.support_email_modal_content_email > div:nth-of-type(2) button {
  position: absolute;
  top: 50%;
  right: 10px;
  font-size: 0.9em;
  background-color: rgba(255, 3, 102, 1);
  border-radius: 4px;
  padding: 2px 4px;
  color: white;
  border: none;
  transform: translate(0, -50%);
}
.support_email_auth_number_box {
  width: 100%;
  padding: 5px 0;
}
.support_email_auth_number_box > div:nth-of-type(1) {
  font-size: 0.9em;
}
.support_email_auth_number_box > div:nth-of-type(2) {
  width: 100%;
  position: relative;
}
.support_email_auth_number_box > div:nth-of-type(2) input {
  width: 100%;
  height: 35px;
  border: 1px solid rgba(169, 173, 178, 1);
  border-radius: 4px;
  padding: 0 10px;
  font-size: 0.9em;
}
.support_email_auth_number_box > div:nth-of-type(2) input:focus {
  border: 1px solid rgba(255, 3, 102, 1);
  outline: none;
}
.support_email_auth_number_box > div:nth-of-type(2) button {
  position: absolute;
  top: 50%;
  right: 10px;
  padding: 2px 4px;
  font-size: 0.9em;
  transform: translate(0, -50%);
  border: none;
  background-color: rgba(255, 3, 102, 1);
  color: white;
  border-radius: 4px;
}
.support_email_certification_success_box {
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.support_email_certification_success_box > div:nth-of-type(1) {
  font-weight: 500;
}
.support_email_certification_success_box > div:nth-of-type(2) {
  width: 100%;
  padding: 20px;
}
.support_email_certification_success_box > div:nth-of-type(2) button {
  width: 100%;
  height: 40px;
  font-size: 0.9em;
  border: none;
  background-color: rgba(255, 3, 102, 1);
  color: white;
  border-radius: 4px;
}

/* 지도 데이터 */
.venue_map_box {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.venue_map_box_img {
  width: 100px;
  overflow: hidden;
}
.venue_map_box_img img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 100 / 100;
  border-radius: 4px;
}
.venue_map_box_content {
  width: calc(100% - 60px);
  padding: 10px;
}
.venue_map_box_content > div:nth-of-type(1) {
  font-size: 1.5em;
  font-weight: 600;
}
.venue_map_box_content > div:nth-of-type(2) {
  padding: 5px 0;
}

@media (max-width: 500px) {
.venue_date :global(.react-calendar__tile.react-calendar__month-view__days__day) {
  height: 60px !important;
  font-size: 0.7em !important;
}
.reserve_backgorund {
  width: 100%;
}
.modal {
  width: 80%;
  height: 80vh;
}
:global(.rbc-header) {
  font-size: 0.8em !important;
}
:global(.rbc-calendar) {
  height: 450px !important;
}
}
@media (max-width: 400px) {
:global(.rbc-calendar) {
  height: 400px !important;
}
.venue_schedule_box {
  font-size: 0.9em;
}
.venue_address_box {
  font-size: 0.9em;
}
.venue_date_calendar_box {
  font-size: 0.9em;
}
}