@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&family=Nanum+Gothic:wght@700&family=Noto+Sans+KR:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://cdn.rawgit.com/innks/NanumSquareRound/master/nanumsquareround.min.css');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');
/* 초기화 */
* { margin: 0; padding: 0; vertical-align: top; box-sizing: border-box; }
html, body { 
  /* font-family: 'NanumSquareRound',sans-serif; */
  width: 100%; height: 100%; -ms-overflow-style: none; scrollbar-width: none; user-select: none; background-attachment: fixed; background-size: cover;}
body::-webkit-scrollbar {display: none;}
/* 길이 변경 */
::-webkit-scrollbar {
  width: 0;
}

/* 트랙 (Track) */
::-webkit-scrollbar-track {
  background: inherit;

}

/* 핸들 (Handle) */
::-webkit-scrollbar-thumb {
  background: inherit;
  border-radius: 15px;
}

/* Hover시 핸들 (Handle) */
::-webkit-scrollbar-thumb:hover {
  /* background: rgba(255, 3, 102, 1); */
}
a { color: inherit !important; text-decoration: none !important; }
li { list-style: none; }
/**********************************************************************************************/

/* 공통 */
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100dvh;
}

/* Chrome, Edge, Safari */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/**/
.row {
  --bs-gutter-x: 0!important;
}
/* 로딩메인 */
.loading_main {
  position: absolute;
  width: 100%;
  height: auto;
  /* background-color: rgba(255, 3, 102, 1); */
  z-index: 15000;
  overflow: hidden;
}
.loading_main img {
  width: 100%;
  /* height: auto; */
  aspect-ratio: 700 / 1516;
  object-fit: cover;
}
/**/

/* page style */
.page {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
}
.page_back_img {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.page_back_img img {
  width: 100%;
}
.page_side {
  width: 15%;
  /* background-color: #aaa; */
  height: 100%;
}
.page_image {
  width: 200px;
  height: 800px;
  position: fixed;
  top: 650px;
  left: calc(50% + 400px); /* 화면 중앙에서 400px 오른쪽에 고정 */
  z-index: 500;
}

.page_image img {
  position: absolute;
  width: 100%;
  object-fit: contain;
}
.page_inner {
  position: relative;
  width: 700px;
  /* background-color: #ccc; */
  height: 100%;
  box-shadow: -4px 0 4px -4px rgba(223, 224, 226, 1), 4px 0 4px -4px rgba(223, 224, 226, 1);
  overflow: hidden;
  background-color: white;
}

/* page_inner */
.main {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}
.logo_box {
  width: 100%;
  text-align: center;
  padding-bottom: 50px;
}
.btn_box {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  padding: 0 30px;
}
.join_box {
  width: 100%;
  text-align: center;
  padding-top: 50px;
}
.link_box {
  width: 100%;
  text-align: center;
  padding: 20px 0;
}
.email_join {
  color: #7a7a7a;
  text-decoration: underline;
  border: none;
  background-color: inherit;
  transition: all 0.3s;
}
.email_join:hover {
  color: rgba(255, 3, 102, 1);
}
.email_join:active {
  color: rgba(255, 3, 102, 1);
}
.main_link {
  color: rgba(255, 3, 102, 1);
  text-decoration: none;
  font-weight: bold;
}
.google_btn {
  margin-right: 0.55em;
}
.apple_btn {
  margin-left: 0.55em;
}
.google_btn span{
  font-size: 0.9em;
}
.apple_btn span {
  font-size: 0.9em;
}


/* Album*/
.album-category{}
.album-favority{
  
}
.album-event{
  
}
.album-notification{
  
}
.album-review{
  
}
.album-favority>a, .album-event>a,
.album-notification>a, .album-review>a{
  text-decoration: none;
  color: inherit;
}
.album_banner {
  padding-top: 10px;
}
.album_title_box {
  width: 70%;
  margin: 0 auto;
  text-align: center;
  padding-top: 20px;
}
.album_main_title {
  font-weight: bold;
}
.album_artist_main, .album_event_main {
  position: relative;
}
.album_artist>a, .album_event>a {
  text-decoration: none;
  color: inherit;
}
.flex-container {
  display: flex;
  overflow-x: scroll;
  /* white-space: nowrap; */
  -webkit-overflow-scrolling: touch;
  /* touch-action: pan-y; */
  scroll-behavior: smooth;
}
.flex-container.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
  user-select: none;
}

.flex-container::-webkit-scrollbar {
  display: none;
}

.album_artist_main>.flex-container > .link-item {  /* 수정된 부분 */
  display: block;
  min-width: 80px;
  height: 80px;
  border-radius: 50px;
  /* border: 1px solid black; */
  margin: 20px;
  overflow: hidden;
}
.album_artist_main>.flex-container>.link-item>img {
  display: block;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.album_artist_leftbtn {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  height: 100%;
  background-color: rgba(170, 170, 170, 0.1);
  border: none;
}
.album_artist_rightbtn {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 1;
  height: 100%;
  background-color: rgba(170, 170, 170, 0.1);
  border: none;
}

.album_event_main>.flex-container>.link-item {
  display: block;
  min-width: 140px;
  /* height: 120px; */
  border-radius: 5px;
  /* border: 1px solid black; */
  margin: 0 0 0 20px;
  overflow: hidden;
}
.album_event_main>.flex-container>.link-item>div>img {
  display: block;
  margin: 0 auto;
  width: 100%;
  object-fit: cover;
}
.album_event_leftbtn {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  height: 100%;
  background-color: rgba(170, 170, 170, 0.1);
  border: none;
}
.album_event_rightbtn {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 1;
  height: 100%;
  background-color: rgba(170, 170, 170, 0.1);
  border: none;
}
.store_name {
  color: #aaa !important;
  font-size: 0.8em;
}
.event_text>.event_product_title {
  max-width: 160px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  word-break: break-all;
  text-overflow: ellipsis;
  line-height: 20px;
}
.event_text>.event_product_title>span {
  font-size: 0.8em;
}

/* Market */
.market_header {
  
}
.market_header a {
  text-decoration: none;
  color: inherit;
}
.market_banner {
  /* padding-top: 10px; */
  width: 100%;
  height: auto;
  max-height: 400px !important;
  overflow: hidden;
}
.market_banner img {
  width: 100%;
  aspect-ratio: 700 / 400;
  object-fit: cover;
}
.market_title_box {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 10px;
}
.market_main_title {
  font-weight: bold;
  font-size: 1.2em;
}
.market_sub_title {
  font-size: 0.95em;
}
.category_title {
  /* margin-top: 30px; */
}
.category_active {
  border-bottom: 4px solid rgba(255, 3, 102, 1);
}
.store, .support {
  /* width: 65px !important; */
  text-align: center;
  display: flex;
  align-items: center;
  /* padding: 5px 0; */
}
.store a, .support a {
  text-align: center;
  text-decoration: none;
  color: inherit;
  display: block;
  font-size: 0.8em;
}
.my_category .swiper-slide {
  /* text-align: center;
  display: flex;
  align-items: center; */
  width: auto !important; /* 버튼의 내용에 따라 크기가 조정되도록 설정 */
  flex: 0 0 auto; /* 플렉스박스 아이템이 줄어들지 않도록 설정 */
}
.my_category a {
  border: none;
  background-color: inherit;
  font-size: 1.05em;
  padding: 5px 10px;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  max-width: 120px; 
  margin: 0 5px; 
}

.my_category_store .swiper-slide {
  width: auto !important; /* 버튼의 내용에 따라 크기가 조정되도록 설정 */
  flex: 0 0 auto; /* 플렉스박스 아이템이 줄어들지 않도록 설정 */
}
/* .my_category_store .swiper-slide:nth-of-type(1) {
  width: 60px !important;
}
.my_category_store .swiper-slide:nth-of-type(2) {
  width: 60px !important;
}
.my_category_store .swiper-slide:nth-of-type(3) {
  width: 60px !important;
}
.my_category_store .swiper-slide:nth-of-type(4) {
  width: 70px !important;
}
.my_category_store .swiper-slide:nth-of-type(5) {
  width: 70px !important;
}
.my_category_store .swiper-slide:nth-of-type(6) {
  width: 80px !important;
}
.my_category_store .swiper-slide:nth-of-type(7) {
  width: 80px !important;
}
.my_category_store .swiper-slide:nth-of-type(8) {
  width: 80px !important;
} */
.my_category_store a {
  border: none;
  background-color: inherit;
  font-size: 1.05em;
  padding: 5px 10px;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  max-width: 120px; 
  margin: 0 5px; 
}

.icon_box {
  width: 70px;
  height: 70px;
  border-radius: 20px;
  background: rgba(248, 248, 248, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
}
.icon_box img {
  width: 100%;
  aspect-ratio: 70 / 70;
  object-fit: cover;
}

/* Support */
.support_container {
  width: 100%;
  /* height: 100vh; */
}
.support_pay_header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  height: 55px;
  z-index: 100;
}
.support_header_btn {
  background-color: inherit;
  border: none;
}
.support_pay_header>div:nth-of-type(1) {
  position: absolute;
  top: 15px;
  left: 15px;
}
.support_pay_header>div:nth-of-type(1) button {
  border: none;
  background-color: inherit;
}
.support_pay_header>div:nth-of-type(2) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2em;
}
.venue_header_btn {
  background-color: inherit;
  border : none;
  color: #000;
}
.support_category {
  margin-top: 60px;
  font-size: 0.9em;
  border-bottom: 1px solid rgba(223, 224, 226, 1);
  margin-bottom: 10px;
}
.payment_box {
  margin-top: 55px;
}

.support_category::-webkit-scrollbar {display: none;}


.support_category > a:hover {
  border-bottom: 3px solid rgba(255, 3, 102, 1);
}
.support_category > a:active {
  border-bottom: 3px solid rgba(255, 3, 102, 1);
}
.filter_box {
  display: flex;
  position: relative;
}
.filter_detailbox {
  border: 2px solid rgba(170, 170, 170, 0.6);
  border-radius: 50px;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter_detailbox>button {
  border: none;
  background-color: white;
}
.region_btn_box {
  border: 2px solid rgba(170, 170, 170, 0.6);
  border-radius: 50px;
  /* width: 120px; */
  height: 40px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.region_btn {
  border: none;
  background-color: inherit;
  justify-content: center;
  align-items: center;
  width: 80px;
}
.region_btn span, .reservation_btn span {
  color: #000;
}
.region_btn>span {
  font-size: 0.8em;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.regionBox {
  position: absolute;
  width: 500px;
  height: 100vh;
  top: 0;
  background-color: white;
  z-index: 9999;
}

/* 달력 */
.date_picker_filter_container {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  background-color: white;
  z-index: 9999;
}
.date_picker_filter_box {
  width: 100%;
  height: 100%;
}
.date_picker_filter_header {
  width: 100%;
  height: 50px;
  box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  position: relative;
}
.date_picker_filter_header button {
  border: none;
  background-color: inherit;
}
.date_picker_filter_header>div:nth-of-type(1) {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translate(0, -50%);
}
.date_picker_filter_header>div:nth-of-type(2) {
  font-size: 1.1em;
  font-weight: 500;
}
.date_picker_filter_header>div:nth-of-type(3) {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
}
.date_picker_filter_calendar {
  width: 100%;
  padding: 20px;
}
.date_picker_filter_calendar .react-datepicker {
  border: none !important;
  width: 100%;
}
.date_picker_filter_calendar .react-datepicker__month-container {
  width: 100% !important;
}
.date_picker_filter_calendar .react-datepicker__header {
  background-color: inherit !important;
}
.date_picker_filter_calendar .react-datepicker__day--in-range {
  background-color: rgba(255, 3, 102, 0.1) !important;
  color: #000 !important;
  border-radius: 0 !important; /* 범위 내의 날짜는 사각형 모양이 되도록 수정 */
}
.date_picker_filter_calendar .react-datepicker__day-names {
  width: 100%;
  display: flex;
}
.date_picker_filter_calendar .react-datepicker__day-name {
  width: 100% !important;
}
.date_picker_filter_calendar .react-datepicker__week {
  width: 100%;
  display: flex;
}
.date_picker_filter_calendar .react-datepicker__day {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 500 / 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.date_picker_filter_calendar .react-datepicker__day-name, 
.date_picker_filter_calendar .react-datepicker__day, 
.date_picker_filter_calendar .react-datepicker__time-name{
  margin: 0 !important;
}
.date_picker_filter_calendar .react-datepicker__day--range-start,
.date_picker_filter_calendar .react-datepicker__day--range-end {
  background-color: rgba(255, 3, 102, 1) !important;
  /* border-radius: 50% !important;  */
  color: white !important;
}
.date_picker_filter_calendar .react-datepicker__day--range-start {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}
.date_picker_filter_calendar .react-datepicker__day--range-end {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 4rem !important;
  padding: 1.3rem !important;
}
.datepick_footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  padding: 0 20px;
  border-top: 1px solid rgba(223, 224, 226, 1);
}
.datepick_footer_select_date {
  padding: 20px 0;
}
.datepick_btn {
  background-color: rgba(223, 223, 223, 0.6);
  border: none;
  width: 100%;
  height: 50px;
  color: rgba(170, 170, 170, 1);
  border-radius: 10px;
}
.datepick_active {
  background-color: rgba(255, 3, 102, 1);
  color: white;
}
.datepick_date {
  color: rgba(255, 3, 102, 1);
  font-weight: bold;
  font-size: 1.2em;
}
/*********/
.region_close {
  background-color: inherit;
  border: none;
}
.region_close span {
  color: #000;
}
.region_name {
  width: 25%;
  text-align: center;
}
.region_name>div {
  border-bottom: 1px solid rgba(115, 122, 130, 1);
  background-color: #F1F2F3;
  /* padding: 5px 0; */
}
.region_name>div>button {
  background-color: inherit;
  border: none;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0;
  color: #000;
}
.region_detail {
  width: 75%;
  font-size: 0.8em;
}
.region_detail>div>div {
  padding: 8px 0;
}
.region_detail>div>div>button {
  border: none;
  background-color: inherit;
  color: #000;
}
.region_active {
  background-color: white !important;
  font-weight: bold;
}
.select_region {
  border: 1px solid rgba(170, 170, 170, 0.3);
  border-radius: 30px;
}
.select_region>button {
  padding: 0 10px;
  display: flex;
}
.reservation_btn_box {
  border: 2px solid rgba(170, 170, 170, 0.6);
  border-radius: 50px;
  /* width: 180px; */
  height: 40px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reservation_btn {
  border: none;
  background-color: inherit;
  justify-content: center;
  align-items: center;
  width: 110px;
}
.reservation_btn>span {
  font-size: 0.8em;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.venue {
  padding: 10px 0;
  position: relative;
}
.venue_total {
  width: 100%;
  background-color: #F6F7F8;
}
.venue_product_box {
  width: 100%;
  height: 70vh;
  overflow-y: auto;
  padding-bottom: 60px;
}
.venue_total button {
  color: #000 !important;
}
.recent {
  border: none;
  background-color: inherit;
}
.heart_box {
  top: 75%;
  right: 10px;
}
.product_count {
  color: #aaa;
}
.venue_img_box>img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 15px;
}
/* .venue_product:hover {
  transition: all 0.5s;
  opacity: 0.7;
}
.venue_product:active {
  transition: all 0.5s;
  opacity: 0.7;
} */
.venue_store_name {
  color: #aaa;
  font-size: 0.9em;
}
.venue_product_title {
  /* max-width: 140px; */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  word-break: break-all;
  text-overflow: ellipsis;
}
.venue_price {
  color: rgba(255, 3, 102, 1);
  font-weight: bold;
}
.star_iconImg {
  display: flex;
  align-items: center;
  vertical-align: middle;
}
.star_iconImg img{
  width: 20px;
  height: 22px;
  padding-top: 4px;
}
.star_iconImg>div:nth-of-type(2) {
  position: relative;
}
.star_iconImg>div:nth-of-type(2) span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(10%, -30%);
}
.heart_btn {
  background-color: inherit;
  border: none;
}
.star_grade {
  font-size: 0.8em;
  font-weight: bold;
  color: #5a5a5a;
}
.venue_header {
  z-index: 100;
}
.swiper {
  width: 100%;
  /* height: 450px; */
  overflow: hidden;
}
.swiper_img_box {
  margin-top: 50px;
  position: relative;
}
.swiper_img_box>div:nth-of-type(2) img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}
.swiper-button-prev {
  color: rgba(255, 3, 102, 0.6) !important;
}
.swiper-button-next {
  color: rgba(255, 3, 102, 0.6) !important;
}
.swiper-pagination {
  bottom: 20px !important;
}
.swiper-pagination-bullet-active {
  background: rgba(255, 3, 102, 0.6) !important;
}
.time_box {
  width: 100%;
  /* height: 100px; */
  overflow: hidden;
}
.content {
  transition: max-height 0.5s ease-in-out;
  max-height: 30px;
  overflow: hidden;
}

.content.open {
  max-height: 500px;
}

.hidden {
  max-height: 0;
  opacity: 0;
  visibility: hidden; 
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  overflow: hidden;
}

.content.open .hidden {
  max-height: 100px;
  opacity: 1;
  visibility: visible;
}
.content p {
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  opacity: 1;
  visibility: visible;
}

.content .hidden {
  opacity: 0;
  visibility: hidden;
}
.venus_detail_box {
  height: 400px;
  overflow: hidden;
}
.venus_detail_box image {
  width: 100% !important;
}
.venus_detail_box img {
  width: 100% !important;
  height: auto;
}
.venus_detail_box>div {
  overflow: hidden;
}
.venus_review_box {
  height: auto;
  overflow: hidden;
}
.venus_detail_more_active {
  height: auto !important;
}
.venus_detail_more_btn {
  width: 100%;
  height: 35px;
  font-size: 0.9em;
  border: none;
  background-color: inherit;
}
.ratio_box {
  border-radius: 10px;
  border: 2px solid rgba(218, 218, 218, 0.6);
  padding: 10px 0;
}
table {
  width: 100%;
}
td {
  padding: 2px 10px;
  color: #aaa;
}
.barBackground {
  height: 10px;
  background-color: #e4e4e4; /* 회색 */
  border-radius: 50px;
}
.bar {
  height: 10px;
  background-color: rgba(255, 3, 102, 1);
  border-radius: 50px;
}
.venue_ratio_box {
  width: 40%;
  padding: 30px 0;
}
.venue_ratio_graph {
  width: 60%;
}
.venue_product_exp {
  margin-bottom: 100px;
}
.review_box {
  margin-bottom: 100px;
}
.review_img_box img {
  border-radius: 8px;
}
.review_img_box .swiper {
  height: auto !important;
}
.review_img_box .swiper-wrapper {
  height: auto !important;
}
.list_select_box>button {
  border: none;
  background-color: inherit;
}
.photo_review {
  appearance: none;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  border: 2px solid rgba(255, 3, 102, 1);
  cursor: pointer;
  position: relative;
}
.photo_review:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  top: 2px;
  left: 6px;
  transform: rotate(45deg);
  display: none;
}
.photo_review:checked {
  background: rgba(255, 3, 102, 1);
}
.photo_review:checked:before {
  display: block;
}
.personal_review {
  width: 100%;
  height: auto;
}
.personal_review_img {
  height: auto;
  overflow: hidden;
  border-radius: 8px;
}
.personal_review_img img {
  width: 100%;
  /* height: 400px; */
  aspect-ratio: 500 / 500;
  object-fit: cover;
}
.personal_review_img>.swiper {
  height: auto;
}
.shorten_words {
    /* max-width: 140px; */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    word-break: break-all;
    text-overflow: ellipsis;
}
.personal_review_content>a {
  color: #dddddd !important;
  text-decoration: underline!important;
}
.personal_review_like>button {
  border: none;
  border-radius: 30px;
}
.personal_review_reviewInput>div>button {
  border: none;
  background-color: inherit;
}
.personal_review_reviewInputBox1 {
  width: 90%;
}
.personal_review_reviewInputBox1>input {
  border-radius: 8px;
  border: 1px solid #ccc;
}
.personal_review_reviewInputBox1>input::placeholder {
  padding-left: 10px;
  color: #d1d1d1;
}
.personal_review_reviewInputBox2 {
  width: 10%;
}
.personal_review_moreless>button {
  border: none;
  background-color: inherit;
}
.review_less, .review_more {
  background-color: inherit;
  border: none;
}
.review-enter {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.review-enter-active {
  max-height: 1000px;
  transition: max-height 0.5s ease-in;
}

.review-exit {
  max-height: 1000px;
  overflow: hidden;
  transition: max-height 0.5s ease-in;
}

.review-exit-active {
  max-height: 0;
  transition: max-height 0.5s ease-out;
}
.faq_box {
  margin-bottom: 100px;
}
.faqDetail_btn {
  background-color: inherit;
  border: none;
  padding-top: 5px;
  color: #000;
}
.business_info {
  margin-bottom: 100px;
}
.business_info-list {
  display: flex;
  flex-direction: column;
}
.business_info-item {
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 10px;
}
.business_info-item>dt {
  width: 30%;
  font-weight: 400;
  color: rgba(115, 122, 130, 1);
}
.business_info-item>dd {
  width: 70%;
  font-weight: bold;
}

/* venue_footer */
.venue_footer {
  position: fixed;
  width: 500px;
  /* height: 100px; */
  background-color: white;
  border-top: 1px solid #ccc;
  bottom: 0;
  z-index: 100;
}
.button_box1 {
  width: 50px;
  height: 50px;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button_box1>button {
  border: none;
  background-color: inherit;
  width: 100%;
  height: 100%;
}
.button_box2 {
  width: 100%;
  height: 50px;
}
.button_box2>button {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 15px;
  background-color: rgba(255, 3, 102, 1);
  color: white;
  transition: all 0.5s;
}
.button_box2>button:hover {
  background-color: rgba(255, 3, 102, 0.5);
}
.pay {
  position: fixed;
  top: 0;
  width: 500px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: rgba(0,0,0,0.6);
}

.pay_back {
  width: 100%;
  height: 50%;  /* 원하는 높이로 설정하세요 */
  background-color: white;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;  
}
.pay_close {
  background-color: inherit;
  border: none;
}
.venue-next-btn {
  border: none;
  background-color: rgba(255, 3, 102, 1);
  width: 100%;
  height: 50px;
  border-radius: 10px;
  color: white;
  font-size: 1.2em;
}
.options>select {
  width: 100% !important;
  height: 50px;
  border-radius: 10px;
  border: 1px solid rgb(228, 228, 228);
  color: #5a5a5a;
}
.option_bak {
  background-color:rgba(248, 248, 248, 1);
  margin-top: 20px;
  height: 150px;
  overflow-y: scroll;
}
.options_item {
  margin: 0 30px;
  background-color: white;
  border-radius: 15px;
}
.option_box {
  height: 100px;
}
.option_btnBox>button {
  width: 25px;
  height: 25px;
  background-color: rgba(223, 224, 226, 1);
  border: none;
  border-radius: 30px;
  color: white;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 5px;
}
.option_close>button {
  border: none;
  background-color: inherit;
}

/* venue_reservation */
.venue_reservation {
  position: absolute;
  width: 100%;
  background-color: white;
  border-top: 1px solid #ccc;
  bottom: 0;
  z-index: 100;
}
.reservation_button_box1 {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reservation_button_box1>button {
  display: flex;
  padding: 10px 20px;
  align-items: center;
  border: none;
  background-color: inherit;
  width: 100%;
  height: 100%;
  font-size: 0.9em;
  transition: all 0.2s;
}
.reservation_button_box1>button:hover {
  background-color: rgba(255, 114, 153, 0.2);
}
.reservation_button_box2 {
  width: 100%;
  height: 50px;
}
.reservation_button_box2>button {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 15px;
  background-color: rgba(255, 3, 102, 1);
  color: white;
  transition: all 0.5s;
}
.reservation_button_box2>button:hover {
  background-color: rgba(255, 3, 102, 0.5);
}
.reservation_pay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: rgba(0,0,0,0.6);
  z-index: 150;
}
.reservation_pay_header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
}
.reservation_pay_header>div:nth-of-type(1) {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(0, -50%);
}
.reservation_pay_header>div:nth-of-type(1) button {
  border: none;
  background-color: inherit;
}
.reservation_pay_header>div:nth-of-type(2) {
  font-size: 1.1em;
  font-weight: 500;
}
.reservation_pay_back {
  width: 100%;
  max-height: 100%;  /* 원하는 높이로 설정하세요 */
  background-color: white; 
  overflow-y: auto;
}
.reservation_pay_close {
  background-color: inherit;
  border: none;
  color: #000;
}
.reservation_swiper {
  width: 100%;
  overflow: hidden;
}
.reservation_swiper img {
  width: 100%;
  height: 450px;
  border-radius: 15px;
  object-fit: cover;
}
.reservation_date button {
  border: none;
  background-color: inherit;
}
.reservation_essential input {
  align-items: center;
  vertical-align: middle;
}
.reservation_essential input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  border-radius: 50%;
  background: #fff;
  position: relative;
}

.reservation_essential input[type="radio"]::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba(255, 3, 102, 1);
  transition: width 0.2s ease, height 0.2s ease;
}

.reservation_essential input[type="radio"]:checked {
  border-color: rgba(255, 3, 102, 1);
}

.reservation_essential input[type="radio"]:checked::before {
  width: 10px;
  height: 10px;
}
.reservation_essential label {
  padding-left: 10px;
}
.reservation_essential input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  border-radius: 3px;
  background: #fff;
  position: relative;
}

.reservation_essential input[type="checkbox"]:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 0;
  height: 0;
  border: solid rgba(255, 3, 102, 1);
  border-width: 0 2px 2px 0;
  transition: all 0.3s;
  opacity: 0;
}

.reservation_essential input[type="checkbox"]:checked {
  border-color: rgba(255, 3, 102, 1);
}

.reservation_essential input[type="checkbox"]:checked:after {
  width: 7px;
  height: 12px;
  opacity: 1;
}
.seller_question_select {
  width: 100%;
  padding: 10px 0;
  padding-left: 10px;
  border-radius: 10px;
  border: 1px solid #DFE0E2;
}
.seller_question {
  width: 100%;
  padding: 10px 0;
  padding-left: 10px;
  border-radius: 10px;
  border: 1px solid #DFE0E2;
}
.seller_question::placeholder, .seller_question_select::placeholder {
  color: #DFE0E2;
  font-size: 0.9em;
}
.seller_question:focus, .seller_question_select:focus {
  border-color: rgba(255, 3, 102, 1);
  outline: none;
}
.reservation_nextbtn {
  border: none;
  background-color: rgba(223, 224, 226, 1);
  color: gray;
  width: 100%;
  padding: 15px 0;
  border-radius: 10px;
}
.reservation_nextbtn_active {
  background-color: rgba(255, 3, 102, 1) !important;
  color: white !important;
}
.reservation_price {
  color: rgba(255, 3, 102, 1);
  font-weight: bold;
}
/**********************************************************/

/* venue_request */
.venue_request_box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0,0,0,0.6);
  z-index: 10000;
}
.venue_request_back {
  width: 100%;
  background-color: white;
  height: 100%;
}
.request_mini_img {
  width: 25%;
  border-radius: 10px;
  overflow: hidden;
}
.request_mini_img>img {
  width: 100%;
  object-fit: cover;
}
.request_mini_txt {
  width: 75%;
}
.drop_box {
  width: 100px;
  height: 100px;
  border: 1px solid #A9ADB2;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.slick-slide {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.slick-slide img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid rgba(170, 170, 170, 0.3);
}
.slick-slide button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  border-radius: 50%;
  padding: 5px;
  background-color: rgba(255, 3, 102, 1);
  margin-right: 5px;
}
.slick-slide button>span {
  color: white;
}
.slick-slide.slick-current {
  width: 100px !important;
  height: 100px !important;
}
.request_input_img {
  display: flex;
}

.request_reservation_datebox button{
  border: none;
  background-color: inherit;
}
.request_drop {
  width: 20%;
}
.request_slider {
  width: 70%;
}

.request_slider .swiper-slide {
  width: 100px !important;
  height: 100px !important;
  border-radius: 8px !important;
  overflow: hidden !important;
}
.request_slider_imgBox {
  position: relative;
}
.request_slider_imgBox>img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.request_slider_imgBox>button {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 999;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: none;
  background-color: inherit;
  color: rgba(255, 3, 102, 1);
}
.request-show {
  height: auto;
}
.request-hide {
  height: 100%;
}
.request_btnbox {
  width: 100%;
}
.request_btnbox button {
  width: 90%;
  padding: 15px 10px;
  border-radius: 10px;
  border: none;
}
.request_next>button {
  background-color: rgba(255, 3, 102, 1);
  color: white;
}
.oner_say_massage textarea{
  width: 100%;
  border-radius: 10px;
  padding-top: 3px;
  padding-left: 3px;
  font-size: 0.9em;
  border: 1px solid rgba(170, 170, 170, 0.6);
}


/* 결제페이지 */
.payment_img {
  width: 25%;
  height: 100px;
  overflow: hidden;
  border-radius: 10px;
}
.payment_img>img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
}
.payment_reservation_title {
  width: 75%;
}
.payment_reservation_option {
  color: rgba(115, 122, 130, 1);
  height: 70px;
  overflow-y: scroll;
  font-size: 0.8em;
}
.payment_coupon_select {
  width: 100%;
  padding-left: 5px;
  font-size: 0.9em;
}
.payment_pay_price_box>div:nth-of-type(2) {
  font-size: 0.9em;
}
.payment_couse {
  width: 100%;
  height: 7px;
  background-color: #F8F8F8;
}
.payment_select_btn_box button {
  border: 1px solid rgba(170, 170, 170, 1);
  background-color: inherit;
  color: #484C51;
  padding: 5px 10px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
}
.payment_select_btn_box {
  width: 100%;
  display: flex;
  align-items: center;
}
.payment_select_btn_active {
  background-color: rgba(255, 3, 102, 1) !important;
  color: white !important;
  border: 1px solid rgba(255, 3, 102, 1) !important;
}
.payment_card {
  padding: 10px 0;
}
.payment_card input {
  width: 100%;
  padding: 15px 0;
  border-radius: 5px;
  padding-left: 5px;
  border: 1px solid #7a7a7a;
}
.payment_card input::placeholder {
  color: #c2c2c2;
}
.css-13cymwt-control, .css-t3ipsp-control {
  min-height: 55px !important;
  border-radius: 10px !important;
}
.payment_active {
  background-color: #484C51 !important;
  color: white !important;
}
.use_point_title {
  display: inline-block;
  padding-top: 5px;
}
.use_point_box {
  display: flex;
  align-items: center;
}
.use_point_box>div:nth-of-type(2) {
  display: flex;
  align-items: center;
  justify-content: right;
}
.use_point_box>div:nth-of-type(2)>div:nth-of-type(1) {
  width: 70%;
  text-align: right;
}
.use_point_box>div:nth-of-type(2)>div:nth-of-type(2) {
  width: 30%;
  text-align: right;
}
.use_point_box input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgba(115, 122, 130, 0.4);
  text-align: right;
  padding: 5px;
  width: 100%;
}
.use_point_box button {
  border: 1px solid #7a7a7a;
  background-color: inherit;
  border-radius: 30px;
  padding: 5px;
}
.payment_agreement {
  display: flex;
  vertical-align: middle;
}
.payment_agreement input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  border-radius: 30px;
  background: #fff;
  position: relative;
}
.payment_agreement input[type="checkbox"]:after {
  content: '';
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 0;
  height: 0;
  border: solid rgba(255, 3, 102, 1);
  border-width: 0 2px 2px 0;
  transition: all 0.3s;
  opacity: 0;
}
.payment_agreement input[type="checkbox"]:checked {
  border-color: rgba(255, 3, 102, 1);
}
.payment_agreement input[type="checkbox"]:checked:after {
  width: 7px;
  height: 12px;
  opacity: 1;
}
.checkable input[type="checkbox"] {
  appearance: none !important;
  width: 20px !important;
  height: 20px !important;
  border: 1px solid #999 !important;
  border-radius: 30px !important;
  background: #fff !important;
  position: relative !important;
}
.checkable input[type="checkbox"]:after {
  content: '' !important;
  position: absolute !important;
  top: 40% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) rotate(45deg) !important;
  width: 0 !important;
  height: 0 !important;
  border: solid rgba(255, 3, 102, 1) !important;
  border-width: 0 2px 2px 0 !important;
  transition: all 0.3s !important;
  opacity: 0 !important;
}
.checkable input[type="checkbox"]:checked {
  border-color: rgba(255, 3, 102, 1) !important;
}
.checkable input[type="checkbox"]:checked:after {
  width: 7px !important;
  height: 12px !important;
  opacity: 1 !important;
}
.payment_btn {
  border: none;
  background-color: #dddddd;
  color: #7e7e7e;
  width: 100%;
  padding: 20px 0;
  border-radius: 10px;
  transition: all 0.3s;
}
.payment_btn_active {
  color: white !important;
  background-color: rgba(255, 3, 102, 1);
}
.empty {
  border: 2px solid red !important;
}
.payment_info_box span {
  cursor: pointer;
  transition: all 0.5s;
}
.payment_info_box span:hover {
  color: lightblue;
}
.payment_info_box span:active {
  color: lightblue;
}
.cardchk_span {
  color: red;
}

/* 결제 시 안내사항 */
.info_box {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: white;
  height: 100%;
  z-index: 100;
}

/* 필터디테일 */
.filter_detail_box {
  position: absolute;
  width: 500px;
  height: 100vh;
  top: 0;
  background-color: white;
  z-index: 9999;
}
.filter_detail_category button {
  background-color: inherit;
  border-radius: 30px;
  padding: 10px 30px;
  margin-top: 10px;
  color: #484C51;
  min-width: 130px;
  border: 1px solid #484C51;
}
.filter_deep_category button {
  background-color: inherit;
  border-radius: 30px;
  padding: 10px 30px;
  margin-top: 10px;
  color: #484C51;
  min-width: 130px;
  border: 1px solid #484C51
}
.filter_count {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: rgba(255, 3, 102, 1);
  color: white;
  bottom: -10px !important;
  right: -10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter_category_active {
  color: rgba(255, 3, 102, 1) !important;
  border: 1px solid rgba(255, 3, 102, 1) !important;
}
.filter_footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
}
.filter_footer button {
  width: 80%;
  background-color: inherit;
  padding: 20px 0;
  border-radius: 15px;
  border: none;
}
.filter_initialize {
  background-color: rgba(218, 218, 218, 0.6) !important;
}
.filter_active {
  background-color: rgba(255, 3, 102, 1) !important;
  color: white;
  font-weight: bold;
}
/* Header */
.dailyduck_header {
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dailyduck_header a {
  display: block;
}
.header_left {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(0, -50%);
}
.header_left a>img {
  width: 35px;
  height: auto;
  object-fit: cover;
  aspect-ratio: 35 / 35;
}
.header_right {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
}
.header_right a>img {
  width: 35px;
  height: auto;
  object-fit: cover;
  aspect-ratio: 35 / 35;
}
.dailyduck_header img {
  padding: 5px;
}
.lang-dropdown {
  position: absolute;
  top: 40px;
  right: 20px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  /* padding: 10px; */
  width: 120px; 
  z-index: 1000;  /* 다른 요소 위에 표시되도록 z-index를 높게 설정 */
}
.lang-dropdown-item {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px dotted grey;
  font-size: 0.9em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: start;
}
.lang-dropdown-item>div:nth-of-type(1) {}
.lang-dropdown-item>div:nth-of-type(2) {
  padding-left: 5px;
  font-size: 0.9em;
}
.dailyduck_header_cart_number {
  background-color: rgba(255, 3, 102, 1);
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6em;
  position: absolute;
  top: -6px;
  right: -6px;
}

/* Navigator */
.navigator {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 999;
  /* padding-bottom: 10px;   */
  box-shadow: 0px -3px 5px -2px rgba(223, 224, 226, 1);
  background-color: white;
}
.navigator p {
  padding-top: 5px;
  font-size: 0.8rem;
  color: #7a7a7a;
}
.navigator a {
  text-decoration: none;
}
.navigator a img {
  width: 24px;
  height: 24px;
  object-fit: cover;
  aspect-ratio: 24 / 24;
}
.navigator>div>div {
  padding-top: 7px;
}

/* 버튼 스타일 */
.twitter, .kakao, .line, .email {
  width: 80%;
  --bs-btn-padding-y: 1rem !important;
  background-color: rgba(29, 161, 242, 1);
  border: none !important;
  font-size: 1em !important;
  /* margin: 0 auto !important; */
}
.google, .apple {
  width: 38%;
  --bs-btn-padding-y: 1rem !important;
  background-color: rgba(29, 161, 242, 1);
  border: none !important;
  font-size: 1em !important;
  /* margin: 0 auto; */
}
.apple {
  background-color: rgba(0,0,0,1) !important;
}
.apple img {
  width: 20px;
  height: 24px;
}

.twitter:hover, .kakao:hover, .line:hover, .google:hover, .email:hover {
  opacity: 0.8;
}

.twitter {background: rgba(29, 161, 242, 1) !important;}
.kakao {background: rgba(254, 229, 0, 1) !important; color: black !important;}
.line {background: rgba(5, 179, 76, 1) !important;}
.google {background: rgba(255, 255, 255, 1) !important; border: 1px solid #ccc !important; color: black !important;}
.email {background: rgba(255, 3, 102, 1) !important;}

.main_color2 {color: rgba(255, 114, 153, 1) !important; font-weight: bold;}


.active {display: inline-block;}
.none {display: none !important;}
.check {border-bottom: 3px solid rgba(255, 3, 102, 1) !important;}
.noncheck {border-bottom: none;}


/* 구분선 */
.contour {width: 100%; height: 10px; background: rgba(248, 248, 248, 1); margin: 20px 0;}
.vertical_line {width: 1px; border-right: 1px solid rgba(170, 170, 170, 0.6);}

.bold {font-weight: bold;}

/* color */
.signature_color {color: rgba(255, 3, 102, 1);}
.gray_color {color: #aaa;}
.gray_color2 {color: #888888;}
.system_error {color: rgba(255, 92, 92, 1);}

/* 취소선 */
.line-through {text-decoration: line-through;}

/* 밑줄 */
.underline {text-decoration: underline;}

/* 폰트사이즈 */
.dd-fs-6 {font-size: 1.1em;}
.dd-fs-7 {font-size: 0.9em;}
.dd-fs-8 {font-size: 0.8em;}
.dd-fs-9 {font-size: 0.7em;}
/* .dd-fw-light {font-weight: 100;} */

/* 하단 구분선 */
.bottom_line {border-bottom: 1px solid rgba(241, 242, 243, 1);}

/* 테스트 선 */
.tb {border: 1px solid black;}

/* 중앙정렬(기본) */
.magin-0a {
  margin: 0 auto;
}



/* 상태 카테고리 */
.state_category {
  width: 60px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 5px;
}
.back_gray {
  background-color: rgb(223, 223, 223);
}
.back_white_yellow {
  background-color: rgba(255, 234, 178, 1);
}
.back_white_purple {
  background-color: rgba(238, 225, 255, 1);
}


/* 퍼센트 넓이 초기화 */
.w-5p {width: 5%;}
.w-10p {width: 10%;}
.w-15p {width: 15%;}
.w-20p {width: 20%;}
.w-30p {width: 30%;}
.w-35p {width: 35%;}
.w-40p {width: 40%;}
.w-45p {width: 45%;}
.w-50p {width: 50%;}
.w-60p {width: 60%;}
.w-70p {width: 70%;}
.w-80p {width: 80%;}
.w-90p {width: 90%;}
.w-100p {width: 100%;}

.icon_size {
  width: 20px !important;
  height: 20px;
  filter: drop-shadow(0px 0px 2px #666666);
}

.gray_round {
  display: inline-block;
  width: 40px;
  height: 25px;
  border-radius: 30px;
  background-color: #A9ADB2;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
}

.swal2-popup.swal2-modal.swal2-show {
  border-radius: 12px !important;
}
.my-logout-btn:focus {
  box-shadow: none !important;
}
.swal2-actions.swal2-actions {
  padding: 0 20px;
  justify-content: center !important;
  flex-wrap: nowrap;
  width: 100%;
}
.swal2-cancel.swal2-styled.swal2-default-outline {
  color : black;
  width: 100%;
  height: 60px;
}
.swal2-confirm.swal2-styled.swal2-default-outline {
  width: 100%;
  height: 60px;
}
.swal2-styled {
  border-radius: 12px !important;
}
.swal2-container.swal2-popup {
  flex-wrap: inherit !important;
}
.my-confirm-btn {
  background-color: rgba(255, 3, 102, 1) !important;
  border: none !important;
  width: 100%;
  height: 60px;
}
.my-confirm-btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.favority_data_confirm {
  width: 100% !important;
  background-color: rgba(255, 3, 102, 1) !important;
  color: white !important;
  height: 50px;
}
.favority_data_cancle {
  width: 100% !important;
  background-color: rgba(223, 224, 226, 1) !important;
  color: black !important;
  height: 50px;
}
.favority_data_modal .swal2-title {
  font-size: 1.2em;
}
.favority_data_modal .swal2-html-container {
  font-size: 1em;
}

.Toastify__toast-container.Toastify__toast-container--bottom-center {
  bottom: 10% !important;
}

.new {
  background-color: rgba(255, 239, 243, 1);
  color: rgba(255, 3, 102, 1);
}

.scheduleToOpen {
  width: 80px;
  height: 30px;
  background-color: rgba(238, 225, 255, 1);
  color: rgba(118, 19, 180, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 0.9em;
}
.preOder {
  width: 80px;
  height: 30px;
  background-color: rgba(255, 234, 178, 1);
  color: rgba(246, 104, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 0.9em;
}
.freeshipping {
  width: 80px;
  height: 30px;
  background-color: rgba(241, 242, 243, 1);
  color: rgba(115, 122, 130, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 0.9em;
}

/* 서포트 관리자 페이지 */
.ant-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5%;
}
.ant-pagination-item {
  border: 1px solid rgba(223, 224, 226, 1) !important;
}
.ant-pagination-item-active {
  border-color: rgba(255, 114, 153, 1) !important;
  color: rgba(255, 114, 153, 1) !important;
}
.ant-pagination-item-link {
  border: 1px solid rgba(223, 224, 226, 1) !important;
}

/* CKEditor CSS */
.ck-editor__editable_inline:not(.ck-comment__input *) {
  height: 500px;
  overflow-y: auto;
}
.ck.ck-editor__top.ck-reset_all {
  border-radius: 30px !important;
}
.ck-editor li {
  list-style: auto !important;
}


/* CSSTransition Menu Animation */
/* CSSTransition 애니메이션 */
.menu-enter {
  transform: translateX(100%);
}
.menu-enter-active {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}
.menu-exit {
  transform: translateX(0);
}
.menu-exit-active {
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
}

.adminMenu-enter {
  transform: translateX(-100%);
}
.adminMenu-enter-active {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}
.adminMenu-exit {
  transform: translateX(0);
}
.adminMenu-exit-active {
  transform: translateX(-100%);
  transition: transform 300ms ease-in-out;
}
.adminCategory-enter {
  transform: translateX(100%);
}
.adminCategory-enter-active {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}
.adminCategory-exit {
  transform: translateX(0);
}
.adminCategory-exit-active {
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
}

/* shop select */
.shop_select {
  width: 130px;
}
.shop_select .ant-select-selector {
  border: none !important;
}

/* 카카오 로그인 체크 / 회원가입 화면 */
.kakao_login_container {
  width: 100%;
  height: 100vh;
  position: relative;
}
.kakao_join_check_box  {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 4px 1px rgba(223, 223, 223, 0.6);
  padding: 20px;
  width: 90%;
  border-radius: 4px;
}
.kakao_join_dailyduck_logo {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.kakao_join_check_box>div:nth-of-type(1)  {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.kakao_join_check_box>div:nth-of-type(1)>div:nth-of-type(1) {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
}
.kakao_join_check_box>div:nth-of-type(1)>div:nth-of-type(1) img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 100 / 100;
}
.kakao_join_check_box>div:nth-of-type(1)>div:nth-of-type(4) {
  font-size: 0.8em;
  color: rgba(170, 170, 170, 1);
}
.kakao_join_check_btn_box {
  width: 100%;
  padding: 20px;
}
.kakao_join_check_btn_box>div {
  width: 100%;
}
.kakao_join_check_btn_box button {
  width: 100%;
  height: 45px;
  border: none;
  background-color: rgba(255, 3, 102, 1);
  border-radius: 4px;
  color: white;
  font-size: 0.9em;
}
.nickname_box>div:nth-of-type(2)>div:nth-of-type(1) span {
  font-size: 0.8em;
}
.nickname_box>div:nth-of-type(2)>div:nth-of-type(2) {
  font-size: 0.8em;
  color: rgba(115, 122, 130, 1);
}

.twitter_login_info_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
  width: 80%;
  padding: 10px;
  border-radius: 4px;
}
.twitter_login_info_img_box {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.twitter_login_info_box img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.twitter_login_info_box>div:nth-of-type(1) {
  margin: 10px 0;
}
.twitter_login_info_btn {
  width: 100%;
  font-size: 0.9em;
  margin: 10px 0;
}
.twitter_login_info_box button {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: rgba(255, 3, 102, 1);
  color: white;
  transition: all 0.2s;
}
.twitter_login_info_box button:hover {
  background-color: rgba(255, 3, 102, 0.5);
}
.twitter_join_email_join_email {
  width: 100%;
}
.twitter_join_success_email_auth {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
}
.twitter_join_success_email_auth button {
  border: none;
  padding: 5px 10px;
  background-color: rgba(255, 3, 102, 1);
  color: white;
  font-size: 0.9em;
  border-radius: 4px;
}
.twitter_join_email_auth_number {
  width: 100%;
}
.twitter_join_email_auth_number>div:nth-of-type(2) {
  width: 100%;
}
.twitter_join_email_auth_number>div:nth-of-type(2) input {
  width: 100%;
  height: 55px;
  border-radius: 8px;
  border: 1px solid rgba(170, 170, 170, 1);
  padding-left: 10px;
}
.twitter_join_email_auth_number>div:nth-of-type(2) input:focus {
  outline: none;
  border: 1px solid rgba(255, 3, 102, 1);
}
.twitter_join_email_auth_number>div:nth-of-type(2) {
  position: relative;
}
.twitter_join_email_auth_number>div:nth-of-type(2)>div:nth-of-type(2) {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
}
.twitter_join_email_auth_number>div:nth-of-type(2)>div:nth-of-type(2) button {
  border: none;
  background-color: rgba(255, 3, 102, 1);
  color: white;
  font-size: 0.8em;
  padding: 3px 7px;
  border-radius: 4px;
}
.twitter_join_auth_complete {
  background-color: rgba(223, 224, 226, 1) !important;
}

.line_login_info_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
  width: 80%;
  padding: 10px;
  border-radius: 4px;
}
.line_login_info_box>div:nth-of-type(1) {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
}
.line_login_info_box>div:nth-of-type(1) img {
  width: 100%;
  object-fit: cover;
}
.line_login_info_box>div:nth-of-type(2) {
  padding-top: 10px;
  font-weight: bold;
}
.line_login_info_box>div:nth-of-type(3) {
  font-size: 0.8em;
  color: rgba(170, 170, 170, 1);
}
.line_login_info_box>div:nth-of-type(5) {
  width: 100%;
  padding: 10px 0;
}
.line_login_info_box>div:nth-of-type(5) button {
  width: 100%;
  height: 40px;
  border: none;
  background-color: rgba(255, 3, 102, 1);
  border-radius: 4px;
  font-size: 0.9em;
  color: white;
  transition: all 0.2s;
}
.line_login_info_box>div:nth-of-type(5) button:hover {
  background-color: rgba(255, 3, 102, 0.6);
}

/* 검색 */
.search_header {
  position: absolute;
  width: 100%;
  top: 0;
  background-color: white;
  height: 50px;
  z-index: 100;
  box-shadow: 0 0 4px 1px rgba(169, 173, 178, 0.2);
}
.search_header>div:nth-of-type(1) {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translate(-50%, -50%);
}
.search_header>div:nth-of-type(2) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2em;
}
.search_content_header {
  width: 100%;
  padding: 10px 20px;
  position: relative;
}
.search_content_header>div:nth-of-type(1) {
  width: 100%;
}
.search_content_header>div:nth-of-type(1) input {
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 30px;
  background-color: rgba(241, 242, 243, 1);
  padding-left: 50px;
  font-size: 0.9em;
  padding-right: 40px;
}
.search_content_header>div:nth-of-type(1) input:focus {
  outline: none;
  border: 1px solid rgba(255, 3, 102, 1);
}
.search_content_header>div:nth-of-type(2) {
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translate(0, -50%);
}
.search_content_header>div:nth-of-type(2) span {
  color: rgba(170, 170, 170, 1);
}
.search_content_header>div:nth-of-type(3) {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translate(0, -50%);
}
.search_content_header>div:nth-of-type(3) button {
  border: none;
  background-color: inherit;
}
.search_content_header>div:nth-of-type(3) button>span {
  color: rgba(115, 122, 130, 1);
}
.search_content_recent_search {
  width: 100%;
  padding: 10px 20px;
  border-bottom: 10px solid rgba(248, 248, 248, 1);
}
.search_content_recent_search>div:nth-of-type(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search_content_recent_search>div:nth-of-type(1) button {
  border: 1px solid rgba(223, 224, 226, 1);
  background-color: inherit;
  font-size: 0.8em;
  padding: 3px 7px;
  border-radius: 15px;
  color: rgba(115, 122, 130, 1);
}
.search_content_recent_search_swiper .swiper-slide {
  width: auto !important;
  flex: 0 0 auto;
}
.search_content_recent_search_btn {
  display: flex;
  align-items: center;
  font-size: 0.8em;
  padding: 3px 8px;
  background-color: rgba(241, 242, 243, 1);
  border: none;
  color: rgba(255, 114, 153, 1);
  border-radius: 15px;
  /* width: 70px !important; */
  justify-content: space-between;
  /* max-width: 80px !important; */
}
.search_content_recent_search_btn>button {
  display: flex;
  align-items: center;
  border: none;
  background-color: inherit;
}
.search_content_recent_search_btn span {
  font-size: 1.1em;
}
.search_content_papularity_search {
  width: 100%;
  padding: 10px 0;
  border-bottom: 10px solid rgba(248, 248, 248, 1);
}
.search_content_papularity_search>div:nth-of-type(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid rgba(248, 248, 248, 1);
  padding: 10px 20px;
}
.search_content_papularity_search>div:nth-of-type(1)>div:nth-of-type(2) {
  font-size: 0.8em;
  color: rgba(170, 170, 170, 1);
}
.search_content_papularity_search>div:nth-of-type(2) {display: flex; width: 100%; padding: 10px 20px;}
.search_content_papularity_search>div:nth-of-type(2)>div {padding-right: 20px;}
.search_content_papularity_search_txt {display: flex; width: 100%; align-items: center; padding-top: 20px;}
.search_content_papularity_search_txt>div:nth-of-type(1) {width: 10%;}
.search_content_papularity_search_txt>div:nth-of-type(2) {
  width: 60%; 
  font-size: 0.8em;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 두 줄로 제한 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* 원래는 nowrap을 사용하지만, 여러 줄 처리를 위해 normal로 설정 */
  line-height: 1.2em; /* 줄 높이 설정 */
  max-height: 2.4em; /* 줄 높이와 줄 수에 따라 최대 높이 설정 (예: 줄 높이 1.2em * 줄 수 2 = 2.4em) */
}
.search_content_papularity_search_txt>div:nth-of-type(3) {width: 30%; text-align: right;}
.search_content_suggestion_keyword {
  width: 100%;
  padding: 10px 20px;
  border-bottom: 10px solid rgba(248, 248, 248, 1);
}
.related_keywords {
  position: absolute;
  width: 100%;
  padding: 10px 20px;
  background-color: white;
  z-index: 10;
  box-shadow: 0 3px 4px 1px rgba(223, 224, 226, 1);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.related_keywords_btn {
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 10;
  font-size: 0.8em;
}
.related_keywords_btn button {
  border: 1px solid rgba(255, 114, 153, 1);
  background-color: inherit;
  border-radius: 4px;
  padding: 3px 8px;
  color: rgba(255, 114, 153, 1);
}
.related_display {
  display: none;
}
.no_results {
  text-align: center;
}
.related_keyword_item {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  padding: 10px 5px;
  cursor: pointer;
  border-radius: 4px;
}
.related_keyword_item:hover {
  background-color: rgba(255, 114, 153, 0.1);
}
.search_content_search_data_btn {
  display: flex;
  align-items: center;
  font-size: 0.8em;
  padding: 3px 8px;
  width: 75px !important;
  justify-content: space-between;
}
.search_content_search_data_btn button {
  width: 100%;
  background-color: inherit;
  border: none;
}
.search_content_search_data_btn_active {
  border-bottom: 2px solid rgba(255, 3, 102, 1);
}
.search_content_no_search_box {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.search_content_no_search_box>div:nth-of-type(1) {
  padding: 10px 0;
}
.search_content_no_search_box>div:nth-of-type(2) {
  font-size: 1.3em;
  font-weight: bold;
  padding: 10px 0 5px 0;
}
.search_content_no_search_box>div:nth-of-type(3) {
  font-size: 0.9em;
  color: rgba(115, 122, 130, 0.4);
  padding-bottom: 20px;
}
.search_content_no_search_box>div:nth-of-type(4),
.search_content_no_search_box>div:nth-of-type(5) {
  padding: 5px 0;
}
.search_content_no_search_box>div:nth-of-type(4) button,
.search_content_no_search_box>div:nth-of-type(5) button {
  border: none;
  background-color: rgba(0,0,0,0.6);
  color: white;
  width: 200px;
  height: 50px;
  border-radius: 30px;
  font-size: 0.9em;
  transition: all 0.2s;
}
.search_content_no_search_box>div:nth-of-type(4) button:hover,
.search_content_no_search_box>div:nth-of-type(5) button:hover,
.search_content_no_search_box>div:nth-of-type(4) button:active,
.search_content_no_search_box>div:nth-of-type(5) button:active {
  background-color: rgba(255, 3, 102, 1);
}
.search_result_box {
  width: 100%;
  height: 65vh;
  overflow-y: scroll;
}
.search_store_box{
  max-height: 640px;
  overflow: hidden;
}
.search_content_store_item {
  width: 100%;
  overflow: hidden;
}
.search_content_store_item>div:nth-of-type(1) {
  padding: 20px;
  cursor: pointer;
}
.search_content_store_item>div>div:nth-of-type(1) {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-radius: 8px;
}
.search_content_store_item>div>div:nth-of-type(1) img {
  width: 100%;
  /* height: 200px; */
  aspect-ratio: 200 / 200;
  object-fit: cover;
}
.search_content_store_item>div>div:nth-of-type(2) {
  font-size: 0.8em;
  color: rgba(115, 122, 130, 1);
  padding-top: 5px;
}
.search_content_store_item>div>div:nth-of-type(3) {
  font-size: 0.9em;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 두 줄로 제한 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* 원래는 nowrap을 사용하지만, 여러 줄 처리를 위해 normal로 설정 */
  line-height: 1.2em; /* 줄 높이 설정 */
  max-height: 2.4em; /* 줄 높이와 줄 수에 따라 최대 높이 설정 (예: 줄 높이 1.2em * 줄 수 2 = 2.4em) */
}
.search_content_store_item>div>div:nth-of-type(4) {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.search_content_store_item>div>div:nth-of-type(4)>div:nth-of-type(1) {color: rgba(255, 3, 102, 1);}
.search_content_store_item>div>div:nth-of-type(4)>div:nth-of-type(2) { padding-left: 15px;}
.search_content_store_item>div>div:nth-of-type(4)>div:nth-of-type(3) { padding-left: 5px; color: rgba(170, 170, 170, 1); font-size: 0.8em; text-decoration: line-through;}
.search_content_store_item_btn {
  width: 100%;
  padding: 0 10px;
  margin: 10px 0;
}
.search_content_store_item_btn button {
  width: 100%;
  border: none;
  background-color: rgba(218, 218, 218, 0.6);
  height: 40px;
  border-radius: 8px;
  font-size: 0.9em;
  color: rgba(115, 122, 130, 0.8);
  transition: all 0.2s;
}
.search_content_store_item_btn button:hover,
.search_content_store_item_btn button:active {
  background-color: rgba(255, 114, 153, 1);
  color: white;
}
.search_content_support_item .swiper-slide {
  max-width: 170px;
  flex: 0 0 auto; /* 플렉스박스 아이템이 줄어들지 않도록 설정 */
}
.search_content_support_item_img {
  overflow: hidden;
  border-radius: 8px;
  width: auto !important;
  height: 115px;
}
.search_content_support_item_img>span {
  display: block;
  width: 100%;
}
.search_content_support_item_img img {
  width: 100%;
  height: 115px;
  object-fit: cover;
}
.search_content_title {
  font-size: 1.3em;
}
.search_content_support_box {
  width: 100%;
  max-height: 490px;
  overflow: hidden;
}
.search_content_support_info {
  padding: 10px;
  cursor: pointer;
}
.search_content_support_info>div:nth-of-type(1) {
  display: flex;
  align-items: center;
}
.search_content_support_info>div:nth-of-type(1)>div:nth-of-type(1) {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  border: 50%;
}
.search_content_support_info>div:nth-of-type(1)>div:nth-of-type(1) img {
  width: 100%;
  height: 30px;
  object-fit: cover;
}
.search_content_support_info>div:nth-of-type(1)>div:nth-of-type(2) {
  font-size: 1.1em;
  padding-left: 8px;
}
.search_content_support_info>div:nth-of-type(2) {
  padding: 3px 0;
  font-size: 0.9em;
}
.search_content_support_info>div:nth-of-type(3) {
  font-size: 0.8em;
  padding: 3px 0;
}
.search_content_event_item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.search_content_event_item:hover,
.search_content_event_item:active {
  background-color: rgba(223, 224, 226, 1);
  border-radius: 8px;
}
.search_content_event_item>div:nth-of-type(1) {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  overflow: hidden;
}
.search_content_event_item>div:nth-of-type(1) img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}
.search_content_event_item>div:nth-of-type(2) {
  width: calc(100% - 80px);
  padding-left: 10px;
}
.search_content_event_item>div:nth-of-type(2)>div:nth-of-type(1) {}
.search_content_event_item>div:nth-of-type(2)>div:nth-of-type(2) {font-size: 0.9em; color: rgba(255, 114, 153, 1);}
.search_content_event_item>div:nth-of-type(2)>div:nth-of-type(3) {font-size: 0.8em;}

.search_store_single_box{
  max-height: 5000px;
  overflow: hidden;
}
.search_content_support_single_box {
  width: 100%;
  max-height: 5000px;
  overflow: hidden;
}
.search_content_event_item_box {
  max-height: 420px;
  overflow: hidden;
}

.loading_page_container {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(218, 218, 218, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  top: 0;
  left: 0;
}
.loading_page_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading_page_box img {
  width: 20%;
}

/* 서포트 모달 프리뷰 설정 */
/* 모달 오버레이의 스타일 (배경색 및 투명도 조절) */
.react-responsive-modal-overlay {
  background-color: rgba(0, 0, 0, 0.7) !important; /* 더 어두운 오버레이 */
}

/* 모달 컨테이너의 스타일 (크기, 위치 조정) */
.react-responsive-modal-container {
  max-width: 100% !important; /* 모달 너비 조절 */
  max-height: 100% !important; /* 모달 높이 조절 */
  border-radius: 10px !important; /* 모달 테두리 둥글게 */
  padding: 0 !important; /* 기본 패딩 제거 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 모달 그림자 추가 */
}

.react-responsive-modal-modal {
  width: 70vw !important;
  max-height: 70vh !important;
  padding: 0 !important;
  border-radius: 12px !important;
}

/* 모달 콘텐츠 스타일 (이미지 조정) */
.react-responsive-modal-content {
  padding: 0 !important; /* 기본 패딩 제거 */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 이미지에 대한 스타일 (모달 내부 이미지 조정) */
.react-responsive-modal-modal img {
  width: 100% !important;
  height: 70vh !important;
  border-radius: 10px; /* 이미지 테두리 둥글게 */
  object-fit: cover;
}

/* 닫기 버튼 스타일 수정 */
.react-responsive-modal-closeButton {
  background-color: white !important;
  border-radius: 50%;
  top: -15px;
  right: -15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* 지도 Tab */
.gm-style-iw-tc {
  top: -50px !important;
}
.gm-style .gm-style-iw-c {
  top: -45px !important;
}
.gm-style {
  font-family: "Pretendard", "Noto Sans KR", sans-serif !important; 
}

.staging_page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.staging_page>div {
  width: 80%;
  height: auto;
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0.9em;
  padding: 10px;
  text-align: center;
}
.staging_page>div>div:nth-of-type(1) {
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 5px;
}
.staging_page>div>div:nth-of-type(5) a {
  color: rgba(255, 3, 102, 1) !important;
}
.staging_page>div>div:nth-of-type(10) {
  margin-bottom: 10px;
}
.staging_page_btn {
  width: 100%;
  height: 40px;
  border: none;
  background-color: rgba(255, 3, 102, 1);
  color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.staging_page_btn a {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prev_dailyduck_login_infomation_btn {
  position: absolute;
  top: 80px;
  right: 25px;
}
.prev_dailyduck_login_infomation_btn button {
  border: none;
  background-color: rgba(255, 3, 102, 1);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9em;
}
.prev_dailyduck_login_infomation_modal_container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.prev_dailyduck_login_infomation_modal_box {
  width: 80%;
  height: 80%;
  max-height: 90%;
  background-color: white;
  overflow: hidden;
  border-radius: 4px;
}
.prev_dailyduck_login_infomation_modal_header {
  width: 100%;
  height: 50px;
  box-shadow: rgba(223, 224, 226, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.prev_dailyduck_login_infomation_modal_header>div:nth-of-type(1) {
  font-size: 1.1em;
  font-weight: 500;
}
.prev_dailyduck_login_infomation_modal_header>div:nth-of-type(2) {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
}
.prev_dailyduck_login_infomation_modal_header>div:nth-of-type(2) button {
  border: none;
  background-color: inherit;
}
.prev_dailyduck_login_infomation_modal_content {
  width: 100%;
  height: calc(100% - 50px);
  overflow-y: auto;
  padding: 20px;
  font-size: 0.9em;
}
.prev_dailyduck_login_infomation_modal_content_login_info {
  box-shadow: 0 0 4px 1px rgba(223, 223, 223, 0.6);
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
}
.prev_dailyduck_login_infomation_modal_content_login_info>div:nth-of-type(1) {
  color: rgba(255, 3, 102, 1);
  font-weight: 500;
}
.prev_dailyduck_login_infomation_modal_content_hi {
  color: rgb(163, 133, 0);
  font-weight: 700;
  font-size: 1.1em;
  text-align: center;
}

@media (max-width: 1260px) {
  .page_side {
    width: 10%;
  }
}

@media (max-width: 1000px) {
  .page_back_img {
    /* display: none; */
  }
  .page_back_img img{
    object-fit: cover;
  }
  .page_side {
    display: none;
  }
  .page_image {
    display: none;
  }
}
@media (max-width: 700px) {
  .navigator {
    position: fixed !important;
    width: 100%;
  }
  .venue_reservation {
    position: fixed;
  }
}
@media (max-width: 500px) {
  .page_inner {
    position: relative;
    width: 100%;
  }
  .loading_main {
    width: 100%;
  }
  .btn_box {
    display: flex;
    flex-direction: column;
    
  }
  .google_btn {
    width: 80% !important;
    margin-bottom: 10px;
    margin-right: 0;
  }
  .apple_btn {
    width: 80% !important;
    margin-left: 0;
    margin-top: 10px;
  }
  .google_btn span{
    font-size: 1em;
  }
  .apple_btn span {
    font-size: 1em;
  }
  .venue_footer {
    width: 100% !important;
  }
  .pay {
    width: 100% !important;
  }
  .regionBox {
    width: 100% !important;
  }
  .Date_box{
    width: 100% !important;
  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 3rem !important;
    padding: 0.6rem !important;
  }
  .venue_reservation {
    width: 100% !important;
  }
  .reservation_pay {
    width: 100% !important;
  }
  .filter_detail_box {
    width: 100% !important;
  }
  .filter_detail_category button {
    min-width: 100px !important;
    font-size: 0.7em;
    padding: 10px 25px !important;
  }
  .filter_deep_category button {
    min-width: 100px !important;
    font-size: 0.7em;
    padding: 10px 25px !important;
  }
  /* .heart_box {
    top: 50% !important;
    right: 10px !important;
  } */
  .album_artist_leftbtn {
    display: none;
  }
  .join_detail_header {
    width: 100% !important;
  }
  .payment_reserve_date>div:nth-of-type(2) {
    font-size: 0.8em;
  }
  .use_point_held_box {
    font-size: 0.9em;
  }
  .use_point_box {
    font-size: 0.9em;
  }
}
@media (max-width : 450px) {
  /* .market_banner {
    height: 450px !important;
  } */
  /* .market_banner img {
    height: 450px !important;
  } */
  .venue_img_box>img {
    height: 200px !important;
  }
  /* .swiper_img_box img {
    height: 400px !important;
  } */
}
@media (max-width : 435px) {
  /* .heart_box {
    top: 45% !important;
    right: 10px !important;
  } */
}
@media (max-width : 400px) {
  .venue_img_box>img {
    height: 180px !important;
  }
}
@media (max-width : 360px) {
  /* .market_banner {
    height: 360px !important;
  } */
  /* .market_banner img {
    height: 360px !important;
  } */
  .venue_img_box>img {
    height: 160px !important;
  }
  /* .heart_box {
    top: 40% !important;
    right: 10px !important;
  } */
}

@media (max-width: 820px) {
  .react-calendar__navigation {
    width: 25% !important;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 10px !important;
  }
  /* .react-calendar__tile.react-calendar__month-view__days__day {
    height: 100px !important;
  } */
}

@media (max-width: 530px) {
  .staging_page>div {
    width: 95%;
  }
}
@media (max-width: 520px) {
  .request_drop {
    margin-right: 30px;
  }
}
@media (max-width: 500px) {
  .search_content_papularity_search_txt>div:nth-of-type(2) {
    padding: 0 10px;
  }
  .search_content_support_item .swiper-slide {
    max-width: 150px;
  }
  .search_content_support_item_img {
    height: 100px;
  }
  .search_content_support_item_img img {
    height: 100px;
  }
}
@media (max-width: 450px) {
  .icon_box {
    width: 60px;
    height: 60px;
  }
  /* .icon_box img {
    width: 30px;
    height: 30px;
    object-fit: contain;
  } */
}
@media (max-width: 360px) { 
  .market_title_box {
    font-size: 0.95em;
  }
  .market_sub_title {
    font-size: 0.8em;
  }
  .icon_box {
    width: 50px;
    height: 50px;
    border-radius: 12px;
  }
  /* .icon_box img {
    width: 25px;
    height: 25px;
    object-fit: contain;
  } */
}


@media screen and (max-width: 500px) and (max-height: 700px) {
  .logo_box {
    padding-bottom: 20px;
  }
  .join_box {
    padding-top: 15px;
  }
}


/* TimePickeer */

/* ck editor dropdown */
.ck-dropdown__panel {
  max-height: 200px; /* 드롭다운 최대 높이 */
  overflow-y: auto; /* 세로 스크롤 활성화 */
  scrollbar-width: thin; /* 스크롤바 크기 조정 (Firefox) */
}

.ck-dropdown__panel::-webkit-scrollbar {
  width: 6px; /* 스크롤바 너비 */
}

.ck-dropdown__panel::-webkit-scrollbar-thumb {
  background-color: #aaa; /* 스크롤바 색상 */
  border-radius: 3px; /* 스크롤바 모서리 둥글게 */
}

.ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
  position: static !important;
}