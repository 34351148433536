.market_container {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
}
.market_auth_popup {
    width: 30%;
    height: 20vh;
}
.market_auth_title {
    font-weight: bold;
}
.market_auth_content {
    font-size: 0.8em;
    padding: 5px 20px;
}
.market_auth_btn button {
    width: 100%;
    height: 50px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    border-radius: 12px;
    color: white;
    font-size: 0.9em;
}

.market_main_popularity_search {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 20px 20px;
    border-bottom: 10px solid rgba(248, 248, 248, 1);
}
.market_main_popularity_product {
    width: 100%;
    padding: 10px 20px;
    border-bottom: 10px solid rgba(248, 248, 248, 1);
}
.market_main_popularity_product>div:nth-of-type(1) {
    width: 100%;
    padding: 10px 0;
}
.market_main_popularity_product>div:nth-of-type(1) button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background-color: inherit;
}
.market_main_popularity_search>div:nth-of-type(1) {width: 60%;}
.market_main_popularity_search>div:nth-of-type(2) {width: 40%;}
.market_main_popularity_search_content :global(.slick-list) {height: 24px !important;}
.market_main_popularity_search_content :global(.slick-slide img) {width: 100% !important; height: 15px !important; object-fit: none !important; border: none !important;}
.market_main_popularity_search_content :global(.slick-slide.slick-current) {width: 100% !important; height: auto !important;}
.market_main_popularity_search_box {
    width: 100% !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.market_main_popularity_search_box>div:nth-of-type(1) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 0.9em;
}
.market_main_popularity_search_box>div>div:nth-of-type(1)>div:nth-of-type(1) {width: 10%;}
.market_main_popularity_search_box>div>div:nth-of-type(1)>div:nth-of-type(2) {width: 90%;}

.main_banner_swiper :global(.swiper-pagination) {
    /* bottom: -10px !important; */
    display: none;
}
.banner_container {
    width: 100%;
    position: relative;
}
.banner_pagination_display {
    position: absolute;
    top: 75%;
    right: 10px;
    z-index: 500;
    background-color: rgba(0,0,0,0.5);
    color: white;
    padding: 5px 15px;
    border-radius: 30px;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing :2px;
}
.market_category_select_btn {
    border: none;
    background-color: inherit;
    font-size: 1.4em;
}
.market_main_popularity_swiper {
    margin-left: 0;
}
.market_main_popularity_swiper_box {
    width: 160px !important;
    /* margin-right: 10px; */
}
.market_main_popularity_box {
    display: inline-block;
    width: 160px !important;
    height: auto;
    overflow: hidden;
    padding: 10px;
    transition: all 0.2s;
    cursor: pointer;
}

.market_main_popularity_box>div:nth-of-type(1) {
    /* width: 150px; */
    height: 140px;
    overflow: hidden;
    border-radius: 8px;
}
.market_main_popularity_box>div:nth-of-type(1) img {
    width: 100%;
    height: 140px;
    object-fit: cover;
}
.market_main_popularity_box>div:nth-of-type(2) {
    padding: 10px 0;
}
.market_main_popularity_box>div:nth-of-type(2)>div:nth-of-type(1) {font-size: 0.8em; color: rgb(189, 189, 189);}
.market_main_popularity_box>div:nth-of-type(2)>div:nth-of-type(2) {
    white-space: normal; 
    display: -webkit-box; 
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical; 
    overflow: hidden; 
    font-size: 0.9em;
    line-height: 18px;
    height: 35px;
}
.market_main_popularity_box>div:nth-of-type(2)>div:nth-of-type(3) {
    display: flex; 
    align-items: center;
    padding: 5px 0;
}
.market_main_popularity_box>div:nth-of-type(2)>div:nth-of-type(3)>div:nth-of-type(1) {
    color: rgba(255, 3, 102, 1);
}
.market_main_popularity_box>div:nth-of-type(2)>div:nth-of-type(3)>div:nth-of-type(2) {
    text-decoration: line-through;
    color: rgb(189, 189, 189);
    font-size: 0.8em;
    padding-left: 10px;
}
.freeDelivery {
    background-color: rgb(226, 226, 226);
    color: rgb(165, 165, 165);
    font-size: 0.7em;
    padding: 3px 5px;
    border-radius: 4px;
}
.md {
    background-color: rgba(228, 246, 234, 1);
    color: rgb(0, 80, 13);
    font-size: 0.7em;
    padding: 3px 5px;
    border-radius: 4px;
}
.best {
    background-color: rgb(255, 248, 188);
    color: rgb(255, 102, 0);
    font-size: 0.7em;
    padding: 3px 5px;
    border-radius: 4px;
}
.hot {
    background-color: rgba(255, 239, 243, 1);
    color: rgba(255, 3, 102, 1);
    font-size: 0.7em;
    padding: 3px 5px;
    border-radius: 4px;
}

/* 인기 수요조사 */
.market_main_popularity_demand_survey {
    width: 100%;
    padding: 10px 20px;
    border-bottom: 10px solid rgba(248, 248, 248, 1);
}
.market_main_popularity_demand_survey>div:nth-of-type(1) {
    width: 100%;
    padding: 10px 0;
}
.market_main_popularity_demand_survey>div:nth-of-type(1) button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background-color: inherit;
}
.market_main_demand_servey_swiper {
    margin-left: 0;
}
.market_main_demand_servey_swiper_box {
    width: 160px !important;
    /* margin-right: 10px; */
}
.market_main_demand_servey_box {
    display: inline-block;
    width: 160px !important;
    height: auto;
    overflow: hidden;
    padding: 10px;
    transition: all 0.2s;
}

.market_main_demand_servey_box:hover {
    background-color: rgba(255, 3, 102, 0.1);
    border-radius: 4px;
}
.market_main_demand_servey_box>div:nth-of-type(1) {
    /* width: 150px; */
    height: 140px;
    overflow: hidden;
    border-radius: 8px;
}
.market_main_demand_servey_box>div:nth-of-type(1) img {
    width: 100%;
    height: 140px;
    object-fit: cover;
}
.market_main_demand_servey_box>div:nth-of-type(2) {
    padding: 10px 0;
}
.market_main_demand_servey_box>div:nth-of-type(2)>div:nth-of-type(1) {font-size: 0.8em; color: rgb(189, 189, 189);}
.market_main_demand_servey_box>div:nth-of-type(2)>div:nth-of-type(2) {
    white-space: normal; 
    display: -webkit-box; 
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical; 
    overflow: hidden; 
    font-size: 0.9em;
    line-height: 18px;
}
.market_main_demand_servey_box>div:nth-of-type(2)>div:nth-of-type(3) {
    display: flex; 
    align-items: center;
    padding: 5px 0;
}
.market_main_demand_servey_box>div:nth-of-type(2)>div:nth-of-type(3)>div:nth-of-type(1) {
    color: rgba(255, 3, 102, 1);
}
.market_main_demand_servey_box>div:nth-of-type(2)>div:nth-of-type(4) {
    font-size: 0.7em;
    color: rgba(91, 97, 103, 1);
}
.market_main_demand_servey_box>div:nth-of-type(2)>div:nth-of-type(6) span {
    background-color: rgba(255, 239, 243, 1);
    color: rgba(255, 114, 153, 1);
    font-size: 0.8em;
    padding: 3px 5px;
    border-radius: 4px;
}

/* 인기리뷰 */
.market_main_popular_reviews {
    width: 100%;
    padding: 10px 20px;
    border-bottom: 10px solid rgba(248, 248, 248, 1);
}
.market_main_popular_reviews>div:nth-of-type(1) {
    width: 100%;
    padding: 10px 0;
}
.market_main_popular_reviews>div:nth-of-type(1) button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background-color: inherit;
}
.market_main_popular_reviews_swiper {
    margin-left: 0;
}
.market_main_popular_reviews_swiper_box {
    width: 80px !important;
    margin-right: 10px;
    margin-left: 5px;
}
.market_main_popular_reviews_box {
    display: inline-block;
    width: 80px !important;
    height: auto;
    overflow: hidden;
    /* padding: 10px; */
    transition: all 0.2s;
    border: none;
    background-color: inherit;
}
.market_main_popular_reviews_box:hover {
    background-color: rgba(255, 3, 102, 0.1);
    border-radius: 4px;
}
.market_main_popular_reviews_box>div:nth-of-type(1) {
    /* width: 150px; */
    height: 80px;
    overflow: hidden;
    border-radius: 8px;
}
.market_main_popular_reviews_box>div:nth-of-type(1) img {
    width: 100%;
    height: 80px;
    object-fit: cover;
}
.preview_content {
    width: 100%;
    height: 450px;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 10px;
}
.preview_content img {
    width: 100%;
    height: 450px;
    object-fit: cover;
}

.market_mobile_category {
    display: none;
    margin-top: 6px;
}
.market_category_pc {
    display: block;
    padding: 10px 20px;
    text-align: center;
    margin-top: 3px;
}

.market_terms_modal_container {
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}
.market_terms_modal_box {
    width: 95%;
    height: 70%;
    border-radius: 4px;
    background-color: white;
    overflow: hidden;
}
.market_terms_modal_box>div:nth-of-type(1) {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0 0 4px 1px rgba(228, 246, 234, 1);

}
.market_terms_modal_box>div:nth-of-type(1)>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.market_terms_modal_box>div:nth-of-type(1)>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.market_terms_modal_box>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.market_terms_modal_box>div:nth-of-type(2) {
    width: 100%;
    height: calc(100% - 40px);
    padding: 10px;
    overflow-y: auto;
}
.market_footer_terms_container {
    width: 100%;
    margin-bottom: 50px;
}
.market_footer_terms_box {
    width: 100%;
    background-color: rgb(65, 65, 65);
    padding: 20px 20px 20px 20px;
}
.market_footer_terms_box button {
    font-size: 0.9em;
    border: none;
    background-color: inherit;
}
.market_footer_logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.market_footer_logo>div:nth-of-type(1) {
    width: 150px;
    height: auto;
    overflow: hidden;
}
.market_footer_logo>div:nth-of-type(1) img {
    width: 100%;
    height: auto;
    object-fit: cover;
}
.market_footer_company_info {
    font-size: 0.8em;
    color: white;
    padding: 20px 0;
}
.market_footer_company_info_tel_customer {
    padding: 20px 0 10px 0;
    font-size: 1.1em;
}
.market_footer_company_info_tel_customer>div:nth-of-type(1) {
    width: 100%;
    display: flex;
    align-items: center;
}
.market_footer_company_info_tel_customer>div:nth-of-type(1)>div:nth-of-type(1) {
    width: 15%;
}
.market_footer_company_info_tel_customer>div:nth-of-type(1)>div:nth-of-type(2) {
    width: 85%;
    display: flex;
    align-items: center;
}
.market_footer_company_info_tel_customer>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2) {
    margin-left: 5px;
}
.market_footer_company_info_tel_customer>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2) button {
    border: none;
    background-color: white;
    border-radius: 4px;
}
.market_footer_company_info_tel_customer>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2) button span {
    color: rgba(91, 97, 103, 1);
}
.market_footer_company_info_tel_customer>div:nth-of-type(2) {
    display: flex;
    align-items: center;
}
.market_footer_company_info_tel_customer>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 15%;
}
.market_footer_company_info_tel_customer>div:nth-of-type(2)>div:nth-of-type(2) {
    width: 85%;
}
.market_footer_sns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-bottom: 20px;
}
.market_footer_sns>a {
    display: block;
    width: 22px;
    height: 22px;
    overflow: hidden;
    margin: 0 10px 0 0;
}
.market_footer_sns>a img {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 100 / 100;
}
.market_footer_terms_info {
    width: 100%;
    padding: 10px 0 20px 0;
}
.market_footer_terms_info button {
    color: white;
    font-size: 0.8em;
    border: 1px solid white;
    border-radius: 4px;
    padding: 2px 5px;
}
.market_footer_terms_info_first,
.market_footer_terms_info_second {
    display: flex;
    align-items: center;
    justify-content: start;
}
.market_footer_terms_info_second {
    margin-top: 10px;
}
.market_footer_content_warning {
    width: 100%;
    color: white;
    font-size: 0.7em;
}
.market_footer_terms_info_first>div,
.market_footer_terms_info_second>div {
    margin-right: 10px;
}
.market_footer_content_warning>div:nth-of-type(1) {
}
.market_footer_content_warning>div:nth-of-type(2) {
    padding-top: 10px;
}
.market_footer_content_warning>div:nth-of-type(3) {
    padding-top: 10px;
}
.market_footer_content_warning>div:nth-of-type(3) button {
    color: white;
    font-size: 1em;
    border: 1px solid white;
    border-radius: 4px;
    padding: 2px 5px;
}
.market_footer_corp {
    width: 100%;
    text-align: center;
    color: white;
    font-size: 0.8em;
    padding: 10px;
}
.market_contents_warning_modal_container {
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}
.market_contents_warning_modal_box {
    width: 95%;
    height: 70%;
    border-radius: 4px;
    background-color: white;
    overflow: hidden;
}
.market_contents_warning_modal_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(228, 246, 234, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.market_contents_warning_modal_header>div:nth-of-type(1) {
    font-size: 1.2em;
    font-weight: 500;
}
.market_contents_warning_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.market_contents_warning_modal_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.market_contents_warning_modal_img {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.market_contents_warning_modal_img>div {
    width: 100px;
    height: 100px;
    overflow: hidden;
}
.market_contents_warning_modal_img>div img {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 100/100;
}
.market_contents_warning_modal_content_box {
    width: 100%;
    height: calc(100% - 200px);
    overflow-y: auto;
}
.market_contents_warning_modal_content {
    width: 100%;
    padding: 20px;
}
.market_contents_warning_modal_content>div {
    width: 100%;
    border-bottom: 1px solid rgba(228, 246, 234, 1);
    padding: 10px 0;
}
.market_contents_warning_modal_content>div>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.market_contents_warning_modal_content>div>div:nth-of-type(2) {
    font-size: 0.9em;
}
.market_contents_warning_modal_footer {
    width: 100%;
    font-size: 0.9em;
    color: rgba(91, 97, 103, 1);
    text-align: center;
    padding: 20px;
}

@media (max-width: 600px) {
    .banner_pagination_display {
      top: 71.5%;
    }
}

@media (max-width : 550px) {
    /* .market_footer_terms_info {
        padding: 20px 20px;
    } */
    .market_footer_company_info_tel_customer>div:nth-of-type(1)>div:nth-of-type(1),
    .market_footer_company_info_tel_customer>div:nth-of-type(2)>div:nth-of-type(1) {
        width: 20%;
    }
    .market_footer_company_info_tel_customer>div:nth-of-type(1)>div:nth-of-type(2),
    .market_footer_company_info_tel_customer>div:nth-of-type(2)>div:nth-of-type(2) {
        width: 80%;
    }
}

@media (max-width : 500px) {
    .preview_content {
        height: 400px;
    }
    .preview_content img {
        height: 400px;
    }
    .market_mobile_category {
        display: block;
    }
    .market_category_pc {
        display: none;
    }
    .banner_pagination_display {
        top: 68.5%;
    }
    /* .market_footer_terms_info {
        padding: 20px 10px;
    } */
}
@media (max-width : 470px) {
    /* .market_footer_terms_info {
        padding: 20px 0;
    } */
}
@media (max-width : 450px) {
    .market_main_popularity_swiper_box,
    .market_main_demand_servey_swiper_box,
    .market_main_popularity_swiper_box {
        width: 150px !important;
    }
    .market_main_popularity_box,
    .market_main_demand_servey_box {
        width: 150px !important;
    }

    .market_main_popularity_box>div:nth-of-type(1),
    .market_main_demand_servey_box>div:nth-of-type(1) {
        height: 125px;
    }
    .market_main_popularity_box>div:nth-of-type(1) img,
    .market_main_demand_servey_box>div:nth-of-type(1) img {
        height: 125px;
    }
    .market_category_select_btn {
        font-size: 1.2em;
    }
    .banner_pagination_display {
        top: 68%;
        padding: 3px 8px;
        font-size: 0.8em;
    }
}
@media (max-width : 420px) {
    .market_footer_company_info_tel_customer>div:nth-of-type(1)>div:nth-of-type(1),
    .market_footer_company_info_tel_customer>div:nth-of-type(2)>div:nth-of-type(1) {
        width: 25%;
    }
    .market_footer_company_info_tel_customer>div:nth-of-type(1)>div:nth-of-type(2),
    .market_footer_company_info_tel_customer>div:nth-of-type(2)>div:nth-of-type(2) {
        width: 75%;
    }
}
@media (max-width : 400px) {
    .market_main_popularity_swiper_box,
    .market_main_demand_servey_swiper_box,
    .market_main_popularity_swiper_box {
        width: 140px !important;
    }
    .market_main_popularity_box,
    .market_main_demand_servey_box {
        width: 140px !important;
    }

    .market_main_popularity_box>div:nth-of-type(1),
    .market_main_demand_servey_box>div:nth-of-type(1) {
        height: 120px;
    }
    .market_main_popularity_box>div:nth-of-type(1) img,
    .market_main_demand_servey_box>div:nth-of-type(1) img {
        height: 120px;
    }
    .market_main_popularity_box>div:nth-of-type(2)>div:nth-of-type(3),
    .market_main_demand_servey_box>div:nth-of-type(2)>div:nth-of-type(3) {
        font-size: 0.9em;
    }
    .preview_content {
        height: 330px;
    }
    .preview_content img {
        height: 330px;
    }    
    .banner_pagination_display {
        top: 67%;
    }
}
@media (max-width : 380px) {
    .market_main_popularity_swiper_box,
    .market_main_demand_servey_swiper_box,
    .market_main_popularity_swiper_box {
        width: 125px !important;
    }
    .market_main_popularity_box,
    .market_main_demand_servey_box {
        width: 125px !important;
    }

    .market_main_popularity_box>div:nth-of-type(1),
    .market_main_demand_servey_box>div:nth-of-type(1) {
        height: 100px;
    }
    .market_main_popularity_box>div:nth-of-type(1) img,
    .market_main_demand_servey_box>div:nth-of-type(1) img {
        height: 100px;
    }
    .market_main_popularity_box>div:nth-of-type(2)>div:nth-of-type(3),
    .market_main_demand_servey_box>div:nth-of-type(2)>div:nth-of-type(3) {
        font-size: 0.9em;
    }
    .preview_content {
        height: 300px;
    }
    .preview_content img {
        height: 300px;
    }
    .market_main_popular_reviews_swiper_box {
        width: 60px !important;
    }
    .market_main_popular_reviews_box {
        width: 60px !important;
    }
    .market_main_popular_reviews_box>div:nth-of-type(1) {
        height: 60px;
    }
    .market_main_popular_reviews_box>div:nth-of-type(1) img {
        height: 60px;
    }
    .market_main_popularity_box>div:nth-of-type(2)>div:nth-of-type(1) {
        white-space: normal; 
        display: -webkit-box; 
        -webkit-line-clamp: 1; 
        -webkit-box-orient: vertical; 
        overflow: hidden; 
    }
    .market_main_popularity_box>div:nth-of-type(2)>div:nth-of-type(3),
    .market_main_demand_servey_box>div:nth-of-type(2)>div:nth-of-type(3) {
        font-size: 0.8em;
    }
    .banner_pagination_display {
        top: 66%;
    }
}
@media (max-width : 360px) {
    .market_category_select_btn {
        font-size: 1.1em;
    }
}