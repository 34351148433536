.admin_container {
    position: relative;
    width: 100%;
    min-height: 100vh;
}
.admin_login_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 463px;
    height: 550px;
    box-shadow: 0 0 6px 3px rgba(241, 242, 243, 1);
}
.admin_login_img_box {
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin_login_img_box img {
    width: 92px;
    height: 92px;
}
.admin_login_input_box input {
    width: 100%;
    height: 30px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(196, 199, 202, 1);
    padding-left: 5px;
    font-size: 0.9em;
}
.admin_login_input_box input::placeholder {
    color: rgba(169, 173, 178, 1);
}
.admin_login_btn_box {
    width: 100%;
    padding: 20px;
}
.admin_login_btn_box button {
    width: 100%;
    height: 50px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 12px;
}
.admin_login_error_box {
    position: absolute;
    bottom: -2rem;
    
}
.admin_login_error {
    font-size: 0.7em;
    color: red;
}
.admin_login_pw_change_btnbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
}
.admin_login_pw_change_btnbox>div:nth-of-type(2) {
    display: flex;
    align-items: end;
    justify-content: flex-end;
    flex-direction: column;
}
.admin_login_pw_change_btnbox button {
    border: none;
    background-color: inherit;
    font-size: 0.8em;
    transition: all 0.3s;
    color: rgba(169, 173, 178, 1)
}
.admin_login_pw_change_btnbox button:hover, .admin_login_pw_change_btnbox button:active {
    color: rgba(255, 3, 102, 1);
}
.admin_login_pwchange_container {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_login_pwchange_box {
    width: 463px;
    height: 637px;
    box-shadow: 0 0 6px 3px rgba(241, 242, 243, 1);
    padding: 20px;
}
.admin_login_pwchange_box > div {
    width: 100%;
    position: relative;
}
.admin_login_pwchange_title {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4em;
    font-weight: 500;
}
.admin_login_pwchange_content {
    width: 100%;
    height: calc(100% - 170px);
    overflow-y: auto;
}
.admin_login_pwchange_footer {
    width: 100%;
    height: 70px;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_login_pwchange_btn_box1,
.admin_login_pwchange_btn_box2 {
    width: 45%;
    padding: 0 10px;
}
.admin_login_pwchange_btn_box1 button {
    width: 100%;
    height: 50px;
    border: none;
}
.admin_pwchange_active {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.admin_login_pwchange_btn_box2 button {
    width: 100%;
    height: 50px;
    border: 1px solid rgba(169, 173, 178, 1);
    background-color: inherit;
    color: black;
}
.admin_swal_title {
    font-size: 1.3em;
}
.admin_swal_text {
    font-size: 0.9em !important;
}
.admin_btn_confirm {
    border-radius: 0 !important;
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}
.admin_btn_cancel {
    background-color: inherit !important;
    border-radius: 0 !important;
    border: 1px solid rgba(169, 173, 178, 1) !important;
}
.success_admin_btn {
    border-radius: 0 !important;
    width: 100px;
    background-color: rgba(255, 3, 102, 1) !important;
}

/* 셀러 회원가입 */
.admin_join_section_loading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
}
.admin_join_section_loading>div {
    width: 100px;
    height: auto;
}

.admin_join_section_loading>div img {
    width: 100%;
    height: auto;
    object-fit: cover;
}
.admin_join_section_box {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: white;
}
.admin_join_section_close_box {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 30;
}
.admin_join_section_close_box button {
    border: none;
    background-color: inherit;
}
.admin_join_section_close_box button>span {
    font-size: 1.8em;
}
.admin_join_section_box>div {
    position: absolute;
    width: 463px;
    height: 800px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    border-radius: 4px;
    overflow-y: scroll;
}
.admin_join_section_header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    height: 100px;
}
.admin_join_section_header img {
    width: 212px;
    height: 31px;
    object-fit: cover;
}
.admin_join_section_content {
    margin-top: 130px;
    font-size: 0.9em;
}
.admin_join_section_content_essential {
    color: rgba(255, 3, 102, 1);
}
.admin_join_section_content_id_box {
    /* padding: 20px 0; */
}
.admin_join_section_content_id_box>div:nth-of-type(1) {
    display: flex;
    align-items: center;
    width: 100%;
}
.admin_join_section_content_id_box>div:nth-of-type(1)>div:nth-of-type(1) {
    width: 30%;
}
.admin_join_section_content_id_box>div:nth-of-type(1)>div:nth-of-type(2) {
    width: 70%;
    display: flex;
    align-items: center;
}
.admin_join_section_content_id_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 70%;
}
.admin_join_section_content_id_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) input {
    width: 100%;
    border-radius: 4px;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    padding-left: 10px;
    font-size: 0.9em;
}
.admin_join_section_content_id_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_join_section_content_id_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) input::placeholder {
    font-size: 0.9em;
}
.admin_join_section_content_id_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2) {
    width: 30%;
    padding: 5px;
}
.admin_join_section_content_id_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2) button {
    width: 100%;
    height: 40px;
    border: none;
    background-color: rgba(223, 224, 226, 1);
    font-size: 0.9em;
    border-radius: 4px;
    transition: all 0.2s;
}
.admin_join_section_content_id_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}
.admin_join_section_content_id_box>div:nth-of-type(2) {
    text-align: right;
    font-size: 0.9em;
    padding: 5px 0;
}
/* support */
.support_admin_join_section_content_id_box {
    /* padding: 20px 0; */
}
.support_admin_join_section_content_id_box>div:nth-of-type(1) {
    /* display: flex;
    align-items: center; */
    width: 100%;
}
.support_admin_join_section_content_id_box>div:nth-of-type(1)>div:nth-of-type(1) {
    width: 100%;
}
.support_admin_join_section_content_id_box>div:nth-of-type(1)>div:nth-of-type(2) {
    width: 100%;
    display: flex;
    align-items: center;
}
.support_admin_join_section_content_id_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 70%;
}
.support_admin_join_section_content_id_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) input {
    width: 100%;
    border-radius: 4px;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    padding-left: 10px;
    font-size: 0.9em;
}
.support_admin_join_section_content_id_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.support_admin_join_section_content_id_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) input::placeholder {
    font-size: 0.9em;
}
.support_admin_join_section_content_id_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2) {
    width: 30%;
    padding: 5px;
}
.support_admin_join_section_content_id_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2) button {
    width: 100%;
    height: 40px;
    border: none;
    background-color: rgba(223, 224, 226, 1);
    font-size: 0.9em;
    border-radius: 4px;
    transition: all 0.2s;
}
.support_admin_join_section_content_id_box>div:nth-of-type(2) {
    text-align: right;
    font-size: 0.9em;
    padding: 5px 0;
}
.support_admin_join_section_content_id_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}
.support_admin_join_section_content_phone_box>div:nth-of-type(1) {
    /* display: flex;
    align-items: center; */
    width: 100%;
}
.support_admin_join_section_content_phone_box>div:nth-of-type(1)>div:nth-of-type(1) {
    width: 100%;
}
.support_admin_join_section_content_phone_box>div:nth-of-type(1)>div:nth-of-type(2) {
    width: 100%;
    display: flex;
    align-items: center;
}
.support_admin_join_section_content_phone_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) {width: 60%;}
.support_admin_join_section_content_phone_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2) {width: 40%; padding: 0 5px;}
.support_admin_join_section_content_phone_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) input {
    width: 100%;
    border: 1px solid rgba(196, 199, 202, 1);
    height: 40px;
    border-radius: 4px;
    padding-left: 10px;
    font-size: 0.9em;
}
.support_admin_join_section_content_phone_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.support_admin_join_section_content_phone_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2) button {
    border: none;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: rgba(223, 224, 226, 1);
    font-size: 0.9em;
}

/* ------- */
.admin_join_section_content_email_box{
    position: relative;
    padding: 20px 0;
}
.admin_join_section_content_email_box>div:nth-of-type(1) {
    display: flex;
    align-items: center;
    width: 100%;
}
.admin_join_section_content_email_box>div:nth-of-type(1)>div:nth-of-type(1) {width: 30%;}
.admin_join_section_content_email_box>div:nth-of-type(1)>div:nth-of-type(2) {width: 70%;}
.admin_join_section_content_email_box>div:nth-of-type(1)>div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    font-size: 0.9em;
    padding-left: 10px;
}
.admin_join_section_content_email_box>div:nth-of-type(1)>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_join_section_content_email_box>div:nth-of-type(2) {
    font-size: 0.9em;
    display: flex;
    justify-content: end;
}
.admin_join_section_content_email_auth_btn {
    position: absolute;
    top: 25px;
    right: 10px;
    text-align: right;
}
.admin_join_section_content_email_auth_btn button {
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 0.9em;
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.admin_join_content_auth_confirm {
    background-color: rgba(223, 224, 226, 1) !important;
    color: rgba(169, 173, 178, 1) !important;
}
.admin_join_section_content_email_auth_number_box {
    display: flex;
    align-items: center;
    width: 100%;
}
.admin_join_section_content_email_auth_number_box>div:nth-child(1) {width: 30%;}
.admin_join_section_content_email_auth_number_box>div:nth-child(2) {width: 70%; display: flex; align-items: center;}
.admin_join_section_content_email_auth_number_box>div:nth-child(2)>div:nth-of-type(1) {width: 70%;}
.admin_join_section_content_email_auth_number_box>div:nth-child(2)>div:nth-of-type(1) input {
    width: 100%;
    border: 1px solid rgba(196, 199, 202, 1);
    height: 40px;
    border-radius: 4px;
    padding-left: 10px;
}
.admin_join_section_content_email_auth_number_box>div:nth-child(2)>div:nth-of-type(2) {width: 30%; padding: 0 5px;}
.admin_join_section_content_email_auth_number_box>div:nth-child(2)>div:nth-of-type(2) button {
    width: 100%;
    height: 40px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    font-size: 0.9em;
    color: white;
    border-radius: 4px;
}
.admin_join_section_content_phone_box>div:nth-of-type(1) {
    display: flex;
    align-items: center;
    width: 100%;
}
.admin_join_section_content_phone_box>div:nth-of-type(1)>div:nth-of-type(1) {
    width: 30%;
}
.admin_join_section_content_phone_box>div:nth-of-type(1)>div:nth-of-type(2) {
    width: 70%;
    display: flex;
    align-items: center;
}
.admin_join_section_content_phone_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) {width: 60%;}
.admin_join_section_content_phone_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2) {width: 40%; padding: 0 5px;}
.admin_join_section_content_phone_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) input {
    width: 100%;
    border: 1px solid rgba(196, 199, 202, 1);
    height: 40px;
    border-radius: 4px;
    padding-left: 10px;
    font-size: 0.9em;
}
.admin_join_section_content_phone_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_join_section_content_phone_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2) button {
    border: none;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: rgba(223, 224, 226, 1);
    font-size: 0.9em;
}
.admin_join_section_content_phone_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white;
}
.admin_join_section_content_phone_certification_success {
    background-color: rgba(223, 224, 226, 1) !important;
    color: rgba(196, 199, 202, 1) !important;
}
.admin_join_section_content_phone_confirm_box {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 0;
}
.admin_join_section_content_phone_confirm_box>div:nth-of-type(1) {width: 30%;}
.admin_join_section_content_phone_confirm_box>div:nth-of-type(2) {width: 70%; display: flex; align-items: center;}
.admin_join_section_content_phone_confirm_box>div:nth-of-type(2)>div:nth-of-type(1) {width: 60%;}
.admin_join_section_content_phone_confirm_box>div:nth-of-type(2)>div:nth-of-type(2) {width: 40%; font-size: 0.9em;}
.admin_join_section_content_phone_confirm_box>div:nth-of-type(2)>div:nth-of-type(1) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    font-size: 0.9em;
    padding-left: 10px;
}
.admin_join_section_content_phone_confirm_box>div:nth-of-type(2)>div:nth-of-type(2) {
    padding: 0 5px;
}
.admin_join_section_content_phone_confirm_box>div:nth-of-type(2)>div:nth-of-type(2) button {
    width: 100%;
    background-color: rgba(223, 224, 226, 1);
    color: rgba(196, 199, 202, 1);
    border: none;
    height: 40px;
    border-radius: 4px;
}
.admin_password_change_phone_box {
    margin-top: 20px;
}
.admin_new_password_info_txt {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 0.9em;
}
.admin_new_password_info_txt>div:nth-of-type(1) span:nth-of-type(1) {
    font-weight: 700;
    color: rgba(255, 114, 153, 1);
}
.admin_join_section_content_phone_confirm_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}
.admin_join_section_content_pw_box {
    padding: 20px 0;
}
.admin_join_section_content_pw_input_box {
    display: flex;
    align-items: center;
    width: 100%;
}
.admin_join_section_content_pw_input_box>div:nth-of-type(1) {width: 30%;}
.admin_join_section_content_pw_input_box>div:nth-of-type(2) {width: 70%; position: relative; display: inline-block}
.admin_join_section_content_pw_input_box>div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding-left: 10px;
    font-size: 0.9em;
}
.admin_join_section_content_pw_input_box>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_join_section_content_pw_input_box>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
}
.admin_join_section_content_pw_input_check {
    display: flex;
    justify-content: end;
    font-size: 0.8em;
}
.admin_join_section_content_pw_confirm_input_box {
    padding: 20px 0;
}
.admin_join_section_content_pw_confirm_input {
    display: flex;
    align-items: center;
    width: 100%;
}
.admin_join_section_content_pw_confirm_input>div:nth-of-type(1) {width: 30%;}
.admin_join_section_content_pw_confirm_input>div:nth-of-type(2) {width: 70%; position: relative; display: inline-block}
.admin_join_section_content_pw_confirm_input>div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding-left: 10px;
    font-size: 0.9em;
}
.admin_join_section_content_pw_confirm_input>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_join_section_content_pw_confirm_input>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
}
.admin_join_section_content_pw_confirm_input_check_txt {
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 0.9em;
}
.admin_join_section_business_person_box {
    padding: 10px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    width: 100%;
    display: flex;
    align-items: center;
} 
.admin_join_section_business_person_box>div:nth-of-type(1) {
    width: 30%;
}
.admin_join_section_business_person_box>div:nth-of-type(2) {
    width: 70%;
    display: flex;
    align-items: center;
}
.admin_join_section_business_person_box>div:nth-of-type(2)>div:nth-of-type(1) {
    display: flex;
    align-items: center;
}
.admin_join_section_business_person_box>div:nth-of-type(2)>div:nth-of-type(2) {
    padding-left: 20px;
    display: flex;
    align-items: center;
}
.admin_join_section_business_person_box>div:nth-of-type(2)>div:nth-of-type(1) label,
.admin_join_section_business_person_box>div:nth-of-type(2)>div:nth-of-type(2) label {
    padding-left: 3px;
}
.admin_join_section_business_person_box input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.admin_join_section_business_person_box input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}

.admin_join_section_business_person_box input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}

.admin_join_section_business_person_box input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}

.admin_join_section_business_header {
    font-size: 1.2em;
    padding: 10px 0;
}
.admin_join_section_business_number, .admin_join_section_representative,
.admin_join_section_business_name, .admin_join_section_business_type,
.admin_join_section_business_sector {
    display: flex;
    align-items: center;
    padding: 10px 0
}
.admin_join_section_business_number>div:nth-of-type(1), .admin_join_section_representative>div:nth-of-type(1),
.admin_join_section_business_name>div:nth-of-type(1), .admin_join_section_business_type>div:nth-of-type(1),
.admin_join_section_business_sector>div:nth-of-type(1) {
    width: 30%;
}
.admin_join_section_business_number>div:nth-of-type(2), .admin_join_section_representative>div:nth-of-type(2),
.admin_join_section_business_name>div:nth-of-type(2), .admin_join_section_business_type>div:nth-of-type(2),
.admin_join_section_business_sector>div:nth-of-type(2) {
    width: 70%;
}
.admin_join_section_business_number>div:nth-of-type(2) input, .admin_join_section_representative>div:nth-of-type(2) input,
.admin_join_section_business_name>div:nth-of-type(2) input, .admin_join_section_business_type>div:nth-of-type(2) input,
.admin_join_section_business_sector>div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding-left: 10px;
}
.admin_join_section_business_number>div:nth-of-type(2) input:focus, .admin_join_section_representative>div:nth-of-type(2) input:focus,
.admin_join_section_business_name>div:nth-of-type(2) input:focus, .admin_join_section_business_type>div:nth-of-type(2) input:focus,
.admin_join_section_business_sector>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
    font-size: 0.9em;
}
.admin_join_section_registration_number {
    width: 100%;
    display: flex;
    align-items: center;
}
.admin_join_section_registration_number>div:nth-of-type(1) {width: 30%;}
.admin_join_section_registration_number>div:nth-of-type(2) {
    width: 70%;
    display: flex;
    align-items: center;
}
.admin_join_section_registration_number>div:nth-of-type(2)>div:nth-of-type(1) {width: 45%;}
.admin_join_section_registration_number>div:nth-of-type(2)>div:nth-of-type(2) {width: 10%; text-align: center;}
.admin_join_section_registration_number>div:nth-of-type(2)>div:nth-of-type(3) {width: 45%;}
.admin_join_section_registration_number>div:nth-of-type(2)>div input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding: 0 10px;
    font-size: 0.9em;
}
.admin_join_section_registration_number>div:nth-of-type(2)>div input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.admin_join_section_registration_number_text {
    font-size: 0.8em;
}
.admin_join_section_business_addr {
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.admin_join_section_business_addr>div:nth-of-type(1) {width: 30%;}
.admin_join_section_business_addr>div:nth-of-type(2) {width: 70%;}
.admin_join_section_business_addr>div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    background-color: inherit;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    font-size: 0.9em;
    transition: all 0.2s;
}
.admin_join_section_business_addr>div:nth-of-type(2) input:hover {
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_join_section_business_addr_box1 {
    display: flex;
    align-items: center;
    padding: 5px 0;
}
.admin_join_section_business_addr_box1 input {
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    height: 40px;
    font-size: 0.9em;
    padding-left: 10px;
    margin: 0 5px;
}
.admin_join_section_business_addr_box1>input:nth-of-type(1) {width: 30%;}
.admin_join_section_business_addr_box1>input:nth-of-type(2) {width: 70%;}

.admin_join_section_business_addr_box2 {
    display: flex;
    align-items: center;
    padding: 5px 0;
}
.admin_join_section_business_addr_box2 input {
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    height: 40px;
    font-size: 0.9em;
    padding-left: 10px;
    margin: 0 5px;
}
.admin_join_section_business_addr_box2>input:nth-of-type(1) {width: 70%;}
.admin_join_section_business_addr_box2>input:nth-of-type(2) {width: 30%;}
.admin_join_section_business_addr_box1 input:focus, .admin_join_section_business_addr_box2 input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.admin_join_section_business_report_number {
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.admin_join_section_business_report_number>div:nth-of-type(1) {width: 30%;}
.admin_join_section_business_report_number>div:nth-of-type(2) {width: 70%;}
.admin_join_section_business_report_number>div:nth-of-type(2) input {
    width: 100%;
    border: 1px solid rgba(196, 199, 202, 1);
    height: 40px;
    border-radius: 4px;
    padding-left: 10px;
}
.admin_join_section_business_report_number>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_join_section_business_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 0 0;
}
.admin_join_section_business_btn>div:nth-of-type(1) {width: 100%; padding: 0 5px;}
.admin_join_section_business_btn>div:nth-of-type(2) {width: 100%; padding: 0 5px;}
.admin_join_section_business_btn>div:nth-of-type(1) button {
    width: 100%;
    height: 45px;
    border-radius: 4px;
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
}
.admin_join_section_business_btn>div:nth-of-type(2) button {
    width: 100%;
    height: 45px;
    border-radius: 4px;
    border: 1px solid rgba(196, 199, 202, 1);
    /* background-color: inherit; */
}
.admin_join_section_business_btn_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}
.admin_join_content_img_preview {
    width: 100px;
    height: 100px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    overflow: hidden;
    margin: 5px 0;
    position: relative;
}
.admin_join_content_img_preview_delete {
    position: absolute;
    top: 5px;
    z-index: 10;
    border: none;
    background-color: white;
    border-radius: 50%;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 5px;
}
.admin_join_content_img_preview_delete span {
    font-size: 1.3em;
}
.admin_join_content_img_span {
    font-size: 0.8em;
    color: rgba(255, 3, 102, 1);
}
.admin_join_content_bank_select_box {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.admin_join_content_bank_select_box>div:nth-of-type(1) {
    width: 30%;
}
.admin_join_content_bank_select_box>div:nth-of-type(2) {
    width: 70%;
}
.admin_join_content_bank_select_btn {
    width: 100%;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    height: 40px;
    font-size: 0.9em;
    background-color: inherit;
}
.admin_join_content_bank_select_btn.join_content_bank_no_data {
    text-align: center;
}
.admin_join_content_bank_select_btn.join_content_bank_data {
    text-align: center;
}
.admin_join_content_bank_select_btn.join_content_bank_data>div {
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_join_content_bank_select_btn.join_content_bank_data>div>div:nth-of-type(1) {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid rgba(255, 114, 153, 0.3);
}
.admin_join_content_bank_select_btn.join_content_bank_data>div>div:nth-of-type(2) {
    padding-left: 2px;
}
.admin_join_content_bank_select_btn.join_content_bank_data>div>div:nth-of-type(1) img  {
    width: 100%;
    object-fit: cover;
}
.admin_join_content_bank_input_box {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_join_content_bank_input_box>div:nth-of-type(1){
    width: 30%;
}
.admin_join_content_bank_input_box>div:nth-of-type(2){
    width: 70%;
}
.admin_join_content_bank_input_box input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding: 0 10px;
    font-size: 0.9em;
}
.admin_join_content_bank_input_box input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_join_content_shop_box {
    padding: 10px 0;
    border-top: 1px solid rgba(223, 224, 226, 1);
}
.admin_join_content_shop_box_header {
    width: 100%;
}
.admin_join_content_shop_box_header>div:nth-of-type(1) {
    font-size: 1.2em;
}
.admin_join_content_shop_box_header>div:nth-of-type(2),
.admin_join_content_shop_box_header>div:nth-of-type(3) {
    font-size: 0.8em;
    color: rgba(255, 3, 102, 1);
}
.admin_join_content_shop_box_content {
    padding-top: 5px;
    width: 100%;
}
.admin_join_content_shop_box_content_shop_name,
.admin_join_content_shop_box_content_shop_content,
.admin_join_content_shop_box_content_shop_profile,
.admin_join_content_shop_box_content_shop_back {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0;
}
.admin_join_content_shop_box_content>div>div:nth-of-type(1) {width: 30%;}
.admin_join_content_shop_box_content>div>div:nth-of-type(2) {width: 70%;}
.admin_join_content_shop_box_content>div:nth-of-type(1)>div:nth-of-type(2) input,
.admin_join_content_shop_box_content>div:nth-of-type(2)>div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding-left: 10px;
}
.admin_join_content_shop_box_content>div:nth-of-type(1)>div:nth-of-type(2) input:focus,
.admin_join_content_shop_box_content>div:nth-of-type(2)>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_join_content_content_shop_box_btn button,
.admin_join_content_content_shop_box_btn button {
    padding: 5px 10px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
    border-radius: 4px;
}
.admin_join_content_img_upload_box {
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.admin_join_content_img_upload_box>div:nth-of-type(1) {width: 30%;}
.admin_join_content_img_upload_box>div:nth-of-type(2) {width: 70%;}
.admin_join_content_img_upload_box>div button {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    background-color: inherit;
    transition: all 0.2s;
    font-size: 0.9em;
}
.admin_join_content_img_upload_box>div button:hover {
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_seller_join_popup {

}
.admin_seller_join_title {
    font-size: 1.1em;
    font-weight: 500;
}
.admin_seller_join_sub_title {
    font-size: 0.8em;
    color: rgba(255, 3, 102, 1);
    padding: 10px 0;
}
.admin_seller_join_confirm_btn_box {
    width: 100%;
}
.admin_seller_join_confirm_btn_box button {
    width: 100%;
    height: 50px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    font-size: 0.9em;
    color: white;
    border-radius: 4px;
}

.admin_join_shop_box_img_preview {
    width: 100px;
    height: 100px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    overflow: hidden;
    margin: 5px 0;
    position: relative;
    padding: 5px;
}
.admin_join_shop_box_img_preview img {
    width: 100%;
    aspect-ratio: 100 / 100;
    object-fit: cover;
}
.admin_join_shop_back_box_img_preview {
    width: 100%;
    height: 200px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    overflow: hidden;
    margin: 5px 0;
    position: relative;
    padding: 5px;
}
.admin_join_shop_back_box_img_preview img {
    width: 100%;
    aspect-ratio: 400 / 200;
    object-fit: cover;
}

/* 인기섹션 */
.admin_operate_popular_header {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_operate_popular_header>div:nth-of-type(1) {font-size: 1.2em;}
.admin_operate_popular_header>div:nth-of-type(2) {font-size: 0.9em; color: rgba(255, 114, 153, 1);}
.admin_operate_popular_tab {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.admin_operate_popular_tab>div {
    width: 100%;
    height: 30px;
}
.admin_operate_popular_tab>div button {
    width: 100%;
    height: 30px;
    border: none;
    background-color: inherit;
}
.admin_operate_popular_tab_active {
    border-bottom: 2px solid rgba(255, 3, 102, 1);
}
.admin_operate_popular_content {
    width: 100%;
    padding: 10px 0;
}
.admin_operate_popular_content_btn {
    width: 100%;
    text-align: right;
}
.admin_operate_popular_content_btn button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    padding: 5px 10px;
    font-size: 0.9em;
    border-radius: 4px;
}
.admin_operate_popular_content_item_box {
    border-top: 1px solid rgba(241, 242, 243, 1);
    margin: 5px 0;
}
.admin_operate_popular_product_modal_container {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}
.admin_operate_popular_product_modal_box {
    width: 80%;
    height: 80%;
    background-color: white;
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}
.admin_operate_popular_product_modal_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    position: relative;
}
.admin_operate_popular_product_modal_header>div:nth-of-type(1) {font-size: 1.1em;}
.admin_operate_popular_product_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
}
.admin_operate_popular_product_modal_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.admin_operate_popular_product_modal_content {
    width: 100%;
    height: 68vh;
    overflow-y: auto;
    padding: 20px;
}
.admin_operate_popular_product_modal_content_infomation {
    font-size: 0.9em;
    color: rgba(255, 3, 102, 1);
    line-height: 1.1em;
}
.admin_operate_popular_product_modal_footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_operate_popular_product_modal_footer>div {
    width: 30%;
    margin: 0 10px;
}
.admin_operate_popular_product_modal_footer>div button {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: none;
}
.admin_operate_popular_product_modal_footer>div:nth-of-type(1) button {
    background-color: rgba(223, 224, 226, 1);
}
.admin_operate_popular_product_modal_footer>div:nth-of-type(2) button {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.admin_operate_popular_product_modal_item_select {
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding: 10px 0;
}
.admin_operate_popular_item_select_header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0;
}
.admin_operate_popular_item_select_header>div:nth-of-type(1) {width: 10%;}
.admin_operate_popular_item_select_header>div:nth-of-type(2) {width: 80%;}
.admin_operate_popular_item_select_header>div:nth-of-type(2) input {
    width: 200px;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding-left: 5px;
    font-size: 0.9em;
}
.admin_operate_popular_item_select_header>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_operate_popular_item_box {
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    border-radius: 8px;
    padding: 10px;
    margin-top: 5px;
}
.admin_operate_popular_item {
    width: 33%;
    display: flex;
    padding: 5px;
}
.admin_operate_popular_item>div:nth-of-type(1) {width: 5%;}
.admin_operate_popular_item>div:nth-of-type(1) input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_operate_popular_item>div:nth-of-type(1) input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_operate_popular_item>div:nth-of-type(1) input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_operate_popular_item>div:nth-of-type(1) input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_operate_popular_item>div:nth-of-type(2) {width: calc(100% - 5%); padding: 0 10px; display: flex;}
.admin_operate_popular_item>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 4px;
}
.admin_operate_popular_item>div:nth-of-type(2)>div:nth-of-type(1) img {
    width: 70px;
    height: 70px;
    object-fit: cover;
}
.admin_operate_popular_item>div:nth-of-type(2)>div:nth-of-type(2) {
    width: calc(100% - 70px);
    font-size: 0.9em;
    padding: 0 3px;
}
.admin_operate_popular_item>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(1) {
    font-size: 0.9em;
    color: rgba(169, 173, 178, 1);
}
.admin_operate_popular_item>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(2) {
    font-weight: bold;
    /* font-size: 1.4em; */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 두 줄로 제한 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* 원래는 nowrap을 사용하지만, 여러 줄 처리를 위해 normal로 설정 */
    line-height: 1.2em; /* 줄 높이 설정 */
    max-height: 2.4em; /* 줄 높이와 줄 수에 따라 최대 높이 설정 (예: 줄 높이 1.2em * 줄 수 2 = 2.4em) */
}
.admin_operate_popular_item>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(3),
.admin_operate_popular_item>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(4) {
    font-size: 0.9em;
    color: rgba(255, 114, 153, 1);
}
/* .admin_operate_popular_item>div:nth-of-type(3) {width: 20%; font-size: 0.8em; display: flex; align-items: center; justify-content: center; flex-direction: column;} */
.admin_operate_popular_selected_items {
    width: 100%;
    padding: 10px 0;
}
.admin_operate_popular_selected_items_content {
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    border-radius: 8px;
    padding: 10px;
}
.admin_operate_popular_selected_items_no_select {
    width: 100%;
    text-align: center;
    padding: 20px;
    font-size: 0.9em;
    color: rgba(255, 3, 102, 1);
}
.admin_operate_popular_selected_item {
    width: 50%;
    display: flex;
    align-items: center;
    padding: 10px;
}
.admin_operate_popular_selected_item>div:nth-of-type(1) {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 4px;
}
.admin_operate_popular_selected_item>div:nth-of-type(1) img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}
.admin_operate_popular_selected_item>div:nth-of-type(2) {width: 50%; padding: 0 5px; font-size: 0.9em;}
.admin_operate_popular_selected_item>div:nth-of-type(2)>div:nth-of-type(1) {
    font-size: 0.9em;
    color: rgba(169, 173, 178, 1);
}
.admin_operate_popular_selected_item>div:nth-of-type(2)>div:nth-of-type(2) {
    font-weight: bold;
    /* font-size: 1.4em; */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 두 줄로 제한 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* 원래는 nowrap을 사용하지만, 여러 줄 처리를 위해 normal로 설정 */
    line-height: 1.2em; /* 줄 높이 설정 */
    max-height: 2.4em; /* 줄 높이와 줄 수에 따라 최대 높이 설정 (예: 줄 높이 1.2em * 줄 수 2 = 2.4em) */
}
.admin_operate_popular_selected_item>div:nth-of-type(2)>div:nth-of-type(3),
.admin_operate_popular_selected_item>div:nth-of-type(2)>div:nth-of-type(4) {
    font-size: 0.9em;
    color: rgba(255, 114, 153, 1);
}
.admin_operate_popular_selected_item>div:nth-of-type(3) {width: 25%; display: flex; align-items: center; justify-content: center; flex-direction: column; font-size: 0.9em;}

.admin_operate_popular_product_item_tap {
    display: flex;
    width: 100%;
    align-items: center;
}
.admin_operate_popular_product_item_tap>div {
    width: 100%;
    height: 40px;
    padding: 5px 0;
}
.admin_operate_popular_product_item_tap>div button {
    width: 100%;
    border: none;
    background-color: inherit;
}
.admin_operate_popular_product_item_tap_active {
    border-bottom: 2px solid rgba(255, 3, 102, 1);
    color: rgba(255, 3, 102, 1);
}
.admin_operate_popular_content_item_header {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 0;
    text-align: center;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_operate_popular_content_item_header>div:nth-of-type(1) {width: 40%;}
.admin_operate_popular_content_item_header>div:nth-of-type(2) {width: 15%;}
.admin_operate_popular_content_item_header>div:nth-of-type(3) {width: 15%;}
.admin_operate_popular_content_item_header>div:nth-of-type(4) {width: 20%;}
.admin_operate_popular_content_item_header>div:nth-of-type(5) {width: 10%;}
.admin_operate_popular_content_item_content {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 0;
    text-align: center;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_operate_popular_content_item_content>div:nth-of-type(1) {width: 40%; display: flex; align-items: center; overflow: hidden;}
.admin_operate_popular_content_item_content>div:nth-of-type(2) {width: 15%;}
.admin_operate_popular_content_item_content>div:nth-of-type(3) {width: 15%;}
.admin_operate_popular_content_item_content>div:nth-of-type(4) {width: 20%; font-size: 0.9em;}
.admin_operate_popular_content_item_content>div:nth-of-type(5) {width: 10%;}
.admin_operate_popular_content_item_content>div:nth-of-type(1)>div:nth-of-type(1) {width: 80px; height: 80px; overflow: hidden; border-radius: 4px;}
.admin_operate_popular_content_item_content>div:nth-of-type(1)>div:nth-of-type(1) img {width: 80px; height: 80px; object-fit: cover;}
.admin_operate_popular_content_item_content>div:nth-of-type(1)>div:nth-of-type(2) {width: calc(100% - 80px); text-align: left; padding-left: 10px; font-size: 0.9em;}
.admin_operate_popular_content_item_content>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) {font-size: 0.9em; color: rgba(169, 173, 178, 1);}
.admin_operate_popular_content_item_content>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2) {font-weight: bold;}
.admin_operate_popular_content_item_content>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(3),
.admin_operate_popular_content_item_content>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(4) {color: rgba(255, 114, 153, 1); font-size: 0.9em;}
.admin_operate_popular_content_item_content>div:nth-of-type(5) button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    font-size: 0.9em;
    padding: 5px 10px;
    border-radius: 4px;
}
.admin_operate_popular_content_item_content>div:nth-of-type(5) button:hover,
.admin_operate_popular_content_item_content>div:nth-of-type(5) button:active {
    opacity: 0.7;
}
.admin_operate_popular_review_item {
    width: 33%;
    display: flex;
    padding: 5px;
}
.admin_operate_popular_review_item>div:nth-of-type(1) {width: 5%;}
.admin_operate_popular_review_item>div:nth-of-type(1) input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_operate_popular_review_item>div:nth-of-type(1) input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_operate_popular_review_item>div:nth-of-type(1) input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_operate_popular_review_item>div:nth-of-type(1) input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_operate_popular_review_item>div:nth-of-type(2) {width: calc(100% - 5%); padding: 0 10px; display: flex;}
.admin_operate_popular_review_item>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 4px;
}
.admin_operate_popular_review_item>div:nth-of-type(2)>div:nth-of-type(1) img {
    width: 70px;
    height: 70px;
    object-fit: cover;
}
.admin_operate_popular_review_item>div:nth-of-type(2)>div:nth-of-type(2) {
    width: calc(100% - 70px);
    font-size: 0.9em;
    padding: 0 3px;
}
.admin_operate_popular_review_item>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(1) {
    font-size: 0.9em;
    color: rgba(169, 173, 178, 1);
}
.admin_operate_popular_review_item>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(2) {
    font-weight: bold;
    /* font-size: 1.4em; */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 두 줄로 제한 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* 원래는 nowrap을 사용하지만, 여러 줄 처리를 위해 normal로 설정 */
    line-height: 1.2em; /* 줄 높이 설정 */
    max-height: 2.4em; /* 줄 높이와 줄 수에 따라 최대 높이 설정 (예: 줄 높이 1.2em * 줄 수 2 = 2.4em) */
}
.admin_operate_popular_review_item>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(3) {
    font-size: 0.9em;
    color: rgba(255, 114, 153, 1);
    max-height: 60px;
    overflow-y: auto;
}
.admin_operate_popular_review_item>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(4) {
    font-size: 0.8em;
}



/* 헤더 */
.admin_header_container {
    position: fixed;
    width: 100%;
    height: 64px;
    background-color: rgba(255, 239, 243, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
}
.admin_header_left {
    display: flex;
    padding-left: 30px;
}
.admin_header_right {
    display: flex;
    padding-right: 30px;
}
.admin_header_right button {
    border: none;
    background-color: inherit;
    transition: all 0.3s;
}
.admin_header_right button:hover {
    color: rgba(255, 3, 102, 1);
}

/* sidebar */
.admin_side_container {
    position: fixed;
    margin-top: 64px;
    width: 230px;
    background-color: rgba(241, 242, 243, 1);
    height: 100vh;
    color: rgba(66, 66, 66, 1);
    z-index: 999;
    overflow-y: auto;
}
.admin_side_top_box {
    display: flex;
    align-items: center;
}
.admin_side_top_box img {
    width: 40px;
    height: 40px;
}
.admin_side_menu_box {
    max-height: 85vh;
    overflow-y: auto;
}
.admin_side_menu_box>div {
    width: 100%;
    /* border: 1px solid blue; */
    padding: 0 5px;
    font-size: 0.8em;
}
.admin_side_menu_box button {
    width: 100%;
    height: auto;
    border: none;
    background-color: inherit;
    color: rgba(66, 66, 66, 1);
}
.admin_side_btn_style {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s;
}
.admin_side_btn_style:hover {
    background-color: rgba(255, 224, 232, 1);
    border-radius: 8px;
}
.admin_board_toggle_menu_btn {
    width: 100%;
    background-color: inherit;
    border: none;
    text-align: left;
    color: rgba(169, 173, 178, 1)
}
.admin_menu_select {
    background-color: rgba(255, 114, 153, 1) !important;
    color: white !important;
    border-radius: 8px;
}


/* container */
.admin_dashboard_container {
    width: 100%;
    padding-left: 230px;
    padding-top: 64px;
}

/* dashboard pw change */
.admin_show_active {
    height: 100vh;
    overflow: hidden;
}
.admin_header_pw_change_container {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}
.admin_header_pw_change_box {
    width: 40%;
    height: auto;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
}
.admin_header_pw_change_header {
    width: 100%;
    height: 40px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.admin_header_pw_change_header>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.admin_header_pw_change_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.admin_header_pw_change_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.admin_header_pw_change_content {
    width: 100%;
    padding: 20px;
}
.admin_header_pw_change_content > div > div:nth-of-type(2) {
    position: relative;
}
.admin_header_pw_change_content > div > div:nth-of-type(2) button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    border: none;
    background-color: inherit;
}
.admin_header_pw_change_content > div > div:nth-of-type(2) button > span {
    color: rgba(169, 173, 178, 1);
}
.admin_header_pw_change_content>div:nth-of-type(1) {
    padding-bottom: 10px;
}
.admin_header_pw_change_content>div:nth-of-type(1)>div:nth-of-type(1),
.admin_header_pw_change_content>div:nth-of-type(2)>div:nth-of-type(1) {
    font-size: 0.9em;
    color: rgba(0, 0, 0, 0.7);
}
.admin_header_pw_change_content input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 0 10px;
    font-size: 0.9em;
}
.admin_header_pw_change_content input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.admin_header_pw_change_footer {
    width: 100%;
    padding: 20px;
}
.admin_header_pw_change_footer button {
    width: 100%;
    height: 45px;
    border: none;
    border-radius: 4px;
    font-size: 0.9em;
}
.admin_active_pw_confirm {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}

/* dashboard */
.admin_dashboard_board {
    display: flex;
}
.admin_dashboard_board_left {
    width: 75%;
}
.admin_dashboard_board_right {
    width: 25%;
}

.admin_dashboard_board_left_today {
    width: 100%;
    box-shadow: 0 0 7px 1px rgba(169, 173, 178, 1);
    border-radius: 4px;
    margin-bottom: 10px;
}
.admin_dashboard_board_left_today_top {
    font-size: 0.9em;
}
.admin_dashboard_board_left_today_top>div {
    padding: 0 20px 0 0;
}
.admin_dashboard_board_left_today_bottom {
    font-size: 0.9em;
}
.admin_dashboard_board_left_today_bottom>div {
    padding: 0 20px 0 0;
}
.admin_dashboard_board_today_title {
    display: flex;
    align-items: center;
}
.admin_dashboard_board_today_count {
    background-color: rgba(255, 114, 153, 1);
    border-radius: 30px;
    font-size: 0.8em;
    height: auto;
    text-align: center;
    color: white;
}
/* chart */
.admin_dashboard_chart_table_box {
    display: flex;
}
.admin_dashboard_visitors_chart_box {
    width: 100%;
    height: 400px;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    border-radius: 4px;
    margin-bottom: 10px;
    overflow: hidden;
}
.admin_dashboard_visitors_chart {
    width: 100% !important;
    height: 300px;
    font-size: 0.8em;
}

/* table */
.admin_dashboard_visitors_table {
    width: 100%;
    height: 400px;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    border-radius: 4px;
    margin-bottom: 10px;
    overflow: hidden;
}
.admin_date_visit_table_head {
    text-align: center;
    font-size: 0.8em;
    font-weight: bold;
    color: rgb(170, 170, 170);
}
.admin_date_visit_table_body {
    text-align: center;
    font-size: 0.7em;
}
.admin_date_visit_table_head>tr {
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_date_visit_table_body>tr>td {
    color: rgba(66, 66, 66, 1);
}
.admin_date_visit_table_body>tr {
    height: 29px;
    line-height: 29px;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}

.admin_date_visit_table_body>tr:nth-of-type(1) {
    background-color: rgba(255, 239, 243, 1);
}
.admin_date_visit_table_body>tr:nth-of-type(9) {
    border: none !important;
}
.admin_date_visit_table_body>tr>td:first-child {
    text-align: left;
    padding-left: 24px;
}
.admin_date_visit_table_body>tr>td:last-child {
    text-align: center;
    padding-right: 24px;
}
.admin_dashboard_visitors_table button {
    border: none;
    background-color: inherit;
    font-size: 0.8em;
    color: rgba(169, 173, 178, 1);
    transition: all 0.3s;
}
.admin_dashboard_visitors_table button:hover {
    color: rgba(255, 3, 102, 1);
}

/* 문의/구매평, 컨텐츠, 소식, 내역 */
.admin_dashboard_customer_box, .admin_dashboard_contents_box, .admin_dashboard_dailyduck_info, .admin_dashboard_dailyduck_inquiry {
    width: 100%;
    height: 400px;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    border-radius: 4px;
    margin-bottom: 10px;
    overflow: hidden;
}

.admin_dashboard_customer_content {
    font-size: 0.9em;
    cursor: pointer;
    transition: all 0.2s;
}
.admin_dashboard_customer_content:hover {
    background-color: rgba(241, 242, 243, 1);
}
.admin_dashboard_customer_content div:nth-of-type(1) {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; 
}
.admin_dashboard_customer_content div:nth-child(2) {
    font-size: 0.8em !important;
    color: #757575;
}
.admin_dashboard_no_data {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    color: rgba(0, 0, 0, 0.7);
    padding: 20px;
}
.admin_dashboard_contents_detail_box {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    transition: all 0.2s;
}
.admin_dashboard_contents_detail_box:hover {
    background-color: rgba(241, 242, 243, 1);
}
.admin_dashboard_contents_img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}
.admin_dashboard_contents_img img {
    width: 40px;
    height: 40px;
    object-fit: cover;
}
.admin_dashboard_contents_content {
    font-size: 0.9em;
}
.admin_dashboard_contents_content div:nth-of-type(2) {
    font-size: 0.8em !important;
    color: #757575;
}
.admin_dashboard_dailyduck_info_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9em;
    cursor: pointer;
    transition: all 0.2s;
}
.admin_dashboard_dailyduck_info_content:hover {
    background-color: rgba(241, 242, 243, 1);
}
.admin_dashboard_dailyduck_info_content div:nth-of-type(2) {
    font-size: 0.8em;
    color: rgba(66, 66, 66, 1);
}
.admin_dashboard_seller_customer_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9em;
    cursor: pointer;
    transition: all 0.2s;
}
.admin_dashboard_seller_customer_box:hover {
    background-color: rgba(241, 242, 243, 1);
}
.admin_dashboard_seller_customer_box div:nth-of-type(2) {
    font-size: 0.8em;
    color: rgba(66, 66, 66, 1);
}

/* dashboard right */
.admin_dashboard_board_user_profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(66, 66, 66, 1);
    padding: 20px;
    color: white;
    margin-left: 10px;
    border-radius: 4px;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
}
.admin_dashboard_board_user_profile>div:nth-of-type(1) {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
}
.admin_dashboard_board_user_profile>div:nth-of-type(1) img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}
.admin_dashboard_board_user_profile>div:nth-of-type(2) {
    padding-top: 10px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}
.admin_dashboard_board_user_profile>div:nth-of-type(2)>div:nth-of-type(2) {
    position: absolute;
    top: 7.5px;
    right: -25px;

}
.admin_dashboard_board_user_profile>div:nth-of-type(3) {
    font-size: 0.8em;
    color: #757575;
}
.admin_dashboard_board_user_memo {
    background-color: #fffbd5;
    padding: 20px 10px;
    margin-top: 10px;
    border-radius: 4px;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    margin-left: 10px;
}
.admin_dashboard_board_user_memo>div:nth-of-type(1) {
    display: flex;
    align-items: center;
}
.admin_dashboard_board_user_memo>div:nth-of-type(1)>div:nth-of-type(1) {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
}
.admin_dashboard_board_user_memo>div:nth-of-type(1)>div:nth-of-type(1) img {
    width: 50px;
    height: 50px;
    object-fit: cover;
}
.admin_dashboard_board_user_memo>div:nth-of-type(2) {
    padding-top: 10px;
    padding-left: 50px;
}
.admin_dashboard_board_user_memo>div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    font-size: 0.8em;
    background-color: inherit;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(169, 173, 178, 1);
}
.admin_dashboard_board_user_memo>div:nth-of-type(2) input:focus {
    outline: none;
    border-bottom: 1px solid rgba(255, 3, 102, 1) !important;
}
.admin_dashboard_board_user_memo>div:nth-of-type(2) input::placeholder {
    color: rgba(169, 173, 178, 1);
}
.admin_dashboard_board_user_memo>div:nth-of-type(2) button {
    width: 100%;
    border: 1px solid rgba(169, 173, 178, 1);
    background-color: white;
    border-radius: 8px;
    height: 40px;
    margin-top: 10px;
}

.admin_dashboard_operator_setting {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    margin-top: 10px;
    border-radius: 4px;
    margin-left: 10px;
}

.admin_dashboard_operator_setting>div:nth-of-type(2)>div {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
}
.admin_dashboard_operator_setting>div:nth-of-type(2)>div img {
    width: 50px;
    height: 50px;
    object-fit: cover;
}
.admin_dashboard_swiper {
    width: 100%;
    height: 100px;
    margin-left: 10px;
    margin-top: 10px;
    border-radius: 4px;
}
.admin_dashboard_swiper img {
    width: 100%;
    height: 100px;
    object-fit: cover;
}
.admin_dashboard_swiper .swiper-pagination {
    top: 100px !important;
}

/* userList */
.admin_userList_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.admin_userList_header_btn_box {
    width: 150px;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    border-radius: 4px;
    font-size: 0.8em;
}
.admin_userList_header_btn_box div {
    width: 100%;
}
.admin_userList_header_btn_box div button {
    width: 100%;
    height: 30px;
    border: none;
    background-color: inherit;
    transition: all 0.3s;
}
.admin_userList_header_btn_box div button:hover {
    background-color: rgba(255, 114, 153, 0.7);
    color: white;
    border-radius: 4px;
}
.admin_userList_search_box {
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid rgba(241, 242, 243, 1);
}
.admin_userList_search_input {
    display: flex;
    justify-content: space-between;
}
.admin_userList_datepicker {
    width: 300px;
    border-radius: 0;
}
.admin_userList_datepicker input {
    text-align: center;
}
.admin_userList_datepicker button {
    display: flex;
    align-items: center;
}
.admin_userList_datepicker :global(.ant-picker-input) input {
    pointer-events: none !important;
    cursor: pointer !important;
}
.admin_userList_search_select {
    width: 100px;
    height: 35px;
}
.admin_userList_search_select :global(.ant-select-selector) {
    border-radius: 4px;
}
.admin_userList_search_input_txt input {
    width: 230px;
    height: 35px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding-left: 10px;
    font-size: 0.9em;
}
.admin_userList_search_btn button {
    width: 65px;
    height: 35px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 4px;
    font-size: 0.9em;
}
.admin_userList_table_header {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
}
.admin_userList_table_header>div:nth-of-type(1) {width: 5%;}
.admin_userList_table_header>div:nth-of-type(2) {width: 10%;}
.admin_userList_table_header>div:nth-of-type(3) {width: 10%;}
.admin_userList_table_header>div:nth-of-type(4) {width: 10%;}
.admin_userList_table_header>div:nth-of-type(5) {width: 15%;}
.admin_userList_table_header>div:nth-of-type(6) {width: 20%;}
.admin_userList_table_header>div:nth-of-type(7) {width: 15%;}
.admin_userList_table_header>div:nth-of-type(8) {width: 10%;}
.admin_userList_table_header>div:nth-of-type(9) {width: 5%;}

.admin_userList_table_header>div:nth-of-type(1) input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_userList_table_header>div:nth-of-type(1) input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_userList_table_header>div:nth-of-type(1) input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_userList_table_header>div:nth-of-type(1) input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}

.admin_userList_table_section {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 0.8em;
    cursor: pointer;
}
.admin_userList_table_section:hover {
    background-color: rgba(241, 242, 243, 1);
}

.admin_userList_table_section>div:nth-of-type(1) {width: 5%;}
.admin_userList_table_section>div:nth-of-type(2) {width: 10%;}
.admin_userList_table_section>div:nth-of-type(3) {width: 10%;}
.admin_userList_table_section>div:nth-of-type(4) {
    width: 10%;
    overflow-x: scroll;
    padding: 0 5px;
}

.admin_userList_table_section>div:nth-of-type(4)::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 2px;
}
.admin_userList_table_section>div:nth-of-type(4)::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.admin_userList_table_section>div:nth-of-type(4)::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.admin_userList_table_section>div:nth-of-type(5) {width: 15%; padding: 0 5px;}
.admin_userList_table_section>div:nth-of-type(6) {width: 20%;}
.admin_userList_table_section>div:nth-of-type(7) {width: 15%;}
.admin_userList_table_section>div:nth-of-type(8) {width: 10%;}
.admin_userList_table_section>div:nth-of-type(9) {width: 5%;}

.admin_userList_table_section>div:nth-of-type(1) input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_userList_table_section>div:nth-of-type(1) input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_userList_table_section>div:nth-of-type(1) input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_userList_table_section>div:nth-of-type(1) input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}

/* management */
.admin_management_box {}
.admin_management_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.admin_management_header_btn_box {
    width: 150px;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    border-radius: 4px;
    font-size: 0.8em;
}
.admin_management_header_btn_box div {
    width: 100%;
}
.admin_management_header_btn_box div button {
    width: 100%;
    height: 30px;
    border: none;
    background-color: inherit;
    transition: all 0.3s;
}
.admin_management_header_btn_box div button:hover {
    background-color: rgba(255, 114, 153, 0.7);
    color: white;
    border-radius: 4px;
}

/* dorment */
.admin_dorment_box {}
.admin_dorment_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* message_push */
.admin_message_push_box {}
.admin_message_push_active {
    border-bottom: 3px solid rgba(255, 3, 102, 1);
}
.admin_message_push_tap_box {
    display: flex;
    font-size: 0.8em;
}
.admin_message_push_tap_box div {
    width: 100px;
}
.admin_message_push_tap_box div button {
    width: 100%;
    height: 30px;
    border: none;
    background-color: inherit;
}
.admin_message_push_content {
    width: 100%;
    padding: 20px;
}
.admin_message_push_app_push_box {
    width: 100%;
    font-size: 0.9em;
    position: relative;
}
.admin_message_push_app_push_box>div:nth-of-type(1) {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.admin_message_push_app_push_box>div:nth-of-type(1)>div:nth-of-type(1) {
    display: flex;
    align-items: center;
}
.admin_message_push_app_push_box>div:nth-of-type(1)>div:nth-of-type(2) button {
    padding: 5px 10px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
    border-radius: 4px;
}
.admin_message_push_app_push_box>div:nth-of-type(2) {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    padding: 10px;
}
.admin_message_push_app_push_box>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.admin_message_push_app_push_box>div:nth-of-type(2)>div:nth-of-type(1)>div:nth-of-type(1) {
    width: 10%;
}
.admin_message_push_app_push_box>div:nth-of-type(2)>div:nth-of-type(1)>div:nth-of-type(2) {
    width: 90%;
}
.admin_message_push_app_push_box>div:nth-of-type(2)>div:nth-of-type(1)>div:nth-of-type(2) input {
    width: 250px;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 0 10px;
    font-size: 0.9em;
}

.admin_message_push_app_push_box>div:nth-of-type(2)>div:nth-of-type(2) {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.admin_message_push_app_push_box>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 10%;
}
.admin_message_push_app_push_box>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(2) {
    width: 90%;
}
.admin_message_push_app_push_box>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(2) textarea {
    width: 250px;
    height: 150px;
    resize: none;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 10px;
    font-size: 0.9em;
}
.admin_message_push_app_push_box>div:nth-of-type(2)>div:nth-of-type(1)>div:nth-of-type(2) input:focus,
.admin_message_push_app_push_box>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(2) textarea:focus,
.admin_message_push_app_push_box>div:nth-of-type(2)>div:nth-of-type(3)>div:nth-of-type(2) input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.admin_message_push_app_push_box>div:nth-of-type(2)>div:nth-of-type(3) {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.admin_message_push_app_push_box>div:nth-of-type(2)>div:nth-of-type(3)>div:nth-of-type(1) {
    width: 10%;
}
.admin_message_push_app_push_box>div:nth-of-type(2)>div:nth-of-type(3)>div:nth-of-type(2) {
    width: 90%;
}
.admin_message_push_app_push_box>div:nth-of-type(2)>div:nth-of-type(3)>div:nth-of-type(2) input {
    width: 250px;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 0 10px;
    font-size: 0.9em;
}
.admin_message_push_app_push_box>div:nth-of-type(2)>div:nth-of-type(4) {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.admin_message_push_app_push_box>div:nth-of-type(2)>div:nth-of-type(4)>div:nth-of-type(1) {
    width: 10%;
}
.admin_message_push_app_push_box>div:nth-of-type(2)>div:nth-of-type(4)>div:nth-of-type(2) {
    width: 90%;
}
.admin_message_push_app_push_box>div:nth-of-type(2)>div:nth-of-type(4)>div:nth-of-type(2) button {
    width: 150px;
    height: 40px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    border-radius: 4px;
    color: white;
}
.admin_message_push_app_push_img {
    width: 150px;
    height: 150px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 5px;
    overflow: hidden;
}
.admin_message_push_app_push_img img {
    width: 100%;
    height: auto;
    object-fit: contain;
    aspect-ratio: 500 / 500;
}
.admin_message_push_app_push_preview {
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.3);
    top: 50px;
    right: 50px;
    width: 200px;
    height: calc(100% - 70px);
    border-radius: 8px;
    overflow: hidden;
}
.admin_message_push_app_push_preview>div {
    width: 100%;
    display: flex;
    align-items: center;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    font-size: 0.9em;
}
.admin_message_push_app_push_preview>div>div:nth-of-type(1) {
    width: 20%;
    overflow: hidden;
    padding: 5px;
}
.admin_message_push_app_push_preview>div>div:nth-of-type(1) img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.admin_message_push_app_push_preview>div>div:nth-of-type(3) {
    width: 20%;
    overflow: hidden;
    padding: 5px;
}
.admin_message_push_app_push_preview>div>div:nth-of-type(3) img {
    width: 100%;
    height: auto;
    aspect-ratio: 100 / 100;
    object-fit: cover;
}
.admin_message_push_app_push_preview>div>div:nth-of-type(2) {
    width: 60%;
    padding: 10px;
    overflow-wrap: break-word;
}
.admin_message_push_app_push_preview>div>div:nth-of-type(2)>div:nth-of-type(1) {
    font-size: 0.9em;
}
.admin_message_push_app_push_preview>div>div:nth-of-type(2)>div:nth-of-type(2) {
    font-weight: bold;
    /* font-size: 1.4em; */
    display: -webkit-box;
    /* -webkit-line-clamp: 2;  */
    -webkit-box-orient: vertical;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    white-space: normal; /* 원래는 nowrap을 사용하지만, 여러 줄 처리를 위해 normal로 설정 */
    /* line-height: 1.2em; 
    max-height: 2.4em;  */
    font-size: 0.8em;
}
.admin_app_push_info_txt {
    font-size: 0.8em;
    color: rgba(255, 3, 102, 1);
    font-weight: 500;
}
.admin_message_push_app_push_agree_user {
    width: 100%;
    padding-top: 20px;
}
.admin_message_push_app_push_agree_user_title {

}
.admin_message_push_app_push_agree_user_container {
    width: 100%;
    padding-top: 10px;
}
.admin_message_push_app_push_agree_user_search {
    width: 100%;
    display: flex;
    align-items: center;
}
.admin_message_push_app_push_agree_user_search>div:nth-of-type(1) {
    width: 10%;
}
.admin_message_push_app_push_agree_user_search>div:nth-of-type(2) {
    width: 90%;
}
.admin_message_push_app_push_agree_user_search>div:nth-of-type(2) input {
    width: 250px;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 0 10px;
    font-size: 0.9em;
}
.admin_message_push_app_push_agree_user_search>div:nth-of-type(2) input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.admin_message_push_app_push_agree_user_header {
    border-top: 1px solid rgba(196, 199, 202, 1);
    border-bottom: 1px solid rgba(196, 199, 202, 1);
    margin-top: 5px;
    padding: 5px 0;
    display: flex;
    width: 100%;
    align-items: center;
    text-align: center;
    font-weight: 500;
}
.admin_message_push_app_push_agree_user_header>div:nth-of-type(1) {width: 5%;}
.admin_message_push_app_push_agree_user_header>div:nth-of-type(2) {width: 10%;}
.admin_message_push_app_push_agree_user_header>div:nth-of-type(3) {width: 10%;}
.admin_message_push_app_push_agree_user_header>div:nth-of-type(4) {width: 15%;}
.admin_message_push_app_push_agree_user_header>div:nth-of-type(5) {width: 10%;}
.admin_message_push_app_push_agree_user_header>div:nth-of-type(6) {width: 10%;}
.admin_message_push_app_push_agree_user_header>div:nth-of-type(7) {width: 20%;}
.admin_message_push_app_push_agree_user_header>div:nth-of-type(8) {width: 20%;}
.admin_message_push_app_push_agree_user_header input[type="checkbox"],
.admin_message_push_app_push_agree_user_content input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
.admin_message_push_app_push_agree_user_header input[type="checkbox"]:after,  
.admin_message_push_app_push_agree_user_content input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}
.admin_message_push_app_push_agree_user_header input[type="checkbox"]:checked,
.admin_message_push_app_push_agree_user_content input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}
.admin_message_push_app_push_agree_user_header input[type="checkbox"]:checked:after,
.admin_message_push_app_push_agree_user_content input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}

.admin_message_push_app_push_agree_user_content {
    padding: 10px 0;
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 0.9em;
    text-align: center;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    color: rgba(0, 0, 0, 0.7);
}
.admin_message_push_app_push_agree_user_content>div:nth-of-type(1) {width: 5%;}
.admin_message_push_app_push_agree_user_content>div:nth-of-type(2) {
    width: 10%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}
.admin_message_push_app_push_agree_user_content>div:nth-of-type(3) {width: 10%;}
.admin_message_push_app_push_agree_user_content>div:nth-of-type(4) {width: 15%;}
.admin_message_push_app_push_agree_user_content>div:nth-of-type(5) {width: 10%;}
.admin_message_push_app_push_agree_user_content>div:nth-of-type(6) {width: 10%;}
.admin_message_push_app_push_agree_user_content>div:nth-of-type(7) {width: 20%;}
.admin_message_push_app_push_agree_user_content>div:nth-of-type(8) {width: 20%;}
.admin_message_push_app_push_agree_user_no_data {
    width: 100%;
    padding: 40px;
    font-size: 0.9em;
    color: rgba(255, 114, 153, 1);
    text-align: center;
}
.admin_kakao_message_select_btn_box {
    width: 100%;
    display: flex;
    align-items: center;
}
.admin_kakao_message_select_btn_box button {
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    background-color: inherit;
    padding: 5px 10px;
    font-size: 0.8em;
}
.admin_kakao_message_select_btn_box>div:nth-of-type(2) {
    padding-left: 10px;
}
.admin_kakao_message_active {
    color: rgba(255, 3, 102, 1);
    border: 1px solid rgba(255, 3, 102, 1) !important;
}
.admin_kakao_message_biz_box {
    padding-top: 10px;
}
.admin_kakao_message_biz_box_header {
    width: 100%;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_kakao_message_biz_box_header > div:nth-of-type(1) {
    font-weight: 500;
}
.admin_kakao_message_biz_box_header > div:nth-of-type(2) {
    color: rgba(255, 3, 102, 1);
    font-size: 0.9em;
}
.admin_kakao_message_biz_box_content {
    width: 100%;
    padding-top: 10px;
    position: relative;
}
.admin_kakao_message_biz_sendbtn {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 0.9em;
}
.admin_kakao_message_biz_box_content_template {
    width: 100%;
    display: flex;
    align-items: center;
}
.admin_kakao_message_biz_box_content_template>div:nth-of-type(1) {
    width: 15%;
    font-size: 0.9em;
}
.admin_kakao_message_biz_box_content_template>div:nth-of-type(2) {
    width: 85%;
}
.admin_kakao_biz_template_select {
    width: 250px;
    height: 40px;
}
.admin_kakao_message_biz_box  .ant-select-item .ant-select-item-option {
    height: 50px !important;
}
.admin_kakao_biz_template_preview {
    width: 250px;
    height: auto;
    overflow: hidden;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    margin: 10px 0;
    border-radius: 4px;
}
.admin_kakao_biz_template_preview_img {
    width: 100%;
    height: auto;
    overflow: hidden;
}
.admin_kakao_biz_template_preview_img img {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 800 / 400;
}
.admin_kakao_biz_template_preview_content {
    width: 100%;
    font-size: 0.8em;
    padding: 10px;
}
.admin_kakao_biz_template_preview_content pre {
    overflow: auto; /* 스크롤 가능 */
    white-space: pre-wrap; /* 줄바꿈도 허용 */
    word-wrap: break-word; /* 단어 단위로 줄바꿈 */
}
.admin_kakao_biz_template_preview_extra {
    width: 100%;
    font-size: 0.75em;
    color: rgba(169, 173, 178, 1);
    padding: 0 10px;
}
.admin_kakao_biz_template_preview_advert {
    width: 100%;
    font-size: 0.75em;
    color: rgba(169, 173, 178, 1);
    padding: 0 10px;
}
.admin_kakao_biz_template_preview_btn {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.admin_kakao_biz_template_preview_btn button {
    width: 100%;
    height: 40px;
    border: none;
    font-size: 0.9em;
    border-radius: 4px;
    margin-top: 3px;
}
.admin_kakao_biz_chennel_add {
    background-color: rgb(255, 230, 0);
    color: black;
    font-weight: 500;
}
.admin_kakao_biz_template_user_box {
    width: 100%;
    position: relative;
}

/* seller */
.admin_seller_box {}
.admin_seller_top_header {
    font-size: 1.2em;
}
.admin_seller_header {
    border-bottom: 3px solid rgba(255, 224, 232, 1);
}
.admin_seller_input_box {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_seller_radio_input_box {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_seller_input_box>div:nth-of-type(1){
    width: 30%;
}
.admin_seller_input_box>div:nth-of-type(2){
    width: 40%;
}
.admin_seller_input_box>div:nth-of-type(3){
    width: 30%;
}
.admin_seller_input_box input {
    width: 100%;
    height: 35px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding: 0 10px;
    font-size: 0.9em;
}
.admin_seller_input_box input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}

.admin_seller_radio_input_box > div:nth-of-type(1) {width: 30%;}
.admin_seller_radio_input_box > div:nth-of-type(2) {width: 70%; display: flex; align-items: center;}
.admin_seller_radio_input_box > div:nth-of-type(2)>div {
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
.admin_seller_radio_input_box > div:nth-of-type(2)>div>div:nth-of-type(1) {
    display: flex;
}
.admin_seller_radio_input_box > div:nth-of-type(2)>div>div:nth-of-type(2) {
    padding-left: 5px;
}
.admin_seller_radio_input_box > div:nth-of-type(2)>div:nth-of-type(2) {
    padding-left: 20px;
}
.admin_seller_radio_input_box input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.admin_seller_radio_input_box input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}

.admin_seller_radio_input_box input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}

.admin_seller_radio_input_box input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}
.admin_seller_id_check_btn button {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.9em;
    color: white;
}
.admin_seller_id_check {
    font-size: 0.8em;
}
.admin_seller_add_pw_check_box {
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
.admin_seller_add_pw_check_box>div {
    padding-left: 4px;
}
.admin_seller_input_upload_img_box {
    width: 100px;
    height: 100px;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 10px;
    border: 1px solid rgba(223, 224, 226, 1);
    padding: 3px;
}
.admin_seller_input_upload_img_box img {
    width: 100%;
    aspect-ratio: 500 / 500;
    object-fit: cover;
}
.seller_shop_setting_bank_select_btn {
    width: 100%;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 8px;
    height: 40px;
    font-size: 0.9em;
    background-color: inherit;
}
.seller_shop_setting_bank_select_btn.seller_bank_no_data {
    text-align: center;
}
.seller_shop_setting_bank_select_btn.seller_bank_data {
    text-align: center;
}
.seller_shop_setting_bank_select_btn.seller_bank_data>div {
    display: flex;
    align-items: center;
    justify-content: center;
}
.seller_shop_setting_bank_select_btn.seller_bank_data>div>div:nth-of-type(1) {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid rgba(255, 114, 153, 0.3);
}
.seller_shop_setting_bank_select_btn.seller_bank_data>div>div:nth-of-type(2) {
    padding-left: 2px;
}
.seller_shop_setting_bank_select_btn.seller_bank_data>div>div:nth-of-type(1) img  {
    width: 100%;
    object-fit: cover;
}
.admin_seller_input_select {
    width: 100%;
    height: 50px;
}
.admin_seller_input_upload_box {
    width: 100%;
    display: flex;
    align-items: center;
}
.admin_seller_input_upload_box>div:nth-of-type(1) {
    width: 70%;
}
.admin_seller_input_upload_box>div:nth-of-type(2) {
    width: 30%;
}
.admin_seller_input_upload_box>div:nth-of-type(2) button {
    width: 100%;
    height: 35px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 4px;
    font-size: 0.9em;
}
.admin_seller_add_btn {
    width: 100%;
    text-align: center;
}
.admin_seller_add_btn button {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    width: 400px;
    height: 50px;
    border-radius: 6px;
}
.admin_seller_add_popup {
    border-radius: 4px !important;
}
.admin_seller_add_title {
    padding: 20px 0 0 0;
}
.admin_seller_add_sub_title {
    font-size: 0.8em;
    padding: 0 0 20px 0;
}
.admin_seller_add_btn_box {
    display: flex;
    align-items: center;
    justify-content: center !important;
    padding: 20px 0 !important;
}
.admin_seller_add_btn_box button {
    width: 30%;
    height: 40px;
}
.admin_seller_add_btn_box button:nth-of-type(1){
    background-color: inherit;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
}
.admin_seller_add_btn_box button:nth-of-type(2){
    background-color: rgba(255, 114, 153, 1);
    border: none;
    color: white;
    border-radius: 4px;
}
.admin_seller_confirm_btn_box {
    display: flex;
    align-items: center;
    justify-content: center !important;
}
.admin_seller_confirm_btn_box button{
    width: 30%;
    height: 40px;
    border: none;
    border-radius: 4px;
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_seller_management_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.admin_seller_management_header_btn_box {
    width: 150px;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    border-radius: 4px;
    font-size: 0.8em;
}
.admin_seller_management_header_btn_box div {
    width: 100%;
}
.admin_seller_management_header_btn_box div button {
    width: 100%;
    height: 30px;
    border: none;
    background-color: inherit;
    transition: all 0.3s;
}
.admin_seller_management_header_btn_box div button:hover {
    background-color: rgba(255, 114, 153, 0.7);
    color: white;
    border-radius: 4px;
}
.admin_sellerList_search_box {
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid rgba(241, 242, 243, 1);
}
.admin_sellerList_search_input {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.admin_sellerList_datepicker {
    width: 300px;
    border-radius: 0;
}
.admin_sellerList_datepicker input {
    text-align: center;
}
.admin_sellerList_datepicker button {
    display: flex;
    align-items: center;
}
.admin_sellerList_datepicker :global(.ant-picker-input) input {
    pointer-events: none !important;
    cursor: pointer !important;
}
.admin_sellerList_search_select {
    width: 100px;
    height: 35px;
}
.admin_sellerList_search_select :global(.ant-select-selector) {
    border-radius: 4px;
}
.admin_sellerList_search_input_txt input {
    width: 230px;
    height: 35px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding-left: 10px;
    font-size: 0.9em;
}
.admin_sellerList_search_input_txt input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.admin_sellerList_search_btn button {
    width: 65px;
    height: 35px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 4px;
    font-size: 0.9em;
}
.admin_seller_delete_btn {
    position: absolute;
    top: 140px;
    right: 180px;
}
.admin_seller_delete_btn button {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
    padding: 5px 10px;
    border-radius: 4px;
}
.admin_sellerList_table_content {
    width: 100%;
    overflow-x: scroll;
}
.admin_sellerList_table_content::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 5px;
}
.admin_sellerList_table_content::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.admin_sellerList_table_content::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.admin_sellerList_table_content>div {
    min-width: 1000px;
}
.admin_sellerList_table_header {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
}
.admin_sellerList_table_header>div:nth-of-type(1) {width: 5%;}
.admin_sellerList_table_header>div:nth-of-type(2) {width: 5%;}
.admin_sellerList_table_header>div:nth-of-type(3) {width: 15%;}
.admin_sellerList_table_header>div:nth-of-type(4) {width: 10%;}
.admin_sellerList_table_header>div:nth-of-type(5) {width: 15%;}
.admin_sellerList_table_header>div:nth-of-type(6) {width: 25%;}
.admin_sellerList_table_header>div:nth-of-type(7) {width: 15%;}
.admin_sellerList_table_header>div:nth-of-type(8) {width: 10%;}

.admin_sellerList_table_header>div:nth-of-type(1) input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_sellerList_table_header>div:nth-of-type(1) input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_sellerList_table_header>div:nth-of-type(1) input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_sellerList_table_header>div:nth-of-type(1) input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}

.admin_sellerList_table_section {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 0.8em;
    cursor: pointer;
    position: relative;
}
.admin_sellerList_table_section:hover {
    background-color: rgba(241, 242, 243, 1);
}

.admin_sellerList_table_section>div:nth-of-type(1) {width: 5%;}
.admin_sellerList_table_section>div:nth-of-type(2) {width: 5%;}
.admin_sellerList_table_section>div:nth-of-type(3) {width: 15%;}
.admin_sellerList_table_section>div:nth-of-type(4) {width: 10%;}
.admin_sellerList_table_section>div:nth-of-type(5) {width: 15%;}
.admin_sellerList_table_section>div:nth-of-type(6) {width: 25%;}
.admin_sellerList_table_section>div:nth-of-type(7) {width: 15%;}
.admin_sellerList_table_section>div:nth-of-type(8) {width: 10%;}

.admin_sellerList_table_section>div:nth-of-type(1) input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_sellerList_table_section>div:nth-of-type(1) input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_sellerList_table_section>div:nth-of-type(1) input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_sellerList_table_section>div:nth-of-type(1) input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_sellerList_table_content_no_data {
    width: 100%;
    padding: 50px 0;
    font-size: 0.9em;
    text-align: center;
}
.admin_seller_list_table_login {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
}
.admin_sellerList_table_enabled {
    position: absolute;
    left: 80px;
    font-size: 0.9em;
    background-color: rgb(0, 99, 5);
    color: white;
    padding: 5px 10px;
}
.admin_sellerList_table_refusal {
    position: absolute;
    left: 80px;
    font-size: 0.9em;
    background-color: rgb(202, 0, 0);
    color: white;
    padding: 5px 10px;
}
.admin_sellerList_table_refusal button {
    border: none;
    background-color: inherit;
}

/* 셀러 상세정보 */
.admin_seller_manage_detail_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.admin_seller_manage_detail_header span {
    font-size: 1.2em;
}
.admin_seller_manage_detail_header>div:nth-of-type(2) {
    display: flex;
    align-items: center;
}
.admin_seller_manage_detail_header>div:nth-of-type(2)>div:nth-of-type(1) {}
.admin_seller_manage_detail_header>div:nth-of-type(2)>div:nth-of-type(2) {padding-left: 10px;}
.admin_seller_manage_detail_header>div:nth-of-type(2)>div:nth-of-type(3) {padding-left: 10px;}
.admin_seller_manage_detail_header>div:nth-of-type(2)>div:nth-of-type(1) button,
.admin_seller_manage_detail_header>div:nth-of-type(2)>div:nth-of-type(2) button,
.admin_seller_manage_detail_header>div:nth-of-type(2)>div:nth-of-type(3) button {
    border: 1px solid rgba(223, 224, 226, 0.7);
    background-color: inherit;
    font-size: 0.8em;
    padding: 5px 10px;
    border-radius: 4px;
}

.admin_seller_manage_detail_tab {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.admin_seller_manage_detail_tab div {
    width: 100%;
    height: 30px;
}
.admin_seller_manage_detail_tab button {
    width: 100%;
    border: none;
    background-color: inherit;
}
.admin_seller_manage_detail_tab_active {
    border-bottom: 3px solid rgba(255, 3, 102, 1);
}
.admin_seller_manage_detail_seller_box {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 0.7);
    padding: 10px;
    border-radius: 4px;
}
.admin_seller_manage_detail_seller_box>div:nth-of-type(1),
.admin_seller_manage_detail_seller_box>div:nth-of-type(2),
.admin_seller_manage_detail_seller_box>div:nth-of-type(3),
.admin_seller_manage_detail_seller_box>div:nth-of-type(4),
.admin_seller_manage_detail_seller_box>div:nth-of-type(5),
.admin_seller_manage_detail_seller_box>div:nth-of-type(6) {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 10px 0;
}
.admin_seller_manage_detail_seller_box>div:nth-of-type(1)>div:nth-of-type(1),
.admin_seller_manage_detail_seller_box>div:nth-of-type(2)>div:nth-of-type(1),
.admin_seller_manage_detail_seller_box>div:nth-of-type(3)>div:nth-of-type(1),
.admin_seller_manage_detail_seller_box>div:nth-of-type(4)>div:nth-of-type(1),
.admin_seller_manage_detail_seller_box>div:nth-of-type(5)>div:nth-of-type(1),
.admin_seller_manage_detail_seller_box>div:nth-of-type(6)>div:nth-of-type(1) {
    width: 20%;
    font-weight: 600;
}
.admin_seller_manage_detail_seller_box>div:nth-of-type(1)>div:nth-of-type(2),
.admin_seller_manage_detail_seller_box>div:nth-of-type(2)>div:nth-of-type(2),
.admin_seller_manage_detail_seller_box>div:nth-of-type(3)>div:nth-of-type(2),
.admin_seller_manage_detail_seller_box>div:nth-of-type(4)>div:nth-of-type(2),
.admin_seller_manage_detail_seller_box>div:nth-of-type(5)>div:nth-of-type(2),
.admin_seller_manage_detail_seller_box>div:nth-of-type(6)>div:nth-of-type(2) {
    width: 80%;
}
.admin_seller_manage_detail_seller_box>div:nth-of-type(7) {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 10px 0;
}
.admin_seller_manage_detail_seller_box>div:nth-of-type(7)>div:nth-of-type(1) {
    width: 20%;
    font-weight: 600;
}
.admin_seller_manage_detail_seller_box>div:nth-of-type(7)>div:nth-of-type(2) {
    width: 80%;
    display: flex;
    align-items: center;
}
.admin_seller_manage_detail_seller_box>div:nth-of-type(7)>div:nth-of-type(2) button {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
    padding: 5px 10px;
    border-radius: 6px;
}
.admin_seller_manage_detail_seller_box>div:nth-of-type(7)>div:nth-of-type(2)>div:nth-of-type(4) {
    padding-left: 5px;
}

.admin_seller_manage_detail_shop_info_box {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 0.7);
    padding: 10px;
}
.admin_seller_manage_detail_shop_info_box>div:nth-of-type(1) {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_seller_manage_detail_shop_info_box>div:nth-of-type(1)>div:nth-of-type(1) {
    width: 20%;
}
.admin_seller_manage_detail_shop_info_box>div:nth-of-type(1)>div:nth-of-type(2) {
    width: 80%;
}
.admin_seller_manage_detail_shop_info_box>div:nth-of-type(2) {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_seller_manage_detail_shop_info_box>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 20%;
}
.admin_seller_manage_detail_shop_info_box>div:nth-of-type(2)>div:nth-of-type(2) {
    width: 80%;
}
.admin_seller_manage_detail_shop_info_box>div:nth-of-type(3) {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_seller_manage_detail_shop_info_box>div:nth-of-type(3)>div:nth-of-type(1) {
    width: 20%;
}
.admin_seller_manage_detail_shop_info_box>div:nth-of-type(3)>div:nth-of-type(2) {
    width: 80%;
}
.admin_seller_manage_detail_shop_info_profile {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border: 1px solid rgba(241, 242, 243, 1);
    border-radius: 4px;
    padding: 5px;
}
.admin_seller_manage_detail_shop_info_profile img {
    width: 100%;
    aspect-ratio: 500 / 500;
    object-fit: cover;
}
.admin_seller_manage_detail_shop_info_box>div:nth-of-type(4) {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.admin_seller_manage_detail_shop_info_box>div:nth-of-type(4)>div:nth-of-type(1) {
    width: 20%;
}
.admin_seller_manage_detail_shop_info_box>div:nth-of-type(4)>div:nth-of-type(2) {
    width: 80%;
}
.admin_seller_manage_detail_shop_info_back {
    width: 500px;
    height: 200px;
    overflow: hidden;
    border: 1px solid rgba(241, 242, 243, 1);
    border-radius: 4px;
    padding: 5px;
}
.admin_seller_manage_detail_shop_info_back img {
    width: 100%;
    aspect-ratio: 700 / 400;
    object-fit: cover;
}
.admin_seller_manage_detail_calcul_info_box {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 0.7);
    padding: 10px;
}
.admin_seller_manage_detail_calcul_info_box>div:nth-of-type(1),
.admin_seller_manage_detail_calcul_info_box>div:nth-of-type(2),
.admin_seller_manage_detail_calcul_info_box>div:nth-of-type(3) {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_seller_manage_detail_calcul_info_box>div:nth-of-type(1)>div:nth-of-type(1),
.admin_seller_manage_detail_calcul_info_box>div:nth-of-type(2)>div:nth-of-type(1),
.admin_seller_manage_detail_calcul_info_box>div:nth-of-type(3)>div:nth-of-type(1) {
    width: 20%;
}
.admin_seller_manage_detail_calcul_info_box>div:nth-of-type(1)>div:nth-of-type(2),
.admin_seller_manage_detail_calcul_info_box>div:nth-of-type(2)>div:nth-of-type(2),
.admin_seller_manage_detail_calcul_info_box>div:nth-of-type(3)>div:nth-of-type(2) {
    width: 80%;
}
.admin_seller_manage_detail_calcul_info_box>div:nth-of-type(1)>div:nth-of-type(2) {
    display: flex;
    align-items: center;
}
.admin_seller_manage_detail_calcul_info_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid rgba(241, 242, 243, 1);
    padding: 5px;
    overflow: hidden;
}
.admin_seller_manage_detail_calcul_info_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) img {
    width: 100%;
    aspect-ratio: 100 / 100;
    object-fit: cover;
}
.admin_seller_manage_detail_calcul_info_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2) {
    padding-left: 5px;
}
.admin_seller_manage_detail_business_box {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 0.7);
    padding: 10px;
    border-radius: 4px;
}
.admin_seller_manage_detail_business_box>div:nth-of-type(1),
.admin_seller_manage_detail_business_box>div:nth-of-type(2),
.admin_seller_manage_detail_business_box>div:nth-of-type(3),
.admin_seller_manage_detail_business_box>div:nth-of-type(4),
.admin_seller_manage_detail_business_box>div:nth-of-type(5),
.admin_seller_manage_detail_business_box>div:nth-of-type(6),
.admin_seller_manage_detail_business_box>div:nth-of-type(7),
.admin_seller_manage_detail_business_box>div:nth-of-type(8),
.admin_seller_manage_detail_business_box>div:nth-of-type(9) {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_seller_manage_detail_business_box>div:nth-of-type(1)>div:nth-of-type(1),
.admin_seller_manage_detail_business_box>div:nth-of-type(2)>div:nth-of-type(1),
.admin_seller_manage_detail_business_box>div:nth-of-type(3)>div:nth-of-type(1),
.admin_seller_manage_detail_business_box>div:nth-of-type(4)>div:nth-of-type(1),
.admin_seller_manage_detail_business_box>div:nth-of-type(5)>div:nth-of-type(1),
.admin_seller_manage_detail_business_box>div:nth-of-type(6)>div:nth-of-type(1),
.admin_seller_manage_detail_business_box>div:nth-of-type(7)>div:nth-of-type(1),
.admin_seller_manage_detail_business_box>div:nth-of-type(8)>div:nth-of-type(1),
.admin_seller_manage_detail_business_box>div:nth-of-type(9)>div:nth-of-type(1) {
    width: 20%;
}
.admin_seller_manage_detail_business_box>div:nth-of-type(1)>div:nth-of-type(2),
.admin_seller_manage_detail_business_box>div:nth-of-type(2)>div:nth-of-type(2),
.admin_seller_manage_detail_business_box>div:nth-of-type(3)>div:nth-of-type(2),
.admin_seller_manage_detail_business_box>div:nth-of-type(4)>div:nth-of-type(2),
.admin_seller_manage_detail_business_box>div:nth-of-type(5)>div:nth-of-type(2),
.admin_seller_manage_detail_business_box>div:nth-of-type(6)>div:nth-of-type(2),
.admin_seller_manage_detail_business_box>div:nth-of-type(7)>div:nth-of-type(2),
.admin_seller_manage_detail_business_box>div:nth-of-type(8)>div:nth-of-type(2),
.admin_seller_manage_detail_business_box>div:nth-of-type(9)>div:nth-of-type(2) {
    width: 80%;
}
.admin_seller_manage_detail_business_box>div:nth-of-type(7)>div:nth-of-type(2) button,
.admin_seller_manage_detail_business_box>div:nth-of-type(9)>div:nth-of-type(2) button {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
    padding: 5px 10px;
    border-radius: 4px;
}

.admin_seller_manage_datail_select {
    width: 100%;
    height: 50px;
}
.admin_seller_update_btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin_seller_update_btn button {
    width: 300px;
    height: 50px;
    border: none;
    border-radius: 4px;
}
.admin_seller_update_save_btn {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.admin_refusal_modal_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}
.admin_refusal_modal_box {
    width: 50%;
    height: 50%;
    background-color: white;
    overflow: hidden;
    position: relative;
}
.admin_refusal_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(241, 242, 243, 1);
}
.admin_refusal_modal_header>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.admin_refusal_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
}
.admin_refusal_modal_content {
    padding: 20px;
}
.admin_refusal_modal_content>div:nth-of-type(1) {
    font-size: 0.9em;
}
.admin_refusal_modal_content>div:nth-of-type(2) {
    width: 100%;
}
.admin_refusal_modal_content>div:nth-of-type(2) textarea {
    width: 100%;
    resize: none;
    height: 300px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding: 10px;
    font-size: 0.9em;
}
.admin_refusal_modal_content>div:nth-of-type(2) textarea:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.admin_refusal_modal_footer {
    position: absolute;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.admin_refusal_modal_footer>div {
    width: 45%;
    margin: 0 5px;
}
.admin_refusal_modal_footer>div button {
    width: 100%;
    height: 40px;
}
.admin_refusal_modal_footer>div:nth-of-type(1) button {
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
    font-size: 0.9em;
    border-radius: 4px;
}
.admin_refusal_modal_footer>div:nth-of-type(2) button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    border-radius: 4px;
    color: white;
    font-size: 0.9em;
}

/* 셀러 상품 검수 */
.admin_seller_inspection_header {
    font-size: 1.2em;
}
.admin_seller_inspection_tap {
    width: 100%;
    display: flex;
}
.admin_seller_inspection_tap div {
    width: 100%;
    height: 30px;
}
.admin_seller_inspection_tap div button {
    width: 100%;
    border: none;
    background-color: inherit;
}
.admin_seller_inspection_active {
    border-bottom: 3px solid rgba(255, 3, 102, 1);
}
.admin_seller_inspection_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(196, 199, 202, 1);
    border-bottom: 1px solid rgba(196, 199, 202, 1);
}
.admin_seller_inspection_search_btn {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translate(0, -50%);
    border: none;
    background-color: inherit;
}
.admin_seller_inspection_approval button {
    border: none;
    background-color: rgba(241, 242, 243, 1);
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.9em;
    transition: all 0.2s;
}
.admin_seller_inspection_approval button:hover {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.admin_seller_inspection_approval button:nth-of-type(2) {
    margin-left: 5px;
}
.admin_seller_inspection_approval button:nth-of-type(3) {
    margin-left: 5px;
}
.admin_seller_inspection_approval button:nth-of-type(4) {
    margin-left: 5px;
}
.admin_seller_inspection_popup {

}
.admin_seller_inspection_title {
    font-size: 1.1em;
    font-weight: 500;
    padding-bottom: 10px;
}
.admin_seller_inspection_sub_title {
    font-size: 0.8em;
    color : rgba(255, 3, 102, 1);
}
.admin_seller_inspection_modal_btn_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    margin-top: 10px;
}
.admin_seller_inspection_modal_btn_box button:nth-of-type(1) {
    width: 45%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    background-color: inherit;
}
.admin_seller_inspection_modal_btn_box button:nth-of-type(2) {
    width: 45%;
    height: 40px;
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    border-radius: 4px;
}
.admin_order_inspection_search_box {
    display: flex;
}
.admin_seller_inspection_download {
    position: relative;
    display: flex;
    align-items: center;
    width: 90px;
    height: 30px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    background-color: inherit;
    transition: all 0.3s;
    font-size: 0.9em;
    margin-right: 5px;
}
.admin_seller_inspection_download:hover {
    background-color: rgba(255, 224, 232, 1);
}
.admin_seller_inspection_download>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translate(0, -50%);
    /* border: 1px solid red; */
}
.admin_seller_inspection_download>div:nth-of-type(2) {
    /* border: 1px solid blue; */
    position: absolute;
    top: 55%;
    right: 5px;
    transform: translate(0, -50%);
}
.admin_seller_inspection_download>div:nth-of-type(2) span {
    font-size: 1.3em;
}
.admin_seller_inspection_input {
    position: relative;
}
.admin_seller_inspection_input input {
    width: 200px;
    height: 30px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding-right: 30px;
    font-size: 0.8em;
    padding-left: 10px;
}
.admin_seller_inspection_product_header {
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
}
.admin_seller_inspection_product_header>div:nth-of-type(1) {width: 3%;}
.admin_seller_inspection_product_header>div:nth-of-type(2) {width: 5%;}
.admin_seller_inspection_product_header>div:nth-of-type(3) {width: 38%;}
.admin_seller_inspection_product_header>div:nth-of-type(4) {width: 8%;}
.admin_seller_inspection_product_header>div:nth-of-type(5) {width: 8%;}
.admin_seller_inspection_product_header>div:nth-of-type(6) {width: 8%;}
.admin_seller_inspection_product_header>div:nth-of-type(7) {width: 10%;}
.admin_seller_inspection_product_header>div:nth-of-type(8) {width: 10%;}
.admin_seller_inspection_product_header>div:nth-of-type(9) {width: 10%;}

.admin_seller_inspection_product_header>div:nth-of-type(1) input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_seller_inspection_product_header>div:nth-of-type(1) input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_seller_inspection_product_header>div:nth-of-type(1) input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_seller_inspection_product_header>div:nth-of-type(1) input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}

.admin_seller_inspection_product_content {
    width: 100%;
    display: flex;
    text-align: center;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
    padding: 10px 0;
    cursor: pointer;
    align-items: center;
    position: relative;
}
.admin_seller_inspection_product_content:hover {
    background-color: rgba(241, 242, 243, 1);
}
.admin_seller_inspection_product_content>div:nth-of-type(1) {width: 3%;}
.admin_seller_inspection_product_content>div:nth-of-type(2) {width: 5%; font-size: 0.8em;}
.admin_seller_inspection_product_content>div:nth-of-type(3) {width: 38%;}
.admin_seller_inspection_product_content>div:nth-of-type(4) {width: 8%; font-size: 0.8em;}
.admin_seller_inspection_product_content>div:nth-of-type(5) {width: 8%; font-size: 0.8em; overflow-y: scroll;}
.admin_seller_inspection_product_content>div:nth-of-type(6) {width: 8%;}
.admin_seller_inspection_product_content>div:nth-of-type(7) {width: 10%; font-size: 0.8em;}
.admin_seller_inspection_product_content>div:nth-of-type(8) {width: 10%; font-size: 0.8em;}
.admin_seller_inspection_product_content>div:nth-of-type(9) {width: 10%;}

.admin_seller_inspection_product_content>div:nth-of-type(1) input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_seller_inspection_product_content>div:nth-of-type(1) input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_seller_inspection_product_content>div:nth-of-type(1) input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_seller_inspection_product_content>div:nth-of-type(1) input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}

/* 내보내기 양식 */
.admin_product_excel_download_container {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_product_excel_download_box {
    width: 300px;
    height: 300px;
    background-color: white;
    overflow: hidden;
    border-radius: 4px;
}
.admin_product_excel_download_header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 0.7);
    position: relative;
}
.admin_product_excel_download_header>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.admin_product_excel_download_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
}
.admin_product_excel_download_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.admin_product_excel_download_content {
    width: 100%;
    height: calc(100% - 50px);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;    
}
.admin_product_excel_download_content button {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
    font-size: 0.9em;
    margin: 10px 0;
    transition: all 0.2s;
}
.admin_product_excel_download_content button:hover,
.admin_product_excel_download_content button:active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white;
}

.admin_product_excel_delivery_container {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_product_excel_delivery_box {
    width: 300px;
    height: auto;
    background-color: white;
    overflow: hidden;
    border-radius: 4px;
}
.admin_product_excel_delivery_header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 0.7);
    position: relative;
}
.admin_product_excel_delivery_header>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.admin_product_excel_delivery_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
}
.admin_product_excel_delivery_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.admin_product_excel_delivery_content {
    width: 100%;
    height: calc(100% - 50px);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;    
}
.admin_product_excel_delivery_content button {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
    font-size: 0.9em;
    margin: 10px 0;
    transition: all 0.2s;
}
.admin_product_excel_delivery_content button:hover,
.admin_product_excel_delivery_content button:active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white;
}

.admin_seller_rejection_reason {
    position: absolute;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    width: 150px;
    height: 150px;
    top: -100px;
    right: 0;
    background-color: rgb(255, 248, 206);
    color: rgba(66, 66, 66, 1);
    font-size: 0.8em;
    padding: 5px;
    text-align: left;
}

.admin_seller_inspection_product_info {
    display: flex;
    padding-right: 10px;
    padding-left: 10px;
}
.admin_seller_inspection_product_info>div:nth-of-type(1) img {
    width: 60px;
    height: 60px;
    border-radius: 8px;
}
.admin_seller_inspection_product_info>div:nth-of-type(2) {
    text-align: left !important;
    padding-left: 10px;
}
.admin_seller_inspection_product_info>div:nth-of-type(2)>div:nth-of-type(2) {
    font-size: 0.85em;
    width: 100%;
    height: 50px;
    overflow-y: auto;
    color: rgba(169, 173, 178, 1);
}
.admin_seller_inspection_btn_box>div:nth-of-type(1) {
    color: rgba(255, 114, 153, 1);
    font-size: 0.9em;
}
.admin_seller_inspection_btn_box>div:nth-of-type(2) button, .admin_seller_inspection_btn_box>div:nth-of-type(3) button {
    width: 100%;
    height: 30px;
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
    border-radius: 4px;
    margin: 3px 0;
    transition: all 0.3s;
    font-size: 0.8em;
}
.admin_seller_inspection_btn_box>div:nth-of-type(2) button:hover, .admin_seller_inspection_btn_box>div:nth-of-type(3) button:hover {
    background-color: rgba(255, 114, 153, 1);
    border: none;
    color: white;
}
.admin_seller_consignment_product_label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 25px;
    background-color: rgba(255, 3, 102, 0.6);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
}
.admin_seller_approval_popup {
    width: 80%;
}
.admin_seller_approval_content {
    width: 100%;
    display: flex;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 15px 20px;
    text-align: left !important;
    font-size: 0.9em;
}
.admin_seller_approval_content>div:nth-of-type(1) {
    width: 30%;
}
.admin_seller_approval_content>div:nth-of-type(2) {
    width: 70%;
    text-align: left !important;
}
.admin_seller_approval_content>div:nth-of-type(2) textarea {
    width: 100%;
    height: 300px;
    border: 1px solid rgba(196, 199, 202, 1);
    font-size: 0.9em;
    border-radius: 4px;
    padding-left: 10px;
    padding-top: 5px;
}
.admin_seller_approval_content>div:nth-of-type(2) textarea:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.admin_seller_approval_title {
    padding-top: 20px;
}
.admin_seller_approval_content {
    font-size: 0.8em;
    color: rgba(196, 199, 202, 1);
    text-align: center !important;
    justify-content: center;
    width: 100%;
    border-bottom: none ;
}

/* 셀러 상품 상세 ( 상품 검수 통해서 진입 ) */
.admin_seller_product_detail_header {
    font-size: 1.2em;
    padding-top: 20px;
    padding-bottom: 10px;
}
.admin_seller_product_detail_type {
    width: 100%;
    font-size: 1.1em;
    border-bottom: 2px solid rgba(255, 224, 232, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1em;
}
.admin_seller_product_detail_product_name {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
}
.admin_seller_product_detail_product_name>div:nth-of-type(1) {
    width: 30%;
}
.admin_seller_product_detail_product_name>div:nth-of-type(2) {
    width: 70%;
}
.admin_seller_product_detail_product_name>div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;

}
.admin_seller_product_detail_three_box {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
}
.admin_seller_product_detail_three_box>div:nth-of-type(1) {
    width: 30%;
}
.admin_seller_product_detail_three_box>div:nth-of-type(2) {
    width: 40%;
}
.admin_seller_product_detail_three_box>div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
}
.admin_seller_product_detail_three_box>div:nth-of-type(3) {
    width: 30%;
    font-size: 0.8em;
    padding-left: 10px;
    color: rgba(169, 173, 178, 1);
}
.admin_seller_product_detail_favority {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
}
.admin_seller_product_detail_favority>div:nth-of-type(1) {
    width: 30%;
}
.admin_seller_product_detail_favority>div:nth-of-type(2) {
    width: 70%;
}
.admin_seller_product_detail_favority>div:nth-of-type(2) span {
    background-color: rgba(241, 242, 243, 1);
    color: rgba(169, 173, 178, 1);
    font-size: 0.8em;
    border-radius: 30px;
    padding: 5px 10px;
    margin-right: 10px;
}
.admin_seller_product_detail_radio {
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
.admin_seller_product_detail_radio>div:nth-of-type(1) {
    width: 30%;
}
.admin_seller_product_detail_radio>div:nth-of-type(2) {
    width: 40%;
    display: flex;
    align-items: center;
}
.admin_seller_product_detail_radio>div:nth-of-type(3) {
    width: 30%;
    font-size: 0.8em;
    color: rgba(169, 173, 178, 1);
}
.admin_seller_product_detail_radio>div:nth-of-type(2) input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_seller_product_detail_radio>div:nth-of-type(2) input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_seller_product_detail_radio>div:nth-of-type(2) input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_seller_product_detail_radio>div:nth-of-type(2) input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_seller_product_detail_inventory {
    display: flex;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
}
.admin_seller_product_detail_inventory>div:nth-of-type(1) {
    width: 30%;
}
.admin_seller_product_detail_inventory>div:nth-of-type(2) {
    width: 35%;
    padding-right: 10px;
}
.admin_seller_product_detail_inventory>div:nth-of-type(3) {
    width: 35%;
    padding-left: 10px;
}
.admin_seller_product_detail_inventory>div:nth-of-type(2) input, .admin_seller_product_detail_inventory>div:nth-of-type(3) input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid rgba(196, 199, 202, 1);
}
.admin_seller_product_detail_image {
    width: 100%;
    display: flex;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 15px 0;
    align-items: center;
    font-size: 0.9em;
}
.admin_seller_product_detail_image>div:nth-of-type(1) {
    width: 30%;
}
.admin_seller_product_detail_image>div:nth-of-type(2) {
    width: 70%;
    display: flex;
    justify-content: space-between;
}
.admin_seller_product_detail_image>div:nth-of-type(2)>div {
    width: 150px;
    height: 150px;
    border: 1px solid rgba(241, 242, 243, 1);
    border-radius: 4px;
    overflow: hidden;
}
.admin_seller_product_detail_image>div:nth-of-type(2)>div img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}
.admin_seller_product_detail_product_content {
    width: 100%;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    height: 400px;
    overflow-y: auto;
    font-size: 0.9em;
}
.admin_seller_product_detail_delivery_box {
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    border-radius: 8px;
    padding: 15px;
}
.admin_seller_product_detail_delivery_header {
    display: flex;
    align-items: center;
    border-bottom: 2px solid rgba(255, 224, 232, 1);
    padding: 10px 0;
}
.admin_seller_product_detail_delivery_header>div:nth-of-type(1) span {
    background-color: rgba(241, 242, 243, 1);
    color: rgba(169, 173, 178, 1);
    font-size: 0.9em;
    padding: 5px 10px;
    border-radius: 4px;
}
.admin_seller_product_detail_delivery_header>div:nth-of-type(2) {
    font-size: 1.1em;
}
.admin_seller_product_detail_delivery_location {
    display: flex;
    align-items: center;
    padding: 15px 0;
}
.admin_seller_product_detail_delivery_location>div:nth-of-type(1) {}
.admin_seller_product_detail_delivery_location>div:nth-of-type(2) {
    font-size: 0.9em;
    color: rgba(66, 66, 66, 1);
}
.admin_seller_product_detail_delivery_info {
    display: flex;
    align-items: center;
    padding: 10px 0;
    font-size: 0.9em;
}
.admin_seller_product_detail_delivery_info>div:nth-of-type(1) {
    width: 40%;
}
.admin_seller_product_detail_delivery_info>div:nth-of-type(2) {
    width: 60%;
}
.admin_seller_product_detail_faq {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 15px 0;
    font-size: 0.9em;
}
.admin_seller_product_detail_faq>div:nth-of-type(1) {
    width: 30%;
}
.admin_seller_product_detail_faq>div:nth-of-type(2) {
    width: 70%;
    font-size: 0.8em;
    color: rgba(169, 173, 178, 1);
}
.admin_seller_product_detail_faq_select {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 15px 0;
    font-size: 0.9em;
}
.admin_seller_product_detail_faq_select>div:nth-of-type(1) {
    width: 30%;
}
.admin_seller_product_detail_faq_select>div:nth-of-type(2) {
    width: 70%;
}
.admin_seller_product_detail_faq_select>div:nth-of-type(2)>div {
    background-color: rgba(241, 242, 243, 1);
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 8px;
    font-size: 0.9em;
}
.admin_seller_product_detail_faq_select>div:nth-of-type(2)>div>div:nth-of-type(1) {
    padding-bottom: 5px;
}
.admin_seller_product_detail_faq_select>div:nth-of-type(2)>div>div span:nth-of-type(1) {
    color: rgba(255, 114, 153, 1);
}
.admin_seller_product_detail_input_box {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
}
.admin_seller_product_detail_input_box>div:nth-of-type(1) {
    width: 30%;
}
.admin_seller_product_detail_input_box>div:nth-of-type(2) {
    width: 40%;
}
.admin_seller_product_detail_input_box>div:nth-of-type(3) {
    width: 30%;
    font-size: 0.8em;
    color: rgba(169, 173, 178, 1);
}
.admin_seller_product_detail_input_box>div:nth-of-type(2) input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_seller_product_detail_input_box>div:nth-of-type(2) input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_seller_product_detail_input_box>div:nth-of-type(2) input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_seller_product_detail_input_box>div:nth-of-type(2) input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_seller_product_detail_input_content {
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 20px 0;
    font-size: 0.9em;
}
.admin_seller_product_detail_input_content>div:nth-of-type(1) {
    width: 30%;
}
.admin_seller_product_detail_input_content>div:nth-of-type(2) {
    width: 70%;
}
.admin_seller_product_detail_input_content_detail {
    display: flex;
    width: 100%;
    align-items: center;

}
.admin_seller_product_detail_input_content_detail>div:nth-of-type(1) {
    width: 100%;
}
.admin_seller_product_detail_input_content_detail>div:nth-of-type(1) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
}
.admin_seller_product_detail_input_content_detail>div:nth-of-type(2) {
    width: 100%;
}
.admin_seller_product_detail_btn_box {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}
.admin_seller_product_detail_input_content_detail>div:nth-of-type(2) input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_seller_product_detail_input_content_detail>div:nth-of-type(2) input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_seller_product_detail_input_content_detail>div:nth-of-type(2) input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_seller_product_detail_input_content_detail>div:nth-of-type(2) input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_seller_product_detail_btn_box>div {
    width: 250px;
}
.admin_seller_product_detail_btn_box>div button {
    width: 100%;
    height: 40px;
    font-size: 0.9em;
}
.admin_seller_product_detail_btn_box>div:nth-of-type(1) button {
    border: 1px solid rgba(66, 66, 66, 1);
    background-color: inherit;
}
.admin_seller_product_detail_btn_box>div:nth-of-type(2) button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_seller_product_detail_btn_box>div:nth-of-type(3) button {
    border: 1px solid rgba(255, 114, 153, 1);
    background-color: inherit;
    color: rgba(255, 114, 153, 1)
}

/* 셀러 상품 관리 */
.admin_seller_product_management_header {
    font-size: 1.2em;
}
.admin_seller_product_management_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(196, 199, 202, 1);
    border-bottom: 1px solid rgba(196, 199, 202, 1);
}
.admin_seller_product_management_state_mobile_btn {
    display: none;
    position: relative;
}
.admin_seller_product_management_state_mobile_btn>div:nth-of-type(1) button {
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 0.9em;
    background-color: inherit;
}
.admin_seller_product_management_state_mobile_btn_content {
    position: absolute;
    width: 100px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px;
    z-index: 100;
    background-color: white;
    top: 5px;
    right: -102px;
}
.admin_seller_product_management_state_mobile_btn_content button {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: inherit;
    font-size: 0.8em;
}
.admin_seller_product_management_state_btn button {
    border: 1px solid rgba(241, 242, 243, 1);
    background-color: inherit;
    padding: 0 10px;
    height: 30px;
    border-radius: 4px;
    font-size: 0.8em;
    margin-right: 5px;
    transition: all 0.3s;
}
.admin_seller_product_management_state_btn button:hover {
    background-color: rgba(255, 114, 153, 1);
    color: white;
}

/* 셀러 공지사항 */
.admin_seller_notice_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    font-size: 1.1em;
    font-weight: 500;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_seller_notice_content {
    margin-top: 5px;
}
.admin_seller_notice_search_box {
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid rgba(241, 242, 243, 1);
}
.admin_seller_notice_search_input {
    display: flex;
    justify-content: space-between;
}
.admin_seller_notice_search_input_txt input {
    width: 300px;
    height: 35px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding-left: 10px;
    font-size: 0.9em;
}
.admin_seller_notice_search_btn button {
    width: 65px;
    height: 35px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 4px;
    font-size: 0.9em;
}
.admin_seller_notice_datepicker {
    width: 300px;
    border-radius: 0;
}
.admin_seller_notice_datepicker input {
    text-align: center;
}
.admin_seller_notice_datepicker button {
    display: flex;
    align-items: center;
}
.admin_seller_notice_datepicker :global(.ant-picker-input) input {
    pointer-events: none !important;
    cursor: pointer !important;
}
.admin_seller_notice_table_header {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    font-size: 0.9em;
}
.admin_seller_notice_table_header>div:nth-of-type(1) {width: 15%;}
.admin_seller_notice_table_header>div:nth-of-type(2) {width: 65%;}
.admin_seller_notice_table_header>div:nth-of-type(3) {width: 20%;}
.admin_seller_notice_table_header>div:nth-of-type(4) {width: 10%;}
.admin_seller_notice_table_section {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 0.8em;
    cursor: pointer;
}
.admin_seller_notice_table_section:hover {
    background-color: rgba(241, 242, 243, 1);
}

.admin_seller_notice_table_section>div:nth-of-type(1) {width: 15%;}
.admin_seller_notice_table_section>div:nth-of-type(2) {width: 65%; text-align: left !important;}
.admin_seller_notice_table_section>div:nth-of-type(3) {width: 20%;}
.admin_seller_notice_table_section>div:nth-of-type(4) {width: 10%;}
.admin_seller_notice_table_section button {
    border: none;
    background-color: inherit;
    color: rgba(255, 3, 102, 1);
    text-decoration: underline;
}
.admin_seller_notice_table_no_data {
    width: 100%;
    text-align: center;
    font-size: 0.9em;
    padding-top: 20px;
}
.admin_seller_notice_add_box {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_seller_notice_add_box button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    font-size: 0.8em;
    padding: 5px 10px;
}

/* 공지사항 등록 */
.admin_seller_notice_add_title {
    width: 100%;
}
.seller_edit_upload_left {
    width: 15%;
}
.seller_edit_upload_middle {
    width: 50%;
    border-left: 1px solid rgba(223, 224, 226, 1);
}
.seller_edit_upload_right {
    width: 35%;
    font-size: 0.8em;
    color: rgba(196, 199, 202, 1);
}
.seller_edit_upload_btn {
    border: 1px solid rgba(255, 114, 153, 1);
    color: rgba(255, 114, 153, 1);
    background-color: inherit;
    width: 120px;
    height: 40px;
    border-radius: 8px;
    font-size: 0.9em;
    transition: all 0.3s;
}
.seller_edit_upload_btn:hover {
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.seller_edit_upload_input {
    border: 1px solid rgba(196, 199, 202, 1);
    color: rgba(196, 199, 202, 1);
    width: 300px;
    height: 40px;
    border-radius: 8px;
    margin-left: 15px;
}

.admin_seller_notice_add_title input {
    width: 100%;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    height: 50px;
    margin-bottom: 10px;
    padding-left: 10px;
}
.admin_seller_notice_add_title input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_seller_notice_add_btn_box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}
.admin_seller_notice_add_btn_box>div {
    width: 300px;
    padding: 10px;
}
.admin_seller_notice_add_btn_box>div button {
    width: 100%;
    height: 50px;
    border-radius: 4px;
}
.admin_seller_notice_add_btn_box>div:nth-child(1) button {
    border: 1px solid rgba(66, 66, 66, 1);
    background-color: inherit;
}
.admin_seller_notice_add_btn_box>div:nth-child(2) button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_seller_add_popup {
    border-radius: 4px !important;
}
.admin_notice_add_title {
    padding: 15px 0;
}
.admin_notice_add_content {
    font-size: 0.9em;
}
.admin_notice_add_btn_box {
    display: flex;
    align-items: center;
    justify-content: center !important;
    padding: 20px 0 !important;
}
.admin_notice_add_btn_box button {
    width: 30%;
    height: 40px;
}
.admin_notice_add_btn_box button:nth-of-type(1){
    background-color: inherit;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
}
.admin_notice_add_btn_box button:nth-of-type(2){
    background-color: rgba(255, 114, 153, 1);
    border: none;
    color: white;
    border-radius: 4px;
}

/* 셀러 공지사항 읽기 */
.admin_seller_notice_read_title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 224, 232, 1);
}
.admin_seller_notice_read_title>div:nth-of-type(2) {
    font-size: 0.8em;    
}
.admin_seller_notice_read_content {
    width: 100%;
    height: 500px;
    overflow-y: auto;
    border: 1px solid rgba(241, 242, 243, 1);
    border-radius: 4px;
    margin: 15px 0;
    padding: 10px;
}
.admin_seller_notice_read_content img {
    width: 100%;
    height: auto;
}
.admin_seller_notice_read_btn_box {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 0.9em;
    
}
.admin_seller_notice_read_btn_box>div:nth-of-type(1) button,
.admin_seller_notice_read_btn_box>div:nth-of-type(2) button,
.admin_seller_notice_read_btn_box>div:nth-of-type(3) button {
    width: 200px;
    height: 40px;
    border: 1px solid rgba(66, 66, 66, 1);
    background-color: inherit;
    border-radius: 4px;
    transition: all 0.2s;
}
.admin_seller_notice_read_btn_box>div:nth-of-type(1) button:hover, 
.admin_seller_notice_read_btn_box>div:nth-of-type(2) button:hover,
.admin_seller_notice_read_btn_box>div:nth-of-type(3) button:hover {
    background-color: rgba(255, 114, 153, 1);
    color: white;
    border: none
}

/* 셀러 FAQ */
.admin_seller_faq_header {
    padding: 20px 0;
    font-size: 1.2em;
}
.admin_seller_faq_delete_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid rgba(241, 242, 243, 1);
    padding-bottom: 10px;
}
.admin_seller_faq_delete_box button {
    border: none;
    background-color: rgba(223, 224, 226, 1);
    color: white;
    font-size: 0.8em;
    padding: 5px 20px;
}
.admin_seller_faq_add {
    padding: 5px 30px;
    font-size: 0.9em;
    display: flex;
    justify-content: end;
    align-items: center;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_seller_faq_add_form {
    display: flex;
    width: 100%;
    align-items: end;
    border-bottom: 1px solid rgba(196, 199, 202, 1);
    padding: 10px 0;
    background-color: rgba(241, 242, 243, 1);
}
.admin_seller_faq_add_form>div:nth-of-type(1) {width: 10%;}
.admin_seller_faq_add_form>div:nth-of-type(2) {width: 20%;}
.admin_seller_faq_add_form>div:nth-of-type(3) {width: 45%;}
.admin_seller_faq_add_form>div:nth-of-type(4) {width: 15%;}
.admin_seller_faq_add_form>div:nth-of-type(5) {width: 10%;}
.admin_seller_faq_add_form>div:nth-of-type(3) input {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid rgba(196, 199, 202, 1);
    width: 100%;
    font-size: 0.9em;
    padding-left: 20px;
    background-color: inherit;
}
.admin_seller_faq_add_form>div:nth-of-type(3) input:focus {
    outline: none;
    border-bottom: 1px solid rgba(255, 114, 153, 1);
}
.admin_seller_faq_add_form>div:nth-of-type(3) span {
    font-size: 0.9em;
}
.admin_seller_faq_add_form>div:nth-of-type(3) textarea {
    width: 100%;
    height: 200px;
    resize: none;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding: 10px;
    font-size: 0.9em;
}
.admin_seller_faq_add_form>div:nth-of-type(3) textarea:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.admin_middle_center {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -65%);
}
.admin_seller_faq_add_form button {
    border: none;
    width: 80%;
    font-size: 0.8em;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
}

.admin_seller_faq_add button {
    border: none;
    background-color: inherit;
    font-size: 0.9em;
    text-decoration: underline;
    transition: all 0.3s;
}
.admin_seller_faq_add button:hover {
    color: rgba(255, 3, 102, 1);
}
.admin_seller_faq_table_header {
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid rgba(196, 199, 202, 1);
    padding: 10px 0;
}
.admin_seller_faq_table_header>div:nth-of-type(1) {width: 10%;}
.admin_seller_faq_table_header>div:nth-of-type(2) {width: 20%;}
.admin_seller_faq_table_header>div:nth-of-type(3) {width: 45%;}
.admin_seller_faq_table_header>div:nth-of-type(4) {width: 15%;}
.admin_seller_faq_table_header>div:nth-of-type(5) {width: 10%;}
.admin_seller_faq_table_header>div:nth-of-type(1) input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_seller_faq_table_header>div:nth-of-type(1) input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_seller_faq_table_header>div:nth-of-type(1) input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_seller_faq_table_header>div:nth-of-type(1) input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}

.admin_seller_faq_table_content {
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 5px 0;
    font-size: 0.9em;
}
.admin_seller_faq_table_content>div:nth-of-type(1) {width: 10%;}
.admin_seller_faq_table_content>div:nth-of-type(2) {width: 20%;}
.admin_seller_faq_table_content>div:nth-of-type(3) {width: 45%;}
.admin_seller_faq_table_content>div:nth-of-type(4) {width: 15%;}
.admin_seller_faq_table_content>div:nth-of-type(5) {width: 10%;}
.admin_seller_faq_table_content>div:nth-of-type(1) input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_seller_faq_table_content>div:nth-of-type(1) input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_seller_faq_table_content>div:nth-of-type(1) input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_seller_faq_table_content>div:nth-of-type(1) input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_seller_faq_table_content>div:nth-of-type(5) {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 20px;
}
.admin_seller_faq_table_content>div:nth-of-type(5) button {
    border: none;
    background-color: inherit;
    font-size: 0.8em;
    text-decoration: underline;
}
.admin_seller_faq_table_content_answer {
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 5px 0;
    font-size: 0.9em;
    background-color: rgba(241, 242, 243, 1);
}
.admin_seller_faq_table_content_answer>div:nth-of-type(1) {width: 10%;}
.admin_seller_faq_table_content_answer>div:nth-of-type(2) {width: 20%;}
.admin_seller_faq_table_content_answer>div:nth-of-type(3) {width: 45%;}
.admin_seller_faq_table_content_answer>div:nth-of-type(4) {width: 15%;}
.admin_seller_faq_table_content_answer>div:nth-of-type(5) {width: 10%; padding-right: 10px; display: flex; align-items: end;}
.faq_content_update_btn {
    background-color: rgba(255, 114, 153, 1);
    color: white;
    padding: 5px 10px;
    font-size: 0.9em;
    border: none;
    border-radius: 4px;
}
.admin_seller_faq_table_udpate_input {
    position: relative;
    width: 100%;
    padding: 0 10px 0 0;
}
.admin_seller_faq_table_udpate_input > div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translate(0, -50%);
}
.admin_seller_faq_table_udpate_input > div:nth-of-type(2) input {
    width: 100%;
    border: 1px solid rgba(223, 224, 226, 1);
    height: 40px;
    border-radius: 4px;
    padding: 0 20px;
}
.admin_seller_faq_table_udpate_input > div:nth-of-type(2) input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.admin_seller_faq_table_udpate_textarea {
    position: relative;
    width: 100%;
    padding: 0 10px 0 0;
}
.admin_seller_faq_table_udpate_textarea > div:nth-of-type(1) {
    position: absolute;
    top: 6px;
    left: 10px;
}
.admin_seller_faq_table_udpate_textarea > div:nth-of-type(2) textarea {
    width: 100%;
    height: 150px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 5px 25px;
    resize: none;
}
.admin_seller_faq_table_udpate_textarea > div:nth-of-type(2) textarea:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.faq_content_update_cancel_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
}
.faq_content_update_cancel_btn > button {
    width: 40%;
    font-size: 0.9em;
    margin: 0 5px;
    height: 30px;
}
.faq_content_update_cancel_btn > button:nth-of-type(1) {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 4px;
}
.faq_content_update_cancel_btn > button:nth-of-type(2) {
    border: none;
    background-color: rgba(0, 115, 255, 1);
    color: white;
    border-radius: 4px;
}

/* 상품 */
.admin_shop_side_bar {
    width: 15%;
    overflow: hidden;
}
.admin_shop_side_check_rander {
    box-shadow: 0 0 4px 1px rgba(196, 199, 202, 1);
    margin: 10px;
    padding: 10px;
    font-size: 0.8em;
    border-radius: 4px;
}
.admin_shop_side_check_rander>div {
    display: flex;
    align-items: center;
    padding: 5px 0;
}
.admin_shop_side_check_rander>div input[type="checkbox"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_shop_side_check_rander>div input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_shop_side_check_rander>div input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_shop_side_check_rander>div input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_shop_category_setting {
    position: relative;
    font-size: 0.8em;
}
.admin_shop_catogory_open_box {
    display: none;
}

.admin_shop_category_setting_btn {
    display: flex;
    align-items: center;
    border: none;
    background-color: inherit;
}
.admin_shop_product_tap {
    width: 100%;
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_shop_product_mobile_active_tap {
    width: 100%;
    display: none;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_shop_product_mobile_tap {
    display: none;
    position: relative;
}
.admin_shop_product_mobile_tap>div:nth-of-type(1) button {
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    font-size: 0.8em;
    padding: 5px 10px;
    border-radius: 4px;
}
.admin_shop_product_tap_swiper :global(.swiper-slide) {
    width: auto !important; /* 버튼의 내용에 따라 크기가 조정되도록 설정 */
    flex: 0 0 auto; /* 플렉스박스 아이템이 줄어들지 않도록 설정 */
}
.admin_shop_product_tap_swiper button {
    max-width: 120px !important;
    margin: 0 5px; 
}
.admin_shop_product_mobile_tap_content {
    position: absolute;
    width: 150px;
    z-index: 100;
    box-shadow: 0 0 4px rgba(223, 224, 226, 1);
    background-color: white;
    padding: 5px;
    border-radius: 4px;
    top: 10px;
    right: -152px;
}
.admin_shop_product_mobile_tap_content button {
    width: 100%;
    height: 40px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    background-color: inherit;
    font-size: 0.8em;
}
.admin_shop_product_tap>div {
    width: 100%;
    height: 30px;
}
.admin_shop_product_tap button,
.admin_shop_product_mobile_active_tap button {
    width: 100%;
    border: none;
    background-color: inherit;
}
.admin_shop_product_tap_active {
    border-bottom: 3px solid rgba(255, 3, 102, 1);
}
.admin_shop_product_management_box {
    position: relative;
    display: flex;
}
.admin_product_popup_subtitle {
    font-size: 0.8em;
    color: rgba(255, 3, 102, 1);
}
.admin_shop_category_setting {width: 90%; margin: 10px; box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1); border-radius: 4px; padding: 15px;}
.admin_shop_product_management_content {width: 85%;}
.admin_shop_total_category {
    width: 100%;
}
.admin_shop_total_category_btn {
    width: 100%;
    border: none;
    background-color: inherit;
    text-align: left;
    padding: 3px 0;
    transition: all 0.2s;
}
.admin_shop_total_category_btn:hover {
    background-color: rgba(255, 114, 153, 1);
    border-radius: 4px;
    color: white;
}
.admin_shop_main_category {
    width: 100%;
    display: flex;
    align-items: center;
    border: none;
    background-color: inherit;
    transition: all 0.2s;
    padding: 3px 0;
}
.admin_shop_main_category:hover {
    background-color: rgba(255, 114, 153, 1);
    color: white;
    border-radius: 4px;
}
.admin_shop_middle_category {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    transition: all 0.2s;
}
.admin_shop_middle_category button {
    width: 100%;
    border: none;
    background-color: inherit;
    text-align: left;
    display: flex;
}
.admin_shop_middle_category:hover {
    background-color: rgba(255, 224, 232, 0.3);
    border-radius: 4px;
}
.fixedElement {
    position: sticky;
    top: 6.8%;
    right: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
    border: none;
}
.admin_shop_product_management_header {
    width: 100%;
    font-size: 1.2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.admin_shop_product_management_header>div:nth-of-type(2) {
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_shop_product_management_header button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    font-size: 0.8em;
    padding: 5px 10px;
    border-radius: 4px;
    transition: all 0.3s;
}
.admin_shop_product_management_header button:hover {
    background-color: white;
    color: rgba(255, 114, 153, 1);
    border: 1px solid rgba(255, 114, 153, 1);
}
.admin_shop_product_content {
    width: 100%;
    max-height: 500px;
    overflow-x: scroll;
}
.admin_shop_product_content::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 5px;
}
.admin_shop_product_content::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.admin_shop_product_content::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.admin_shop_product_content>div {
    min-width: 1200px;
}
/* 상품 추가 */
.admin_shop_product_add_box>div:nth-of-type(2) {
    width: 100%;
    display: flex;
}
.admin_shop_product_add_top {
    display: flex;
    width: 100%;
}
.admin_shop_product_add_product_box {
    width: 80%;
}
.admin_shop_side {
    width: 20%;
}
.admin_shop_side_box {
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
}
.admin_shop_product_add_top>div:nth-of-type(1) {
    width: 50%;
    height: 500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
}
.admin_shop_product_add_top>div:nth-of-type(2) {
    width: 50%;
    height: 500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
}
.admin_shop_product_add_img_box {
    width: 100%;
    height: 50%;
}
.admin_shop_product_add_img_box>div:nth-of-type(1) {
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: space-between;
    padding: 10px;
    font-size: 0.9em;
}
.admin_shop_product_add_img_box>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
    color: rgb(0, 115, 255);
    transition: all 0.2s;
}
.admin_shop_product_add_img_box>div:nth-of-type(1) button:hover {
    color: rgba(255, 114, 153, 1);
    text-decoration: underline;
}
.admin_shop_product_add_default_img {
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.admin_shop_product_add_default_img img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}
.admin_shop_product_add_default_img {
    line-height: 20px;
}
.admin_shop_product_add_default_img span {
    font-size: 0.8em;
    color: rgba(223, 224, 226, 1);

}
.admin_shop_product_add_main_select_img {
    width: 100%;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}
.admin_shop_product_add_select_img {
    width: 100%;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}
.admin_shop_product_add_main_select_img>div {
    width: 150px;
    height: auto;
    overflow: hidden;
    position: relative;
}
.admin_shop_product_add_main_select_img>div img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
    aspect-ratio: 500 / 500;
}
.admin_shop_product_add_main_select_img>div button {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
}
.admin_shop_product_add_main_select_img button>span {
    color: rgba(255, 3, 102, 1);
    font-weight: 500;
    font-size: 1.5em;
}
.admin_shop_product_add_select_img>div {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
}
.admin_shop_product_add_select_img>div img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
    aspect-ratio: 500 / 500;
}
.admin_shop_product_add_select_img>div button {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
}
.admin_shop_product_add_select_img button>span {
    color: rgba(255, 3, 102, 1);
    font-weight: 500;
    font-size: 1.5em;
}
.admin_shop_product_add_swiper :global(.swiper-slide) {
    width: auto !important; /* 버튼의 내용에 따라 크기가 조정되도록 설정 */
    flex: 0 0 auto; /* 플렉스박스 아이템이 줄어들지 않도록 설정 */
    /* width: 100% !important;
    height: auto !important; */
}
.admin_shop_product_add_swiper_item {
    position: relative;
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 4px;
}
.admin_shop_product_add_swiper_item button {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
}
.admin_shop_product_add_swiper_item button>span {
    color: rgba(255, 3, 102, 1);
    font-weight: 500;
    font-size: 1.5em;
}
.admin_shop_product_add_product_name {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 15px 0;
}
.admin_shop_product_add_product_name>div:nth-of-type(1) {
    width: 30%;
    font-size: 0.9em;
}
.admin_shop_product_add_product_name>div:nth-of-type(2) {
    width: 70%;
}
.admin_shop_product_add_product_name>div:nth-of-type(2) input {
    width: 100%;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    height: 40px;
    font-size: 0.8em;
    padding-left: 10px;
}
.admin_shop_product_add_select_box {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 15px 0;
}
.admin_shop_product_add_select_box>div:nth-of-type(1) {
    width: 30%;
}
.admin_shop_product_add_select_box>div:nth-of-type(2) {
    width: 70%;
}
.admin_shop_product_add_select_box span {
    font-size: 0.9em;
}
.admin_shop_product_add_select {
    width: 100%;
    height: 40px;
}
.admin_shop_product_badge_title_box {
    display: flex;
    justify-content: space-between;
    font-size: 0.8em;
}
.admin_shop_product_badge_title_box button {
    border: none;
    background-color: inherit;
    color: rgba(0, 115, 255, 1);
    transition: all 0.2s;
}
.admin_shop_product_badge_title_box button:hover {
    color: rgba(255, 114, 153, 1);
    text-decoration: underline;
}
.admin_shop_product_badge_content_box>div{
    position: relative;
}
.admin_shop_product_badge_content_box label {
    font-size: 0.8em;
}
.admin_shop_product_badge_content_box input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.admin_shop_product_badge_content_box input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}

.admin_shop_product_badge_content_box input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}

.admin_shop_product_badge_content_box input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}

.admin_shop_product_add_consignment_title {
    font-size: 0.8em;
    display: flex;
    align-items: center;
}
.admin_shop_product_add_consignment_title button {
    border: none;
    background-color: inherit;
    color: rgba(196, 199, 202, 1);
    margin-left: 3px;
    position: relative;
}
.admin_shop_product_add_approval_explan,
.admin_shop_product_add_consignment_explan,
.admin_shop_product_add_related_explan,
.admin_shop_product_add_favority_select_explan {
    position: absolute;
    width: 200px;
    height: auto;
    background-color: white;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    padding: 10px;
    border-radius: 4px;
    top: 0;
    right: calc(50% + (-220px));
    color: rgba(66, 66, 66, 1) !important;
    z-index: 100;
}
.admin_shop_product_add_approval_explan>div:nth-of-type(1),
.admin_shop_product_add_consignment_explan>div:nth-of-type(1) {
    text-align: left;
    font-weight: 600;
}
.admin_shop_product_add_approval_explan>div:nth-of-type(2) {
    padding-top: 10px;
    text-align: left;
}
.admin_shop_product_add_approval_explan>div:nth-of-type(3) {
    padding-top: 10px;
    text-align: left;
}
.admin_shop_product_add_approval_explan>div:nth-of-type(2)>div:nth-of-type(3),
.admin_shop_product_add_approval_explan>div:nth-of-type(3)>div:nth-of-type(3) {
    padding-left: 10px;
}
.admin_shop_product_add_approval_explan>div:nth-of-type(3)>div:nth-of-type(4) {
    padding-left: 15px;
}
.admin_shop_product_add_consignment_content {
    display: flex;
    align-items: center;
}
.admin_shop_product_add_consignment_content label {
    font-size: 0.8em;
    padding-top: 1px;
}
.admin_shop_product_add_consignment_content input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.admin_shop_product_add_consignment_content input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}

.admin_shop_product_add_consignment_content input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}

.admin_shop_product_add_consignment_content input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}

.admin_shop_product_add_discount_box {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    padding: 15px 0;
}
.admin_shop_product_add_discount_box>div:nth-of-type(1) {
    width: 30%;
}
.admin_shop_product_add_discount_box>div:nth-of-type(2) {
    width: 70%;
    display: flex;
}
.admin_shop_product_add_discount_box>div:nth-of-type(2) input[type="checkbox"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_shop_product_add_discount_box>div:nth-of-type(2) input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_shop_product_add_discount_box>div:nth-of-type(2) input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_shop_product_add_discount_box>div:nth-of-type(2) input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_shop_product_add_weight {
    width: 100%;
    display: flex;
}
.admin_shop_product_add_weight>div {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    margin: 10px;
    border-radius: 2px;
}
.admin_shop_product_add_weight>div>div:nth-of-type(2) {
    width: 100%;
    padding-top: 5px;
}
.admin_shop_product_add_weight>div>div:nth-of-type(2)>div:nth-of-type(1) {
    font-size: 0.9em;
}
.admin_shop_product_add_weight>div>div:nth-of-type(2)>div:nth-of-type(2) {
    width: 200px;
    position: relative;
}
.admin_shop_product_add_weight>div>div:nth-of-type(2)>div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding: 10px 30px 10px 10px;
    font-size: 0.9em;
    text-align: right;
}
.admin_shop_product_add_weight>div>div:nth-of-type(2)>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_shop_product_add_weight>div>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    font-size: 0.9em;
    color: rgba(223, 224, 226, 1);
}
.admin_shop_product_add_weight_info_box {
    border: none;
    background-color: inherit;
    position: relative;
}
.admin_shop_product_add_weight_info_box>span {
    color: rgba(196, 199, 202, 1);
    font-size: 1.1em;
    padding-left: 3px;
}
.admin_shop_product_add_weight_info {
    width: 300px;
    position: absolute;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 0.7);
    padding: 10px;
    border-radius: 4px;
    top: 0;
    left: 30px;
    font-size: 0.8em;
    z-index: 100;
    background-color: white;
    text-align: left;
}
.admin_shop_product_add_weight_info>div:nth-of-type(1) {font-weight: 500;}
.admin_shop_product_add_weight_info>div:nth-of-type(2) {}
.admin_shop_product_add_weight_info>div:nth-of-type(3) {border: 1px solid rgba(196, 199, 202, 1); padding: 5px; border-radius: 4px;}
.admin_shop_product_add_weight_info>div:nth-of-type(4) {padding-bottom: 4px;}
.admin_shop_product_add_weight_info>div:nth-of-type(4)>span:nth-of-type(1),
.admin_shop_product_add_weight_info>div:nth-of-type(5)>span:nth-of-type(1) {
    color: rgba(255, 114, 153, 1);
    font-weight: 500;
}
.admin_shop_product_add_weight_info>div:nth-of-type(5) {}
.adimn_shop_kg_active {
    color: black !important;
}

.admin_shop_product_add_middle {
    width: 100%;
    display: flex;
}
.admin_seller_auth {
    width: 100% !important;
    display: block !important;
}
.admin_seller_auth>div {
    width: 98.2% !important;
}
.admin_shop_product_add_middle>div:nth-of-type(1) {
    width: 50%;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    margin: 10px;
    border-radius: 2px;
}
.admin_shop_product_add_middle>div:nth-of-type(2) {
    width: 50%;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    margin: 10px;
    border-radius: 2px;
}
.admin_shop_product_add_related_radio {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    padding: 10px 0;
}

.admin_shop_product_add_related_radio input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.admin_shop_product_add_related_radio input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}

.admin_shop_product_add_related_radio input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}

.admin_shop_product_add_related_radio input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}
.admin_shop_product_add_related_true_btn {
    display: flex;
    align-items: center;
}
.admin_shop_product_add_related_true_btn>div:nth-of-type(1) button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    font-size: 0.9em;
    padding: 5px 10px;
    border-radius: 6px;
}
.admin_shop_product_add_related_true_btn>div:nth-of-type(2) button {
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
    color: rgba(169, 173, 178, 1);
    font-size: 0.9em;
    padding: 5px 10px;
    border-radius: 6px;
}
.admin_shop_product_add_price_setting_box {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding: 10px 0;
    font-size: 0.9em;
}
.admin_shop_product_add_price_setting_box>div:nth-of-type(1) {
    width: 30%;
}
.admin_shop_product_add_price_setting_box>div:nth-of-type(2) {
    width: 70%;
}
.admin_shop_product_add_price_setting_select {
    width: 150px;
    height: 40px;
}
.admin_shop_product_add_price_setting_box>div:nth-of-type(2) input {
    border: 1px solid rgba(196, 199, 202, 1);
    width: 100%;
    height: 40px;
    border-radius: 4px;
    padding-left: 10px;
    text-align: right;
    padding-right: 10px;
}
.admin_shop_product_add_price_setting_box>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.admin_shop_product_add_discount_rate_box {
    display: flex;
    align-items: center;
}
.admin_shop_product_add_discount_rate_box>div:nth-of-type(1) {width: 100px;}
.admin_shop_product_add_discount_rate_box>div:nth-of-type(1) input {width: 100%; text-align: right; padding-right: 10px;}
.admin_shop_product_add_discount_rate_box>div:nth-of-type(2) {width: 30px;}
.admin_shop_product_add_product_option_box {
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    border-radius: 4px;
    margin: 10px;
}
.admin_shop_product_add_product_option_box input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.admin_shop_product_add_product_option_box input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}

.admin_shop_product_add_product_option_box input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}

.admin_shop_product_add_product_option_box input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}
.admin_shop_product_add_product_option_box>div:nth-child(2) {
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
.admin_shop_product_add_option_add {
    display: flex;
    align-items: center;
}
.admin_shop_product_add_option_add>div:nth-of-type(1) button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 0.9em;
}
.admin_shop_product_add_option_add>div:nth-of-type(2) button {
    border: none ;
    background-color: rgba(241, 242, 243, 1);
    color: rgba(169, 173, 178, 1);
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 0.9em;
}
.admin_shop_product_add_option_add_combination{
    padding-left: 10px;
}
.admin_shop_product_add_option_add_combination input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_shop_product_add_option_add_combination input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_shop_product_add_option_add_combination input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_shop_product_add_option_add_combination input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_shop_product_add_option_add_combination label {
    font-size: 0.9em;
    padding-left: 5px;
}
.admin_shop_product_add_option_info {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding: 10px 0;
    font-size: 0.9em;
}
.admin_shop_product_add_option_info>div:nth-of-type(1) {
    width: 38%;
    padding: 0 10px 0 0;
}
.admin_shop_product_add_option_info>div:nth-of-type(2) {
    width: 38%;
    padding: 0 10px 0 0;
}
.admin_shop_product_add_option_info>div:nth-of-type(3) {
    width: 20%;
    padding: 20px;
}
.admin_shop_product_add_option_info input[type="text"] {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding-left: 10px;
}
.admin_shop_product_add_option_info>div:nth-of-type(4) {
    width: 4%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_shop_product_add_option_info>div:nth-of-type(4) button {
    background-color: rgba(255, 3, 102, 1);
    border: none;
    border-radius: 50%;
}
.admin_shop_product_add_option_info>div:nth-of-type(4) button>span {
    color: white;
}
.admin_shop_product_add_option_info input[type="text"]:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.admin_shop_product_add_option_value>div:nth-of-type(3) {
    padding: 5px 0 0 0;
}
.admin_shop_product_add_option_value>div:nth-of-type(3) span {
    font-size: 0.8em;
    color: rgba(169, 173, 178, 1);
}
.admin_shop_product_add_option_info>div:nth-of-type(3) input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_shop_product_add_option_info>div:nth-of-type(3) input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_shop_product_add_option_info>div:nth-of-type(3) input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_shop_product_add_option_info>div:nth-of-type(3) input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_combination_option_box {
    width: 100%;
}
.admin_combination_option_name_box_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: 500;
    padding: 0 0 10px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 0.7);
}
.admin_combination_option_name_box_header>div:nth-of-type(1) {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.admin_combination_option_name_box_header>div:nth-of-type(1)>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_combination_option_name_box_header>div:nth-of-type(2) {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 10px 0 30px;
}
.admin_combination_option_name_box_header>div:nth-of-type(2)>div {
    width: 33.3%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_combination_option_value_box {
    width: 100%;
    padding: 5px 0;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 0.7);
    margin: 10px 0;
}
.admin_combination_option_value_box_content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 0.9em;
}
.admin_combination_option_value_box_content>div:nth-of-type(1) {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.admin_combination_option_value_box_content>div:nth-of-type(1)>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}
.admin_combination_option_value_box_content>div:nth-of-type(2) {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 10px 0 30px;
}
.admin_combination_option_value_box_content>div:nth-of-type(2)>div {
    width: 33.3%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_combination_option_value_box_content>div:nth-of-type(2) input {
    width: calc(100% - 20px);
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding: 5px;
}
.admin_combination_option_value_box_content>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_combination_option_select_box {
    width: 100%;
}
.admin_shop_product_add_inventory_box {
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    margin: 10px;
    border-radius: 4px;
}
.admin_shop_product_add_inventory_radio {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    padding: 15px 0;
}
.admin_shop_product_add_inventory_box input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.admin_shop_product_add_inventory_box input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}

.admin_shop_product_add_inventory_box input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}

.admin_shop_product_add_inventory_box input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}

.admin_shop_product_add_inventory_option_value {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.85em;
}
.admin_shop_product_add_inventory_option_value>div:nth-of-type(1) {
    width: 20%;
    padding: 0 10px;
}
.admin_shop_product_add_inventory_option_value>div:nth-of-type(2) {
    width: 20%;
    padding: 0 10px;
}
.admin_shop_product_add_inventory_option_value>div:nth-of-type(3) {
    width: 15%;
    padding: 0 10px;
}
.admin_shop_product_add_inventory_option_value>div:nth-of-type(3)>div:nth-of-type(2) {
    display: flex;
    align-items: center;
    height: 40px;
    line-height: 40px;
}
.admin_shop_product_add_inventory_option_value>div:nth-of-type(4) {
    width: 40%;
    padding: 0 10px;
}
.admin_shop_product_add_inventory_option_value>div:nth-of-type(5) {
    width: 5%;
    padding: 0 5px;
}
.admin_shop_product_add_inventory_option_value>div:nth-of-type(5) button {
    width: 25px;
    height: 25px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    font-size: 0.9em;
    border-radius: 50%;
    color: white;
}
/* .admin_shop_product_add_inventory_option_value>div:nth-of-type(5) button span {
    font-size: 1.1em;
} */
.admin_shop_product_add_inventory_option_value input[type="text"] {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 30px;
}
.admin_shop_product_add_inventory_option_value input[type="text"]:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.admin_shop_product_add_inventory_option_price_txt {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
}
.admin_shop_product_add_inventory_option_state {
    display: flex;
    align-items: center;
}
.admin_shop_product_add_inventory_option_state>div:nth-child(1) {
    width: 50%;
    padding: 0 10px;
}
.admin_shop_product_add_inventory_option_state>div:nth-child(2) {
    width: 50%;
    padding: 0 10px;
}
.admin_shop_product_add_inventory_option_state input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding-left: 10px;
}
.admin_shop_product_add_inventory_option_state input:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.admin_shop_product_add_inventory_option_state_select {
    width: 100%;
    height: 40px;
}
.admin_shop_product_add_inventory_single_count {
    display: flex;
    align-items: center;
    padding: 5px 0;
    font-size: 0.9em;
}
.admin_shop_product_add_inventory_single_count>div:nth-of-type(1) {width: 30%;}
.admin_shop_product_add_inventory_single_count>div:nth-of-type(2) {width: 70%;}
.admin_shop_product_add_inventory_single_count input {
    border: 1px solid rgba(196, 199, 202, 1); 
    border-radius: 4px;
    width: 100px;
    height: 40px;
    padding-left: 10px;
}
.admin_shop_product_add_inventory_single_count input:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.admin_shop_product_add_product_detail {
    position: relative;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    margin: 10px;
    border-radius: 4px;
}
.admin_shop_product_add_delivery_form_box {
    display: flex;
    width: 100%;
}
.admin_shop_product_add_delivery_form_box>div:nth-of-type(1) {
    margin: 10px;
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
}
.admin_shop_product_add_delivery_box {
    display: flex;
    font-size: 0.9em;
    align-items: center;
}
.admin_shop_product_add_delivery_select {
    width: 100%;
    height: 40px;
}
.admin_shop_product_add_delivery_box>div:nth-of-type(1) {
    width: 30%;
}
.admin_shop_product_add_delivery_box>div:nth-of-type(2) {
    width: 70%;
}
.admin_shop_product_add_delivery_form_box>div:nth-of-type(2) {
    margin: 10px;
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
}
.admin_shop_product_add_delivery_templete_add button {
    border: none;
    background-color: inherit;
    color: rgba(0, 115, 255, 1);
    font-size: 0.9em;
}
.admin_shop_product_add_delevery_add {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100vh;
    z-index: 1000;
}
.admin_shop_product_add_delivery_active {
    height: 100vh;
    overflow: hidden;
}
.admin_shop_product_add_delevery_add>div {
    position: absolute;
    background-color: white;
    width: 80vh;
    height: 80vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    overflow-y: scroll;
}
.admin_shop_product_add_delevery_templete_info {
    font-size: 0.7em;
}
.admin_shop_product_add_delevery_templete_info>div {
    display: flex;
    padding: 5px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_shop_product_add_delevery_templete_info>div>div:nth-of-type(1) {width: 30%;}
.admin_shop_product_add_delevery_templete_info>div>div:nth-of-type(2) {width: 70%;}
.admin_shop_delivery_add_deliverytype_select, 
.admin_shop_delivery_add_dc_select, 
.admin_shop_delivery_add_paymethod_select {
    width: 200px;
}
.admin_shop_product_add_form_box {
    display: flex;
    align-items: center;
    font-size: 0.8em;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_shop_product_add_form_box>div:nth-of-type(1) {
    width: 35%;
}
.admin_shop_product_add_form_box>div:nth-of-type(2) {
    width: 65%;
}
.admin_shop_product_add_form_content_btn {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    padding: 0 10px;
    transition: all 0.3s;
}
.admin_shop_product_add_form_content_btn:hover {
    background-color: white;
    color: rgba(255, 114, 153, 1);
    border: 1px solid rgba(255, 114, 153, 1);
}
.admin_shop_product_add_form_box input[type="checkbox"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_shop_product_add_form_box input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_shop_product_add_form_box input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_shop_product_add_form_box input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}.admin_product_date_select {

}
.admin_product_date_select input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.admin_product_date_select input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}

.admin_product_date_select input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}

.admin_product_date_select input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}

.admin_shop_product_date_select {
    width: 100%;
    font-size: 0.8em;
    padding: 10px 0;
}
.admin_shop_product_date_select>div {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.admin_shop_product_date_select>div>div:nth-of-type(1) {
    width: 30%;
}
.admin_shop_product_date_select>div>div:nth-of-type(2) {
    width: 70%;
}
.admin_shop_product_date_select>div>div:nth-of-type(2) input {
    padding: 5px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
}

.admin_shop_product_add_faq_box {
    margin: 10px;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    position: relative;
}
.admin_shop_product_add_faq_box>div:nth-of-type(2) {
    width: 100%;
    display: flex;
    font-size: 0.9em;
    align-items: center;
    padding: 10px 0;
}
.admin_shop_product_add_faq_box>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 30%;
}
.admin_shop_product_add_faq_box>div:nth-of-type(2)>div:nth-of-type(2) {
    width: 70%;
}
.admin_shop_product_add_faq_box>div:nth-of-type(2)>div:nth-of-type(2) button {
    width: 250px;
    height: 40px;
    border: none;
    border-radius: 4px;
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_shop_product_add_faq_add_btn {
    position: absolute;
    top: 10px;
    right: 10px;
}
.admin_shop_product_add_faq_add_btn button {
    padding: 5px 10px;
    border: none;
    background-color: rgba(0, 115, 255, 1);
    font-size: 0.9em;
    border-radius: 4px;
    color: white;
}
.admin_shop_product_add_return_box {
    margin: 10px;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
}
.admin_shop_product_add_btn_box {
    width: 100%;
    text-align: center;
    padding: 20px 0;
}
.admin_product_add_btn_active {
    background-color: rgba(255, 114, 153, 1) !important;
    color: white !important;
}
.admin_shop_product_add_btn_box button {
    border: none;
    background-color: rgba(241, 242, 243, 1);
    color: rgba(169, 173, 178, 1);
    width: 250px;
    height: 50px;
    border-radius: 8px;
}
.admin_shop_product_add_delivery_add_close {
    position: absolute;
    width: 100%;
    text-align: right;
}
.admin_shop_product_add_delivery_add_close button {
    border: none;
    background-color: inherit;
    padding: 10px;
}
.admin_shop_product_add_content_input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.admin_shop_product_add_content_input input {
    width: 75%;
    border: 1px solid rgba(196, 199, 202, 1);
    height: 35px;
    margin: 5px 0;
    border-radius: 4px;
    font-size: 0.8em;
    padding: 0 10px;
}
.admin_shop_product_add_content_input input:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.admin_shop_product_add_content_input_delete {
    width: 20%;
    height: 35px;
    font-size: 0.7em;
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    border-radius: 4px;
}
/* 상품 추가 에디터 */
.admin_shop_product_add_editor_image_box {
    position: absolute;
    top: 60px;
    right: 20px;
    width: 50px;
    height: 50px;
    z-index: 100;
}
.admin_product_add_edit_upload_btn {
    border: 1px solid rgba(255, 114, 153, 1);
    color: rgba(255, 114, 153, 1);
    background-color: inherit;
    width: 30px;
    height: 30px;
    border-radius: 8px;
    font-size: 0.9em;
    transition: all 0.3s;
}
.admin_product_add_edit_upload_btn:hover {
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_shop_product_add_editor_image_txt {
    color: rgba(196, 199, 202, 1);
    font-size: 0.8em;
    padding: 5px;
}
.admin_shop_product_add_favority_select_box {
    width: 100%;
    position: relative;
}
.admin_shop_product_add_favority_select_box>div:nth-of-type(1) {
    display: flex;
    align-items: center;
}
.admin_shop_product_add_favority_select_box>div:nth-of-type(2) {
    width: 100%;
    font-size: 0.9em;
}
.admin_shop_product_add_favority_select_box>div:nth-of-type(2) textarea {
    width: 100%;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    resize: none;
    height: 60px;
    padding: 10px;
    font-size: 0.8em;
}
.admin_shop_product_add_favority_select_box>div:nth-of-type(2) textarea:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.admin_shop_product_add_favority_select_box>div:nth-of-type(3) {
    position: absolute;
    top: 0;
    right: 10px;
}
.admin_shop_product_add_favority_select_box>div:nth-of-type(3) button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    font-size: 0.9em;
    padding: 5px 10px;
    border-radius: 4px;
}
.showFavorityselect_active {
    overflow-y: hidden !important;
    max-height: 90vh !important;
}
.admin_product_add_favority_modal_container {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}
.admin_product_add_favority_modal_box {
    width: 80%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
}
.admin_product_add_favority_modal_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    position: relative;
}
.admin_product_add_favority_modal_header>div:nth-of-type(1) {
    font-size: 1.1em;
}
.admin_product_add_favority_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.admin_product_add_favority_modal_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.admin_product_add_modal_content_total_fav_list,
.admin_product_add_modal_content_select_fav_list {
    width: 40%;
    height: 45vh;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    padding: 20px;
    overflow-y: scroll;
    font-size: 0.9em;
}
.admin_product_add_modal_content_total_fav_list::-webkit-scrollbar,
.admin_product_add_modal_content_select_fav_list::-webkit-scrollbar {
    width: 5px;  /* 스크롤바의 너비 */
    height: 5px;
}
.admin_product_add_modal_content_total_fav_list::-webkit-scrollbar-thumb,
.admin_product_add_modal_content_select_fav_list::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.admin_product_add_modal_content_total_fav_list::-webkit-scrollbar-track,
.admin_product_add_modal_content_select_fav_list::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.admin_product_add_modal_content_total_fav_list>div,
.admin_product_add_modal_content_select_fav_list>div {
    display: flex;
    align-items: center;
    padding: 5px;
}
.admin_product_add_modal_content_total_fav_list>div>div:nth-of-type(2),
.admin_product_add_modal_content_select_fav_list>div>div:nth-of-type(2) {
    display: flex;
    align-items: center;
}
.admin_product_add_modal_content_total_fav_list>div>div:nth-of-type(2)>div:nth-of-type(1),
.admin_product_add_modal_content_select_fav_list>div>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 4px;
}
.admin_product_add_modal_content_total_fav_list>div>div:nth-of-type(2)>div:nth-of-type(1) img,
.admin_product_add_modal_content_select_fav_list>div>div:nth-of-type(2)>div:nth-of-type(1) img {
    width: 100%;
    height: 60px;
    object-fit: cover;
}
.admin_product_add_modal_content_total_fav_list>div>div:nth-of-type(2)>div:nth-of-type(2),
.admin_product_add_modal_content_select_fav_list>div>div:nth-of-type(2)>div:nth-of-type(2) {
    padding-left: 5px;
}
.admin_product_add_modal_content_total_fav_list>div>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(1),
.admin_product_add_modal_content_select_fav_list>div>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(1) {
    color: rgba(169, 173, 178, 1);
    font-size: 0.9em;
}
.admin_product_add_modal_content_total_fav_list>div>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(2),
.admin_product_add_modal_content_select_fav_list>div>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(2) {
    color: rgba(255, 114, 153, 1);
}
.admin_product_add_modal_content_total_fav_list>div>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(3),
.admin_product_add_modal_content_select_fav_list>div>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(3) {
    color: rgba(169, 173, 178, 1);
    font-size: 0.9em;
}
.admin_product_add_favority_modal_content_info {
    width: 100%;
    padding: 20px;
    height: 60vh;
    overflow-y: scroll;
}
.admin_product_add_favority_modal_content_info>div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9em;
}
.admin_product_add_favority_modal_content_info>div:nth-of-type(1)>div:nth-of-type(1) {width: 40%; text-align: center; padding-bottom: 5px;}
.admin_product_add_favority_modal_content_info>div:nth-of-type(1)>div:nth-of-type(2) {width: 40%; text-align: center; padding-bottom: 5px;}
.admin_product_add_favority_modal_content_info input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_product_add_favority_modal_content_info input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_product_add_favority_modal_content_info input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_product_add_favority_modal_content_info input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_product_add_favority_modal_footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_product_add_favority_modal_footer>div {
    width: 35%;
    margin: 0 10px;
}
.admin_product_add_favority_modal_footer>div:nth-of-type(1) button {
    width: 100%;
    height: 40px;
    font-size: 0.9em;
    border: 1px solid rgba(169, 173, 178, 1);
    background-color: inherit;
    border-radius: 4px;
}
.admin_product_add_favority_modal_footer>div:nth-of-type(2) button {
    width: 100%;
    height: 40px;
    font-size: 0.9em;
    border-radius: 4px;
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_badge_add_modal_container {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
    top: 0;
    left: 0;
}
.admin_badge_add_modal_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
}
.admin_badge_add_modal_header {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: center;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
}
.admin_badge_add_modal_header>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 600;
}
.admin_badge_add_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
}
.admin_badge_add_modal_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.admin_badge_add_modal_content {
    width: 100%;
    padding: 20px 20px 10px 20px;
}
.admin_badge_add_modal_content_info {
    width: 100%;
    display: flex;
    font-size: 0.9em;
}
.admin_badge_add_modal_content_info>div:nth-of-type(1) {
    width: 50%;
}
.admin_badge_add_modal_content_info>div:nth-of-type(1)>div {
    display: flex;
    align-items: center;
    padding: 5px 0;
}
.admin_badge_add_modal_content_info>div:nth-of-type(1)>div>div:nth-of-type(1) {width: 20%;}
.admin_badge_add_modal_content_info>div:nth-of-type(1)>div>div:nth-of-type(2) {width: 80%;}
.admin_badge_add_modal_content_info>div:nth-of-type(1)>div>div:nth-of-type(2) input[type="text"] {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 0 10px;
    font-size: 0.9em;
}
.admin_badge_add_modal_content_info>div:nth-of-type(1)>div>div:nth-of-type(2) input[type="text"]:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_badge_add_modal_content_info>div:nth-of-type(2) {
    width: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_badge_add_modal_content_list {
    width: 100%;
    height: 15vh;
    overflow-y: auto;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 5px;
}
.admin_badge_add_modal_content_list_header {
    width: 100%;
    height: 30px;
    border-bottom: 1px solid rgba(255, 224, 232, 1);
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 0.9em;
    font-weight: 500;
}
.admin_badge_add_modal_content_list_header>div:nth-of-type(1) {width: 20%;}
.admin_badge_add_modal_content_list_header>div:nth-of-type(2) {width: 20%;}
.admin_badge_add_modal_content_list_header>div:nth-of-type(3) {width: 20%;}
.admin_badge_add_modal_content_list_header>div:nth-of-type(4) {width: 20%;}
.admin_badge_add_modal_content_list_header>div:nth-of-type(5) {width: 20%;}

.admin_badge_add_modal_content_list_content {
    width: 100%;
    /* height: 30px; */
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 0.8em;
    font-weight: 500;
    padding: 5px 0;
}
.admin_badge_add_modal_content_list_content>div:nth-of-type(1) {width: 20%;}
.admin_badge_add_modal_content_list_content>div:nth-of-type(2) {width: 20%;}
.admin_badge_add_modal_content_list_content>div:nth-of-type(3) {width: 20%;}
.admin_badge_add_modal_content_list_content>div:nth-of-type(4) {width: 20%;}
.admin_badge_add_modal_content_list_content>div:nth-of-type(5) {width: 20%;}

.admin_badge_add_modal_footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_badge_add_modal_footer>div {
    width: 35%;
    margin: 0 10px;
}
.admin_badge_add_modal_footer>div button {
    width: 100%;
    height: 40px;
    border-radius: 4px;
}
.admin_badge_add_modal_footer>div:nth-of-type(1) button {
    border: 1px solid rgba(223, 224, 226, 1) !important;
    background-color: inherit;
    font-size: 0.9em;
}
.admin_badge_add_modal_footer>div:nth-of-type(2) button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    font-size: 0.9em;
}

/* 주문 탭 */
.admin_seller_order_management_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(196, 199, 202, 1);
    border-bottom: 1px solid rgba(196, 199, 202, 1);
}
.admin_seller_order_management_state_btn {
    display: flex;
}
.admin_seller_order_management_state_btn>div button {
    border: 1px solid rgba(241, 242, 243, 1);
    background-color: inherit;
    padding: 0 10px;
    height: 30px;
    border-radius: 4px;
    font-size: 0.8em;
    margin-right: 5px;
    transition: all 0.3s;
}
.admin_seller_order_management_state_btn>div button:hover {
    background-color: rgba(241, 242, 243, 1);
}
.admin_shop_order_other_btn {
    width: 150px;
    position: absolute;
    top: 100%;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    border-radius: 8px;
    background-color: white;
    z-index: 100;
}
.admin_shop_order_other_btn button {
    width: 100%;
    text-align: left;
    height: 40px !important;
    transition: all 0.3s;
}
.admin_shop_order_other_btn button:hover {
    background-color: rgba(241, 242, 243, 1);
}
.admin_order_content {
    position: relative;
}
.admin_order_content_box {
    width: 100%;
    max-height: 500px;
    overflow-x: scroll;
}
.admin_order_content_box::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 5px;
}
.admin_order_content_box::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.admin_order_content_box::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.admin_order_content_box>div {
    min-width: 1300px;
}
.admin_order_header {
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
}
.admin_order_header>div:nth-of-type(1) {width: 5%; }
.admin_order_header>div:nth-of-type(2) {width: 10%;}
.admin_order_header>div:nth-of-type(3) {width: 30%; }
.admin_order_header>div:nth-of-type(4) {width: 8%; }
.admin_order_header>div:nth-of-type(5) {width: 5%;}
.admin_order_header>div:nth-of-type(6) {width: 10%;}
.admin_order_header>div:nth-of-type(7) {width: 10%;}
.admin_order_header>div:nth-of-type(8) {width: 10%;}
.admin_order_header>div:nth-of-type(9) {width: 15%;}

.admin_order_header>div:nth-of-type(1) input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_order_header>div:nth-of-type(1) input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_order_header>div:nth-of-type(1) input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_order_header>div:nth-of-type(1) input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_order_content_user_info {
    position: absolute;
    top: 50px;
    left: 10px;
}
.admin_order_content_user_info button {
    border: none;
    background-color: rgba(223, 224, 226, 1);
    padding: 3px 7px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}
.admin_order_content_user_info button>span {
    font-size: 1.4em;
}
.admin_order_content_form_info {
    position: absolute;
    top: 80px;
    left: 10px;
}
.admin_order_content_form_info button {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    padding: 3px 7px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: white;
}
.admin_order_user_modal_container {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_order_user_modal_box {
    width: 50%;
    height: 80%;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
}
.admin_order_user_modal_header {
    position: relative;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_order_user_modal_header>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.admin_order_user_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
}
.admin_order_user_modal_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.admin_order_user_modal_content {
    width: 100%;
    padding: 20px;
    height: calc(100% - 50px);
    overflow-y: auto;
}
.admin_order_user_modal_content_user_profile {
    width: 100%;
    display: flex;
}
.admin_order_user_modal_content_user_profile_img {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
}
.admin_order_user_modal_content_user_profile_img>div:nth-of-type(1) {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
}
.admin_order_user_modal_content_user_profile_img>div:nth-of-type(1) img {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 100 / 100;
}
.admin_order_user_modal_content_user_profile_img>div:nth-of-type(2) {
    padding: 10px 0;
}
.admin_order_user_modal_content_user_profile_img>div:nth-of-type(2) button {
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: rgba(241, 242, 243, 1);
    font-size: 0.8em;
    padding: 3px 10px;
    border-radius: 4px;
    transition: all 0.2s;
}
.admin_order_user_modal_content_user_profile_img>div:nth-of-type(2) button:hover {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.admin_order_user_modal_content_user_profile_info {
    width: 70%;
}
.admin_order_user_modal_content_user_profile_info>div {
    width: 100%;
    display: flex;
    font-size: 0.9em;
    padding-bottom: 20px;
}
.admin_order_user_modal_content_user_profile_info>div>div:nth-of-type(1) {
    width: 30%;
    text-align: right;
    color: rgba(169, 173, 178, 1);
}
.admin_order_user_modal_content_user_profile_info>div>div:nth-of-type(2) {
    width: 70%;
    padding-left: 15px;
}
.admin_order_user_modal_content_user_orders {
    width: 100%;
}
.admin_order_user_modal_content_user_orders>div:nth-of-type(1) {
    width: 100%;
    padding: 20px 0 5px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}

.admin_order_content input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_order_content input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_order_content {
    width: 100%;
    display: flex;
    text-align: center;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.8em;
    padding: 15px 0;
    cursor: pointer;
    transition: all 0.2s;
}
.admin_order_content:hover {
    background-color: rgba(241, 242, 243, 1);
}
.admin_order_content>div:nth-of-type(1) {width: 5%;}
.admin_order_content>div:nth-of-type(2) {width: 10%;}
.admin_order_content>div:nth-of-type(3) {width: 50%;}
.admin_order_content>div:nth-of-type(4) {width: 10%;}
.admin_order_content>div:nth-of-type(5) {width: 10%; overflow-x: scroll;}
.admin_order_content>div:nth-of-type(6) {width: 15%;}

.admin_order_content input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_order_content input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_order_content_product {
    display: flex;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
.admin_order_content_product>div:nth-of-type(1) {
    width: 58%;
}
.admin_order_content_product>div:nth-of-type(2) {
    width: 12%;
}
.admin_order_content_product>div:nth-of-type(3) {
    width: 16%;
}
.admin_order_content_product>div:nth-of-type(4) {
    width: 12%;
}
.admin_order_content_product_order {
    display: flex;
    padding: 0 5px;
}
.admin_order_content_product_order>div:nth-of-type(2) {
    padding-left: 5px;
    width: 60px;
    height: 60px;
    border-radius: 4px;
}
.admin_order_content_product_order>div:nth-of-type(2)>img {
    width: 60px;
    height: 60px;
    object-fit: cover;

}
.admin_order_content_product_order>div:nth-of-type(3) {
    padding-left: 10px;
}

.admin_order_content>div:nth-of-type(4) input {
    width: 100%;
    height: 30px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding-left: 5px;
    font-size: 0.9em;
}
.admin_order_content>div:nth-of-type(4) input:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.admin_order_content>div:nth-of-type(4) input::placeholder {
    color: rgba(223, 224, 226, 1);
}
.admin_order_delivery_select {
    width: 100%;
    height: 30px;
}
.admin_order_delivery_select :global(.ant-select-selector) {
    border: 1px solid rgba(169, 173, 178, 1) !important;
    border-radius: 4px !important;
}
.admin_order_delivery_select :global(.ant-select-selection-placeholder) {
    font-size: 0.8em;
}
.admin_order_event_label {
    position: absolute;
    top: 60px;
    left: 10px;
    background-color: rgba(0, 115, 255, 1);
    color: white;
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center;    
    justify-content: center;
}
.admin_order_each_delivery {
    width: 100%;
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
    border-radius: 4px;
    margin-top: 5px;
    height: 30px;
    transition: all 0.2s;
}
.admin_order_each_delivery:hover {
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_order_delivery_info_update {
    width: 100%;
    margin: 5px 0;
}
.admin_order_delivery_info_update button {
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    width: 100%;
    height: 30px;
    border-radius: 4px;
    font-size: 0.9em;
    transition: all 0.2s;
}
.admin_order_delivery_info_update button:hover,
.admin_order_delivery_info_update button:active {
    background-color: rgba(255, 114, 153, 1) !important;
    color: white !important;
}
.admin_seller_order_delivery_delay_container {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_seller_order_delivery_delay_box {
    position: relative;
    width: 50%;
    height: 50%;
    background-color: white;
    border-radius: 6px;
    overflow: hidden;
}
.admin_overflow_y_hidden {
    height: 90vh;
    overflow: hidden !important;
}
.admin_seller_order_delivery_delay_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.admin_seller_order_delivery_delay_header>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.admin_seller_order_delivery_delay_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
}
.admin_seller_order_delivery_delay_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.admin_seller_order_delivery_delay_content {
    width: 100%;
    height: calc(100% - 50px - 80px);
    overflow-y: auto;
}
.admin_seller_order_delivery_delay_item {
    width: 100%;
    padding: 20px;
}
.admin_seller_order_delivery_delay_item_info {
    width: 100%;
    display: flex;
    align-items: center;
}
.admin_seller_order_delivery_delay_item_info>div:nth-of-type(1) {width: 20%; overflow: hidden;}
.admin_seller_order_delivery_delay_item_info>div:nth-of-type(1) img {
    width: auto;
    height: 100px;
    aspect-ratio: 100 / 100;
    object-fit: cover;
    border-radius: 4px;
}
.admin_seller_order_delivery_delay_item_info>div:nth-of-type(2) {width: 80%; font-size: 0.9em;}
.admin_seller_order_delivery_delay_item_info>div:nth-of-type(2)>div:nth-of-type(1) {font-size: 0.9em; color: rgba(169, 173, 178, 1);}
.admin_seller_order_delivery_delay_item_info>div:nth-of-type(2)>div:nth-of-type(2) {font-weight: 500;}
.admin_seller_order_delivery_delay_item_info>div:nth-of-type(2)>div:nth-of-type(3) {font-size: 0.9em; color: rgba(255, 3, 102, 1);}
.admin_seller_order_delivery_delay_text_area {
    width: 100%;
    padding-top: 5px;
    position: relative;
}
.admin_seller_order_delivery_delay_text_area textarea {
    resize: none;
    width: 100%;
    height: 80px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 10px;
    font-size: 0.9em;
}
.admin_seller_order_delivery_delay_text_area textarea:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_seller_order_delivery_delay_text_area>div {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 0.9em;
    color: rgba(0, 115, 255, 1);
}
.admin_seller_order_delivery_delay_footer {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_seller_order_delivery_delay_footer >div{
    width: 45%;
    padding: 0 10px;
}
.admin_seller_order_delivery_delay_footer > div:nth-of-type(1) button {
    width: 100%;
    height: 50px;
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    font-size: 0.9em;
    border-radius: 4px;
}
.admin_seller_order_delivery_delay_footer > div:nth-of-type(2) button {
    width: 100%;
    height: 50px;
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    font-size: 0.9em;
    border-radius: 4px;
}

/* 취소 */
.admin_shop_cancel_box {
    max-width: 100%;
    overflow: hidden;
}
.admin_shop_cancel_header {
    font-size: 1.2em;
    padding: 10px 0;
}
.admin_shop_cancel_tap,
.admin_shop_return_tap {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_shop_cancel_tap>div,
.admin_shop_return_tap>div {
    width: 100%;
}
.admin_shop_cancel_tap>div button,
.admin_shop_return_tap button,
.admin_shop_cancel_mobile_tap button {
    width: 100%;
    height: 30px;
    border: none;
    background-color: inherit;
}
.admin_shop_cancel_tap_active {
    border-bottom: 3px solid rgba(255, 3, 102, 1);
}
.admin_shop_cancel_mobile_tap {
    display: none;
}
.admin_shop_cancel_progress_btn {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_shop_cancel_progress_btn>div button {
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
    padding: 3px 10px;
    border-radius: 4px;
    font-size: 0.9em;
    transition: all 0.2s;
}
.admin_shop_cancel_progress_btn>div button:hover {
    background-color: rgba(255, 224, 232, 0.5);
}
.admin_shop_exchange_progress_btn {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_shop_exchange_progress_btn>div button {
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
    padding: 3px 10px;
    border-radius: 4px;
    font-size: 0.9em;
    transition: all 0.2s;
}
.admin_shop_exchange_progress_btn>div button:hover {
    background-color: rgba(255, 224, 232, 0.5);
}
.admin_shop_exchange_progress_mobile_btn {
    display: none;
    position: relative;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 5px 0;
}
.admin_shop_exchange_progress_mobile_btn > div:nth-of-type(1) button {
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    background-color: inherit;
    font-size: 0.9em;
    padding: 5px 10px;
}
.admin_shop_exchange_progress_mobile_btn_content {
    position: absolute;
    width: 150px;
    font-size: 0.8em;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    padding: 5px;
    border-radius: 4px;
    background-color: white;
    z-index: 500;
    top: 10px;
    right: -152px;
}
.admin_shop_exchange_progress_mobile_btn_content button {
    width: 100%;
    height: 30px;
    border: none;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
}
.admin_shop_return_progress_mobile_btn {
    display: none;
    position: relative;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 5px 0;
}
.admin_shop_return_progress_mobile_btn > div:nth-of-type(1) button {
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    background-color: inherit;
    font-size: 0.9em;
    padding: 5px 10px;
}
.admin_shop_return_progress_mobile_btn_content {
    position: absolute;
    width: 150px;
    font-size: 0.8em;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    padding: 5px;
    border-radius: 4px;
    background-color: white;
    z-index: 500;
    top: 10px;
    right: -152px;
}
.admin_shop_return_progress_mobile_btn_content button {
    width: 100%;
    height: 30px;
    border: none;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
}
.admin_shop_cancel_progress_other_box {
    position: absolute;
    width: 200px;
    background-color: white;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    z-index: 999;
}
.admin_shop_cancel_progress_other_box button {
    width: 100%;
    height: 35px;
    border: none !important;
    border-bottom: 1px solid rgba(223, 224, 226, 1) !important;
    border-radius: 0 !important;
}
.admin_shop_cancel_content_box {
    width: 100%;
    max-height: 500px;
    overflow-x: scroll;
}
.admin_shop_cancel_content_box::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 5px;
}
.admin_shop_cancel_content_box::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.admin_shop_cancel_content_box::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.admin_shop_cancel_content_box>div {
    min-width: 1700px;
}
.admin_shop_cancel_content_header {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
    text-align: center;
}
.admin_shop_cancel_content_header input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_shop_cancel_content_header input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_shop_cancel_content_header input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_shop_cancel_content_header input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_shop_cancel_content_header>div:nth-of-type(1) {
    width: 3%;
}
.admin_shop_cancel_content_header>div:nth-of-type(2) {
    width: 10%;
}
.admin_shop_cancel_content_header>div:nth-of-type(3) {
    width: 20%;
}
.admin_shop_cancel_content_header>div:nth-of-type(4) {
    width: 7%;
}
.admin_shop_cancel_content_header>div:nth-of-type(5) {
    width: 3%;
}
.admin_shop_cancel_content_header>div:nth-of-type(6) {
    width: 7%;
}
.admin_shop_cancel_content_header>div:nth-of-type(7) {
    width: 5%;
}
.admin_shop_cancel_content_header>div:nth-of-type(8) {
    width: 10%;
}
.admin_shop_cancel_content_header>div:nth-of-type(9) {
    width: 12.5%;
}
.admin_shop_cancel_content_header>div:nth-of-type(10) {
    width: 10%;
}
.admin_shop_cancel_content_header>div:nth-of-type(11) {
    width: 12.5%;
}
.admin_shop_cancel_content_content {
    width: 100%;
    min-width: 1000px;
    overflow-x: auto;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
    display: flex;
    cursor: pointer;
    transition: all 0.2s;
}
.admin_shop_cancel_content_content:hover {
    background-color: rgba(241, 242, 243, 1);
}
.admin_shop_cancel_content_content>div:nth-of-type(1) {width: 3%; text-align: center;}
.admin_shop_cancel_content_content>div:nth-of-type(2) {width: 10%; font-size: 0.9em;}
.admin_shop_cancel_content_content>div:nth-of-type(3) {width: 20%; display: flex; padding: 0 5px;}
.admin_shop_cancel_content_content>div:nth-of-type(4) {width: 7%; text-align: center;}
.admin_shop_cancel_content_content>div:nth-of-type(5) {width: 3%; text-align: center;}
.admin_shop_cancel_content_content>div:nth-of-type(6) {width: 7%; text-align: center;}
.admin_shop_cancel_content_content>div:nth-of-type(7) {width: 5%; text-align: center; font-size: 0.8em;}
.admin_shop_cancel_content_content>div:nth-of-type(8) {width: 10%;}
.admin_shop_cancel_content_content>div:nth-of-type(9) {width: 12.5%; font-size: 0.9em; padding: 0 10px;}
.admin_shop_cancel_content_content>div:nth-of-type(10) {width: 10%; font-size: 0.9em;}
.admin_shop_cancel_content_content>div:nth-of-type(11) {width: 12.5%; font-size: 0.9em; padding: 0 10px;}

.admin_shop_cancel_content_content>div:nth-of-type(2)>div:nth-child(2) {font-size: 0.9em; color: rgba(169, 173, 178, 1);}
.admin_shop_cancel_content_content>div:nth-of-type(3)>div img {width: 60px; height: 60px; border-radius: 4px; object-fit: cover;}
.admin_shop_cancel_content_content>div:nth-of-type(8)>div:nth-of-type(1) {
    font-size: 0.9em;
}
.admin_shop_cancel_content_content>div:nth-of-type(8)>div:nth-of-type(2) { font-size: 0.9em; border: 1px solid rgba(0, 0, 0, 0.1); padding: 5px; margin: 3px 0; border-radius: 4px;}
.admin_shop_cancel_content_content>div:nth-of-type(8)>div:nth-of-type(2)>div:nth-of-type(2),
.admin_shop_cancel_content_content>div:nth-of-type(8)>div:nth-of-type(2)>div:nth-of-type(3) {font-size: 0.9em; color: rgba(0, 115, 255, 1);}
.admin_shop_cancel_content_content>div:nth-of-type(8) button {border: none; background-color: rgba(255, 114, 153, 1); padding: 5px 10px; color: white; border-radius: 4px; font-size: 0.9em;}
.admin_shop_cancel_content_content>div:nth-of-type(9) button{border: none; background-color: rgba(255, 114, 153, 1); padding: 5px 10px; color: white; border-radius: 4px;}
.admin_shop_cancel_content_content input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
.admin_shop_cancel_order_payment_info_box>div:nth-of-type(3)>div:nth-of-type(1) {
    font-size: 0.9em;
    color: rgba(196, 199, 202, 1);
}
.admin_shop_cancel_order_payment_info_box>div:nth-of-type(3)>div:nth-of-type(3) {
    font-size: 0.8em;
    color: rgba(196, 199, 202, 1);
    width: 100%;
    height: 30px;
    overflow-y: scroll;
}
.admin_shop_cancel_order_payment_info_box>div:nth-of-type(3)>div:nth-of-type(3)::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 5px;
}
.admin_shop_cancel_order_payment_info_box>div:nth-of-type(3)>div:nth-of-type(3)::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.admin_shop_cancel_order_payment_info_box>div:nth-of-type(3)>div:nth-of-type(3)::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
  
.admin_shop_cancel_content_content input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_shop_cancel_content_content input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_shop_cancel_content_content input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_shop_cancel_order_number>div:nth-of-type(3) {
    padding: 10px 0;
}
.admin_shop_cancel_order_number>div:nth-of-type(3) button {
    border: none;
    background-color: inherit;
    color: rgba(0, 115, 255, 1);
}
.admin_shop_cancel_order_number>div:nth-of-type(3) button:hover {
    color: rgba(255, 114, 153, 1);
}
.admin_shop_cancel_order_number>div:nth-of-type(4) button {
    border: 1px solid rgba(255, 114, 153, 1);
    background-color: inherit;
    border-radius: 4px;
    padding: 5px 10px;
}
.admin_shop_cancel_order_number>div:nth-of-type(4) button:hover {
    background-color: rgba(255, 114, 153, 1);
    border: none;
    color: white;
}
.admin_shop_cancel_refuse_modal_container {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    z-index: 1000;
}
.admin_shop_cancel_refuse_modal_box {
    position: absolute;
    width: 80%;
    height: 80%;
    top: 50%;
    left: 50%;
    border-radius: 12px;
    transform: translate(-50%, -50%);
    background-color: white;
}
.admin_shop_cancel_refuse_modal_header {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.admin_shop_cancel_refuse_modal_header>div:nth-of-type(1) {font-size: 1.1em;}
.admin_shop_cancel_refuse_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
}
.admin_shop_cancel_refuse_modal_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.admin_shop_cancel_refuse_modal_txt {
    color: rgba(255, 114, 153, 1);
    font-size: 0.9em;
    padding: 20px;
}
.admin_shop_cancel_refuse_modal_content {
    width: 100%;
    height: 60vh;
    overflow-y: scroll;
}
.admin_shop_cancel_refuse_modal_content::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 5px;
}
.admin_shop_cancel_refuse_modal_content::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}
.admin_shop_cancel_refuse_modal_content::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.admin_shop_cancel_refuse_modal_footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_shop_cancel_refuse_modal_footer>div:nth-of-type(1) {width: 40%; padding: 0 10px;}
.admin_shop_cancel_refuse_modal_footer>div:nth-of-type(2) {width: 40%; padding: 0 10px;}
.admin_shop_cancel_refuse_modal_footer>div:nth-of-type(1) button {width: 100%; height: 50px; border-radius: 8px; font-size: 0.9em; border: none; background-color: rgba(223, 224, 226, 1);}
.admin_shop_cancel_refuse_modal_footer>div:nth-of-type(2) button {width: 100%; height: 50px; border-radius: 8px; font-size: 0.9em; border: none; background-color: rgba(255, 114, 153, 1); color: white;}
.admin_shop_cancel_refuse_modal_refuse_box {
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    margin: 10px;
    padding: 20px;
    border-radius: 12px;
}
.admin_shop_cancel_refuse_modal_refuse_info {
    display: flex;
    /* align-items: center; */
    width: 100%;
    border: 1px solid rgba(223, 224, 226, 1);
    padding: 5px;
    border-radius: 4px;
}
.admin_shop_cancel_refuse_modal_refuse_order {
    width: 50%;
}
.admin_shop_cancel_refuse_modal_refuse_order>div:nth-of-type(1) {
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    font-size: 0.9em;
    padding: 5px 0;
}
.admin_shop_cancel_refuse_modal_refuse_order>div:nth-of-type(2) {
    padding: 5px 0;
    display: flex;
    align-items: center;
}
.admin_shop_cancel_refuse_modal_refuse_order>div:nth-of-type(2)>div:nth-of-type(1) img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
}
.admin_shop_cancel_refuse_modal_refuse_order>div:nth-of-type(2)>div:nth-of-type(2) {
    padding-left: 10px;
    font-size: 0.9em;
}
.admin_shop_cancel_refuse_modal_refuse_order>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(1) {color: rgba(169, 173, 178, 1);}
.admin_shop_cancel_refuse_modal_refuse_order>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(2) {font-weight: bold;}
.admin_shop_cancel_refuse_modal_refuse_order>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(3) {color: rgba(255, 114, 153, 1);}
.admin_shop_cancel_refuse_modal_refuse_cancel {
    width: 50%;
    border-left: 1px solid rgba(223, 224, 226, 1);
    margin-left: 5px;
    padding-left: 5px;
}
.admin_shop_cancel_refuse_modal_refuse_cancel>div:nth-of-type(1) {
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    font-size: 0.9em;
    padding: 5px 0;
}
.admin_shop_cancel_refuse_modal_refuse_cancel>div:nth-of-type(2) {
    width: 100%;
    padding: 5px 0;
}
.admin_shop_cancel_refuse_modal_refuse_cancel_info {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    padding: 2px 0;
}
.admin_shop_cancel_refuse_modal_refuse_cancel_info>div:nth-of-type(1) {width: 30%;}
.admin_shop_cancel_refuse_modal_refuse_cancel_info>div:nth-of-type(2) {width: 70%;}
.admin_shop_cancel_refuse_modal_refuse_textarea {
    padding: 5px 0;
    font-size: 0.9em;
}
.admin_shop_cancel_refuse_modal_refuse_textarea textarea {
    width: 100%;
    height: 100px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 8px;
    padding: 10px;
    resize: none;
}
.admin_shop_cancel_refuse_modal_refuse_textarea textarea:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_virtual_order_modal {
    width: 100%;
}
.admin_virtual_order_modal_info {
    text-align: left;
    font-size: 0.8em;
    color: rgba(255, 3, 102, 1);
}
.admin_virtual_order_modal_bank {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.9em;
    padding: 20px 0 10px 0;
}
.admin_virtual_order_modal_bank>div:nth-of-type(1) {
    width: 80px;
    text-align: left;
}
.admin_virtual_order_modal_bank>div:nth-of-type(2) {
    width: calc(100% - 80px);
}
.admin_virtual_order_modal_bank>div:nth-of-type(2) select {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid rgba(196, 199, 202, 1);
    padding: 10px;
}
.admin_virtual_order_modal_account {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    font-size: 0.9em;
}
.admin_virtual_order_modal_account > div:nth-of-type(1) {
    width: 80px;
    text-align: left;
}
.admin_virtual_order_modal_account > div:nth-of-type(2) {
    width: calc(100% - 80px);
}
.admin_virtual_order_modal_account > div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding: 10px;
}
.admin_virtual_order_modal_account > div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_virtual_order_modal_holder {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
.admin_virtual_order_modal_holder>div:nth-of-type(1) {
    width: 80px;
    text-align: left;
}
.admin_virtual_order_modal_holder>div:nth-of-type(2) {
    width: calc(100% - 80px);
}
.admin_virtual_order_modal_holder>div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding: 10px;
}
.admin_virtual_order_modal_holder>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}


/* 반품 */
.admin_shop_return_content_box {
    width: 100%;
    overflow-x: scroll;
}
.admin_shop_return_content_box::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 5px;
}
.admin_shop_return_content_box::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}
.admin_shop_return_content_header {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
    text-align: center;
}

.admin_shop_return_content_box::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.admin_shop_return_content_box>div {
    min-width: 1700px;
}
.admin_shop_return_content_header input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_shop_return_content_header input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_shop_return_content_header input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_shop_return_content_header input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_shop_return_content_header>div:nth-of-type(1) {width: 3%;}
.admin_shop_return_content_header>div:nth-of-type(2) {width: 10%;}
.admin_shop_return_content_header>div:nth-of-type(3) {width: 16%;}
.admin_shop_return_content_header>div:nth-of-type(4) {width: 7%;}
.admin_shop_return_content_header>div:nth-of-type(5) {width: 5%;}
.admin_shop_return_content_header>div:nth-of-type(6) {width: 8%;}
.admin_shop_return_content_header>div:nth-of-type(7) {width: 7%;}
.admin_shop_return_content_header>div:nth-of-type(8) {width: 7%;}
.admin_shop_return_content_header>div:nth-of-type(9) {width: 10%;}
.admin_shop_return_content_header>div:nth-of-type(10) {width: 10%;}
.admin_shop_return_content_header>div:nth-of-type(11) {width: 10%;}
.admin_shop_return_content_header>div:nth-of-type(12) {width: 12%;}

.admin_shop_return_content_content {
    display: flex;
    font-size: 0.8em;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_shop_return_content_content input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_shop_return_content_content input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_shop_return_content_content input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_shop_return_content_content input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_shop_return_content_content>div:nth-of-type(1) {width: 3%; text-align: center;}
.admin_shop_return_content_content>div:nth-of-type(2) {width: 10%;}
.admin_shop_return_content_content>div:nth-of-type(3) {width: 16%; display: flex;}
.admin_shop_return_content_content>div:nth-of-type(4) {width: 7%;}
.admin_shop_return_content_content>div:nth-of-type(5) {width: 5%;}
.admin_shop_return_content_content>div:nth-of-type(6) {width: 8%;}
.admin_shop_return_content_content>div:nth-of-type(7) {width: 7%;}
.admin_shop_return_content_content>div:nth-of-type(8) {width: 7%;}
.admin_shop_return_content_content>div:nth-of-type(9) {width: 10%;}
.admin_shop_return_content_content>div:nth-of-type(10) {width: 10%;}
.admin_shop_return_content_content>div:nth-of-type(11) {width: 10%;}
.admin_shop_return_content_content>div:nth-of-type(12) {width: 12%;}

.admin_shop_return_content_content>div:nth-of-type(2)>div:nth-child(2) {font-size: 0.9em; color: rgba(169, 173, 178, 1);}
.admin_shop_return_content_content>div:nth-of-type(3)>div img {width: 60px; height: 60px; border-radius: 4px;}
.admin_shop_return_content_content>div:nth-of-type(7) button {
    border: 1px solid rgba(196, 199, 202, 1);
    padding: 5px 10px;
    border-radius: 4px;
    width: 100%;
    background-color: inherit;
}
.admin_shop_return_content_content>div:nth-of-type(7) button:hover {
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_shop_return_content_content>div:nth-of-type(9) button {
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
    border-radius: 4px;
    padding: 5px 10px;
}
.admin_shop_return_content_content>div:nth-of-type(9) button:hover {
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_shop_return_order_product_info>div:nth-of-type(3)>div:nth-of-type(1) {
    font-size: 0.9em;
    color: rgba(196, 199, 202, 1);
}
.admin_shop_return_order_product_info>div:nth-of-type(3)>div:nth-of-type(3) {
    width: 100%;
    height: 30px;
    overflow-y: scroll;
    color: rgba(196, 199, 202, 1);
    font-size: 0.9em;
}

.admin_shop_return_order_product_info>div:nth-of-type(3)>div:nth-of-type(3)::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 5px;
}
.admin_shop_return_order_product_info>div:nth-of-type(3)>div:nth-of-type(3)::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.admin_shop_return_order_product_info>div:nth-of-type(3)>div:nth-of-type(3)::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.admin_shop_return_reason_box>div:nth-of-type(2) {
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 2px;
    font-size: 0.9em;
    color: rgba(0, 115, 255, 1);
}
.admin_shop_return_refuse_box>div:nth-of-type(2) {
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 2px;
    font-size: 0.9em;
    color: rgba(255, 114, 153, 1);
}
.admin_shop_return_refund_info>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.admin_shop_return_refund_info>div:nth-of-type(1) {
    font-weight: bold;
    color: rgba(255, 3, 102, 1);
}
.admin_return_collection_content>div:nth-of-type(2) input {
    width: 100%;
    height: 30px;
    font-size: 0.9em;
    padding-left: 5px;
    margin: 2px 0;
}
.admin_return_collection_content>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.admin_shop_return_total_box {
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 5px;
    color: rgba(255, 3, 102, 1);
}

/* 교환 */
.admin_shop_change_content_box {
    width: 100%;
    overflow-x: scroll;
}
.admin_shop_change_content_box::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 5px;
}
.admin_shop_change_content_box::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}
.admin_shop_change_content_box::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.admin_shop_change_content_box>div {
    min-width: 1700px;
}
.admin_shop_change_content_header {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
    text-align: center;
}

.admin_shop_change_content_header input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_shop_change_content_header input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_shop_change_content_header input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_shop_change_content_header input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_shop_change_content_header>div:nth-of-type(1) {width: 3%;}
.admin_shop_change_content_header>div:nth-of-type(2) {width: 10%;}
.admin_shop_change_content_header>div:nth-of-type(3) {width: 18%;}
.admin_shop_change_content_header>div:nth-of-type(4) {width: 7%;}
.admin_shop_change_content_header>div:nth-of-type(5) {width: 5%;}
.admin_shop_change_content_header>div:nth-of-type(6) {width: 8%;}
.admin_shop_change_content_header>div:nth-of-type(7) {width: 7%;}
.admin_shop_change_content_header>div:nth-of-type(8) {width: 7%;}
.admin_shop_change_content_header>div:nth-of-type(9) {width: 5%;}
.admin_shop_change_content_header>div:nth-of-type(10) {width: 15%;}
.admin_shop_change_content_header>div:nth-of-type(11) {width: 5%;}
.admin_shop_change_content_header>div:nth-of-type(12) {width: 13%;}

.admin_shop_change_content_content {
    display: flex;
    font-size: 0.8em;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_shop_change_content_content input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_shop_change_content_content input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_shop_change_content_content input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_shop_change_content_content input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_shop_change_content_content>div:nth-of-type(1) {width: 3%; text-align: center;}
.admin_shop_change_content_content>div:nth-of-type(2) {width: 10%;}
.admin_shop_change_content_content>div:nth-of-type(3) {width: 18%;}
.admin_shop_change_content_content>div:nth-of-type(4) {width: 7%;}
.admin_shop_change_content_content>div:nth-of-type(5) {width: 5%;}
.admin_shop_change_content_content>div:nth-of-type(6) {width: 8%;}
.admin_shop_change_content_content>div:nth-of-type(7) {width: 7%;}
.admin_shop_change_content_content>div:nth-of-type(8) {width: 7%;}
.admin_shop_change_content_content>div:nth-of-type(9) {width: 5%;}
.admin_shop_change_content_content>div:nth-of-type(10) {width: 15%;}
.admin_shop_change_content_content>div:nth-of-type(11) {width: 5%;}
.admin_shop_change_content_content>div:nth-of-type(12) {width: 13%;}

.admin_shop_change_content_content>div:nth-of-type(2)>div:nth-child(2) {font-size: 0.9em; color: rgba(169, 173, 178, 1);}
.admin_shop_change_content_content>div:nth-of-type(3)>div:nth-of-type(1) {display: flex;}
.admin_shop_change_content_content>div:nth-of-type(3)>div img {width: 60px; height: 60px; border-radius: 4px; object-fit: cover;}
.admin_shop_exchage_order_product_info_box>div:nth-of-type(3)>div:nth-of-type(1) {
    font-size: 0.9em;
    color: rgba(196, 199, 202, 1);
}
.admin_shop_exchage_order_product_info_box>div:nth-of-type(1)>div:nth-of-type(3)>div:nth-of-type(3) {
    width: 100%;
    height: 30px;
    overflow-y: scroll;
    color: rgba(169, 173, 178, 1);
    font-size: 0.9em;
}
.admin_shop_exchage_order_product_info_box>div:nth-of-type(1)>div:nth-of-type(3)>div:nth-of-type(3)::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 5px;
}
.admin_shop_exchage_order_product_info_box>div:nth-of-type(1)>div:nth-of-type(3)>div:nth-of-type(3)::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}
.admin_shop_exchage_order_product_info_box>div:nth-of-type(1)>div:nth-of-type(3)>div:nth-of-type(3)::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.admin_shop_exchage_order_product_info_box>div:nth-of-type(2)>div:nth-of-type(2) {
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    padding: 10px;
    border-radius: 8px;
    font-size: 0.9em;
    color: rgba(0, 115, 255, 1);
}

.admin_shop_change_content_content>div:nth-of-type(7) button {
    border: 1px solid rgba(196, 199, 202, 1);
    padding: 5px 10px;
    border-radius: 4px;
    width: 100%;
    background-color: inherit;
}
.admin_shop_change_content_content>div:nth-of-type(7) button:hover {
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_shop_change_content_content>div:nth-of-type(9) button {
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
    border-radius: 4px;
    padding: 5px 10px;
}
.admin_shop_change_content_content>div:nth-of-type(9) button:hover {
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_shop_change_more_btn {
    width: 100%;
    height: 25px !important;
    font-size: 0.9em;
    border: none !important;
    background-color: rgba(241, 242, 243, 1) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
}
.admin_shop_change_more_btn:hover {
    background-color: rgba(255, 114, 153, 0.2) !important;
}
.admin_shop_exchange_collection_point_btn {
    width: 100%;
}
.admin_shop_exchange_collection_point_btn button {
    padding: 5px 10px;
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    border-radius: 4px;
    font-size: 0.9em;
}
.admin_shop_exchange_collection_point_btn button:hover,
.admin_shop_exchange_collection_point_btn button:active {
    background-color: rgba(255, 114, 153, 0.3);
}
.admin_shop_exchange_exchange_payment_box {
    padding: 5px;
    margin-top: 5px;
    border: 1px solid rgba(255, 114, 153, 1);
    border-radius: 4px;
}
.admin_shop_exchange_exchange_payment_box>div:nth-of-type(1) {
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_shop_exchange_exchange_payment_box>div:nth-of-type(2) {
    padding-top: 5px;
    color: rgba(255, 3, 102, 1);
}
.admin_shop_exchange_exchange_payment_box>div:nth-of-type(3) {
    color: rgba(255, 3, 102, 1);
}
.admin_shop_exchange_other_costs {
    font-size: 0.9em;
    color: rgba(255, 3, 102, 1);
}
.admin_shop_exchange_redelivery_info>div:nth-of-type(2)>div:nth-of-type(2) input {
    width: 100%;
    padding-left: 5px;
    font-size: 0.9em;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    height: 30px;
}
.admin_shop_exchange_redelivery_info>div:nth-of-type(2)>div:nth-of-type(2) input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}

/* 구매평 */
.admin_shop_review_header {
    font-size: 1.2em;
    padding-top: 15px;
}
.admin_shop_review_count_search {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_shop_review_count_search>div:nth-of-type(2) {
    display: flex;
}
.admin_shop_review_download_btn_box {
    padding-right: 5px;
}
.admin_shop_review_download_btn_box button {
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
    border-radius: 4px;
    width: 85px;
    height: 35px;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_shop_review_search_box {
    display: flex;
    align-items: center;
}
.admin_shop_review_search_select {
    width: 100px;
    height: 35px;
}
.admin_shop_review_search_box input {
    width: 200px;
    height: 35px;
    border: none;
    border-bottom: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding-left: 10px;
    font-size: 0.85em;
}
.admin_shop_review_search_box input:focus {
    outline: none;
    border-bottom: 1px solid rgba(255, 114, 153, 1);
}
.admin_shop_review_search_box button {
    border: none;
    border-radius: 4px;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    width: 60px;
    height: 35px;
    font-size: 0.9em;
}
.admin_shop_review_count_list {
    border: none;
    background-color: inherit;
    display: flex;
    align-items: center;
    font-size: 0.8em;
}
.admin_shop_review_content_header {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 5px 0;
    font-size: 0.9em;
    text-align: center;
}
.admin_shop_review_content_header>div:nth-of-type(1) {width: 5%;}
.admin_shop_review_content_header>div:nth-of-type(2) {width: 5%;}
.admin_shop_review_content_header>div:nth-of-type(3) {width: 20%;}
.admin_shop_review_content_header>div:nth-of-type(4) {width: 10%;}
.admin_shop_review_content_header>div:nth-of-type(5) {width: 20%;}
.admin_shop_review_content_header>div:nth-of-type(6) {width: 10%;}
.admin_shop_review_content_header>div:nth-of-type(7) {width: 10%;}
.admin_shop_review_content_header>div:nth-of-type(8) {width: 10%;}
.admin_shop_review_content_header>div:nth-of-type(9) {width: 10%;}
.admin_shop_review_content_header input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_shop_review_content_header input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_shop_review_content_header input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_shop_review_content_header input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_shop_review_content_content {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 5px 0;
    font-size: 0.8em;
    cursor: pointer;
    transition: all 0.2s;
}
.admin_shop_review_content_content:hover {
    background-color: rgba(241, 242, 243, 1);
}
.admin_shop_review_content_content>div:nth-of-type(1) {width: 5%; text-align: center;}
.admin_shop_review_content_content>div:nth-of-type(2) {width: 5%; text-align: center;}
.admin_shop_review_content_content>div:nth-of-type(3) {width: 20%; display: flex; align-items: center;}
.admin_shop_review_content_content>div:nth-of-type(4) {width: 10%;}
.admin_shop_review_content_content>div:nth-of-type(5) {width: 20%; padding: 0 10px;}
.admin_shop_review_content_content>div:nth-of-type(6) {width: 10%; text-align: center; padding: 0 5px;}
.admin_shop_review_content_content>div:nth-of-type(7) {width: 10%; font-size: 0.9em; text-align: center;}
.admin_shop_review_content_content>div:nth-of-type(8) {width: 10%; padding: 0 5px; text-align: center;}
.admin_shop_review_content_content>div:nth-of-type(9) {width: 10%; padding: 0 5px; text-align: center;}
.admin_shop_review_content_content input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_shop_review_content_content input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_shop_review_content_content input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_shop_review_content_content input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_shop_review_content_content>div:nth-of-type(3)>div:nth-of-type(2)>div:nth-of-type(2) {
    width: 100%;
    height: 50px;
    overflow-y: auto;
    font-size: 0.85em;
    color: rgba(169, 173, 178, 1);
}
.admin_shop_review_content_content>div:nth-of-type(3)>div:nth-of-type(2)>div:nth-of-type(2)::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 5px;
}
.admin_shop_review_content_content>div:nth-of-type(3)>div:nth-of-type(2)>div:nth-of-type(2)::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.admin_shop_review_content_content>div:nth-of-type(3)>div:nth-of-type(2)>div:nth-of-type(2)::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.admin_shop_review_content_content>div:nth-of-type(3) img {
    width: 60px;
    height: 60px;
    border-radius: 4px;
}
.admin_shop_review_content_content>div:nth-of-type(4) img {
    width: 100%;
    height: 30px;
    border-radius: 4px;
}
.admin_shop_review_content_content>div:nth-of-type(9) button {border: none; background-color: inherit; text-decoration: underline;}
.admin_shop_review_content_content>div:nth-of-type(9)>div:nth-of-type(1) button {color: rgba(0, 115, 255, 1);}
.admin_shop_review_content_content>div:nth-of-type(9)>div:nth-of-type(2) button {color: rgba(255, 3, 102, 1);}

.admin_shop_review_modal_container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1000;
}
.admin_shop_review_modal_box {
    width: 80%;
    height: 80%;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    border-radius: 12px;
}
.admin_shop_review_modal_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.admin_shop_review_modal_header>div:nth-of-type(1) {font-size: 1.1em;}
.admin_shop_review_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
}
.admin_shop_review_modal_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.admin_shop_review_modal_content {
    width: 100%;
    padding: 20px;
    height: 65vh;
    overflow-y: auto;
    font-size: 0.9em;
}
.admin_shop_review_modal_content>div:nth-of-type(1) {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding-bottom: 10px;
}
.admin_shop_review_modal_content>div:nth-of-type(1)>div:nth-of-type(1) {width: 20%;}
.admin_shop_review_modal_content>div:nth-of-type(1)>div:nth-of-type(2) {width: 80%; display: flex; align-items: center;}
.admin_shop_review_modal_content>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 100px;
    height: 100px;
    border-radius: 4px;
    overflow: hidden;
}
.admin_shop_review_modal_content>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) img {
    width: 100%;
    height: 100px;
    object-fit: cover;
}
.admin_shop_review_modal_content>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2) {
    padding-left: 10px;
}
.admin_shop_review_modal_content>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(2) {
    font-size: 0.9em;
    color: rgba(255, 3, 102, 1);
    height: 30px;
    overflow-y: auto;
}
.admin_shop_review_modal_content>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(2)::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 5px;
}
.admin_shop_review_modal_content>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(2)::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.admin_shop_review_modal_content>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(2)::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.admin_shop_review_modal_content>div:nth-of-type(2) {
    width: 100%;
    margin: 10px 0;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_shop_review_modal_content>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 20%;
}
.admin_shop_review_modal_content>div:nth-of-type(2)>div:nth-of-type(2) {
    width: 80%;
    display: flex;
    align-items: center;
}
.admin_shop_review_modal_content>div:nth-of-type(2)>div:nth-of-type(2)>div {
    width: 100px;
    height: 100px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 10px;
}
.admin_shop_review_modal_content>div:nth-of-type(2)>div:nth-of-type(2)>div img {
    width: 100%;
    height: 100px;
    object-fit: contain;
}

.admin_shop_review_modal_content>div:nth-of-type(3) {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_shop_review_modal_content>div:nth-of-type(3)>div:nth-of-type(1) {width: 20%;}
.admin_shop_review_modal_content>div:nth-of-type(3)>div:nth-of-type(2) {
    width: 80%;
}
.admin_shop_review_modal_content>div:nth-of-type(4) {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_shop_review_modal_content>div:nth-of-type(4)>div:nth-of-type(1) {width: 20%;}
.admin_shop_review_modal_content>div:nth-of-type(4)>div:nth-of-type(2) {
    width: 80%;
}
.admin_shop_review_modal_content>div:nth-of-type(4)>div:nth-of-type(2) textarea {
    width: 100%;
    resize: none;
    height: 150px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 10px;
}
.admin_shop_review_modal_content>div:nth-of-type(4)>div:nth-of-type(2) textarea:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
    font-size: 0.9em;
}
.admin_shop_review_modal_footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_shop_review_modal_footer>div:nth-of-type(1) {
    width: 40%;
    margin: 0 5px;
}
.admin_shop_review_modal_footer>div:nth-of-type(2) {
    width: 40%;
    margin: 0 5px;
}
.admin_shop_review_modal_footer>div:nth-of-type(1) button,
.admin_shop_review_modal_footer>div:nth-of-type(2) button {
    width: 100%;
    height: 40px;
    border-radius: 4px;
}
.admin_shop_review_modal_footer>div:nth-of-type(1) button {
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
}
.admin_shop_review_modal_footer>div:nth-of-type(2) button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
}

/* 배송설정 */
.admin_shop_delivery_update_active {
    height: 80vh;
    overflow-y: hidden;
}
.admin_shop_delivery_setting_header {
    font-size: 1.2em;
    padding: 15px 0;
}
.admin_shop_delivery_setting_btn {
    display: flex;
    width: 100%;
    justify-content: right;
    padding: 10px 0;
}
.admin_shop_delivery_setting_btn button {
    border: none;
    background-color: inherit;
    color: rgba(0, 115, 255, 1);
    transition: all 0.2s;
    font-size: 0.9em;
}
.admin_shop_delivery_setting_btn button:hover {
    color: rgba(255, 114, 153, 1);
}
.admin_shop_delivery_setting_content_header {
    display: flex;
    width: 100%;
    border-top: 1px solid rgba(241, 242, 243, 1);
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 10px 0;
    text-align: center;
    font-size: 0.9em;
}
.admin_shop_delivery_setting_content_header>div:nth-of-type(1) {width: 10%;}
.admin_shop_delivery_setting_content_header>div:nth-of-type(2) {width: 15%;}
.admin_shop_delivery_setting_content_header>div:nth-of-type(3) {width: 50%;}
.admin_shop_delivery_setting_content_header>div:nth-of-type(4) {width: 25%;}
.admin_shop_delivery_setting_content_content {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 10px 0;
    font-size: 0.8em;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
}
.admin_shop_delivery_setting_content_content:hover {
    background-color: rgba(241, 242, 243, 1);
}
.admin_shop_delivery_setting_content_content>div:nth-of-type(1) {width: 10%;}
.admin_shop_delivery_setting_content_content>div:nth-of-type(2) {width: 15%;}
.admin_shop_delivery_setting_content_content>div:nth-of-type(3) {width: 50%;}
.admin_shop_delivery_setting_content_content>div:nth-of-type(4) {width: 25%; text-align: right;}

.admin_shop_delivery_setting_content_detail_content>div {
    display: flex;
    padding: 10px 30px;
    font-size: 0.8em;
    background-color: rgba(241, 242, 243, 1);
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_shop_delivery_setting_content_detail_content>div>div:nth-of-type(1) {
    width: 30%;
}
.admin_shop_delivery_setting_content_detail_content>div>div:nth-of-type(2) {
    width: 70%;
}

.admin_shop_delivery_setting_content_btn {
    position: absolute;
    display: flex;
    align-items: center;
    top: 50%;
    right: 50px;
    transform: translate(0, -50%);
}
.admin_shop_delivery_setting_content_btn button {
    padding: 3px 7px;
    border-radius: 4px;
    border: none;
}
.admin_shop_delivery_setting_content_btn>div:nth-of-type(2) {padding-left: 5px;}
.admin_shop_delivery_setting_content_btn>div:nth-of-type(1) button {
    background-color: rgba(255, 194, 0, 1); 
    color: white;
}
.admin_shop_delivery_setting_content_btn>div:nth-of-type(2) button {
    background-color: rgba(255, 3, 102, 1); 
    color: white;
}

/* 배송템플릿 추가 */
.admin_shop_delivery_add_title {
    font-size: 1.2em;
    padding: 5px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.post_background {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}
.post_container {
    position: absolute;
    width: 60vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.post_close {
    width: 100%;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    height: 40px;
}
.admin_shop_delivery_add_templrate_title {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_shop_delivery_add_templrate_title>div:nth-of-type(1) {width: 30%;}
.admin_shop_delivery_add_templrate_title>div:nth-of-type(2) {width: 70%;}
.admin_shop_delivery_add_templrate_title input {
    width: 300px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    height: 35px;
    padding-left: 10px;
}
.admin_shop_delivery_add_templrate_title input:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.admin_shop_delivery_input_box {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_shop_delivery_input_box>div:nth-of-type(1) {width: 30%;}
.admin_shop_delivery_input_box>div:nth-of-type(2) {width: 70%;}
.admin_shop_delivery_oversease_radio {
    display: flex;
    align-items: center;
}
.admin_shop_delivery_oversease_radio>div:nth-of-type(2) {padding-left: 30px;}
.admin_shop_delivery_oversease_radio>div {display: flex; align-items: center;}
.admin_shop_delivery_oversease_radio input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.admin_shop_delivery_oversease_radio input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}

.admin_shop_delivery_oversease_radio input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}

.admin_shop_delivery_oversease_radio input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}
.admin_shop_delivery_oversease_radio label {
    padding-left: 3px;
}
.admin_shop_delivery_oversease_add_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
}
.admin_shop_delivery_oversease_add_btn button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: white;
    transition: all 0.2s;
}
.admin_shop_delivery_oversease_add_btn button:hover {
    opacity: 0.5;
}
.admin_shop_delivery_input_box input[type=number] {
    width: 300px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    height: 35px;
    padding-left: 10px;
}
.admin_shop_delivery_input_box input:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.admin_shop_delivery_input_hipen {
    font-size: 0.9em;
    color: rgba(255, 3, 102, 1);
}
.admin_shop_delivery_add_number_input {
    position: relative;
}
.admin_shop_delivery_add_number_input>div {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
}
.admin_shop_delivery_oversease_add_input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_shop_delivery_oversease_add_input>div:nth-of-type(1) {
    display: flex;
    align-items: center;
}
.admin_shop_delivery_oversease_add_input>div:nth-of-type(1) input {
    width: 50px;
    padding-right: 10px;
    text-align: center;
}
.admin_shop_delivery_oversease_add_input>div:nth-of-type(1) span {
    padding-left: 10px;
}
.admin_shop_delivery_oversease_add_input>div:nth-of-type(2) {
    display: flex;
    align-items: center;
}
.admin_shop_delivery_oversease_add_input>div:nth-of-type(2) input {
    width: 200px;
    text-align: right;
    padding-right: 10px;
}
.admin_shop_delivery_oversease_add_input>div:nth-of-type(2) span {
    padding-left: 5px;
}
.admin_shop_delivery_oveseaes_remove_btn {
    border-radius: 50%;
    padding: 3px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
}
.admin_shop_delivery_oveseaes_remove_btn span {
    color: white;
}
.admin_shop_delivery_add_address_btn {
    display: flex;
    flex-direction: column;
}
.admin_shop_delivery_add_address_btn input {
    width: 300px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    height: 35px;
    padding-left: 10px;
}
.admin_shop_delivery_add_address_btn button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    width: 100px;
    height: 35px;
    border-radius: 4px;
    color: white;
}
.admin_shop_delivery_add_btn_box {
    display: flex;
    align-items: center;
    padding: 30px 0;
    justify-content: center;
}
.admin_shop_delivery_add_btn_box button {
    border: none;
    width: 200px;
    height: 40px;
    margin: 0 10px;
    font-size: 0.9em;
}
.admin_shop_delivery_add_btn_box>div:nth-of-type(1) button {}
.admin_shop_delivery_add_btn_box>div:nth-of-type(2) button {}
.admin_shop_delivery_add_btn_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}

/* 배너 */
.admin_operate_banner_title {
    font-size: 1.2em;
    padding: 15px 0;
}
.admin_operate_banner_tap_btn {
    display: flex;
}
.admin_operate_banner_tap_active {
    border-bottom: 3px solid rgba(255, 114, 153, 1);
}
.admin_operate_banner_tap_btn>div {
    height: 30px;
}
.admin_operate_banner_tap_btn button {
    border: none;
    background-color: inherit;
    padding: 5px 10px;
}
.admin_operate_banner_btn_style {
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 0.9em;
    margin: 0 5px;
}
.admin_operate_banner_btn_style:hover {
    background-color: rgba(255, 114, 153, 0.1);
}
.admin_operate_banner_content_header {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 5px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
}
.admin_operate_banner_content_header input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_operate_banner_content_header input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_operate_banner_content_header input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_operate_banner_content_header input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_operate_banner_content_header>div:nth-of-type(1) {width: 5%;}
.admin_operate_banner_content_header>div:nth-of-type(2) {width: 20%;}
.admin_operate_banner_content_header>div:nth-of-type(3) {width: 25%;}
.admin_operate_banner_content_header>div:nth-of-type(4) {width: 10%;}
.admin_operate_banner_content_header>div:nth-of-type(5) {width: 10%;}
.admin_operate_banner_content_header>div:nth-of-type(6) {width: 10%;}
.admin_operate_banner_content_header>div:nth-of-type(7) {width: 10%;}
.admin_operate_banner_content_header>div:nth-of-type(8) {width: 10%;}

.admin_operate_banner_content_content {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 5px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
}
.admin_operate_banner_content_content input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_operate_banner_content_content input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_operate_banner_content_content input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_operate_banner_content_content input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_operate_banner_content_content>div:nth-of-type(1) {width: 5%;}
.admin_operate_banner_content_content>div:nth-of-type(2) {width: 20%;}
.admin_operate_banner_content_content>div:nth-of-type(3) {width: 25%;}
.admin_operate_banner_content_content>div:nth-of-type(4) {width: 10%;}
.admin_operate_banner_content_content>div:nth-of-type(5) {width: 10%;}
.admin_operate_banner_content_content>div:nth-of-type(6) {width: 10%;}
.admin_operate_banner_content_content>div:nth-of-type(7) {width: 10%;}
.admin_operate_banner_content_content>div:nth-of-type(8) {width: 10%;}
.admin_operate_banner_content_content>div:nth-of-type(3) img {
    width: 200px;
    height: 200px;
    border-radius: 4px;
}

.admin_active_switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
}

.admin_active_switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}
  
.admin_active_slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
  
.admin_active_slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
  
input:checked + .admin_active_slider {
    background-color: rgba(255, 3, 102, 1);
}
  
input:focus + .admin_active_slider {
    box-shadow: 0 0 1px rgba(255, 3, 102, 1);
}
  
input:checked + .admin_active_slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
}
.admin_active_slider.admin_active_round {
    border-radius: 34px;
}

.admin_active_slider.admin_active_round:before {
    border-radius: 50%;
}
.admin_operate_banner_content_content>div:nth-of-type(8) button {
    border: none;
    background-color: inherit;
    text-decoration: underline;
    color: rgba(0, 115, 255, 1);
}

/* 메인배너 등록 */
.admin_operate_banner_main_banner_container {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    z-index: 999;
}
.admin_operate_banner_main_banner_box {
    width: 80dvh;
    height: 80dvh;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    padding: 10px;
}
.admin_operate_banner_main_banner_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.admin_operate_banner_main_banner_title>div:nth-of-type(2) {
    cursor: pointer;
}
.admin_operate_banner_main_banner_content {
    height: 70vh;
    overflow-y: auto;
}
.admin_operate_banner_main_banner_content>div {
    min-height: 70vh;
}
.admin_operate_banner_main_banner_name {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
    width: 100%;
}
.admin_operate_banner_main_banner_name>div:nth-of-type(1) {width: 30%;}
.admin_operate_banner_main_banner_name>div:nth-of-type(2) {width: 70%;}
.admin_operate_banner_main_banner_name>div:nth-of-type(2) input {
    width: 100%;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    height: 35px;
    padding-left: 10px;
}
.admin_operate_banner_main_banner_name>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.admin_operate_banner_main_banner_img_btn_txt {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px 0;
    justify-content: space-between;
}
.admin_operate_banner_img_text {
    color: rgba(255, 3, 102, 1);
    font-size: 0.9em;
    font-weight: bold;
}
.admin_operate_banner_title_input_box {
    position: relative;
    width: 100%;
}
.admin_operate_banner_title_input_box>div:nth-of-type(2) {
    position: absolute;
    top: 45%;
    right: 10px;
    transform: translate(0, -50%);
    font-size: 0.9em;
}
.admin_operate_banner_main_banner_exposure {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
}
.admin_operate_banner_main_banner_exposure>div:nth-of-type(1) {width: 30%;}
.admin_operate_banner_main_banner_exposure>div:nth-of-type(2) {width: 70%;}
.admin_operate_banner_main_banner_img {
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
}
.admin_operate_banner_main_banner_img>div:nth-of-type(2) {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
}
.admin_operate_banner_main_banner_img>div:nth-of-type(2)>div {
    width: auto;
    height: auto;
    border-radius: 4px;
    overflow: hidden;
}
.admin_operate_banner_main_banner_img>div:nth-of-type(2)>div img {
    width: 100%;
    /* height: 250px; */
    aspect-ratio: 700 / 400;
    object-fit: cover;
}
.admin_operate_banner_main_banner_img button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
}
.admin_operate_banner_main_banner_link {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
}
.admin_operate_banner_main_banner_link>div:nth-of-type(1) {width: 30%;}
.admin_operate_banner_main_banner_link>div:nth-of-type(2) {width: 70%; display: flex;}
.admin_operate_banner_main_banner_link>div:nth-of-type(2) input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.admin_operate_banner_main_banner_link>div:nth-of-type(2) input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}

.admin_operate_banner_main_banner_link>div:nth-of-type(2) input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}

.admin_operate_banner_main_banner_link>div:nth-of-type(2) input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}

.admin_operate_banner_main_banner_type {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
}
.admin_operate_banner_main_banner_type>div:nth-of-type(1) {width: 30%;}
.admin_operate_banner_main_banner_type>div:nth-of-type(2) {width: 70%; display: flex;}
.admin_operate_banner_main_banner_type>div:nth-of-type(2) input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.admin_operate_banner_main_banner_type>div:nth-of-type(2) input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}

.admin_operate_banner_main_banner_type>div:nth-of-type(2) input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}

.admin_operate_banner_main_banner_type>div:nth-of-type(2) input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}
.admin_operate_banner_main_banner_select_box {
    width: 100%;
}
.admin_operate_banner_main_banner_select_box>div:nth-of-type(1) {
    
}
.admin_operate_banner_main_banner_add_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
}
.admin_operate_banner_main_banner_add_btn button {
    width: 150px;
    height: 50px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
    border-radius: 4px;
    transition: all 0.3s;
}
.admin_operate_banner_main_banner_add_btn button:hover {
    background-color: rgba(255, 3, 102, 0.6);
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
}

/* 팝업 */
.admin_operate_popup_content_box {
    width: 100%;
    overflow-x: scroll;
}
.admin_operate_popup_content_box::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 5px;
}
.admin_operate_popup_content_box::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.admin_operate_popup_content_box::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.admin_operate_popup_content_box>div {
    min-width: 1300px;
}
.admin_operate_banner_main_popup_date,
.admin_operate_popup_exposure_locate,
.admin_operate_popup_exposure_direction {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
}
.admin_operate_banner_main_popup_date>div:nth-of-type(1),
.admin_operate_popup_exposure_locate>div:nth-of-type(1),
.admin_operate_popup_exposure_direction>div:nth-of-type(1) {
    width: 30%;
}
.admin_operate_banner_main_popup_date>div:nth-of-type(2),
.admin_operate_popup_exposure_locate>div:nth-of-type(2),
.admin_operate_popup_exposure_direction>div:nth-of-type(2) {
    width: 70%;
}
.admin_operate_popup_select {
    width: 30%;   
}
.admin_popup_datepicker {
    width: 100%;
    border-radius: 0;
}
.admin_popup_datepicker input {
    text-align: center;
}
.admin_popup_datepicker button {
    display: flex;
    align-items: center;
}
.admin_popup_datepicker :global(.ant-picker-input) input {
    pointer-events: none !important;
    cursor: pointer !important;
}

/* 약관 */
.adimn_operate_agree_title {
    font-size: 1.2em;
}
.admin_operate_agree_tap {
    display: flex;
}
.admin_operate_agree_tap_active {
    border-bottom: 3px solid rgba(255, 3, 102, 1);
}
.admin_operate_agree_tap button {
    border: none;
    background-color: inherit;
    padding: 5px 10px;
}
.admin_operate_agree_info_txt {
    font-size: 0.8em;
    color: rgba(196, 199, 202, 1);
}
.admin_operate_agree_add_btn {
    width: 100%;
    display: flex;
    justify-content: end;
}
.admin_operate_agree_add_btn button {
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.9em;
}
.adimn_operate_agree_content_header {
    display: flex;
    border-top: 1px solid rgba(241, 242, 243, 1);
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 5px 0;
    font-size: 0.9em;
    text-align: center;
}
.adimn_operate_agree_content_header input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.adimn_operate_agree_content_header input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.adimn_operate_agree_content_header input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.adimn_operate_agree_content_header input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.adimn_operate_agree_content_header>div:nth-of-type(1) {width: 5%;}
.adimn_operate_agree_content_header>div:nth-of-type(2) {width: 20%;}
.adimn_operate_agree_content_header>div:nth-of-type(3) {width: 60%;}
.adimn_operate_agree_content_header>div:nth-of-type(4) {width: 15%;}
.adimn_operate_agree_content_header>div:nth-of-type(5) {width: 15%;}

.adimn_operate_agree_content_content {
    width: 100%;
    height: 70px;
    display: flex;
    border-top: 1px solid rgba(241, 242, 243, 1);
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
    text-align: center;
    align-items: center;
    overflow-y: hidden;
}
.adimn_operate_agree_content_content input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.adimn_operate_agree_content_content input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.adimn_operate_agree_content_content input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.adimn_operate_agree_content_content input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.adimn_operate_agree_content_content>div:nth-of-type(1) {width: 5%;}
.adimn_operate_agree_content_content>div:nth-of-type(2) {width: 20%;}
.adimn_operate_agree_content_content>div:nth-of-type(3) {width: 60%; overflow-y: scroll; height: 70px; padding-top: 20px;}
.adimn_operate_agree_content_content>div:nth-of-type(3)::-webkit-scrollbar {
    width: 5px;  /* 스크롤바의 너비 */
    height: 5px;
}
.adimn_operate_agree_content_content>div:nth-of-type(3)::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}
.adimn_operate_agree_content_content>div:nth-of-type(3)::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, 0.1);  /*스크롤바 뒷 배경 색상*/
}
.adimn_operate_agree_content_content>div:nth-of-type(3) img {
    width: 100%;
    height: auto;
}
.adimn_operate_agree_content_content>div:nth-of-type(4) {width: 15%;}
.adimn_operate_agree_content_content>div:nth-of-type(5) {width: 15%;}
.admin_operate_agree_content_btn button{
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    width: 100%;
    height: 30px;
    background-color: inherit;
    font-size: 0.9em;
}
.admin_terms_modal {
    width: 80vw !important;
}
.admin_terms_modal :glbal(.ant-modal-body input) {
    height: 45px !important;
}
.admin_terms_modal :global(.ant-modal-content) {
    height: 80vh !important;
}
.admin_terms_modal :global(.ant-modal-footer) {
    position: absolute !important;
    bottom: 30px;
    left: 0;
    width: 100%;
    display: flex !important;
    justify-content: space-between;
    padding: 0 30px;
}
.admin_terms_modal :global(.ant-btn-primary) {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
    width: 100px !important;
    height: 40px !important;
}
.admin_terms_modal :global(.ant-btn-default) {
    width: 100px !important;
    height: 40px !important;
}
.admin_terms_modal_title_box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.admin_terms_modal_title_box>div:nth-of-type(1) {width: 90%;}
.admin_terms_modal_title_box>div:nth-of-type(2) {width: 10%;}
.admin_terms_modal_title {
    width: 100%;
    height: 45px;
}
.admin_terms_modal_select {
    width: 100%;
    height: 45px;
}
.admin_quill {
    height: 60vh !important;
    overflow: auto !important;
}
.admin_quill :global(.ql-container) {
    height: 55vh !important;
}
.inquiry_quill {
    background-color: white !important;
    height: 30vh !important;
}
.inquiry_quill :global(.ql-container) {
    height: 25.5vh !important;
}

/* 문의 */
.admin_operate_inquiry_title {
    font-size: 1.2em;
    padding: 10px 0;
}
.admin_operate_inquiry_tap {
    display: flex;
    width: 100%;
}
.admin_operate_inquiry_tap>div {
    width: 100%;
}
.admin_operate_inquiry_tap_active {
    border-bottom: 3px solid rgba(255, 3, 102, 1);
}
.admin_operate_inquiry_tap button {
    width: 100%;
    border: none;
    background-color: inherit;
}
.admin_operate_inquiry_content_header {
    display: flex;
    padding: 5px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
    text-align: center;
}
.admin_operate_inquiry_content_header>div:nth-of-type(1) {width: 5%;}
/* .admin_operate_inquiry_content_header>div:nth-of-type(2) {width: 24%;} */
.admin_operate_inquiry_content_header>div:nth-of-type(2) {width: 10%;}
.admin_operate_inquiry_content_header>div:nth-of-type(3) {width: 10%;}
.admin_operate_inquiry_content_header>div:nth-of-type(4) {width: 45%;}
.admin_operate_inquiry_content_header>div:nth-of-type(5) {width: 10%;}
.admin_operate_inquiry_content_header>div:nth-of-type(6) {width: 10%;}
.admin_operate_inquiry_content_header>div:nth-of-type(7) {width: 10%;}

.admin_operate_inquiry_content_content {
    display: flex;
    padding: 5px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.8em;
    text-align: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s;
}
.admin_operate_inquiry_content_content:hover {
    background-color: rgba(241, 242, 243, 1);
}
.admin_operate_inquiry_content_quick button{
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    font-size: 0.9em;
    padding: 5px 10px;
    border-radius: 4px;
}
.admin_operate_inquiry_content_content>div:nth-of-type(1) {width: 5%;}
/* .admin_operate_inquiry_content_content>div:nth-of-type(2) {width: 24%;} */
.admin_operate_inquiry_content_content>div:nth-of-type(2) {width: 10%;}
.admin_operate_inquiry_content_content>div:nth-of-type(3) {width: 10%;}
.admin_operate_inquiry_content_content>div:nth-of-type(4) {width: 45%;}
.admin_operate_inquiry_content_content>div:nth-of-type(5) {width: 10%;}
.admin_operate_inquiry_content_content>div:nth-of-type(6) {width: 10%;}
.admin_operate_inquiry_content_content>div:nth-of-type(7) {width: 10%;}
.admin_operate_inquiry_content_product {
    display: flex;
    align-items: center;
}
.admin_operate_inquiry_content_product img {
    width: 60px;
    height: 60px;
    border-radius: 4px;
}
.admin_operate_inquiry_content_user_review img {
    width: 60px;
    height: 60px;
}
.admin_operate_inquiry_quick_content {
    width: 100%;
    background-color: rgba(241, 242, 243, 1);
    padding: 15px;
    font-size: 0.8em;
    position: relative;
}
.inquiry_btn {
    position: absolute;
    top: 30px;
    right: 30px;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
}
.admin_operate_inquiry_quick_content>div:nth-of-type(1) {
    display: flex;
    align-items: center;
}
.admin_operate_inquiry_quick_content>div:nth-of-type(1) span:nth-of-type(1) {
    font-weight: bold;
    font-size: 1.2em;
}
.admin_operate_inquiry_quick_content>div:nth-of-type(3) {
    font-size: 1.2em;
    font-weight: bold;
    padding: 15px 0;
}
.admin_operate_inquiry_quick_content>div:nth-of-type(4) textarea {
    width: 100%;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding: 10px;
}
.admin_operate_inquiry_quick_content_img {
    display: flex;
    align-items: center;
}
.admin_operate_inquiry_quick_content_img>div {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 8px;
    margin: 0 5px;
}
.admin_operate_inquiry_quick_content_img>div img {
    width: 100%;
    height: 80px;
    object-fit: cover;
}

/* 최애관리 */
.admin_operate_favority_box {
    position: relative;
}
.admin_operate_favority_content_header {
    display: flex;
    font-size: 0.9em;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 5px;
    text-align: center;
}
.admin_operate_favority_content_header>div:nth-of-type(1) {width: 5%;}
.admin_operate_favority_content_header>div:nth-of-type(2) {width: 25%;}
.admin_operate_favority_content_header>div:nth-of-type(3) {width: 25%;}
.admin_operate_favority_content_header>div:nth-of-type(4) {width: 25%;}
.admin_operate_favority_content_header>div:nth-of-type(5) {width: 10%;}
.admin_operate_favority_content_header>div:nth-of-type(6) {width: 10%;}

.admin_operate_favority_add {
    width: 100%;
    text-align: right;
}
.admin_operate_favority_add button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    border-radius: 4px;
    color: white;
    font-size: 0.8em;
    padding: 5px 10px;
}
.admin_operate_favority_content_content {
    display: flex;
    font-size: 0.8em;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 10px 0;
    text-align: center;
    align-items: center;
}
.admin_operate_favority_content_content>div:nth-of-type(1) {width: 5%;}
.admin_operate_favority_content_content>div:nth-of-type(2) {width: 25%;}
.admin_operate_favority_content_content>div:nth-of-type(3) {width: 25%;}
.admin_operate_favority_content_content>div:nth-of-type(4) {width: 25%;}
.admin_operate_favority_content_content>div:nth-of-type(5) {width: 10%;}
.admin_operate_favority_content_content>div:nth-of-type(6) {width: 10%;}
.admin_operate_favority_content_content>div:nth-of-type(2) img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.admin_operate_favority_content_content>div:nth-of-type(6) button {
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
    border-radius: 4px;
    padding: 3px 6px;
    margin: 3px;
}
.admin_favority_edit_modal {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    top: 0;
    left: 0;
    z-index: 1000;
}
.admin_favority_edit_modal_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vh;
    height: 80vh;
    background-color: white;
    border-radius: 12px;
    padding: 20px;
    overflow-y: scroll;
}
.admin_favority_edit_modal_header {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 0 20px 0;
    font-size: 1.2em;
}
.admin_favority_edit_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 20px;
    right: 20px;
    
}
.admin_favority_edit_modal_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.admin_favority_edit_modal_content>div:nth-of-type(1),
.admin_favority_edit_modal_content>div:nth-of-type(3),
.admin_favority_edit_modal_content>div:nth-of-type(4),
.admin_favority_edit_modal_content>div:nth-of-type(5),
.admin_favority_edit_modal_content>div:nth-of-type(6),
.admin_favority_edit_modal_content>div:nth-of-type(7),
.admin_favority_edit_modal_content>div:nth-of-type(8),
.admin_favority_edit_modal_content>div:nth-of-type(9),
.admin_favority_edit_modal_content>div:nth-of-type(10),
.admin_favority_edit_modal_content>div:nth-of-type(11),
.admin_favority_edit_modal_content>div:nth-of-type(12) {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 0;
}
.admin_favority_edit_modal_content>div:nth-of-type(1)>div:nth-of-type(1),
.admin_favority_edit_modal_content>div:nth-of-type(3)>div:nth-of-type(1),
.admin_favority_edit_modal_content>div:nth-of-type(4)>div:nth-of-type(1),
.admin_favority_edit_modal_content>div:nth-of-type(5)>div:nth-of-type(1),
.admin_favority_edit_modal_content>div:nth-of-type(6)>div:nth-of-type(1),
.admin_favority_edit_modal_content>div:nth-of-type(7)>div:nth-of-type(1),
.admin_favority_edit_modal_content>div:nth-of-type(8)>div:nth-of-type(1),
.admin_favority_edit_modal_content>div:nth-of-type(9)>div:nth-of-type(1),
.admin_favority_edit_modal_content>div:nth-of-type(10)>div:nth-of-type(1),
.admin_favority_edit_modal_content>div:nth-of-type(11)>div:nth-of-type(1),
.admin_favority_edit_modal_content>div:nth-of-type(12)>div:nth-of-type(1) {
    width: 30%;
}
.admin_favority_edit_modal_content>div:nth-of-type(1)>div:nth-of-type(2),
.admin_favority_edit_modal_content>div:nth-of-type(3)>div:nth-of-type(2),
.admin_favority_edit_modal_content>div:nth-of-type(4)>div:nth-of-type(2),
.admin_favority_edit_modal_content>div:nth-of-type(5)>div:nth-of-type(2),
.admin_favority_edit_modal_content>div:nth-of-type(6)>div:nth-of-type(2),
.admin_favority_edit_modal_content>div:nth-of-type(7)>div:nth-of-type(2),
.admin_favority_edit_modal_content>div:nth-of-type(8)>div:nth-of-type(2),
.admin_favority_edit_modal_content>div:nth-of-type(9)>div:nth-of-type(2),
.admin_favority_edit_modal_content>div:nth-of-type(10)>div:nth-of-type(2),
.admin_favority_edit_modal_content>div:nth-of-type(11)>div:nth-of-type(2),
.admin_favority_edit_modal_content>div:nth-of-type(12)>div:nth-of-type(2) {
    width: 70%;
}
.admin_favority_edit_modal_content>div:nth-of-type(2) {
    position: relative;
    width: 100%;
}
.admin_favority_edit_modal_content>div:nth-of-type(2)>div:nth-of-type(2) {
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin_favority_edit_modal_content>div:nth-of-type(2)>div:nth-of-type(3) {
    position: absolute;
    top: 25%;
    right: 25%;
    transform: translate(-50%, -50%);
}
.admin_favority_edit_modal_content>div:nth-of-type(2)>div:nth-of-type(3) button {
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding: 5px 10px;
    background-color: inherit;
    transition: all 0.2s;
}
.admin_favority_edit_modal_content>div:nth-of-type(2)>div:nth-of-type(3) button:hover {
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_favority_edit_modal_content>div:nth-of-type(2)>div:nth-of-type(2)>div {
    width: 150px;
    height: 150px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.admin_favority_edit_modal_content>div:nth-of-type(2)>div:nth-of-type(2)>div img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}
.admin_favority_edit_modal_content input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding-left: 10px;
    font-size: 0.9em;
}
.admin_favority_edit_modal_content input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.admin_favority_edit_modal_content textarea {
    width: 100%;
    height: 80px;
    border-radius: 4px;
    border: 1px solid rgba(196, 199, 202, 1);
    padding: 10px;
    font-size: 0.9em;
}
.admin_favority_edit_modal_content input[type="date"] {
    font-size: 0.9em;
    padding-right: 10px;
}
.admin_favority_edit_modal_content textarea:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.admin_favority_edit_modal_content>div:nth-of-type(13) {
    text-align: center;
    padding: 20px 0;
}
.admin_favority_edit_modal_content>div:nth-of-type(13) button {
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border: none;
    padding: 10px 50px;
    border-radius: 8px;
}
.admin_favority_request_btn {
    position: absolute;
    top: 20px;
    right: 20px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
}
.admin_favority_request_btn button {
    border: none;
    background-color: inherit;
    font-size: 0.9em;
    padding: 5px 10px;
}
.admin_favority_request_btn span {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    border-radius: 50%;
    position: absolute;
    color: white;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -10px;
    right: -10px;
    font-size: 0.8em;
    font-weight: 500;
}
.admin_favority_request_container {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    z-index: 1000;
}
.admin_favority_request_box {
    position: absolute;
    width: 80%;
    height: 80%;
    background-color: white;
    border-radius: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}
.admin_favority_request_header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    position: relative;
}
.admin_favority_request_header>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.admin_favority_request_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
}
.admin_favority_request_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.admin_favority_request_tab {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.admin_favority_request_tab>div {
    width: 100%;
    text-align: center;
}
.admin_favority_request_tab>div button {
    width: 100%;
    border: none;
    background-color: inherit;
}
.admin_favority_request_active {
    border-bottom: 2px solid rgba(255, 3, 102, 1);
}
.admin_favority_request_content {
    width: 100%;
    padding: 10px 20px;
}
.admin_favority_request_content_header {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    font-size: 0.9em;
    font-weight: 500;
}
.admin_favority_request_content_header>div:nth-of-type(1) {width: 15%;}
.admin_favority_request_content_header>div:nth-of-type(2) {width: 15%;}
.admin_favority_request_content_header>div:nth-of-type(3) {width: 35%; padding: 0 5px;}
.admin_favority_request_content_header>div:nth-of-type(4) {width: 10%;}
.admin_favority_request_content_header>div:nth-of-type(5) {width: 15%;}
.admin_favority_request_content_header>div:nth-of-type(6) {width: 10%;}

.admin_favority_request_content_info {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid rgba(223, 224, 226, 0.7);
    font-size: 0.8em;
}
.admin_favority_request_content_info>div:nth-of-type(1) {width: 15%;}
.admin_favority_request_content_info>div:nth-of-type(2) {width: 15%;}
.admin_favority_request_content_info>div:nth-of-type(3) {width: 35%; padding: 0 5px;}
.admin_favority_request_content_info>div:nth-of-type(4) {width: 10%;}
.admin_favority_request_content_info>div:nth-of-type(5) {width: 15%;}
.admin_favority_request_content_info>div:nth-of-type(6) {width: 10%; padding: 5px}
.admin_favority_request_content_info>div:nth-of-type(6) button {
    width: 100%;
    height: 30px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 4px;
}

/* 추천키워드 */
.adimn_operate_suggest_header {
    width: 100%;
}
.adimn_operate_suggest_header>div:nth-of-type(1) {
    font-size: 1.1em;
}
.adimn_operate_suggest_header>div:nth-of-type(2) {
    font-size: 0.9em;
    color: rgba(255, 114, 153, 1);
}
.adimn_operate_suggest_content {
    padding: 20px 0;
    width: 100%;
}
.adimn_operate_suggest_content_input {
    width: 100%;
    display: flex;
    align-items: center;
}
.adimn_operate_suggest_content_input>div:nth-of-type(1) {width: 10%; font-size: 0.9em;}
.adimn_operate_suggest_content_input>div:nth-of-type(2) {width: 30%;}
.adimn_operate_suggest_content_input>div:nth-of-type(2) input {
    width: 100%;
    border: 1px solid rgba(223, 224, 226, 1);
    height: 40px;
    border-radius: 4px;
    font-size: 0.9em;
    padding-left: 10px;
}
.adimn_operate_suggest_content_input>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.adimn_operate_suggest_content_input>div:nth-of-type(3) {width: 40%; padding-left: 10px; display: flex; align-items: center;}
.adimn_operate_suggest_content_input>div:nth-of-type(3)>div:nth-of-type(1) button {
    width: 80px;
    height: 40px;
    border: none;
    background-color: rgba(0, 115, 255, 1);
    font-size: 0.9em;
    color: white;
    border-radius: 4px;
}
.adimn_operate_suggest_content_input>div:nth-of-type(3)>div:nth-of-type(2) {
    padding-left: 10px;
}
.adimn_operate_suggest_content_input>div:nth-of-type(3)>div:nth-of-type(2) button {
    width: 80px;
    height: 40px;
    border: none;
    background-color: rgba(255, 3, 102, 0.6);
    font-size: 0.9em;
    color: white;
    border-radius: 4px;
}
.adimn_operate_suggest_content_textarea {
    width: 100%;
    padding: 20px 0;
}
.adimn_operate_suggest_content_textarea textarea {
    width: 100%;
    height: 500px;
    font-size: 0.9em;
    padding: 10px;
    resize: none;
}

/* 페이지 세팅 */
.admin_operate_pagesetting_title {
    font-size: 1.2em;
    padding-top: 15px;
}
.admin_operate_pagesetting_content {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    padding: 10px;
}
.admin_operate_pagesetting_content>div:nth-of-type(2) {
    font-size: 0.8em;
    color: rgba(196, 199, 202, 1);
}
.admin_operate_pagesetting_btn {
    display: flex;
    width: 100%;
    justify-content: end;
}
.admin_operate_pagesetting_btn>div:nth-of-type(1) button {
    padding: 5px 10px;
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
    font-size: 0.8em;
    border-radius: 4px;
}
.admin_operate_pagesetting_btn>div:nth-of-type(1) button:hover {
    background-color: rgba(0, 115, 255, 1);
    color: white;
}
.admin_operate_pagesetting_btn>div:nth-of-type(2) button {
    padding: 5px 10px;
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
    font-size: 0.8em;
    border-radius: 4px;
}
.admin_operate_pagesetting_btn>div:nth-of-type(2) button:hover {
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_operate_pagesetting_content {
    border: 1px solid rgba(223, 224, 226, 1);
    padding: 10px;
}
.admin_operate_pagesetting_content_header {
    width: 100%;
    display: flex;
    font-size: 0.9em;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding: 10px 0;
}
.admin_operate_pagesetting_content_header>div:nth-of-type(1) {width: 50%;}
.admin_operate_pagesetting_content_header>div:nth-of-type(2) {width: 50%;}
.admin_operate_pagesetting_content_info {
    width: 100%;
    display: flex;
    font-size: 0.9em;
    padding: 10px 0;
    align-items: center;
}
.admin_operate_pagesetting_content_info>div:nth-of-type(1),
.admin_operate_pagesetting_content_side_info>div:nth-of-type(1),
.admin_operate_pagesetting_content_info>div:nth-of-type(1) {
    width: 100%;
    display: flex;
    align-items: center;
}
.admin_operate_pagesetting_content_info>div:nth-of-type(1)>div:nth-of-type(1) {width: 50%;}
.admin_operate_pagesetting_content_info>div:nth-of-type(1)>div:nth-of-type(2) {width: 50%;}
.admin_operate_pagesetting_content_info img {
    width: 200px;
    height: 100px;
    object-fit: cover;
}
.admin_operate_pagesetting_content_side_info {
    width: 100%;
    display: flex;
    font-size: 0.9em;
    padding: 10px 0;
    align-items: center;
}
.admin_operate_pagesetting_content_side_info>div:nth-of-type(1)>div:nth-of-type(1) {width: 50%;}
.admin_operate_pagesetting_content_side_info>div:nth-of-type(1)>div:nth-of-type(2) {width: 50%;}
.admin_operate_pagesetting_content_side_info img {
    width: 100px;
    height: 200px;
    object-fit: cover;
}
.admin_operate_pagesetting_sub_title {
    font-size: 0.8em;
    color: rgba(196, 199, 202, 1);
}

/* 이벤트 관리 */
.admin_event_setting_title {
    font-size: 1.2em;
    padding: 5px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_event_setting_event_add_btn {
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 5px 0;
}
.admin_event_setting_event_add_btn button {
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 0.9em;
}
.admin_event_setting_event_add_btn button:hover {
    background-color: rgba(255, 114, 153, 0.1);
}
.admin_event_setting_tap {
    display: flex;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    align-items: center;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_event_setting_tap_active {
    border-bottom: 3px solid rgba(255, 3, 102, 1);
}
.admin_event_setting_tap>div {
    width: 100%;
}
.admin_event_setting_tap>div button {
    border: none;
    background-color: inherit;
}
.admin_event_setting_event_content_box {
    width: 100%;
    overflow-x: scroll;
}
.admin_event_setting_event_content_box::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 5px;
}
.admin_event_setting_event_content_box::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.admin_event_setting_event_content_box::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.admin_event_setting_event_content_box>div {
    min-width: 1300px;
}
.admin_event_setting_event_content_header {
    display: flex;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 5px 0;
    font-size: 0.9em;
    align-items: center;
    text-align: center;
}
.admin_event_setting_event_content_header input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_event_setting_event_content_header input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_event_setting_event_content_header input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_event_setting_event_content_header input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_event_setting_event_content_header>div:nth-of-type(1) {width: 5%;} 
.admin_event_setting_event_content_header>div:nth-of-type(2) {width: 15%;} 
.admin_event_setting_event_content_header>div:nth-of-type(3) {width: 15%;} 
.admin_event_setting_event_content_header>div:nth-of-type(4) {width: 25%;} 
.admin_event_setting_event_content_header>div:nth-of-type(5) {width: 20%;} 
.admin_event_setting_event_content_header>div:nth-of-type(6) {width: 10%;} 
.admin_event_setting_event_content_header>div:nth-of-type(7) {width: 10%;} 

.admin_event_setting_event_content_content {
    display: flex;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 5px 0;
    font-size: 0.8em;
    align-items: center;
    text-align: center;
}
.admin_event_setting_event_content_content input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_event_setting_event_content_content input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_event_setting_event_content_content input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_event_setting_event_content_content input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_event_setting_event_content_content>div:nth-of-type(1) {width: 5%;} 
.admin_event_setting_event_content_content>div:nth-of-type(2) {width: 15%;} 
.admin_event_setting_event_content_content>div:nth-of-type(3) {width: 15%; text-align: left;} 
.admin_event_setting_event_content_content>div:nth-of-type(4) {width: 25%; text-align: left;} 
.admin_event_setting_event_content_content>div:nth-of-type(5) {width: 20%;} 
.admin_event_setting_event_content_content>div:nth-of-type(6) {width: 10%;} 
.admin_event_setting_event_content_content>div:nth-of-type(7) {width: 10%;}
.admin_event_setting_event_content_content>div:nth-of-type(2) img {
    width: 100px;
    height: 100px;
    border-radius: 4px;
}
.admin_event_setting_event_content_content>div:nth-of-type(7) button {
    border: none;
    background-color: inherit;
}
.admin_event_setting_event_content_content>div:nth-of-type(7)>div:nth-of-type(1) button {color: rgba(0, 115, 255, 1); transition: all 0.2s;}
.admin_event_setting_event_content_content>div:nth-of-type(7)>div:nth-of-type(1) button:hover {color: rgba(255, 114, 153, 1);}
.admin_event_setting_event_content_content>div:nth-of-type(7)>div:nth-of-type(2) button {color: rgba(255, 3, 102, 1);}
.admin_event_setting_event_content_content>div:nth-of-type(7)>div:nth-of-type(2) button:hover {color: rgba(169, 173, 178, 1);}

.admin_event_setting_add_modal_container {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100vh;
    z-index: 1000;
}
.admin_event_setting_add_modal_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    height: 80vh;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
}
.admin_event_setting_add_modal_header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    justify-content: center;
}
.admin_event_setting_add_modal_header>div:nth-of-type(1) {
    font-size: 1.2em;
}
.admin_event_setting_add_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 25px;
    right: 25px;
}
.admin_event_setting_add_modal_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.admin_event_setting_add_modal_event_type {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 20px;
}
.admin_event_setting_add_modal_event_type>div:nth-of-type(1) {width: 20%;}
.admin_event_setting_add_modal_event_type>div:nth-of-type(2) {width: 80%; display: flex; align-items: center;}
.admin_event_setting_add_modal_event_type>div:nth-of-type(2) button {
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    padding: 5px 10px;
    font-size: 0.9em;
    margin: 0 5px;
    border-radius: 8px;
}
.admin_event_setting_add_modal_event_type_active {
    border: 1px solid rgba(255, 3, 102, 1) !important;
    color: rgba(255, 3, 102, 1) !important;
}

.admin_event_setting_add_modal_vote {
    width: 100%;
    padding: 10px 20px;
    height: 55vh;
    overflow-y: scroll;
}
.admin_event_vote_radio_box {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_event_vote_radio_box>div:nth-of-type(1) {width: 20%; font-size: 0.9em;}
.admin_event_vote_radio_box>div:nth-of-type(2) {
    width: 80%;
    display: flex;
    align-items: center;
}
.admin_event_vote_radio_box>div:nth-of-type(2)>div {
    display: flex;
    align-items: center;
}
.admin_event_vote_radio_box>div:nth-of-type(2)>div:nth-of-type(2) {
    padding-left: 20px;
}
.admin_event_vote_radio_box>div:nth-of-type(2)>div label {
    padding-left: 3px;
    font-size: 0.9em;
}
.admin_event_vote_radio_box input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.admin_event_vote_radio_box input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}

.admin_event_vote_radio_box input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}

.admin_event_vote_radio_box input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}
.admin_event_vote_cnt_box {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_event_vote_cnt_box>div:nth-of-type(1) {width: 20%; font-size: 0.9em;}
.admin_event_vote_cnt_box>div:nth-of-type(2) {
    width: 80%;
}
.admin_event_vote_cnt_box>div:nth-of-type(2)>div {
    width: 200px;
    display: flex;
    align-items: center;
}
.admin_event_vote_cnt_box>div:nth-of-type(2)>div input {
    width: 90%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 0 20px;
    text-align: right;
}
.admin_event_vote_cnt_box>div:nth-of-type(2)>div input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_event_setting_add_modal_vote_number {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_event_setting_add_modal_vote_number>div:nth-of-type(1) {width: 20%; font-size: 0.9em;}
.admin_event_setting_add_modal_vote_number>div:nth-of-type(2) {
    width: 80%;
    flex-direction: column;
    display: flex;align-items: center;
    justify-content: center;
}
.admin_event_setting_add_modal_vote_number_add_btn {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: 10px 0;
}
.admin_event_setting_add_modal_vote_number_add_btn > div {
    width: 50px;
    height: 50px;
}
.admin_event_setting_add_modal_vote_number_add_btn > div button {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 50%;
    background-color: inherit;
    transition: all 0.2s;
}
.admin_event_setting_add_modal_vote_number_add_btn > div button>span {
    color: rgba(196, 199, 202, 1);
}
.admin_event_setting_add_modal_vote_number_add_btn > div button:hover,
.admin_event_setting_add_modal_vote_number_add_btn > div button:active {
    background-color: rgba(255, 3, 102, 1);
    border: none;
}
.admin_event_setting_add_modal_vote_number_add_btn > div button>span:hover,
.admin_event_setting_add_modal_vote_number_add_btn > div button>span:active {
    color: white;
}
.admin_event_setting_add_modal_vote_number_add_image_box {
    display: flex;
    align-items: center;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    padding: 5px;
    border-radius: 4px;
    margin-bottom: 10px;
}
.admin_event_setting_add_modal_vote_number_add_image {
    width: 60px;
    height: 60px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.admin_event_setting_add_modal_vote_number_add_image button {
    width: 100%;
    height: 100%;
    background-color: inherit;
    border: none;
}
.admin_event_setting_add_modal_vote_number_add_image button > span { 
    color: rgba(196, 199, 202, 1);
}
.admin_event_setting_add_modal_vote_number_add_input {
    width: 200px;
    margin-left: 10px;
}
.admin_event_setting_add_modal_vote_number_add_input input {
    width: 100%;
    height: 40px;
    border: none;
    padding: 10px;
    border-bottom: 1px solid rgba(169, 173, 178, 1);
    font-size: 0.9em;
}
.admin_event_setting_add_modal_vote_number_add_input input:focus {
    outline: none;
    border-bottom: 1px solid rgba(255, 3, 102, 1);
}
.admin_event_setting_add_modal_vote_number_add_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 100 / 100;
}

.admin_event_setting_add_modal_lucked {
    width: 100%;
    padding: 10px 20px;
    height: 55vh;
    overflow-y: scroll;
}
.admin_event_setting_add_modal_input_box {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_event_setting_add_modal_input_box>div:nth-of-type(1) {width: 20%; font-size: 0.9em;}
.admin_event_setting_add_modal_input_box>div:nth-of-type(2) {width: 80%;}
.admin_event_setting_add_modal_input_box>div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding-left: 10px;
    font-size: 0.9em;
}
.admin_event_setting_add_modal_input_box>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_event_setting_add_modal_price_box {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_event_setting_add_modal_input_box textarea {
    width: 100%;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    height: 100px;
    resize: none;
    padding: 10px;
    font-size: 0.9em;
}
.admin_event_setting_add_modal_input_box textarea:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.admin_event_setting_add_modal_price_box>div:nth-of-type(1) {width: 20%; font-size: 0.9em;}
.admin_event_setting_add_modal_price_box>div:nth-of-type(2) {width: 80%;}
.admin_event_setting_add_modal_price_box>div:nth-of-type(2)>div {
    width: 30%;
    position: relative;
}
.admin_event_setting_add_modal_price_box>div:nth-of-type(2)>div>div:nth-of-type(1) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding-right: 25px;
    font-size: 0.9em;
    text-align: right;
}
.admin_event_setting_add_modal_price_box>div:nth-of-type(2)>div>div:nth-of-type(1) input:focus{
    border: 1px solid rgba(255, 114, 153, 1);
    outline: none;
}
.admin_event_setting_add_modal_price_box>div:nth-of-type(2)>div>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    font-size: 0.9em;
}
 
.admin_event_setting_add_modal_swiper_img {
    width: 150px;
    height: 150px;
    border: none;
    border-radius: 4px;
    overflow: hidden;
}
.admin_event_setting_add_modal_swiper_img>img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}
.admin_event_setting_add_modal_img_box {
    font-size: 0.9em;
    padding: 10px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_event_setting_add_modal_img_box>div:nth-of-type(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.admin_event_setting_add_modal_img_box>div:nth-of-type(1) button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.9em;
    transition: all 0.2s;
}
.admin_event_setting_add_modal_img_box>div:nth-of-type(1) button:hover {
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
}
.admin_event_setting_add_modal_lucked_content {
    position: relative;
    width: 100%;
    padding: 10px 0;
    font-size: 0.9em;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_event_setting_add_modal_lucked_content>div:nth-of-type(1) {
    padding-bottom: 15px;
    padding-top: 10px;
}
.admin_event_setting_add_modal_lucked_secret {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
.admin_event_setting_add_modal_lucked_secret>div:nth-of-type(1) {width: 20%;}
.admin_event_setting_add_modal_lucked_secret>div:nth-of-type(2) {
    width: 80%;
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
.admin_event_setting_add_modal_lucked_secret>div:nth-of-type(2) > div {
    display: flex;
    align-items: center;
}
.admin_event_setting_add_modal_lucked_secret>div:nth-of-type(2) > div:nth-of-type(2) {
    padding-left: 20px;
}
.admin_event_setting_add_modal_lucked_secret>div:nth-of-type(2) > div label {
    padding-left: 3px;
}
.admin_event_setting_add_modal_lucked_secret input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.admin_event_setting_add_modal_lucked_secret input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}

.admin_event_setting_add_modal_lucked_secret input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}

.admin_event_setting_add_modal_lucked_secret input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}
.admin_event_setting_add_modal_date_box {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
.admin_event_setting_add_modal_date_box>div:nth-of-type(1) {width: 20%;}
.admin_event_setting_add_modal_date_box>div:nth-of-type(2) {
    width: 80%;
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
.admin_event_setting_add_modal_date_box>div:nth-of-type(2) input {
    width: 200px;
    height: 40px;
    padding: 0 5px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 8px;
    color: rgba(255, 114, 153, 1);
}
.admin_event_setting_add_modal_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.admin_event_setting_add_modal_footer>div {
    width: 40%;
    margin: 0 10px;
}
.admin_event_setting_add_modal_footer>div button {
    width: 100%;
    height: 50px;
    font-size: 0.9em;
}
.admin_event_setting_add_modal_footer>div:nth-of-type(1) button {
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    background-color: inherit;
}
.admin_event_setting_add_modal_footer>div:nth-of-type(2) button {
    border: none;
    border-radius: 4px;
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_event_setting_add_modal_plan {
    width: 100%;
    padding: 10px 20px;
    height: 55vh;
    overflow-y: scroll;
}
.admin_event_setting_add_modal_input_box {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_event_setting_add_modal_input_box>div:nth-of-type(1) {width: 20%; font-size: 0.9em;}
.admin_event_setting_add_modal_input_box>div:nth-of-type(2) {width: 80%;}
.admin_event_setting_add_modal_input_box>div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding-left: 10px;
    font-size: 0.9em;
}
.admin_event_setting_add_modal_input_box>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_event_setting_plan_select {
    width: 30%;
    height: 40px;
}
.admin_event_setting_add_modal_discount_box {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_event_setting_add_modal_discount_box>div:nth-of-type(1) {width: 20%; font-size: 0.9em;}
.admin_event_setting_add_modal_discount_box>div:nth-of-type(2) {width: 80%;}
.admin_event_setting_add_modal_discount_box>div:nth-of-type(2)>div {width: 30%; position: relative;}
.admin_event_setting_add_modal_discount_box>div:nth-of-type(2)>div>div:nth-of-type(1) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    font-size: 0.9em;
    text-align: right;
    padding-right: 25px;
}
.admin_event_setting_add_modal_discount_box>div:nth-of-type(2)>div>div:nth-of-type(1) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_event_setting_add_modal_discount_box>div:nth-of-type(2)>div>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    font-size: 0.9em;
}
.admin_event_setting_add_modal_product_select {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_event_setting_add_modal_product_select>div:nth-of-type(1) {width: 20%; font-size: 0.9em;}
.admin_event_setting_add_modal_product_select>div:nth-of-type(2) {width: 80%; display: flex; font-size: 0.9em;}
.admin_event_setting_add_modal_product_select>div:nth-of-type(2)>div {display: flex; align-items: center;}
.admin_event_setting_add_modal_product_select input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.admin_event_setting_add_modal_product_select input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}

.admin_event_setting_add_modal_product_select input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}

.admin_event_setting_add_modal_product_select input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}
.admin_event_setting_plan_all_select {
    width: 100%;
    padding: 20px;
    text-align: center;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    font-size: 0.9em;
}
.admin_event_setting_plan_product_select {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
}
.admin_event_setting_plan_product_select>div {
    width: 45%;
    height: 50vh;
    border-radius: 12px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    overflow-y: scroll;
    padding: 20px;
}
.admin_event_setting_plan_product_select>div::-webkit-scrollbar {
    width: 5px;  /* 스크롤바의 너비 */
    height: 5px;
}
.admin_event_setting_plan_product_select>div::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.admin_event_setting_plan_product_select>div::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.admin_event_setting_plan_product_select input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_event_setting_plan_product_select input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_event_setting_plan_product_select input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_event_setting_plan_product_select input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_event_setting_plan_product_select_item {
    display: flex;
    align-items: center;
    width: 100%;
}
.admin_event_setting_plan_product_select_item>div:nth-of-type(1) {width: 10%;}
.admin_event_setting_plan_product_select_item>div:nth-of-type(2) {width: 90%; display: flex; align-items: center; padding: 5px;}
.admin_event_setting_plan_product_select_item>div:nth-of-type(2)>div:nth-of-type(1) {width: 50px; height: 50px; overflow: hidden; border-radius: 4px;}
.admin_event_setting_plan_product_select_item>div:nth-of-type(2)>div:nth-of-type(1) img {width: 100%; height: 50px; object-fit: cover;}
.admin_event_setting_plan_product_select_item>div:nth-of-type(2)>div:nth-of-type(2) {width: calc(100% - 50px); font-size: 0.8em; padding-left: 5px;}
.admin_event_setting_plan_product_select_item>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(1) {font-size: 0.9em; color: rgba(196, 199, 202, 1);}
.admin_event_setting_plan_product_select_item>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(2) {
    font-weight: bold;
    /* font-size: 1.4em; */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 두 줄로 제한 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* 원래는 nowrap을 사용하지만, 여러 줄 처리를 위해 normal로 설정 */
    line-height: 1.2em; /* 줄 높이 설정 */
    max-height: 2.4em; /* 줄 높이와 줄 수에 따라 최대 높이 설정 (예: 줄 높이 1.2em * 줄 수 2 = 2.4em) */
}
.admin_event_setting_plan_product_select_item_choice {
    display: flex;
    align-items: center;
    width: 100%;
}
.admin_event_setting_plan_product_select_item_choice>div:nth-of-type(1) {width: 10%;}
.admin_event_setting_plan_product_select_item_choice>div:nth-of-type(2) {width: 50%; display: flex; align-items: center; padding: 5px;}
.admin_event_setting_plan_product_select_item_choice>div:nth-of-type(2)>div:nth-of-type(1) {width: 50px; height: 50px; overflow: hidden; border-radius: 4px;}
.admin_event_setting_plan_product_select_item_choice>div:nth-of-type(2)>div:nth-of-type(1) img {width: 100%; height: 50px; object-fit: cover;}
.admin_event_setting_plan_product_select_item_choice>div:nth-of-type(2)>div:nth-of-type(2) {width: calc(100% - 50px); font-size: 0.8em; padding-left: 5px;}
.admin_event_setting_plan_product_select_item_choice>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(1) {font-size: 0.9em; color: rgba(196, 199, 202, 1);}
.admin_event_setting_plan_product_select_item_choice>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(2) {
    font-weight: bold;
    /* font-size: 1.4em; */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 두 줄로 제한 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* 원래는 nowrap을 사용하지만, 여러 줄 처리를 위해 normal로 설정 */
    line-height: 1.2em; /* 줄 높이 설정 */
    max-height: 2.4em; /* 줄 높이와 줄 수에 따라 최대 높이 설정 (예: 줄 높이 1.2em * 줄 수 2 = 2.4em) */
}
.admin_event_setting_plan_product_rate {
    width: 40%;
    display: flex;
    align-items: center;
    font-size: 0.8em;
    overflow: hidden;
}
.admin_event_setting_plan_product_rate>div:nth-of-type(1) {
    width: 60%;
    padding: 5px;
}
.admin_event_setting_plan_product_rate>div:nth-of-type(2) {
    width: 40%;
    padding: 5px;
}
.admin_event_setting_plan_product_rate>div:nth-of-type(1)>div:nth-of-type(2),
.admin_event_setting_plan_product_rate>div:nth-of-type(2)>div:nth-of-type(2) {
    position: relative;
}
.admin_event_setting_plan_product_rate>div:nth-of-type(1)>div:nth-of-type(2) input,
.admin_event_setting_plan_product_rate>div:nth-of-type(2)>div:nth-of-type(2) input {
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    width: 100%;
    padding: 0 5px;
    height: 30px;
    font-size: 0.9em;
}
.admin_event_setting_plan_product_rate>div:nth-of-type(1)>div:nth-of-type(2) input:focus,
.admin_event_setting_plan_product_rate>div:nth-of-type(2)>div:nth-of-type(2) input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.admin_event_setting_plan_product_rate>div:nth-of-type(1)>div:nth-of-type(2) span,
.admin_event_setting_plan_product_rate>div:nth-of-type(2)>div:nth-of-type(2) span {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}


/* 당첨자 발표 */
.admin_event_winner_title {
    font-size: 1.2em;
    padding: 5px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_event_winner_add_btn {
    width: 100%;
    display: flex;
    justify-content: right;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_event_winner_add_btn button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    font-size: 0.9em;
    padding: 5px 10px;
    border-radius: 4px;
}
.admin_event_winner_content_header {
    width: 100%;
    display: flex;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 5px 0;
    align-items: center;
    font-size: 0.9em;
    text-align: center;
}
.admin_event_winner_content_header>div:nth-of-type(1) {width: 10%;}
.admin_event_winner_content_header>div:nth-of-type(2) {width: 50%;}
.admin_event_winner_content_header>div:nth-of-type(3) {width: 10%;}
.admin_event_winner_content_header>div:nth-of-type(4) {width: 15%;}
.admin_event_winner_content_header>div:nth-of-type(5) {width: 15%;}
.admin_event_winner_content_content {
    width: 100%;
    display: flex;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 5px 0;
    align-items: center;
    font-size: 0.8em;
}
.admin_event_winner_content_content>div:nth-of-type(1) {width: 10%; text-align: center;}
.admin_event_winner_content_content>div:nth-of-type(2) {width: 50%;}
.admin_event_winner_content_content>div:nth-of-type(3) {width: 10%; text-align: center;}
.admin_event_winner_content_content>div:nth-of-type(4) {width: 15%; text-align: center;}
.admin_event_winner_content_content>div:nth-of-type(5) {width: 15%; text-align: center;}
.admin_event_winner_content_content>div:nth-of-type(5) button {
    border: none;
    background-color: inherit;
}

.admin_event_winner_content_content>div:nth-of-type(5)>div:nth-of-type(1) button {color: rgba(0, 115, 255, 1); transition: all 0.2s;}
.admin_event_winner_content_content>div:nth-of-type(5)>div:nth-of-type(1) button:hover {color: rgba(255, 114, 153, 1);}
.admin_event_winner_content_content>div:nth-of-type(5)>div:nth-of-type(2) button {color: rgba(255, 3, 102, 1);}
.admin_event_winner_content_content>div:nth-of-type(5)>div:nth-of-type(2) button:hover {color: rgba(169, 173, 178, 1);}
.admin_event_winner_content_content_select {
    width: 100%;
    height: 30px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    text-align: center;
}
.admin_event_winner_modal_container {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    z-index: 1000;
}
.admin_event_winner_modal_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
}
.admin_event_winner_modal_header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
}
.admin_event_winner_modal_header>div:nth-of-type(1) {font-size: 1.1em;}
.admin_event_winner_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
}
.admin_event_winner_modal_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.admin_event_winner_modal_content {
    width: 100%;
    height: 65vh;
    overflow-y: auto;
}
.admin_event_winner_modal_content_title {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-left: 20px;
    font-size: 0.9em;
}
.admin_event_winner_modal_content_title>div:nth-of-type(1) {width: 20%;}
.admin_event_winner_modal_content_title>div:nth-of-type(2) {width: 80%;}
.admin_event_winner_modal_content_title>div:nth-of-type(2) input {
    width: 350px;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding-left: 10px;
}
.admin_event_winner_modal_content_state {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-left: 20px;
    font-size: 0.9em;
}
.admin_event_winner_modal_content_state>div:nth-of-type(1) {width: 20%;}
.admin_event_winner_modal_content_state>div:nth-of-type(2) {width: 80%;}

.admin_event_winner_modal_content_title>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1)
}
.admin_event_winner_modal_content_event_select {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_event_winner_modal_content_event_select>div:nth-of-type(1) {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    width: 100%;
}
.admin_event_winner_modal_content_event_select>div:nth-of-type(1)>div:nth-of-type(1) {width: 20%;}
.admin_event_winner_modal_content_event_select>div:nth-of-type(1)>div:nth-of-type(2) {width: 80%;}
.admin_event_winner_modal_content_event_select>div:nth-of-type(1)>div:nth-of-type(2) input {
    width: 250px;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding-left: 10px;
}
.admin_event_winner_modal_content_event_select>div:nth-of-type(1)>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}

.admin_event_winner_modal_content_event_select>div:nth-of-type(2) {
    padding: 10px;
    width: 100%;
}
.admin_event_winner_modal_content_event_select>div:nth-of-type(2)>div {
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
}
.admin_event_winner_modal_content_item {
    width: 33%;
}
.admin_event_winner_modal_content_item>div {
    width: 100%;
    overflow: hidden;
    padding: 5px;
    display: flex;
    align-items: center;
}
.admin_event_winner_modal_content_item>div input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.admin_event_winner_modal_content_item>div input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}

.admin_event_winner_modal_content_item>div input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}

.admin_event_winner_modal_content_item>div input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}
.admin_event_winner_modal_content_item_info {
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin: 10px 0;
}
.admin_event_winner_modal_content_item_info>div:nth-of-type(1) {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 8px;
}
.admin_event_winner_modal_content_item_info>div:nth-of-type(1) img {
    width: 100%;
    height: 80px;
    object-fit: cover;
}
.admin_event_winner_modal_content_item_info>div:nth-of-type(2) {
    font-size: 0.9em;
    line-height: 16px;
    padding-left: 5px;
}
.admin_event_winner_modal_content_item_info>div:nth-of-type(2)>div:nth-of-type(1) {
    font-size: 0.8em;
    color: rgba(196, 199, 202, 1);
}
.admin_event_winner_modal_content_item_info>div:nth-of-type(2)>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: bold;
}
.admin_event_winner_modal_content_item_info>div:nth-of-type(2)>div:nth-of-type(3) {
    font-size: 0.9em;
    color: rgba(255, 3, 102, 1);
}
.admin_event_winner_modal_content_item_info>div:nth-of-type(2)>div:nth-of-type(4) {
    font-size: 0.8em;
    color: rgba(0, 115, 255, 1);
}
.admin_event_winner_modal_content_select_item {
    width: 100%;
    display: flex;
    align-items: center;
}
.admin_event_winner_modal_content_select_item>div:nth-of-type(1) {
    width: 50%;
    display: flex;
    align-items: center;
}
.admin_event_winner_modal_content_select_item>div:nth-of-type(1)>div:nth-of-type(1) {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 8px;
}
.admin_event_winner_modal_content_select_item>div:nth-of-type(1)>div:nth-of-type(1) img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}
.admin_event_winner_modal_content_select_item_detail {
    width: calc(100% - 150px);
    padding-left: 5px;
}
.admin_event_winner_modal_content_select_item_detail>div {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.9em;
    padding: 5px 0;
}
.admin_event_winner_modal_content_select_item_detail>div>div:nth-of-type(1) {width: 30%;}
.admin_event_winner_modal_content_select_item_detail>div>div:nth-of-type(2) {width: 70%;}
.admin_event_winner_modal_content_select_item_detail>div:nth-of-type(5)>div:nth-of-type(2) {
    color: rgba(196, 199, 202, 1);
    font-size: 0.8em;
}
.admin_event_winner_modal_content_select_item_detail2 {
    width: 50%;
}
.admin_event_winner_modal_content_select_item_detail2>div {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.9em;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding: 3px 0;
}
.admin_event_winner_modal_content_select_item_detail2>div>div:nth-of-type(1) {width: 30%;}
.admin_event_winner_modal_content_select_item_detail2>div>div:nth-of-type(2) {width: 70%;}
.admin_event_winner_modal_content_select_item_detail2>div:nth-of-type(1)>div:nth-of-type(2) {
    color: rgba(255, 114, 153, 1);
    font-size: 0.8em;
}
.admin_event_winner_modal_content_select_item_detail2>div:nth-of-type(3)>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 100%;
    max-height: 100px;
    overflow: hidden;
}
.admin_event_winner_modal_content_select_item_detail2>div:nth-of-type(3)>div:nth-of-type(2)>div:nth-of-type(1) img {
    width: 100%;
    object-fit: cover;
}
.admin_event_winner_modal_content_select_item_detail2>div:nth-of-type(3)>div:nth-of-type(2)>div:nth-of-type(2) {
    width: 100%;
}
.admin_event_winner_modal_content_select_item_detail2>div:nth-of-type(3)>div:nth-of-type(2)>div:nth-of-type(2) button {
    width: 100%;
    height: 30px;
    border: none;
    background-color: rgba(223, 224, 226, 1);
    font-size: 0.9em;
    border-radius: 4px;
    transition: all 0.2s;
}
.admin_event_winner_modal_content_select_item_detail2>div:nth-of-type(3)>div:nth-of-type(2)>div:nth-of-type(2) button:hover,
.admin_event_winner_modal_content_select_item_detail2>div:nth-of-type(3)>div:nth-of-type(2)>div:nth-of-type(2) button:active {
    background-color: rgba(255, 114, 153, 0.7);
    color: white;
}
.admin_event_participants_search {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    font-size: 0.9em;
    border: 1px solid rgba(223, 224, 226, 1);
}
.admin_event_participants_search>div:nth-of-type(1) {width: 20%;}
.admin_event_participants_search>div:nth-of-type(2) {width: 80%;}
.admin_event_participants_search>div:nth-of-type(2) input {
    width: 250px;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding-left: 10px;
}
.admin_event_participants_search>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_event_winner_modal_footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 100px;
}
.admin_event_winner_modal_footer>div {
    width: 40%;
    margin: 0 5px;
}
.admin_event_winner_modal_footer>div:nth-of-type(1) button {
    width: 100%;
    height: 40px;
    border: none;
    background-color: rgba(223, 224, 226, 1);
    border-radius: 4px;
    font-size: 0.9em;
}
.admin_event_winner_modal_footer>div:nth-of-type(2) button {
    width: 100%;
    height: 40px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    border-radius: 4px;
    font-size: 0.9em;
    color: white;
}

/* 이벤트 참여자 */
.admin_event_participants_title {
    font-size: 1.2em;
    padding: 5px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_event_participants_tab {
    width: 100%;
    display: flex;
    align-items: center;
}
.admin_event_participants_tab>div {
    width: 100%;
}
.admin_event_participants_tab>div button {
    width: 100%;
    height: 40px;
    border: none;
    background-color: inherit;
}
.admin_event_participants_active {
    border-bottom: 3px solid rgba(255, 3, 102, 1) !important;
    color: rgba(255, 3, 102, 1) !important;
}
.admin_event_participants_header {
    width: 100%;
    display: flex;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 5px 0;
    align-items: center;
    font-size: 0.9em;
    text-align: center;
}
.admin_event_participants_header>div:nth-of-type(1) {width: 5%;}
.admin_event_participants_header>div:nth-of-type(2) {width: 5%;}
.admin_event_participants_header>div:nth-of-type(3) {width: 10%;}
.admin_event_participants_header>div:nth-of-type(4) {width: 30%;}
.admin_event_participants_header>div:nth-of-type(5) {width: 20%;}
.admin_event_participants_header>div:nth-of-type(6) {width: 20%;}
.admin_event_participants_header>div:nth-of-type(7) {width: 10%;}
.admin_event_participants_content {
    width: 100%;
    display: flex;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 20px 0;
    align-items: center;
    font-size: 0.9em;
    text-align: center;
    cursor: pointer;
}
.admin_event_participants_content:hover {
    background-color: rgba(223, 224, 226, 1);
}
.admin_event_participants_content>div:nth-of-type(1) {width: 5%;}
.admin_event_participants_content>div:nth-of-type(2) {width: 5%;}
.admin_event_participants_content>div:nth-of-type(3) {width: 10%;}
.admin_event_participants_content>div:nth-of-type(4) {width: 30%;}
.admin_event_participants_content>div:nth-of-type(5) {width: 20%;}
.admin_event_participants_content>div:nth-of-type(6) {width: 20%;}
.admin_event_participants_content>div:nth-of-type(7) {width: 10%; color: rgba(255, 3, 102, 1);}

.admin_event_participants_content>div:nth-of-type(3) img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
}

.admin_event_participants_container input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_event_participants_container input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_event_participants_container input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_event_participants_container input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_event_participants_container {
    width: 100%;
    overflow-x: scroll;
}
.admin_event_participants_container::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 5px;
}
.admin_event_participants_container::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.admin_event_participants_container::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.admin_event_participants_container>div {
    min-width: 1200px;
}
.admin_participants_modal_container {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
    top: 0;
    left: 0;
}
.admin_participants_modal_box {
    position: absolute;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 12px;
}
.admin_participants_modal_header {
    width: 100%;
    position: relative;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_participants_modal_header>div:nth-of-type(1) {font-size: 1.1em;}
.admin_participants_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
}
.admin_participants_modal_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.admin_participants_modal_event_box {
    padding: 20px;
    width: 100%;
    height: 70vh;
    overflow-y: scroll;
}
.admin_participants_modal_event_info {
    display: flex;
}
.admin_participants_modal_event_info>div:nth-of-type(1) {width: 50%;}
.admin_participants_modal_event_info>div:nth-of-type(2) {width: 50%;}
.admin_participants_swiper {
    width: 100%;
    height: 450px;
    overflow: hidden;
}
.admin_participants_swiper img {
    width: 100%;
    height: 450px;
    object-fit: cover;
}
.admin_participants_modal_event_detail {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding: 10px 0;
    font-size: 0.9em;
}
.admin_participants_modal_event_detail>div:nth-of-type(1) {width: 30%; color: rgba(169, 173, 178, 1);}
.admin_participants_modal_event_detail>div:nth-of-type(2) {width: 70%;}
.admin_participants_modal_event_info>div:nth-of-type(2)>div:nth-of-type(3)>div:nth-of-type(2) {
    font-size: 0.9em;
}
.admin_participants_modal_event_detail_content {
    padding: 10px 0;
    width: 100%;
    overflow: hidden;
    height: 250px;
}
.admin_participants_modal_event_detail_content img {
    width: 100%;
    object-fit: cover;
}
.admin_participants_modal_event_detail_btn {
    width: 100%;
}
.admin_participants_modal_event_detail_btn button {
    width: 100%;
    border: none;
    height: 30px;
    font-size: 0.9em;
}
.admin_participants_modal_event_detail_btn button:hover {
    opacity: 0.7;
}
.admin_participant_list {
    margin-top: 20px;
    box-shadow: 0 0 4px 1px rgba(196, 199, 202, 1);
    padding: 10px;
    border-radius: 4px;
}
.admin_participant_list_header {
    width: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(223, 224, 226, 1);
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding: 10px 0;
    font-size: 0.9em;
    text-align: center;
}
.admin_participant_list_header>div:nth-of-type(1) {width: 10%;}
.admin_participant_list_header>div:nth-of-type(2) {width: 10%;}
.admin_participant_list_header>div:nth-of-type(3) {width: 20%;}
.admin_participant_list_header>div:nth-of-type(4) {width: 10%;}
.admin_participant_list_header>div:nth-of-type(5) {width: 10%;}
.admin_participant_list_header>div:nth-of-type(6) {width: 20%;}
.admin_participant_list_header>div:nth-of-type(7) {width: 20%;}

.admin_participant_list_content {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding: 10px 0;
    font-size: 0.9em;
    text-align: center;
    cursor: pointer;
}
.admin_participant_list_content:hover,
.admin_participant_list_content:active {
    background-color: rgba(223, 224, 226, 1);
}
.admin_participant_list_content>div:nth-of-type(1) {width: 10%;}
.admin_participant_list_content>div:nth-of-type(2) {width: 10%;}
.admin_participant_list_content>div:nth-of-type(3) {width: 20%;}
.admin_participant_list_content>div:nth-of-type(4) {width: 10%;}
.admin_participant_list_content>div:nth-of-type(5) {width: 10%;}
.admin_participant_list_content>div:nth-of-type(6) {width: 20%; text-align: left; font-size: 0.9em;}
.admin_participant_list_content>div:nth-of-type(7) {width: 20%;}
.admin_participant_list_no_data {
    padding: 20px 0;
    text-align: center;
    width: 100%;
    border-top: 1px solid rgba(223, 224, 226, 1);
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    color: rgba(255, 114, 153, 1);
    font-size: 0.9em;
}

.admin_vote_participant_list_header {
    width: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(223, 224, 226, 1);
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding: 10px 0;
    font-size: 0.9em;
    text-align: center;
}
.admin_vote_participant_list_header>div:nth-of-type(1) {width: 10%;}
.admin_vote_participant_list_header>div:nth-of-type(2) {width: 10%;}
.admin_vote_participant_list_header>div:nth-of-type(3) {width: 30%;}
.admin_vote_participant_list_header>div:nth-of-type(4) {width: 20%;}
.admin_vote_participant_list_header>div:nth-of-type(5) {width: 10%;}
.admin_vote_participant_list_header>div:nth-of-type(6) {width: 20%;}

.admin_vote_participant_list_content {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding: 10px 0;
    font-size: 0.9em;
    text-align: center;
    cursor: pointer;
}
.admin_vote_participant_list_content:hover,
.admin_vote_participant_list_content:active {
    background-color: rgba(223, 224, 226, 1);
}
.admin_vote_participant_list_content>div:nth-of-type(1) {width: 10%;}
.admin_vote_participant_list_content>div:nth-of-type(2) {width: 10%;}
.admin_vote_participant_list_content>div:nth-of-type(3) {width: 30%;}
.admin_vote_participant_list_content>div:nth-of-type(4) {width: 20%;}
.admin_vote_participant_list_content>div:nth-of-type(5) {width: 10%;}
.admin_vote_participant_list_content>div:nth-of-type(6) {width: 20%;}

.admin_vote_participant_list_content>div:nth-of-type(3) img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 8px;
}

/* 쿠폰 */
.admin_give_coupon_title {
    font-size: 1.2;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 5px 0;
}
.admin_give_coupon_tap {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 5px 0;
}
.admin_give_coupon_tap>div {
    width: 100%;
    height: 30px;
}
.admin_give_coupon_tap>div button {
    width: 100%;
    border: none;
    background-color: inherit;
}
.admin_give_coupon_tap_active {
    border-bottom: 3px solid rgba(255, 3, 102, 1);
}
.admin_give_coupon_search_add {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    width: 100%;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_give_coupon_search {
    display: flex;
    align-items: center;
}
.admin_give_coupon_search input {
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    width: 200px;
    height: 35px;
    padding-left: 10px;
    font-size: 0.8em;
}
.admin_give_coupon_search button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    font-size: 0.9em;
    width: 100px;
    height: 35px;
    border-radius: 4px;
}
.admin_give_coupon_add button {
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    background-color: inherit;
    width: 80px;
    height: 35px;
    font-size: 0.8em;
    transition: all 0.2s;
}
.admin_give_coupon_add button:hover {
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_give_coupon_content_header {
    display: flex;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 5px 0;
    font-size: 0.9em;
    align-items: center;
    text-align: center;
}
.admin_give_coupon_content_header input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_give_coupon_content_header input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_give_coupon_content_header input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_give_coupon_content_header input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_give_coupon_content_header>div:nth-of-type(1) {width: 5%;}
.admin_give_coupon_content_header>div:nth-of-type(2) {width: 27%;}
.admin_give_coupon_content_header>div:nth-of-type(3) {width: 5%;}
.admin_give_coupon_content_header>div:nth-of-type(4) {width: 10%;}
.admin_give_coupon_content_header>div:nth-of-type(5) {width: 8%;}
.admin_give_coupon_content_header>div:nth-of-type(6) {width: 20%;}
.admin_give_coupon_content_header>div:nth-of-type(7) {width: 10%;}
.admin_give_coupon_content_header>div:nth-of-type(8) {width: 10%;}
.admin_give_coupon_content_header>div:nth-of-type(9) {width: 5%;}

.admin_give_coupon_content_content {
    display: flex;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 10px 0;
    font-size: 0.8em;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s;
}
.admin_give_coupon_content_content:hover {
    background-color: rgba(241, 242, 243, 1);
}
.admin_give_coupon_content_content input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_give_coupon_content_content input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_give_coupon_content_content input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_give_coupon_content_content input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_give_coupon_content_content>div:nth-of-type(1) {width: 5%;}
.admin_give_coupon_content_content>div:nth-of-type(2) {width: 27%;}
.admin_give_coupon_content_content>div:nth-of-type(3) {width: 5%;}
.admin_give_coupon_content_content>div:nth-of-type(4) {width: 10%;}
.admin_give_coupon_content_content>div:nth-of-type(5) {width: 8%;}
.admin_give_coupon_content_content>div:nth-of-type(6) {width: 20%;}
.admin_give_coupon_content_content>div:nth-of-type(7) {width: 10%;}
.admin_give_coupon_content_content>div:nth-of-type(8) {width: 10%;}
.admin_give_coupon_content_content>div:nth-of-type(9) {width: 5%;}
.admin_give_coupon_content_content>div:nth-of-type(2)>div:nth-of-type(1) {
    font-size: 1.1em;
}
.admin_give_coupon_content_content>div:nth-of-type(2)>div:nth-of-type(2) {
    font-size: 0.9em;
    color: rgba(196, 199, 202, 1);
}
.admin_give_coupon_content_content>div:nth-of-type(5) button {
    border: none;
    background-color: inherit;
    text-decoration: underline;
}
.admin_give_coupon_content_content>div:nth-of-type(9) button {
    border: none;
    background-color: inherit;
}
.admin_give_coupon_content_content>div:nth-of-type(9)>div:nth-of-type(1) button {color: rgba(0, 115, 255, 1); transition: all 0.2s;}
.admin_give_coupon_content_content>div:nth-of-type(9)>div:nth-of-type(2) button {color: rgba(255, 114, 153, 1); transition: all 0.2s;}
.admin_give_coupon_content_content>div:nth-of-type(9)>div:nth-of-type(1) button:hover {color: rgba(255, 114, 153, 1);}
.admin_give_coupon_content_content>div:nth-of-type(9)>div:nth-of-type(2) button:hover {color: rgba(169, 173, 178, 1);}

.admin_coupon_modal_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999;
}
.admin_coupon_modal_box {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: white;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90vh;
    border-radius: 8px;
    overflow: hidden;
    padding: 20px;
}
.admin_coupon_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 40px;
}
.admin_coupon_modal_header>div:nth-of-type(1) {
    font-size: 1.1em;
}
.admin_coupon_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.admin_coupon_modal_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.admin_coupon_modal_content {
    width: 100%;
    height: 75vh;
    overflow-y: scroll;
}
.admin_coupon_modal_content_coupon_type input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.admin_coupon_modal_content_coupon_type input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}

.admin_coupon_modal_content_coupon_type input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}

.admin_coupon_modal_content_coupon_type input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}
.admin_coupon_modal_content_coupon_type_user input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_coupon_modal_content_coupon_type_user input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_coupon_modal_content_coupon_type_user input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_coupon_modal_content_coupon_type_user input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_coupon_modal_content_coupon_name {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 0;
    font-size: 0.9em;
}
.admin_coupon_modal_content_coupon_name>div:nth-of-type(1) {width: 20%;}
.admin_coupon_modal_content_coupon_name>div:nth-of-type(2) {width: 80%;}
.admin_coupon_modal_content_coupon_name>div:nth-of-type(2) input {
    width: 50%;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    height: 40px;
    padding-left: 10px;
}
.admin_coupon_modal_content_coupon_name>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_coupon_modal_content_coupon_type {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 0;
    font-size: 0.9em;
}
.admin_coupon_modal_content_coupon_type>div:nth-of-type(1) {width: 20%;}
.admin_coupon_modal_content_coupon_type>div:nth-of-type(2) {
    width: 80%;
    display: flex;
}
.admin_coupon_modal_content_coupon_type_user {
    width: 100%;
    padding: 10px;
}
.admin_coupon_modal_content_coupon_type_user>div:nth-of-type(1) {display: flex; align-items: center; justify-content: space-between; font-size: 0.9em;}
.admin_coupon_modal_content_coupon_type_user>div:nth-of-type(1)>div:nth-of-type(1) {width: 40%;}
.admin_coupon_modal_content_coupon_type_user>div:nth-of-type(1)>div:nth-of-type(2) {width: 40%;}
.admin_coupon_modal_content_coupon_type_user>div:nth-of-type(2) {display: flex; align-items: center; justify-content: space-between; font-size: 0.8em;}
.admin_coupon_modal_content_coupon_type_user>div:nth-of-type(2)>div:nth-of-type(1) {width: 40%;}
.admin_coupon_modal_content_coupon_type_user>div:nth-of-type(2)>div:nth-of-type(2) {width: 40%;}

.admin_coupon_modal_content_coupon_type_code {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 0;
    font-size: 0.9em;
}
.admin_coupon_modal_content_coupon_type_code>div:nth-of-type(1) {width: 20%;}
.admin_coupon_modal_content_coupon_type_code>div:nth-of-type(2) {
    width: 80%;
    display: flex;
}
.admin_coupon_modal_content_coupon_type_code>div:nth-of-type(2) input {
    width: 200px;
    border: none;
    border-radius: 4px;
    height: 40px;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding-left: 10px;
    font-size: 0.9em;
}
.admin_coupon_modal_content_coupon_type_code>div:nth-of-type(2) input:focus {
    outline: none;
    border-bottom: 1px solid rgba(255, 3, 102, 1);
}
.admin_coupon_modal_content_coupon_type_code>div:nth-of-type(2)>div:nth-of-type(2) {
    padding-left: 5px;
}
.admin_coupon_modal_content_coupon_type_code_select {
    width: 150px;
    height: 40px;
}

.admin_coupon_modal_footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_coupon_modal_content_coupon_type_auto {
    width: 100%;
    font-size: 0.9em;
    display: flex;
    align-items: center;
}
.admin_coupon_modal_content_coupon_type_auto>div:nth-of-type(1) {width: 20%;}
.admin_coupon_modal_content_coupon_type_auto>div:nth-of-type(2) {width: 80%;}
.admin_coupon_modal_content_coupon_type_auto_select {width: 200px; height: 40px;}

.admin_coupon_modal_content_coupon_benefit {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 0;
    font-size: 0.9em;
}
.admin_coupon_modal_content_coupon_benefit>div:nth-of-type(1) {width: 20%;}
.admin_coupon_modal_content_coupon_benefit>div:nth-of-type(2) {width: 80%; display: flex; align-items: center;}
.admin_coupon_modal_content_coupon_benefit_select {
    width: 150px;
    height: 40px;
}
.admin_coupon_modal_content_coupon_benefit>div:nth-of-type(2)>div:nth-of-type(2) {
    padding-left: 5px;
    display: flex;
}
.admin_coupon_modal_content_coupon_benefit>div:nth-of-type(2)>div:nth-of-type(2) input {
    width: 100px;
    height: 40px;
    border: none;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    text-align: right;
    padding: 0 10px;
}
.admin_coupon_modal_content_coupon_benefit>div:nth-of-type(2)>div:nth-of-type(2) input:focus {
    outline: none;
    border-bottom: 1px solid rgba(255, 3, 102, 1);
}
.admin_coupon_modal_content_coupon_benefit_amount_select {
    width: 100px;
    height: 40px;
}
.admin_coupon_modal_content_coupon_discount_condition,
.admin_coupon_modal_content_coupon_maximum_discount,
.admin_coupon_modal_content_coupon_date_use,
.admin_coupon_modal_content_coupon_state {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 0;
    font-size: 0.9em;
}
.admin_coupon_modal_content_coupon_discount_condition>div:nth-of-type(1) {width: 20%;}
.admin_coupon_modal_content_coupon_discount_condition>div:nth-of-type(2) {width: 80%;}
.admin_coupon_modal_content_coupon_maximum_discount>div:nth-of-type(1) {width: 20%;}
.admin_coupon_modal_content_coupon_maximum_discount>div:nth-of-type(2) {width: 80%;}
.admin_coupon_modal_content_coupon_date_use>div:nth-of-type(1) {width: 20%;}
.admin_coupon_modal_content_coupon_date_use>div:nth-of-type(2) {width: 80%;}
.admin_coupon_modal_content_coupon_state>div:nth-of-type(1) {width: 20%;}
.admin_coupon_modal_content_coupon_state>div:nth-of-type(2) {width: 80%;}

.admin_coupon_modal_content_coupon_discount_condition>div:nth-child(2)>div {
    width: 30%;
    position: relative;
}
.admin_coupon_modal_content_coupon_discount_condition>div:nth-child(2)>div>div:nth-of-type(1) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 0 30px;
    text-align: right;
}
.admin_coupon_modal_content_coupon_discount_condition>div:nth-child(2)>div>div:nth-of-type(1) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_coupon_modal_content_coupon_discount_condition>div:nth-child(2)>div>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
}
.admin_coupon_modal_content_coupon_maximum_discount>div:nth-child(2)>div {
    width: 30%;
    position: relative;
}
.admin_coupon_modal_content_coupon_maximum_discount>div:nth-child(2)>div>div:nth-of-type(1) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 0 30px;
    text-align: right;
}
.admin_coupon_modal_content_coupon_maximum_discount>div:nth-child(2)>div>div:nth-of-type(1) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_coupon_modal_content_coupon_maximum_discount>div:nth-child(2)>div>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
}

.admin_coupon_modal_content_coupon_date_use input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_coupon_modal_content_coupon_date_use input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_coupon_modal_content_coupon_date_use input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_coupon_modal_content_coupon_date_use input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}

.admin_coupon_modal_content_coupon_date_use>div:nth-of-type(2) {
    display: flex;
    align-items: center;
}
.admin_coupon_modal_content_coupon_date_use>div:nth-of-type(2)>div:nth-of-type(2) {
    padding-left: 10px;
}
.admin_coupon_modal_content_coupon_state_select {
    width: 150px;
    height: 40px;
}
.admin_coupon_modal_footer>div {
    width: 40%;
    margin: 0 5px;
}
.admin_coupon_modal_footer>div button {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    font-size: 0.9em;
}
.admin_coupon_modal_footer>div:nth-of-type(1) button {
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
}
.admin_coupon_modal_footer>div:nth-of-type(2) button {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.admin_coupon_issue_tag {
    color: rgba(255, 114, 153, 1);
    font-size: 0.9em;
}

/* 포인트 */
.admin_give_point_box {
    width: 100%;
}
.show_point_modal_active {
    height: 100vh !important;
    overflow: hidden;
}
.admin_give_point_title {
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.admin_give_point_title>div:nth-of-type(1) {
    font-size: 1.2em;
}
.admin_give_point_title>div:nth-of-type(2) {
    font-size: 0.8em;
}
.admin_give_point_title>div:nth-of-type(2) button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    transition: all 0.2s;
}
.admin_give_point_title>div:nth-of-type(2) button:hover,
.admin_give_point_title>div:nth-of-type(2) button:active {
    background-color: rgba(0, 115, 255, 1);
}
.admin_give_point_give_box {
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    padding: 10px;
    font-size: 0.9em;
    border-radius: 4px;
    margin: 10px;
}
.admin_give_point_give_box>div:nth-of-type(1) {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    cursor: pointer;
}
.admin_give_point_target_box {
    display: flex;
    margin: 10px 50px;
    align-items: center;
}
.admin_give_point_target_box input {
    width: 300px;
    height: 40px;
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid rgba(196, 199, 202, 1);
    padding-left: 10px;
}
.admin_give_point_target_box input:focus {
    outline: none;
    border-bottom: 1px solid rgba(255, 3, 102, 1);
}
.admin_give_point_target_select {
    width: 100px;
    height: 40px;
}
.admin_give_point_target_select :global(.ant-select-selector) {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid rgba(196, 199, 202, 1) !important;
    border-radius: 0;
}
.admin_give_point_pay_box {
    display: flex;
    margin: 10px 68px;
    align-items: center;
    padding: 10px 0;
}
.admin_give_point_pay_select {
    width: 100px;
    height: 40px;
}
.admin_give_point_pay_select :global(.ant-select-selector) {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid rgba(196, 199, 202, 1) !important;
    border-radius: 0;
}
.admin_give_point_pay_box>div:nth-of-type(3) input {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(196, 199, 202, 1);
    padding-left: 10px;
    width: 80px;
    height: 40px;
}
.admin_give_point_pay_box>div:nth-of-type(3) input:focus {
    outline: none;
    border-bottom: 1px solid rgba(255, 3, 102, 1);
}
.admin_give_point_pay_box>div:nth-of-type(4) input {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(196, 199, 202, 1);
    padding-left: 10px;
    width: 200px;
    height: 40px;
}
.admin_give_point_pay_box>div:nth-of-type(4) input:focus {
    outline: none;
    border-bottom: 1px solid rgba(255, 3, 102, 1);
}
.admin_give_point_pay_box>div:nth-of-type(5) button {
    width: 80px;
    height: 40px;
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    border-radius: 4px;
}
.admin_give_point_content {
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    padding: 10px;
    font-size: 0.9em;
    border-radius: 4px;
    margin: 10px;
}
.admin_give_point_content_search {
    display: flex;
    align-items: center;
    justify-content: right;
}
.admin_give_point_content_search input {
    width: 200px;
    height: 35px;
    border: 1px solid rgba(196, 199, 202, 1);
    padding-left: 10px;
    font-size: 0.9em;
    border-radius: 4px;
}
.admin_give_point_content_search input:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.admin_give_point_content_search button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    width: 80px;
    height: 35px;
    border-radius: 4px;
    color: white;
}
.admin_give_point_content_title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.admin_give_point_content_title button {
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding: 5px 10px;
    background-color: inherit;
}
.admin_give_point_content_header {
    padding: 5px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    display: flex;
    width: 100%;
    align-items: center;
    text-align: center;
}
.admin_give_point_content_header>div:nth-of-type(1) {width: 5%;}
.admin_give_point_content_header>div:nth-of-type(2) {width: 10%;}
.admin_give_point_content_header>div:nth-of-type(3) {width: 20%;}
.admin_give_point_content_header>div:nth-of-type(4) {width: 30%;}
.admin_give_point_content_header>div:nth-of-type(5) {width: 5%;}
.admin_give_point_content_header>div:nth-of-type(6) {width: 10%;}
.admin_give_point_content_header>div:nth-of-type(7) {width: 20%;}

.admin_give_point_content_content {
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    display: flex;
    width: 100%;
    align-items: center;
    text-align: center;
    font-size: 0.9em;
}
.admin_give_point_content_content>div:nth-of-type(1) {width: 5%;}
.admin_give_point_content_content>div:nth-of-type(2) {width: 10%;}
.admin_give_point_content_content>div:nth-of-type(3) {width: 20%; padding: 0 5px;}
.admin_give_point_content_content>div:nth-of-type(4) {width: 30%; padding: 0 5px;}
.admin_give_point_content_content>div:nth-of-type(5) {width: 5%;}
.admin_give_point_content_content>div:nth-of-type(6) {width: 10%;}
.admin_give_point_content_content>div:nth-of-type(7) {width: 20%;}
.admin_point_modal_container {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100vh;
    z-index: 999;
}
.admin_point_modal_box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 90vh;
    background-color: white;
    border-radius: 8px;
    transform: translate(-50%, -50%);
    overflow: hidden;
}
.admin_point_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    position: relative;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
}
.admin_point_modal_header>div:nth-of-type(1) {
    font-size: 1.1em;
}
.admin_point_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
}
.admin_point_modal_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.admin_point_modal_select_box {
    padding: 20px;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    width: 100%;
}
.admin_point_modal_select_box>div:nth-of-type(1),
.admin_point_modal_select_box>div:nth-of-type(2),
.admin_point_modal_select_box>div:nth-of-type(3) {
    display: flex; 
    font-size: 0.9em; 
    align-items: center;
    padding: 5px 0;
}
.admin_point_modal_select_box>div:nth-of-type(1)>div:nth-of-type(1),
.admin_point_modal_select_box>div:nth-of-type(2)>div:nth-of-type(1),
.admin_point_modal_select_box>div:nth-of-type(3)>div:nth-of-type(1) {
    width: 30%;
}
.admin_point_modal_select_box>div:nth-of-type(1)>div:nth-of-type(2),
.admin_point_modal_select_box>div:nth-of-type(2)>div:nth-of-type(2),
.admin_point_modal_select_box>div:nth-of-type(3)>div:nth-of-type(2) {
    width: 70%;
}
.admin_point_modal_select {
    width: 20%;
    height: 40px;
}
.admin_point_modal_point_input_box>div:nth-of-type(2)>div {
    position: relative;
    width: 40%;
}
.admin_point_modal_point_input_box>div:nth-of-type(2)>div>div:nth-of-type(1) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 25px;
    text-align: right;
}
.admin_point_modal_point_input_box>div:nth-of-type(2)>div>div:nth-of-type(1) input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.admin_point_modal_point_input_box>div:nth-of-type(2)>div>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    color: rgba(255, 3, 102, 1);
}
.admin_point_modal_point_textarea_box>div:nth-of-type(2)>div {
    width: 50%;
}
.admin_point_modal_point_textarea_box>div:nth-of-type(2)>div textarea {
    width: 100%;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    height: 100px;
    font-size: 0.8em;
    padding: 10px;
    resize: none;
}
.admin_point_modal_point_textarea_box>div:nth-of-type(2)>div textarea:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_point_modal_content {
    width: 100%;
    padding: 20px;
    height: 45vh;
    overflow-y: scroll;
}
.admin_point_modal_content>div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9em;
}
.admin_point_modal_content>div:nth-of-type(1)>div:nth-of-type(1) {width: 40%; text-align: center; padding-bottom: 5px;}
.admin_point_modal_content>div:nth-of-type(1)>div:nth-of-type(2) {width: 40%; text-align: center; padding-bottom: 5px;}
.admin_point_modal_content input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_point_modal_content input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_point_modal_content input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_point_modal_content input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}

.admin_point_modal_content_total_user_list,
.admin_point_modal_content_select_user_list {
    width: 40%;
    height: 45vh;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    padding: 20px;
    overflow-y: scroll;
    font-size: 0.9em;
}
.admin_point_modal_content_total_user_list::-webkit-scrollbar,
.admin_point_modal_content_select_user_list::-webkit-scrollbar {
    width: 5px;  /* 스크롤바의 너비 */
    height: 5px;
}
.admin_point_modal_content_total_user_list::-webkit-scrollbar-thumb,
.admin_point_modal_content_select_user_list::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.admin_point_modal_content_total_user_list::-webkit-scrollbar-track,
.admin_point_modal_content_select_user_list::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.admin_point_modal_content_total_user_list>div,
.admin_point_modal_content_select_user_list>div {
    display: flex;
    align-items: center;
    padding: 5px;
}
.admin_point_modal_content_total_user_list>div>div:nth-of-type(2),
.admin_point_modal_content_select_user_list>div>div:nth-of-type(2) {
    display: flex;
    align-items: center;
}

.admin_point_modal_footer {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_point_modal_footer>div {
    width: 30%;
    margin: 0 5px;
}
.admin_point_modal_footer>div:nth-of-type(1) button {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    border-radius: 4px;
    font-size: 0.9em;
}
.admin_point_modal_footer>div:nth-of-type(2) button {
    width: 100%;
    height: 40px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 4px;
    font-size: 0.9em;
}
.admin_point_modal_point_search_box {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0;
    font-size: 0.9em;
}
.admin_point_modal_point_search_box>div:nth-of-type(1) {width: 30%;}
.admin_point_modal_point_search_box>div:nth-of-type(2) {width: 40%;}
.admin_point_modal_point_search_box>div:nth-of-type(3) {width: 30%; font-size: 0.9em; color: rgba(169, 173, 178, 1);}
.admin_point_modal_point_search_box>div:nth-of-type(2) input {
    width: 90%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding-left: 10px;
}
.admin_point_modal_point_search_box>div:nth-of-type(2) input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}

/* 상점 관리 */
.admin_store_setting_back_img {
    width: 350px;
    height: auto;
    aspect-ratio: 7 / 3;
    border-radius: 8px;
    border: 1px solid rgba(223, 224, 226, 1);
    overflow: hidden;
}

.admin_store_setting_back_img>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.admin_store_setting_active_btn_box {
    width: 100%;
    display: flex;
    text-align: center;
    padding: 10px 0;
}
.admin_store_setting_active_btn_box>div:nth-of-type(1),.admin_store_setting_active_btn_box>div:nth-of-type(2) {
    width: 100%;
}
.admin_store_setting_active_btn_box>div:nth-of-type(1) button,.admin_store_setting_active_btn_box>div:nth-of-type(2) button {
    width: 100%;
    height: 40px;
    background-color: inherit;
    border: none;
}
.admin_store_setting_active {
    border-bottom: 3px solid rgba(255, 3, 102, 1);
}

/* 정산 공통 */
.admin_calculate_header {
    font-size: 1.2em;
    padding-bottom: 5px;
    border-bottom: 2px solid rgba(196, 199, 202, 1);
}
.admin_calculate_title {
    padding-top: 20px;
    border-bottom: 1px solid rgba(255, 224, 232, 1);
}
/* 수익금내역 */
.admin_calculate_proceed_total_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.admin_calculate_proceed_total_box>div {
    width: 30%;
    border-radius: 12px;
    margin: 20px 0;
    padding: 15px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    overflow: hidden;
}
.admin_calculate_proceed_total_box>div>div:nth-of-type(1) {
    font-size: 0.9em;
}
.admin_calculate_proceed_total_box>div>div:nth-of-type(2) {
    display: flex;
    align-items: center;
    padding: 5px 0;
    width: 100%;
}
.admin_calculate_proceed_total_box>div>div:nth-of-type(2)>div:nth-of-type(1) {
    font-size: 1.5em;
    font-weight: bold;
}
.admin_calculate_proceed_total_box>div>div:nth-of-type(2)>div:nth-of-type(2) {
    font-size: 0.9em;
    color: rgba(169, 173, 178, 1);
    padding-left: 10px;
}
.admin_calculate_proceed_total_box>div>div:nth-of-type(3) {
    font-size: 0.8em;
    display: flex;
}
.admin_calculate_proceed_total_box>div>div:nth-of-type(3)>div:nth-of-type(1) {
    color: rgba(169, 173, 178, 1);
}
.admin_calculate_proceed_detail_box {
    padding-top: 20px;
}
.admin_calculate_proceed_detail_title {
    border-bottom: 1px solid rgba(255, 224, 232, 1);
}
.admin_calculate_proceed_detail_search_btn_box {
    width: 100%;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.admin_calculate_proceed_detail_search_btn_box>div:nth-of-type(1) {
    width: 70%;
    display: flex;
    align-items: center;
}
.admin_calculate_proceed_detail_payment_date {
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
.admin_calculate_proceed_detail_payment_date>div:nth-of-type(2) {
    display: flex;
    align-items: center;
    padding-left: 5px;
}
.admin_calculate_proceed_detail_payment_date>div:nth-of-type(2) input {
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    font-size: 0.9em;
    padding: 5px 10px;
}
.admin_calculate_proceed_detail_payment_date>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.admin_calculate_proceed_detail_search {
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 0.9em;
}
.admin_calculate_proceed_detail_search>div:nth-of-type(2) {
    width: 200px;
    overflow: hidden;
    position: relative;
    padding-left: 5px;
}
.admin_calculate_proceed_detail_search>div:nth-of-type(2) input {
    width: 100%;
    height: 30px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding: 0 30px 0 10px;
}
.admin_calculate_proceed_detail_search>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.admin_calculate_proceed_detail_search>div:nth-of-type(2)>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translate(0, -50%);
}
.admin_calculate_proceed_detail_search>div:nth-of-type(2)>div:nth-of-type(2) span {
    color: rgba(196, 199, 202, 1);
}
.admin_calculate_proceed_detail_search_btn_box>div:nth-of-type(2) {
    width: 30%;
}
.admin_calculate_proceed_detail_btn {
    display: flex;
    align-items: center;
    justify-content: end;
}
.admin_calculate_proceed_detail_btn>div {
    margin: 0 5px;
}
.admin_calculate_proceed_detail_btn button {
    border: 1px solid rgba(196, 199, 202, 1);
    padding: 5px 10px;
    background-color: inherit;
    font-size: 0.85em;
    border-radius: 4px;
    transition: all 0.2s;
}
.admin_calculate_proceed_detail_btn button:hover,
.admin_calculate_proceed_detail_btn button:active {
    background-color: rgba(255, 114, 153, 0.7);
    color: white;
    border: none;
}
.admin_calculate_proceed_detail_container {
    width: 100%;
    overflow-x: scroll;
}
.admin_calculate_proceed_detail_container>div {
    min-width: 1200px;
}
.admin_calculate_proceed_detail_container::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 5px;
}
.admin_calculate_proceed_detail_container::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}
.admin_calculate_proceed_detail_container::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.admin_calculate_proceed_detail_header {
    width: 100%;
    border: 1px solid rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    font-size: 0.9em;
    height: 40px;
    background-color: rgba(241, 242, 243, 1);
    text-align: center;
}
.admin_calculate_proceed_detail_header>div:nth-of-type(1) {width: 8%;}
.admin_calculate_proceed_detail_header>div:nth-of-type(2) {width: 10%;}
.admin_calculate_proceed_detail_header>div:nth-of-type(3) {width: 18%;}
.admin_calculate_proceed_detail_header>div:nth-of-type(4) {width: 8%;}
.admin_calculate_proceed_detail_header>div:nth-of-type(5) {width: 3%;}
.admin_calculate_proceed_detail_header>div:nth-of-type(6) {width: 8%;}
.admin_calculate_proceed_detail_header>div:nth-of-type(7) {width: 8%;}
.admin_calculate_proceed_detail_header>div:nth-of-type(8) {width: 7%;}
.admin_calculate_proceed_detail_header>div:nth-of-type(9) {width: 10%;}
.admin_calculate_proceed_detail_header>div:nth-of-type(9)>div:nth-of-type(2) {
    font-size: 0.8em;
    color: rgba(169, 173, 178, 1);
}
.admin_calculate_proceed_detail_header>div:nth-of-type(10) {width: 5%;}
.admin_calculate_proceed_detail_header>div:nth-of-type(11) {width: 5%;}
.admin_calculate_proceed_detail_header>div:nth-of-type(12) {width: 10%;}
.admin_consignment_delivery_price {
    text-decoration: line-through !important;
    color: rgba(196, 199, 202, 1) !important;
}
.admin_calculate_proceed_detail_content {
    width: 100%;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    font-size: 0.8em;
    text-align: center;
    height: 40px;
}
.admin_calculate_proceed_detail_content>div:nth-of-type(1) {width: 8%;}
.admin_calculate_proceed_detail_content>div:nth-of-type(2) {
    width: 10%;
    /* font-weight: bold; */
    /* font-size: 1.4em; */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 두 줄로 제한 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* 원래는 nowrap을 사용하지만, 여러 줄 처리를 위해 normal로 설정 */
    line-height: 1.2em; /* 줄 높이 설정 */
    max-height: 2.4em; /* 줄 높이와 줄 수에 따라 최대 높이 설정 (예: 줄 높이 1.2em * 줄 수 2 = 2.4em) */
}
.admin_calculate_proceed_detail_content>div:nth-of-type(3) {width: 18%;}
.admin_calculate_proceed_detail_content>div:nth-of-type(4) {width: 8%;}
.admin_calculate_proceed_detail_content>div:nth-of-type(5) {width: 3%;}
.admin_calculate_proceed_detail_content>div:nth-of-type(6) {width: 8%;}
.admin_calculate_proceed_detail_content>div:nth-of-type(7) {width: 8%;}
.admin_calculate_proceed_detail_content>div:nth-of-type(8) {width: 7%;}
.admin_calculate_proceed_detail_content>div:nth-of-type(9) {width: 10%;}
.admin_calculate_proceed_detail_content>div:nth-of-type(10) {width: 5%;}
.admin_calculate_proceed_detail_content>div:nth-of-type(11) {width: 5%;}
.admin_calculate_proceed_detail_content>div:nth-of-type(12) {width: 10%;}


.admin_calculate_proceed_detail_header>div:nth-of-type(12),
.admin_calculate_proceed_detail_content>div:nth-of-type(12) {
    position: sticky;
    right: 0;
    z-index: 2;
    background-color: rgba(255, 224, 232, 1);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-bottom: 1px solid rgba(196, 199, 202, 1);
}
.admin_calculate_proceed_detail_content>div:nth-of-type(11) {
    z-index: 1;
}
.admin_calculate_proceed_detail_no_data {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    color: rgba(169, 173, 178, 1);
}

/* 정산내역 */
/* .admin_calculate_settle_detail_container {
    width: 100%;
    overflow-x: scroll;
}
.admin_calculate_settle_detail_container>div {
    min-width: 1200px;
}
.admin_calculate_settle_detail_container::-webkit-scrollbar {
    width: 10px;  
    height: 5px;
}
.admin_calculate_settle_detail_container::-webkit-scrollbar-thumb {
    height: 30%; 
    background: rgba(255, 114, 153, 1); 
    border-radius: 2px;
}
.admin_calculate_settle_detail_container::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1); 
} */
 .admin_calculate_settle_detail_state_tab {
    width: 100%;
    display: flex;
    align-items: center;
 }
 .admin_calculate_settle_detail_state_tab>div {
    width: 100%;
    height: 30px;
    margin: 5px;
 }
 .admin_calculate_settle_detail_state_tab>div button {
    width: 100%;
    border: none;
    background-color: inherit;
 }
.admin_calculate_settle_detail_tab_btn {
    padding: 10px 0;
    border-top: 1px solid rgba(223, 224, 226, 1);
}
.admin_calculate_settle_detail_tab_btn button {
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 5px 10px;
    background-color: inherit;
    font-size: 0.9em;
    margin-right: 5px;
    transition: all 0.2s;
}
.admin_calculate_settle_detail_tab_btn_active {
    border-bottom: 2px solid rgba(255, 3, 102, 1) !important;
    color: rgba(255, 3, 102, 1) !important;
}
.admin_calculate_settle_detail_tab_btn button:hover,
.admin_calculate_settle_detail_tab_btn button:active {
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_calculate_settle_detail_header {
    width: 100%;
    border: 1px solid rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    font-size: 0.9em;
    height: 40px;
    background-color: rgba(241, 242, 243, 1);
    text-align: center;
}
.admin_calculate_settle_detail_header>div:nth-of-type(1) {width: 5%;}
.admin_calculate_settle_detail_header>div:nth-of-type(2) {width: 25%;}
.admin_calculate_settle_detail_header>div:nth-of-type(3) {width: 10%;}
.admin_calculate_settle_detail_header>div:nth-of-type(4) {width: 10%;}
.admin_calculate_settle_detail_header>div:nth-of-type(5) {width: 10%;}
.admin_calculate_settle_detail_header>div:nth-of-type(6) {width: 15%;}
.admin_calculate_settle_detail_header>div:nth-of-type(7) {width: 15%;}
.admin_calculate_settle_detail_header>div:nth-of-type(8) {width: 10%;}

.admin_calculate_settle_detail_header input[type="checkbox"],
.admin_calculate_settle_detail_content input[type="checkbox"]{
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
    
.admin_calculate_settle_detail_header input[type="checkbox"]:after,
.admin_calculate_settle_detail_content input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_calculate_settle_detail_header input[type="checkbox"]:checked,
.admin_calculate_settle_detail_content input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_calculate_settle_detail_header input[type="checkbox"]:checked:after,
.admin_calculate_settle_detail_content input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_calculate_settle_detail_content {
    width: 100%;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    font-size: 0.8em;
    text-align: center;
    height: 40px;
}
.admin_calculate_settle_detail_content>div:nth-of-type(1) {width: 5%;}
.admin_calculate_settle_detail_content>div:nth-of-type(2) {width: 25%;}
.admin_calculate_settle_detail_content>div:nth-of-type(3) {width: 10%;}
.admin_calculate_settle_detail_content>div:nth-of-type(4) {width: 10%;}
.admin_calculate_settle_detail_content>div:nth-of-type(5) {width: 10%;}
.admin_calculate_settle_detail_content>div:nth-of-type(6) {width: 15%;}
.admin_calculate_settle_detail_content>div:nth-of-type(7) {width: 15%;}
.admin_calculate_settle_detail_content>div:nth-of-type(8) {width: 10%;}
.admin_calculate_settle_withdrawal_btn {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    padding: 5px 10px;
    color: white;
    border-radius: 4px;
}
.admin_calculate_settle_withdrawal_btn:hover,
.admin_calculate_settle_withdrawal_btn:active {
    opacity: 0.8;
}
.admin_calculate_settle_detail_content>div:nth-of-type(8) button {
    border: none;
    background-color: inherit;
    transition: all 0.2s;
}
.admin_calculate_settle_detail_content>div:nth-of-type(8) button:hover,
.admin_calculate_settle_detail_content>div:nth-of-type(8) button:active {
    color: rgba(0, 115, 255, 1);
    text-decoration: underline;
}
.admin_calculate_settle_detail_admin_header {
    width: 100%;
    border: 1px solid rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    font-size: 0.9em;
    height: 40px;
    background-color: rgba(241, 242, 243, 1);
    text-align: center;
}
.admin_calculate_settle_detail_admin_header>div:nth-of-type(1) {width: 5%;}
.admin_calculate_settle_detail_admin_header>div:nth-of-type(2) {width: 10%;}
.admin_calculate_settle_detail_admin_header>div:nth-of-type(3) {width: 23%;}
.admin_calculate_settle_detail_admin_header>div:nth-of-type(4) {width: 10%;}
.admin_calculate_settle_detail_admin_header>div:nth-of-type(5) {width: 8%;}
.admin_calculate_settle_detail_admin_header>div:nth-of-type(6) {width: 10%;}
.admin_calculate_settle_detail_admin_header>div:nth-of-type(7) {width: 14%;}
.admin_calculate_settle_detail_admin_header>div:nth-of-type(8) {width: 14%;}
.admin_calculate_settle_detail_admin_header>div:nth-of-type(9) {width: 6%;}

.admin_calculate_settle_detail_admin_header input[type="checkbox"],
.admin_calculate_settle_detail_admin_content input[type="checkbox"]{
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
    
.admin_calculate_settle_detail_admin_header input[type="checkbox"]:after,
.admin_calculate_settle_detail_admin_content input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_calculate_settle_detail_admin_header input[type="checkbox"]:checked,
.admin_calculate_settle_detail_admin_content input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_calculate_settle_detail_admin_header input[type="checkbox"]:checked:after,
.admin_calculate_settle_detail_admin_content input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.admin_calculate_settle_detail_admin_content {
    width: 100%;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    font-size: 0.8em;
    text-align: center;
    height: 40px;
}
.admin_calculate_settle_detail_admin_content>div:nth-of-type(1) {width: 5%;}
.admin_calculate_settle_detail_admin_content>div:nth-of-type(2) {width: 10%;}
.admin_calculate_settle_detail_admin_content>div:nth-of-type(3) {width: 23%;}
.admin_calculate_settle_detail_admin_content>div:nth-of-type(4) {width: 10%;}
.admin_calculate_settle_detail_admin_content>div:nth-of-type(5) {width: 8%;}
.admin_calculate_settle_detail_admin_content>div:nth-of-type(6) {width: 10%;}
.admin_calculate_settle_detail_admin_content>div:nth-of-type(7) {width: 14%;}
.admin_calculate_settle_detail_admin_content>div:nth-of-type(8) {width: 14%;}
.admin_calculate_settle_detail_admin_content>div:nth-of-type(9) {width: 6%;}
.admin_calculate_settle_withdrawal_btn {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    padding: 5px 10px;
    color: white;
    border-radius: 4px;
}
.admin_calculate_settle_withdrawal_btn:hover,
.admin_calculate_settle_withdrawal_btn:active {
    opacity: 0.8;
}
.admin_calculate_settle_detail_content>div:nth-of-type(8) button {
    border: none;
    background-color: inherit;
    transition: all 0.2s;
}
.admin_calculate_settle_detail_content>div:nth-of-type(8) button:hover,
.admin_calculate_settle_detail_content>div:nth-of-type(8) button:active {
    color: rgba(0, 115, 255, 1);
    text-decoration: underline;
}
.admin_calculate_settle_detail_admin_content>div:nth-of-type(9) button {
    border: none;
    background-color: inherit;
    transition: all 0.2s;
}
.admin_calculate_settle_detail_admin_content>div:nth-of-type(9) button:hover,
.admin_calculate_settle_detail_admin_content>div:nth-of-type(9) button:active {
    color: rgba(0, 115, 255, 1);
    text-decoration: underline;
}
.admin_calculate_settle_withdrawal_state_btn {
    padding: 5px 10px;
    background-color: rgba(223, 224, 226, 1) !important;
    border: none;
    border-radius: 4px;
    color: rgba(169, 173, 178, 1) !important;
}
.admin_test {
    position: absolute;
    width: 500px;
    height: 500px;
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: white;
}

.admin_fee_setting_usercheck_box {
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    border-radius: 8px;
    padding: 20px;
    margin: 10px 0;
}
.admin_fee_setting_usercheck_box input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.admin_fee_setting_usercheck_box input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}

.admin_fee_setting_usercheck_box input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}

.admin_fee_setting_usercheck_box input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}
.admin_fee_setting_usercheck {
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
.admin_fee_setting_usercheck>div:nth-of-type(1) {
    display: flex;
    align-items: center;
}
.admin_fee_setting_usercheck>div:nth-of-type(1)>div:nth-of-type(2) {
    padding-left: 5px;
}
.admin_fee_setting_usercheck>div:nth-of-type(2) {
    display: flex;
    align-items: center;
    margin-left: 20px;
}
.admin_fee_setting_usercheck>div:nth-of-type(2)>div:nth-of-type(2) {
    padding-left: 5px;
}
.admin_fee_setting_usercheck_all {
    margin-top: 10px;
    position: relative;
}
.admin_fee_setting_usercheck_all_title {
    font-size: 0.8em;
    color: rgba(255, 3, 102, 1);
}
.admin_fee_setting_usercheck_all_content {
    width: 100%;
    font-size: 0.9em;
}
.admin_fee_setting_usercheck_all_content>div {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 5px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_fee_setting_usercheck_all_content>div>div:nth-of-type(1) {width: 20%;}
.admin_fee_setting_usercheck_all_content>div>div:nth-of-type(2) {width: 80%;}
.admin_fee_setting_usercheck_all_content>div>div:nth-of-type(2)>div {
    width: 150px;
    height: 40px;
    position: relative;
}
.admin_fee_setting_usercheck_all_content>div>div:nth-of-type(2)>div input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 0 25px;
    text-align: right;
}
.admin_fee_setting_usercheck_all_content>div>div:nth-of-type(2)>div input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.admin_fee_setting_usercheck_all_content>div>div:nth-of-type(2)>div>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.admin_fee_setting_usercheck_all_update_btn {
    position: absolute;
    top: -30px;
    right: 10px;
}
.admin_fee_setting_usercheck_all_update_btn button {
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    font-size: 0.9em;
    padding: 5px 10px;
    border-radius: 4px;
    transition: all 0.2s;
}
.admin_fee_setting_usercheck_all_update_btn button:hover,
.admin_fee_setting_usercheck_all_update_btn button:active {
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_calculate_fee_setting_update_btn {
    display: flex;
    justify-content: end;
    align-items: center;
}
.admin_calculate_fee_setting_update_btn button {
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 5px 10px;
    background-color: inherit;
    font-size: 0.9em;
    transition: all 0.2s;
}
.admin_calculate_fee_setting_update_btn button:hover,
.admin_calculate_fee_setting_update_btn button:active {
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_fee_setting_user_list_header {
    width: 100%;
    border: 1px solid rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    font-size: 0.9em;
    height: 40px;
    background-color: rgba(241, 242, 243, 1);
    text-align: center;
}
.admin_fee_setting_user_list_header>div:nth-of-type(1) {width: 5%;}
.admin_fee_setting_user_list_header>div:nth-of-type(2) {width: 10%;}
.admin_fee_setting_user_list_header>div:nth-of-type(3) {width: 10%;}
.admin_fee_setting_user_list_header>div:nth-of-type(4) {width: 13%;}
.admin_fee_setting_user_list_header>div:nth-of-type(5) {width: 13%;}
.admin_fee_setting_user_list_header>div:nth-of-type(6) {width: 13%;}
.admin_fee_setting_user_list_header>div:nth-of-type(7) {width: 13%;}
.admin_fee_setting_user_list_header>div:nth-of-type(8) {width: 23%;}

.admin_fee_setting_user_list_content {
    width: 100%;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    font-size: 0.8em;
    height: 40px;
    text-align: center;
}
.admin_fee_setting_user_list_content>div:nth-of-type(1) {width: 5%;}
.admin_fee_setting_user_list_content>div:nth-of-type(2) {width: 10%;}
.admin_fee_setting_user_list_content>div:nth-of-type(3) {width: 10%;}
.admin_fee_setting_user_list_content>div:nth-of-type(4) {width: 13%;}
.admin_fee_setting_user_list_content>div:nth-of-type(5) {width: 13%; position: relative; padding: 0 10px;}
.admin_fee_setting_user_list_content>div:nth-of-type(6) {width: 13%; position: relative; padding: 0 10px;}
.admin_fee_setting_user_list_content>div:nth-of-type(7) {width: 13%; position: relative; padding: 0 10px;}
.admin_fee_setting_user_list_content>div:nth-of-type(8) {width: 23%;}
.admin_fee_setting_user_list_content>div:nth-of-type(5)>div:nth-of-type(2),
.admin_fee_setting_user_list_content>div:nth-of-type(6)>div:nth-of-type(2),
.admin_fee_setting_user_list_content>div:nth-of-type(7)>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
}
.admin_fee_setting_user_list_content>div:nth-of-type(5) input,
.admin_fee_setting_user_list_content>div:nth-of-type(6) input,
.admin_fee_setting_user_list_content>div:nth-of-type(7) input {
    width: 100%;
    height: 30px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 0 25px 0 10px;
    text-align: right;
}
.admin_fee_setting_user_list_content>div:nth-of-type(5) input:focus,
.admin_fee_setting_user_list_content>div:nth-of-type(6) input:focus,
.admin_fee_setting_user_list_content>div:nth-of-type(7) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_fee_setting_user_list_header input[type="checkbox"],
.admin_fee_setting_user_list_content input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.admin_fee_setting_user_list_header input[type="checkbox"]:after,
.admin_fee_setting_user_list_content input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.admin_fee_setting_user_list_header input[type="checkbox"]:checked,
.admin_fee_setting_user_list_content input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.admin_fee_setting_user_list_header input[type="checkbox"]:checked:after,
.admin_fee_setting_user_list_content input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}

/* 포인트 확률 */
.admin_point_rate_apply_title {
    font-size: 1.1em;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_point_rate_apply_box {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    border-radius: 8px;
    overflow: hidden;
    margin: 10px 0;
    padding: 20px;
    position: relative;
}
.admin_point_rate_apply_min_point,
.admin_point_rate_apply_max_point,
.admin_point_rate_apply_division {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 5px;
    font-size: 0.9em;
}
.admin_point_rate_apply_min_point>div:nth-of-type(1),
.admin_point_rate_apply_max_point>div:nth-of-type(1),
.admin_point_rate_apply_division>div:nth-of-type(1) {
    width: 10%;
}
.admin_point_rate_apply_min_point>div:nth-of-type(2),
.admin_point_rate_apply_max_point>div:nth-of-type(2) {
    width: 90%;
}
.admin_point_rate_apply_min_point>div:nth-of-type(2)>div,
.admin_point_rate_apply_max_point>div:nth-of-type(2)>div {
    width: 100px;
    position: relative;
}
.admin_point_rate_apply_min_point>div:nth-of-type(2) input,
.admin_point_rate_apply_max_point>div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 25px;
    text-align: right;
}
.admin_point_rate_apply_min_point>div:nth-of-type(2) input:focus,
.admin_point_rate_apply_max_point>div:nth-of-type(2) input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.admin_point_rate_apply_min_point>div:nth-of-type(2) span,
.admin_point_rate_apply_max_point>div:nth-of-type(2) span {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    font-size: 0.9em;
}
.admin_point_rate_apply_division>div:nth-of-type(2) {width: 30%;}
.admin_point_rate_apply_division>div:nth-of-type(2)>div {
    width: 100px;
    position: relative;
}
.admin_point_rate_apply_division>div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 25px;
    text-align: right;
}
.admin_point_rate_apply_division>div:nth-of-type(2) input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.admin_point_rate_apply_division>div:nth-of-type(2) span {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    font-size: 0.9em;
}
.admin_point_rate_apply_division>div:nth-of-type(3) {width: 60%; color: rgba(255, 3, 102, 1);}
.admin_point_rate_apply_btn {
    position: absolute;
    top: 10px;
    right: 20px;
}
.admin_point_rate_apply_btn button {
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    border-radius: 4px;
    transition: all 0.2s;
    font-size: 0.9em;
    padding: 5px 10px;
}
.admin_point_rate_apply_btn button:hover,
.admin_point_rate_apply_btn button:active {
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.admin_point_rate_apply_content_update {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
}
.admin_point_rate_apply_content_update button {
    padding: 5px 10px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    font-size: 0.9em;
    background-color: inherit;
    transition: all 0.2s;
}
.admin_point_rate_apply_content_update button:hover,
.admin_point_rate_apply_content_update button:active {
    background-color: rgba(255, 114, 153, 1) !important;
    color: white !important;
}
.admin_point_rate_apply_container {
    padding-top: 10px;
}
.admin_point_rate_apply_header {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    border-top: 1px solid rgba(223, 224, 226, 1);
    font-size: 0.9em;
}
.admin_point_rate_apply_header>div:nth-of-type(1) {width: 15%;}
.admin_point_rate_apply_header>div:nth-of-type(2) {width: 15%;}
.admin_point_rate_apply_header>div:nth-of-type(3) {width: 55%;}
.admin_point_rate_apply_header>div:nth-of-type(4) {width: 15%;}

.admin_point_rate_apply_content {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    font-size: 0.8em;
}
.admin_point_rate_apply_content>div:nth-of-type(1) {width: 15%;}
.admin_point_rate_apply_content>div:nth-of-type(2) {width: 15%;}
.admin_point_rate_apply_content>div:nth-of-type(3) {
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.admin_point_rate_apply_content>div:nth-of-type(4) {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin_point_rate_apply_content>div:nth-of-type(3)>div:nth-of-type(1),
.admin_point_rate_apply_content>div:nth-of-type(3)>div:nth-of-type(3) {
    width: 150px;
    height: 30px;
    position: relative;
}
.admin_point_rate_apply_content>div:nth-of-type(3)>div:nth-of-type(1) span,
.admin_point_rate_apply_content>div:nth-of-type(3)>div:nth-of-type(3) span {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    font-size: 0.9em;
}
.admin_point_rate_apply_content>div:nth-of-type(3) input {
    width: 100%;
    height: 30px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 0 25px 0 10px;
    text-align: right;
}
.admin_point_rate_apply_content>div:nth-of-type(3) input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.admin_point_rate_apply_content>div:nth-of-type(4)>div {
    width: 100px;
    position: relative;
}
.admin_point_rate_apply_content>div:nth-of-type(4) input {
    width: 100%;
    height: 30px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 25px;
    text-align: right;
}
.admin_point_rate_apply_content>div:nth-of-type(4) input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.admin_point_rate_apply_content>div:nth-of-type(4) span {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    font-size: 0.9em;
}

/* 카테고리 */
.admin_category_title {
    font-size: 1.1em;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.admin_category_tab {
    width: 100%;
    display: flex;
    align-items: center;
}
.admin_category_tab>div {
    width: 100%;
    height: 40px;
    padding: 10px 0;
}
.admin_category_tab>div button {
    width: 100%;
    height: auto;
    border: none;
    background-color: inherit;
}
.admin_category_active {
    border-bottom: 2px solid rgba(255, 3, 102, 1);
}
.admin_category_active_txt {
    color: rgba(255, 3, 102, 1);
    font-weight: 500;
}
.admin_category_store_box {}
.admin_category_store_add_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px 0;
}
.admin_category_store_add_btn button {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.9em;
}
.admin_category_store_item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding: 10px 0;
    cursor: pointer;
}
.admin_category_store_item_info {
    width: 80%;
    display: flex;
    align-items: center;
}
.admin_category_store_item_info>div:nth-of-type(1) img {
    width: 80px;
    height: 80px;
    object-fit: cover;   
}
.admin_category_store_item_info>div:nth-of-type(2) {
    width: calc(100% - 160px);
    padding: 0 10px;
}
.admin_category_store_item_info>div:nth-of-type(2)>div:nth-of-type(1) {
    font-size: 0.9em;
    color: rgba(169, 173, 178, 1);
}
.admin_category_store_item_info>div:nth-of-type(2)>div:nth-of-type(2) {
    font-weight: 500;
}
.admin_category_store_item_info>div:nth-of-type(3) {
    width: 80px;
}
.admin_category_store_item_info>div:nth-of-type(3)>div {
    width: 100%;
    text-align: center;
}
.admin_category_store_item_info_btn {
    width: 20%;
    display: flex;
    align-items: end;
    justify-content: end;
    flex-direction: column;
}
.admin_category_store_item_info_btn button:nth-of-type(1) {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.8em;
    padding: 3px 8px;
    border-radius: 4px;
    margin-bottom: 5px;
}
.admin_category_store_item_info_btn button:nth-of-type(2) {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    font-size: 0.8em;
    padding: 3px 8px;
    border-radius: 4px;
}
.admin_category_store_sub_item {
    width: 100%;
    padding: 10px 0 10px 30px;
}
.admin_category_store_sub_item_add_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
}
.admin_category_store_sub_item_add_btn button {
    border: none;
    background-color: rgba(0, 115, 255, 1);
    color: white;
    padding: 2px 6px;
    font-size: 0.8em;
    border-radius: 4px;
}
.admin_category_add_modal {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_category_add_modal_box {
    width: 70%;
    height: 50%;
    border-radius: 8px;
    background-color: white;
    padding: 10px;
}
.admin_category_add_modal_box_header {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: end;
}
.admin_category_add_modal_box_header button {
    border: none;
    background-color: inherit;
}
.admin_category_add_modal_box_info {
    width: 100%;
    height: calc(100% - 80px);
    overflow-y: auto;
}
.admin_category_add_modal_footer {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_category_add_modal_footer>div {
    width: 40%;
    height: 40px;
    padding: 0 5px;
}
.admin_category_add_modal_footer>div:nth-of-type(1) button {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    border-radius: 4px;
    font-size: 0.9em;
}
.admin_category_add_modal_footer>div:nth-of-type(2) button {
    width: 100%;
    height: 40px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 4px;
    font-size: 0.9em;
}
.admin_category_add_modal_box_info_img {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(223, 224, 226, 0.7);
    padding: 10px 0;
}
.admin_category_add_modal_box_info_img>div:nth-of-type(1) {
    width: 20%;
    font-size: 0.9em;
}
.admin_category_add_modal_box_info_img>div:nth-of-type(2) {
    width: 80%;
}
.admin_category_add_modal_box_info_img>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 100px;
    height: 100px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid rgba(223, 224, 226, 0.7);
    padding: 5px;
}
.admin_category_add_modal_box_info_img>div:nth-of-type(2)>div:nth-of-type(1) img {
    width: 100%;
    height: auto;
    object-fit: cover;
}
.admin_category_add_modal_box_info_img>div:nth-of-type(2)>div:nth-of-type(2) {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.admin_category_add_modal_box_info_img>div:nth-of-type(2)>div:nth-of-type(2)>div{
    width: 45%;
    margin-top: 5px;
}
.admin_category_add_modal_box_info_img>div:nth-of-type(2)>div:nth-of-type(2)>div button {
    width: 100%;
    font-size: 0.9em;
    height: 30px;
    border-radius: 4px;
}
.admin_category_add_modal_box_info_img>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(1) button {
    border: none;
    background-color: rgba(0, 115, 255, 1);
    color: white;
}
.admin_category_add_modal_box_info_img>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(2) button {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.admin_category_add_modal_box_info_txt {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(223, 224, 226, 0.7);
    padding: 10px 0;
}
.admin_category_add_modal_box_info_txt>div:nth-of-type(1) {
    width: 20%;
    font-size: 0.9em;
}
.admin_category_add_modal_box_info_txt>div:nth-of-type(2) {
    width: 80%;
}
.admin_category_add_modal_box_info_txt>div:nth-of-type(2) input {
    width: 250px;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 5px;
    font-size: 0.9em;
}
.admin_category_add_modal_box_info_txt>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_modal_active {
    height: 90vh !important;
    overflow: hidden;
}
.admin_sub_category_modal {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}
.admin_sub_category_modal_box {
    width: 30%;
    height: 30%;
    background-color: white;
    border-radius: 8px;
    padding: 10px;
}
.admin_sub_category_modal_title {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: end;
}
.admin_sub_category_modal_title button {
    border: none;
    background-color: inherit;
}
.admin_sub_category_modal_content {
    width: 100%;
    height: calc(100% - 80px);
}
.admin_sub_category_modal_content>div {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 0.7);
}
.admin_sub_category_modal_content>div>div:nth-of-type(1) {
    width: 100%;
    font-size: 0.9em;
}
.admin_sub_category_modal_content>div>div:nth-of-type(2) {
    width: 100%;
}
.admin_sub_category_modal_content>div>div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding: 5px;
    font-size: 0.9em;
}
.admin_sub_category_modal_content>div>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_sub_category_modal_footer {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_sub_category_modal_footer>div {
    width: 45%;
    height: 40px;
    padding: 0 5px;
}
.admin_sub_category_modal_footer>div:nth-of-type(1) button {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: none;
    background-color: rgba(255, 3, 102, 0.6);
    color: white;
    font-size: 0.9em;
}
.admin_sub_category_modal_footer>div:nth-of-type(2) button {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: none;
    background-color: rgba(0, 115, 255, 1);
    color: white;
    font-size: 0.9em;
}
.admin_category_store_sub_item_info {
    width: 350px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 0.7);
    border-radius: 4px;
    padding: 10px;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.admin_category_store_sub_item_info>div:nth-of-type(1) {
    width: 70%;
}
.admin_category_store_sub_item_info>div:nth-of-type(2) {
    width: 20%;
}
.admin_category_store_sub_item_info>div:nth-of-type(3) {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: end;
}
.admin_category_store_sub_item_info>div:nth-of-type(1)>div {
    width: 100%;
    display: flex;
    align-items: center;
}
.admin_category_store_sub_item_info>div:nth-of-type(3) button {
    border-radius: 50%;
    border: none;
    background-color: rgba(255, 3, 102, 1);
}
.admin_category_store_sub_item_info>div:nth-of-type(3) button span {
    color: white;
}

/* default */
.admin_signature_color {
    color: rgba(255, 3, 102, 1);
}
.admin_side_menu_btn {
    display: none;
}
.admin_board_user_active {
    background-color: rgba(255, 114, 153, 0.7) !important;
    color: white !important;
    border-radius: 4px;
}
.admin_font_date_font {
    font-size: 0.85em;
    color: rgba(196, 199, 202, 1);
}
.admin_signature_color2 {
    color: rgba(255, 114, 153, 1);
}
.admin_gray_color {
    color: rgba(169, 173, 178, 1);
}
.admin_gray_font_1 {
    font-size: 1em;
}
.admin_gray_font_1_1 {
    font-size: 1.1em;
}
.admin_gray_font_2 {
    font-size: 1.2em;
}
.admin_gray_font_3 {
    font-size: 1.4em;
}
.admin_btn_style {
    border: none;
    background-color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_through_text {
    text-decoration: line-through;
    color: rgba(169, 173, 178, 1);
    font-size: 0.9em;
}

/* 월별 통계 */
.admin_monthly_container {
    width: 100%;
    padding: 20px;
}
.admin_monthly_header {
    width: 100%;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    margin-bottom: 10px;
    font-size: 1.1em;
    font-weight: 500;
}
.admin_dayly_total {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    text-align: left;
    font-size: 0.9em;
    padding: 0 10px;
}
.admin_monthly_content {
    width: 100%;
    padding-top: 10px;
}
.admin_monthly_content>div:nth-of-type(1) {
    width: 100%;
    font-size: 1.1em;
    font-weight: 500;
}
.admin_monthly_content>div:nth-of-type(2) {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(223, 224, 226, 1);
    text-align: center;
}
.admin_monthly_content>div:nth-of-type(2)>div {
    width: 33.3%;
}
.admin_monthly_content>div:nth-of-type(3) {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(223, 224, 226, 1);
    text-align: center;
    font-size: 0.9em;

}
.admin_monthly_content>div:nth-of-type(3)>div {
    width: 33.3%;
}

/* 유저 엑셀 등록 */
.admin_user_list_box {
    width: 100%;
    padding: 20px;
}
.admin_user_list_box button {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.9em;
}
.admin_user_list_loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}
.admin_user_list_loading>div {
    width: 80px;
    height: auto;
}
.admin_user_list_loading>div img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

/* 어드민 서포트 */
.admin_support_dashboard_container {
    width: 100%;
}

/* 해외 배송정보 등록 */
.admin_overseas_active {
    height: 80vh;
    overflow-y: hidden;
}
.admin_overseas_tempalate_container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
}
.admin_overseas_tempalate_box {
    width: 50%;
    height: 80%;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
}
.admin_overseas_tempalate_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_overseas_tempalate_header>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.admin_overseas_tempalate_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
}
.admin_overseas_tempalate_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.admin_overseas_tempalate_content {
    width: 100%;
    height: calc(100% - 100px);
    overflow-y: auto;
    padding: 20px;
}
.admin_overseas_tempalate_footer {
    width: 100%;
    height: 50px;
}
.admin_overseas_tempalate_content_delivery_country {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0;
    font-size: 0.9em;
}
.admin_overseas_tempalate_content_delivery_country>div:nth-of-type(1) {width: 30%;}
.admin_overseas_tempalate_content_delivery_country>div:nth-of-type(2) {width: 70%;}
.admin_overseas_tempalate_content_delivery_country_select {width: 100%;}
.admin_overseas_tempalate_content_delivery_name,
.admin_overseas_tempalate_content_delivery_return,
.admin_overseas_tempalate_content_delivery_exchange {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0;
    font-size: 0.9em;
}

.admin_overseas_tempalate_content_delivery_name>div:nth-of-type(1),
.admin_overseas_tempalate_content_delivery_return>div:nth-of-type(1), 
.admin_overseas_tempalate_content_delivery_exchange>div:nth-of-type(1) {width: 30%;}

.admin_overseas_tempalate_content_delivery_name>div:nth-of-type(2),
.admin_overseas_tempalate_content_delivery_return>div:nth-of-type(2), 
.admin_overseas_tempalate_content_delivery_exchange>div:nth-of-type(2) {width: 70%;}

.admin_overseas_tempalate_content_delivery_name>div:nth-of-type(2) input,
.admin_overseas_tempalate_content_delivery_return>div:nth-of-type(2) input,
.admin_overseas_tempalate_content_delivery_exchange>div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding: 0 10px;
}
.admin_overseas_tempalate_content_delivery_name>div:nth-of-type(2) input:focus,
.admin_overseas_tempalate_content_delivery_return>div:nth-of-type(2) input:focus,
.admin_overseas_tempalate_content_delivery_exchange>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.admin_overseas_tempalate_content_delivery_return>div:nth-of-type(2) input,
.admin_overseas_tempalate_content_delivery_exchange>div:nth-of-type(2) input {
    text-align: right;
    padding-right: 30px;
}
.admin_overseas_tempalate_content_delivery_return>div:nth-of-type(2),
.admin_overseas_tempalate_content_delivery_exchange>div:nth-of-type(2) {
    position: relative;
}
.admin_overseas_tempalate_content_delivery_return>div:nth-of-type(2) span,
.admin_overseas_tempalate_content_delivery_exchange>div:nth-of-type(2) span {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.admin_overseas_tempalate_content_delivery_kgcost {
    width: 100%;
    border-top: 1px solid rgba(223, 224, 226, 1);
    padding: 10px 0;
    font-size: 0.9em;
}
.admin_overseas_tempalate_content_delivery_kgcost_text {
    font-weight: 500;
}
.admin_overseas_tempalate_content_delivery_kgcost > .admin_shop_delivery_oversease_add_input input {
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    height: 30px;
}
.admin_overseas_tempalate_footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.admin_overseas_tempalate_footer>div:nth-of-type(1),
.admin_overseas_tempalate_footer>div:nth-of-type(2) {width: 40%;}
.admin_overseas_tempalate_footer>div:nth-of-type(1) button,
.admin_overseas_tempalate_footer>div:nth-of-type(2) button {width: 100%; height: 40px; border-radius: 4px; font-size: 0.9em;}
.admin_overseas_tempalate_footer>div:nth-of-type(1) button {
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
}
.admin_overseas_tempalate_footer>div:nth-of-type(2) button {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.admin_overseas_list_box {
    width: 100%;
    margin-bottom: 10px;
}
.admin_overseas_list_content {
    width: 100%;
    height: 50px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    font-weight: 500;
}
.admin_overseas_list_toggle {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 10px;
}
.admin_overseas_list_content_info {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.admin_overseas_list_content_info>div:nth-of-type(1) {width: 30%;}
.admin_overseas_list_content_info>div:nth-of-type(2) {width: 70%;}

.admin_overseas_list_weight_text {
    width: 100%;
    border-top: 1px solid rgba(223, 224, 226, 1);
}
.admin_overseas_list_weight_content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.admin_overseas_list_weight_content_header {
    width: 200px;
    display: flex;
    border: 1px solid rgba(169, 173, 178, 1);
}
.admin_overseas_list_weight_content_header>div:nth-of-type(1) {width: 100%; text-align: center; border-right: 1px solid rgba(169, 173, 178, 1);}
.admin_overseas_list_weight_content_header>div:nth-of-type(2) {width: 100%; text-align: center;}
.admin_overseas_list_weight_content_info {
    width: 200px;
    display: flex;
    border-left: 1px solid rgba(169, 173, 178, 1);
    border-right: 1px solid rgba(169, 173, 178, 1);
    border-bottom: 1px solid rgba(169, 173, 178, 1);
    color: rgba(255, 3, 102, 1);
}
.admin_overseas_list_weight_content_info>div:nth-of-type(1) {width: 100%; text-align: center; border-right: 1px solid rgba(169, 173, 178, 1);}
.admin_overseas_list_weight_content_info>div:nth-of-type(2) {width: 100%; text-align: center;}

.admin_delivery_setting_oveseas_list {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    background-color: white;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
}
.admin_delivery_setting_oveseas_header {
    width: 100%;
    padding: 5px 0;
    border-bottom: 1px solid rgba(196, 199, 202, 1);
    font-weight: 500;
    color: rgba(255, 3, 102, 1);
}
.admin_delivery_setting_oveseas_item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.admin_delivery_setting_oveseas_item>div:nth-of-type(1) {width: 30%;}
.admin_delivery_setting_oveseas_item>div:nth-of-type(2) {width: 70%;}

.admin_delivery_setting_oveseas_weight_item {
    width: 100%;
    border-top: 1px solid rgba(0, 115, 255, 0.3);
    padding-top: 5px;
}
.admin_delivery_setting_oveseas_weight_item_content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}
.admin_delivery_setting_oveseas_weight_item_content_header {
    width: 200px;
    display: flex;
    align-items: center;
    border: 1px solid rgba(196, 199, 202, 1);
}
.admin_delivery_setting_oveseas_weight_item_content_header>div:nth-of-type(1) {width: 100%; text-align: center; border-right: 1px solid rgba(196, 199, 202, 1);}
.admin_delivery_setting_oveseas_weight_item_content_header>div:nth-of-type(2) {width: 100%; text-align: center;}
.admin_delivery_setting_oveseas_weight_item_content_box {
    width: 200px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(196, 199, 202, 1);
    border-right: 1px solid rgba(196, 199, 202, 1);
    border-left: 1px solid rgba(196, 199, 202, 1);
    color: rgba(169, 173, 178, 1);
}
.admin_delivery_setting_oveseas_weight_item_content_box>div:nth-of-type(1) {width: 100%; text-align: center; border-right: 1px solid rgba(196, 199, 202, 1);}
.admin_delivery_setting_oveseas_weight_item_content_box>div:nth-of-type(2) {width: 100%; text-align: center;}

/* 배송템플릿 수정 */
.admin_shop_delivery_update_container {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_shop_delivery_update_box {
    width: 80%;
    height: 80%;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
}
.admin_shop_delivery_update_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.admin_shop_delivery_update_header>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.admin_shop_delivery_update_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
}
.admin_shop_delivery_update_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.admin_shop_delivery_update_content {
    width: 100%;
    height: calc(100% - 100px);
    overflow-y: auto;
    padding: 20px;
}
.admin_shop_delivery_update_btn_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin_shop_delivery_update_btn_box>div:nth-of-type(1),
.admin_shop_delivery_update_btn_box>div:nth-of-type(2) {
    width: 40%;
}
.admin_shop_delivery_update_btn_box>div:nth-of-type(1) button,
.admin_shop_delivery_update_btn_box>div:nth-of-type(2) button {
    width: 90%;
    height: 40px;
    border-radius: 4px;
    font-size: 0.9em;
}
.admin_shop_delivery_update_btn_box>div:nth-of-type(1) button {
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
}
.admin_shop_delivery_update_btn_box>div:nth-of-type(2) button {
    border: none;
}

.admin_order_content_form_container {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}
.admin_order_content_form_box {
    width: auto;
    height: auto;
    overflow: hidden;
    border-radius: 4px;
    background-color: white;
}
.admin_order_content_form_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.admin_order_content_form_header>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.admin_order_content_form_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
}
.admin_order_content_form_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}

.admin_order_content_form_content {
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
    padding: 20px;
}
.admin_order_content_form_content>div {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 10px;
    margin: 10px 0;
    font-size: 0.9em;
}
.admin_order_content_form_content>div>div:nth-of-type(1) {
    font-weight: 600;
}
.admin_order_content_form_content>div>div:nth-of-type(2) {
    color: rgba(255, 3, 102, 1);
}

/* 소셜 회원가입 전체 동의 */
.terms_agree_total {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.terms_agree_total_first input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 50%;
    background: white;
    position: relative;
}
  
.terms_agree_total_first input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.terms_agree_total_first input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.terms_agree_total_first input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.terms_agree_total_first {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.terms_agree_total_first>div:nth-of-type(1) {
    width: 10%;
    display: flex;
    align-items: center;
}
.terms_agree_total_first>div:nth-of-type(2) {
    width: 70%;
    text-align: left;
}
.terms_agree_total_first>div:nth-of-type(3) {
    width: 20%;
    text-align: right;
}
.terms_agree_total_second {
    width: 100%;
    max-height: 80px;
    overflow-y: auto; /* 세로 스크롤만 활성화 */
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    padding: 5px;
    margin-top: 2px;
    scroll-behavior: smooth; /* 부드러운 스크롤 */
}

.terms_agree_total_second input[type="checkbox"] {
    appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.terms_agree_total_second input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.terms_agree_total_second input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.terms_agree_total_second input[type="checkbox"]:checked:after {
    width: 6px;
    height: 10px;
    opacity: 1;
}
.terms_agree_total_second > div {
    width: 100%;
    padding: 2px 0 2px 5px;
    display: flex;
    align-items: center;
    font-size: 0.8em;
    color: rgba(169, 173, 178, 1);
    cursor: pointer;
}
.terms_agree_total_second > div > div:nth-of-type(1) {
    width: 10%;
    display: flex;
    align-items: center;
}
.terms_agree_total_second > div > div:nth-of-type(2) {
    width: 80%;
    text-align: left;
}
.terms_agree_total_second > div > div:nth-of-type(3) {
    width: 10%;
}

@media screen and ( max-width: 1280px ) {
    .admin_dashboard_board {
        display: block;
    }
    .admin_dashboard_board_left {
        width: 100%;
    }
    .admin_dashboard_board_right {
        width: 100%;
    }
    .admin_dashboard_board_user_profile {
        margin-left: 0;
    }
    .admin_dashboard_board_user_memo {
        margin-left: 0;
    }
    .admin_dashboard_operator_setting {
        margin-left: 0;
    }
    .admin_dashboard_swiper {
        width: 100%;
        height: 300px;
        margin-left: 0;
        margin-top: 10px;
    }
    .admin_dashboard_swiper img {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
    .admin_shop_product_add_swiper_item {
        width: 130px;
        height: 130px;
    }
}
@media screen and ( max-width: 991px ) {
    .admin_dashboard_chart_table_box {
        display: block;
        width: 100%;
    }
    .admin_dashboard_visitors_chart_box {
        height: 500px;
    }
    .admin_dashboard_visitors_chart {
        height: 400px !important;
        font-size: 0.7em;
    }
    .admin_dashboard_visitors_table {
        margin-left: 0 !important;
    }
    .admin_side_menu_btn {
        display: flex;
        align-items: center;
        justify-content: end;
        position: fixed;
        left: -70px;
        top: 50%;
        z-index: 999;
        width: 100px;
        height: 100px;
        background-color: rgba(255, 114, 153, 1);
        border-radius: 50%;
        color: white;
        cursor: pointer;
        transition: all 0.5s;
    }
    .admin_side_menu_btn span {
        padding-right: 5px;
    }
    .admin_side_menu_btn:hover {
        background-color: rgba(255, 224, 232, 1) !important;
        color: black;
    }
    .admin_dashboard_container {
        padding-left: 0;
    }
    .admin_side_menu_active {
        left: 160px !important;
    }
    .admin_shop_product_management_box {
        display: block;
        width: 100%;
    }
    .admin_shop_product_management_content {
        width: 100%;
    }
    .admin_shop_side_bar {
        width: 20%;
        position: fixed;
        top: 10%;
        right: 0;
        z-index: 100;
        background-color: white;
    }
    .admin_shop_side_check_rander {
        margin: 10px 0 0 10px;
        }
    .admin_shop_category_setting {
        width: 100%;
        margin: 10px 0 10px 10px;
    }
    .admin_shop_catogory_open_box {
        position: fixed;
        background-color: rgba(255, 224, 232, 1);
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: start;
        border-radius: 50%;
        top: 230px;
        right: 145px;
        transform: translate(-50%, -50%);
        z-index: 99;
        cursor: pointer;
    }
    .admin_shop_category_active {
        right: -50px !important;
        transition: all 0.3s;
    }
    .admin_shop_product_add_swiper_item {
        width: 110px;
        height: 110px;
    }
}

@media ( max-width: 850px ) {
    .admin_seller_order_management_state_btn {
        display: none !important;
    }
    .admin_shop_product_mobile_tap {
        display: block;
    }
    .admin_shop_review_content_container {
        width: 100%;
        overflow-x: scroll;
    }
    .admin_shop_review_content_container::-webkit-scrollbar {
        width: 10px;  /* 스크롤바의 너비 */
        height: 5px;
    }
    .admin_shop_review_content_container::-webkit-scrollbar-thumb {
        height: 30%; /* 스크롤바의 길이 */
        background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
        border-radius: 2px;
    }
    
    .admin_shop_review_content_container::-webkit-scrollbar-track {
        background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
    }
    .admin_shop_review_content_container>div {
        min-width: 1000px;
    }
}
@media ( max-width: 765px ) {
    .admin_shop_exchange_progress_btn {
        display: none;
    }
    .admin_shop_exchange_progress_mobile_btn {
        display: inline-block;
    }
}
@media screen and ( max-width: 750px ) {
    .admin_userList_table_header {
        display: none;
    }
    .admin_userList_table_section {
        position: relative;
        display: block;
        text-align: left;
    }
    .admin_userList_table_section>div:nth-of-type(1) {
        position: absolute;
        top: 10px;
        left: 0;
    }
    .admin_userList_table_section>div:nth-of-type(2),.admin_userList_table_section>div:nth-of-type(3),.admin_userList_table_section>div:nth-of-type(4),
    .admin_userList_table_section>div:nth-of-type(5),.admin_userList_table_section>div:nth-of-type(6),.admin_userList_table_section>div:nth-of-type(7),
    .admin_userList_table_section>div:nth-of-type(8) 
    {
        width: 100%;
        padding-left: 30px;
    }
    .admin_userList_table_section>div:nth-of-type(3),.admin_userList_table_section>div:nth-of-type(4),.admin_userList_table_section>div:nth-of-type(6),
    .admin_userList_table_section>div:nth-of-type(7) {
        display: none;
    }
    .admin_userList_table_section>div:nth-of-type(9) {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);  
    }
    .admin_userList_search_select {
        width: 80px;
    }
    .admin_userList_search_input_txt input {
        width: 120px;
    }
    .admin_userList_datepicker {
        width: 150px;
    }
    :global(.ant-picker-panels) {
        display: block;
    }
    .admin_shop_product_add_box>div:nth-of-type(2) {
        display: block;
    }
    .admin_shop_product_add_product_box {
        width: 100%;
    }
    .admin_shop_side {
        width: 97%;
        margin-bottom: 10px;
        margin-right: 10px;
        margin-left: 10px;
    }
    .admin_shop_product_add_editor_image_box {
        top: 100px;
        right: 15px;
    }
    .admin_shop_product_add_delevery_add>div {
        width: 100%;
    }
    .admin_seller_auth>div {
        width: 97% !important;
    }
}
@media screen and ( max-width: 670px ) {
    .adimn_dashboard_cusetomer_contents_box {
        display: block !important;
    }
    .admin_dashboard_info_inquiry {
        display: block !important;
    }
    .admin_dashboard_contents_box {
        margin-left: 0 !important;
    }
    .admin_dashboard_dailyduck_inquiry {
        margin-left: 0 !important;
    }
    .admin_shop_product_add_top {
        display: block;
    }
    .admin_shop_product_add_top>div:nth-of-type(1) {
        width: 100%;
        margin: 0;
    }
    .admin_shop_product_add_top>div:nth-of-type(2) {
        width: 100%;
        margin: 0;
        margin-top: 10px;
    }
    .admin_shop_product_add_middle {
        display: block;
    }
    .admin_shop_product_add_middle>div:nth-of-type(1) {
        width: 100%;
        margin: 0;
        margin-top: 10px;
    }
    .admin_shop_product_add_middle>div:nth-of-type(2) {
        width: 100%;
        margin: 0;
        margin-top: 10px;
    }
    .admin_shop_product_add_product_option_box {
        margin: 0;
        margin-top: 10px;
        border-radius: 2px;
    }
    .admin_shop_product_add_inventory_box {
        margin: 0;
        margin-top: 10px;
        border-radius: 2px;
    }
    .admin_shop_product_add_product_detail {
        margin: 0;
        margin-top: 10px;
        border-radius: 2px;
    }
    .admin_shop_product_add_delivery_form_box {
        display: block;
    }
    .admin_shop_product_add_delivery_form_box>div:nth-of-type(1) {
        margin: 0;
        margin-top: 10px;
        border-radius: 2px;
    }
    .admin_shop_product_add_delivery_form_box>div:nth-of-type(2) {
        margin: 0;
        margin-top: 10px;
        border-radius: 2px;
    }
    .admin_shop_product_add_faq_box {
        margin: 0;
        margin-top: 10px;
        border-radius: 2px;
    }
    .admin_shop_product_add_return_box{
        margin: 0;
        margin-top: 10px;
        border-radius: 2px;
    }
    .admin_shop_side {
        margin-bottom: 10px;
    }
    .admin_shop_delivery_input_box input {
        width: 150px;
    }
    .admin_shop_delivery_add_btn_box button {
        width: 120px;
    }
    .admin_shop_side_bar {
        width: 25%;
    }
    .admin_shop_catogory_open_box {
        right: 120px;
    }
}
@media (max-width: 610px) {
    .admin_seller_product_management_state_btn {
        display: none;
    }
    .admin_seller_product_management_state_mobile_btn {
        display: block;
    }
}
@media (max-width: 600px) {
    .admin_shop_cancel_progress_btn {
        display: none;
    }
    .admin_shop_return_progress_mobile_btn {
        display: inline-block;
    }
}
@media (max-width: 570px){
    .admin_shop_cancel_tap {
        display: none;
    }
    .admin_shop_cancel_mobile_tap {
        display: flex;
    }
}
@media screen and ( max-width: 500px ) {
    .admin_userList_search_box {
        display: block;
    }
    .admin_userList_search_input {
        width: 100%;
        justify-content: left;
    }
    .admin_userList_search_date {
        margin-top: 10px;
    }
    .admin_userList_datepicker {
        width: 100%;
        height: 40px;
    }
    .admin_userList_search_input>div:nth-child(1){
        width: 20%;
    }
    .admin_userList_search_input>div:nth-child(2){
        width: 60%;
    }
    .admin_userList_search_input>div:nth-child(3){
        width: 20%;
    }
    .admin_userList_search_select {
        width: 100%;
        height: 45px;
    }
    .admin_userList_search_input_txt input{
        width: 100%;
        height: 45px;
    }
    .admin_userList_search_btn button {
        width: 100%;
        height: 45px;
    }
    :global(.ant-picker-panels) {
        display: block !important;
    }
    .admin_header_right {
        display: none;
    }
    .admin_header_container {
        justify-content: center;
    }
    .admin_header_left {
        display: block;
        padding: 0;
    }
    .admin_header_left div:nth-of-type(2) {
        padding: 0 !important;
        font-size: 0.9em;
        text-align: center;
    }
    .admin_shop_product_add_faq_box>div:nth-of-type(2)>div:nth-of-type(2) button {
        width: 190px;
    }
    .admin_shop_side_bar {
        width: 35%;
    }
    .admin_shop_catogory_open_box {
        right: 125px;
    }
    .admin_login_box {
        width: 100%;
    }
    .admin_join_section_box>div {
        width: 100%;
        height: 100%;
    }
    .admin_join_section_header {
        position: relative;
    }
    .admin_join_section_content {
        margin-top: 15px;
    }
    .admin_shop_product_tap {
        display: none;
    }
    .admin_shop_product_mobile_active_tap {
        display: flex;
    }
    .terms_agree_total {
        width: 100%;
    }
}
@media (max-width: 400px) {
    .admin_order_inspection_search_box {
        justify-content: end;
        flex-direction: column;
        align-items: flex-end;
    }
    .admin_seller_inspection_download {
        margin-right: 0;
        margin-bottom: 5px;
    }
}