.store_container {
    width: 100%;
    /* height: 100%; */
}
.store_header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    background-color: white;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    position: relative;
}
.store_category {
    margin-top: 15px;
    font-size: 1.05em;
}
.store_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0, -50%);
}
.store_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}

.store_filter_btn_box { 
    display: flex;
    align-items: center;
    padding: 10px 0;
    font-size: 0.9em;
}
.store_filter_btn_box button {
    padding: 5px 12px;
    border: none;
    border-radius: 30px;
    margin-right: 10px;
}
.store_filter_active {
    background-color: rgba(72, 76, 81, 1);
    color: white;
}
.store_favority_product_check {
    display: flex;
    align-items: center;
}
.store_favority_product_check div label, .store_favority_product_check div input {
    cursor: pointer;
    font-size: 0.9em;
}
.store_favority_product_check input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 100%;
    background: #fff;
    position: relative;
}  
.store_favority_product_check input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}
  
.store_favority_product_check input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}
  
.store_favority_product_check input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.store_favority_select {
    width: 130px;
    font-size: 0.9em;
}
.store_rental_product_sort_btn button {
    display: flex;
    align-items: center;
    border: none;
    background-color: inherit;
    /* font-size: 0.9em; */
}
.store_rental_product_sort_modal_container {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;
}
.store_rental_product_sort_modal_box {
    width: 100%;
    height: 50%;
    background-color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: absolute;
    bottom: 0;
}
.store_rental_product_sort_modal_header {
    width: 100%;
    text-align: right;
    padding: 20px;
}
.store_rental_product_sort_modal_header button {
    border: none;
    background-color: inherit;
}
.store_rental_product_sort_modal_content {
    padding: 0 20px;
}
.store_rental_product_sort_modal_content input[type="radio"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}
  
.store_rental_product_sort_modal_content input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}
  
.store_rental_product_sort_modal_content input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}
  
.store_rental_product_sort_modal_content input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}
.store_rental_product_sort_modal_content>div {
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center; 
}
.store_rental_product_sort_modal_content>div>div:nth-of-type(1) {width: 10%; height: auto;}
.store_rental_product_sort_modal_content>div>div:nth-of-type(2) {width: 90%; height: auto;}
.store_rental_product_sort_modal_content>div>div:nth-of-type(2) label {
    width: 100%;
    height: 80px;
    line-height: 80px;
    cursor: pointer;
}

/* item list */
.store_item_list_box {
    position: relative;
    margin-bottom: 10px;
}
.store_stockCnt_zero_box {
    position: absolute;
    top: 0;
    width: 100%;
    /* padding-right: 2em; */
    /* padding-bottom: 2em; */
    z-index: 500;
    /* padding: 10px 20px 20px 0; */
}
.store_stockCnt_zero {
    width: 100%;
    height: 100%;
    background-color: rgba(115, 122, 130, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 12px;
    transition: all 0.3s;
    aspect-ratio: 500 / 500;
}
/* .store_stockCnt_zero:hover {
    background-color: rgba(115, 122, 130, 0.7);
} */
.store_stockCnt_zero span{
    color: white;
}
.store_item_list_img_box {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 12px;
    position: relative;
}
.store_item_list_img_box img {
    width: 100%;
    aspect-ratio: 500 / 500;
    object-fit: cover;
}
.store_item_list_productname {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 두 줄로 제한 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* 원래는 nowrap을 사용하지만, 여러 줄 처리를 위해 normal로 설정 */
    line-height: 1.2em; /* 줄 높이 설정 */
    max-height: 2.4em; /* 줄 높이와 줄 수에 따라 최대 높이 설정 (예: 줄 높이 1.2em * 줄 수 2 = 2.4em) */
}
.store_gray {
    color: rgba(115, 122, 130, 1);
}
.store_fs_small {
    font-size: 0.8em;
}
.store_fs_middle {
    font-size: 0.95em;
}
.store_item_list_discount_rate {
    color: rgba(255, 3, 102, 1);
    font-weight: bold;
}
.store_item_list_discount_price {
    color: rgba(0, 0, 0, 1);
    font-weight: bold;
    font-size: 1em;
    padding: 0 5px;
}
.store_return_box {
    border-top: 2px solid rgba(238, 225, 255, 1);
    border-bottom: 2px solid rgba(238, 225, 255, 1);
}
.store_item_list_price {
    color: rgba(141, 147, 154, 1);
    text-decoration: line-through;
    font-size: 0.85em;
}
.star_iconImg {
    width: 20px;
    height: 20px;
    overflow: hidden;
}
.star_iconImg img{
    width: 100%;
}
.store_item_list_shop_name {
    padding: 8px 0 2px 0;
}
.store_item_list_badge_review {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 3px;
}
.store_item_list_label {
    width: 65px;
    height: 20px;
    font-size: 0.8em;
    border-radius: 3px;
    text-align: center;
    line-height: 20px;
}
.store_item_list {
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 8px;
    padding: 10px;
    position: relative;
}
/* .store_item_list:hover {
    cursor: pointer;
    opacity: 0.8;
    box-shadow: 1px 1px 5px 4px rgba(238, 225, 255, 1);
} */
.store_item_heart_box {
    position: absolute;
    bottom: 10px;
    right: 10px;
    /* transform: translate(0, -50%); */
    z-index: 550;
}

/* 스토어 상품 상세 */
.store_product_detail_container {
    /* position: relative;
    overflow-y: auto; */
    width: 100%;
    height: 100svh;
}
.store_product_detail_header {
    position: relative;
    /* display: flex;
    justify-content: space-between; */
    width: 100%;
    height: 50px;
    background-color: white;
    /* top: 0; */
    /* z-index: 10; */
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
}
.store_product_detail_header_left {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0, -50%);
}
.store_product_detail_header_right {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
}
.store_login_check_popup {

}
.store_login_check_popup_title {
    font-weight: 500;
    padding: 5px 0;
}
.store_login_check_popup_sub_title {
    font-size: 0.9em;
    padding-bottom: 10px;
}
.store_login_check_popup_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.store_login_check_popup_btn>button:nth-of-type(1) {
    width: 45%;
    height: 45px;
    border: 1px solid rgba(238, 225, 255, 1);
    background-color: inherit;
    font-size: 0.9em;
    border-radius: 4px;
}
.store_login_check_popup_btn>button:nth-of-type(2) {
    width: 45%;
    height: 45px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
    border-radius: 4px;
}
.my_store_detail_favority_popup {
    
}
.my_store_detail_favority_title {
    font-weight: 500;
    padding: 20px 0;
}
.my_store_detail_favority_confirm_btn_box {
    width: 100%;
}
.my_store_detail_favority_confirm_btn_box button {
    width: 100%;
    height: 50px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
    border-radius: 6px;
}
.store_product_detail_header_left button, .store_product_detail_header_right button{
    border: none;
    background-color: inherit;
    /* color: white; */
}
.store_product_detail_loading {
    position: absolute;
    top: 0;
    left: 0;
    background-color: inherit;
    width: 100%;
    height: 100svh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}
.store_product_detail_loading img {
    width: 20%;
}
.store_product_detail_content {
    width: 100%;
    height: calc(100svh - 50px); /* 기본 뷰포트 높이에서 하단 버튼 제외 */
    padding-bottom: calc(env(safe-area-inset-bottom, 0) + 50px); /* 하단 버튼과 안전 영역 포함 */
    overflow-y: auto; /* 스크롤 가능 */
    box-sizing: border-box;
}
.store_product_detail_content img {
    width: 100%;
    height: auto;
    object-fit: cover;
    /* aspect-ratio: 500 / 500; */
}
.store_detail_faq_item {
    width: 100%;
}
.store_detail_faq_item button {
    width: 100%;
    text-align: left;
    border: none;
    background-color: inherit;
    padding: 10px 0;
    border-bottom: 1px solid rgba(241, 241, 241, 1);
}
.store_detail_faq_item button>div:nth-of-type(1) {
    font-weight: 500;
}
.store_detail_faq_item button>div:nth-of-type(2) {
    font-size: 0.9em;
    color: rgba(223, 224, 226, 1);
}
.store_faq_detail_container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    top: 0;
    left: 0;
}
.store_faq_detail_box {
    width: 100%;
    height: auto;
}
.store_faq_detail_header {
    position: relative;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}
.store_faq_detail_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0, -50%);
}
.store_faq_detail_header>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.store_faq_detail_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.store_faq_detail_content {
    width: 100%;
    padding: 20px;
}
.store_faq_detail_content_title {
    font-size: 1.3em;
    font-weight: 500;
}
.store_faq_detail_content_date {
    font-size: 0.9em;
    color: rgba(169, 173, 178, 1);
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding-bottom: 10px;
}
.store_faq_detail_content_text {
    padding: 10px 0 0 0;
}

.store_favority_heart_box {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 100;
}
.store_favority_heart_box button {
    border: none;
    background-color: inherit;
    width: 30px;
    height: 30px;
}
.store_review_btn_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 5px 0;
    border-bottom: 1px solid rgba(238, 225, 255, 1);
}
.store_review_btn_box>div:nth-of-type(1) {
    display: flex;
    align-items: center;
}
.store_user_review_box {
    display: flex;
    align-items: center;
}
.store_user_review_date {
    padding-left: 4px;
    font-size: 0.9em;
    color: rgba(115, 122, 130, 1);
}
.store_user_review_profile {
    display: flex;
    align-items: center;
    font-weight: 500;
}
.store_user_review_profile>div:nth-of-type(1) {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}
.store_user_review_profile>div:nth-of-type(1) img {
    width: 100%;
    aspect-ratio: 500 / 500;
    object-fit: cover;
}
.store_user_review_profile>div:nth-of-type(2) {
    padding-left: 7px;
    font-size: 1.1em;
}
/* 상품 상세 이미지 슬라이더 */
.store_my_slider {
    width: 100%;
    height: auto;
}
.store_my_slider img {
    width: 100%;
    /* height: 450px; */
    aspect-ratio: 500 / 500;
    object-fit: cover;
}
.store_my_slider .swiper-pagination-bullet {
    background: white !important;
}

/* 스토어 상품 상세 FAQ */
.store_detail_faq_no_data {
    width: 100%;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
}

/* 스토어 상품 상세 하단 버튼 */
.store_bottom_btn_box {
    width: 100%;
    position: absolute;
    bottom: env(safe-area-inset-bottom, 0); /* 하단 safe area 적용 */
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px 10px 15px; /* 기본 패딩 */
    border-top: 1px solid rgba(241, 242, 243, 1);
    background-color: white;
    z-index: 100;
    box-sizing: border-box; /* 패딩을 포함하여 전체 크기 계산 */
}


.store_bottom_btn_left button {
    padding: 15px;
    background-color: inherit;
    border: 1px solid rgba(241, 242, 243, 1);
    border-radius: 12px;
}
.store_bottom_btn_right {
    width: 45%;
}
.store_bottom_btn_right button {
    width: 100%;
    height: 60px;
    background-color: rgba(255, 3, 102, 1);
    border: none;
    border-radius: 8px;
    color: white;
}
.store_bottom_btn_login {
    width: 100%;
}
.store_bottom_btn_login button {
    width: 100%;
    height: 60px;
    background-color: rgba(255, 3, 102, 1);
    border: none;
    border-radius: 8px;
    color: white;
}
.store_bottom_btn_cart {
    width: 45%;
}
.store_bottom_btn_cart button {
    width: 100%;
    height: 60px;
    background-color: rgba(223, 224, 226, 1);
    border: none;
    border-radius: 8px;
}
.store_shop_message_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.store_shop_product_name {
    font-size: 1.2em;
}
.store_shop_review_box {
    font-size: 0.9em;
}
.store_shop_review_box>div {
    display: flex;
    align-items: center;
}
.store_shop_profile_box {
    display: flex;
    align-items: center;
}
.store_shop_profile_box>div:nth-of-type(1) {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
}
.store_shop_profile_box>div:nth-of-type(2) {
    padding-top: 4px;
}
.store_shop_profile_box>div:nth-of-type(1)>img {
    width: 100%;
    height: 30px;
    object-fit: cover;
}
.store_bottom_btn_middle button {
    display: flex;
    align-items: center;
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.9em;
    transition: all 0.2s;
}
.store_bottom_btn_middle button:hover,
.store_bottom_btn_middle button:active {
    background-color: rgba(255, 224, 232, 1);
}
.store_bottom_btn_middle button>div:nth-of-type(1) span {
    font-size: 1.5em;
}
.store_plan_product_btn {
    border: none;
    background-color: inherit;
}

/* 결제 준비 창 */
.store_total_price_box span {
    font-size: 1.1em;
}
.store_total_price span {
    color: rgba(255, 3, 102, 1);
    font-weight: bold;
    font-size: 1.2em;
}
.store_delivery_fee_box span {
    color: rgba(169, 173, 178, 1);
}
.store_cart_buy_btn_box button{
    border: none;
    width: 100%;
    height: 60px;
    border-radius: 12px;
}
.store_buy_btn {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}

.store_review_my_swiper :global(.swiper-slide) {
    max-width: 120px !important;
    flex: 0 0 auto; /* 플렉스박스 아이템이 줄어들지 않도록 설정 */
}
.store_review_my_swiper_img {
    overflow: hidden;
    width: 120px;
    height: 120px;
    border-radius: 8px;
}
.store_review_my_swiper_img img {
    width: 100%;
    aspect-ratio: 500 / 500;
    object-fit: cover;
}

.store_review_filter_modal_container {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 200;
}
.store_review_filter_modal_box {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50%;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.store_review_filter_modal_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 20px;
}
.store_review_filter_modal_header button {
  border: none;
  background-color: inherit;
}
.store_review_filter_modal_content {
  width: 100%;
  padding: 20px;
}
.store_review_filter_modal_content>div {
  width: 100%;
  margin-bottom: 5px;
}
.store_review_filter_modal_content>div button {
  width: 100%;
  height: 50px;
  border: 1px solid rgba(241, 242, 243, 1);
  background-color: inherit;
  border-radius: 4px;
}
.store_filter_active {
  background-color: rgba(255, 3, 102, 1) !important;
  color: white;
}

/* 공유하기 */
.store_detaile_shared_active {
    overflow: hidden !important;
    height: 100vh !important;
}
.store_shared_background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 200;
    background-color: rgba(72, 76, 81, 0.3);
}
.store_shard_box {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 350px;
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.store_shard_btn_box {
    width: 20%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1em;
    margin-right: 1em;
}
.store_shard_btn_bottom_box {
    width: 19%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1em;
    margin-right: 1em;
}
.store_shard_btn_box button, .store_shard_btn_bottom_box button {
    border: none;
    background-color: inherit;
}
.store_shard_btn_box span, .store_shard_btn_bottom_box button {
    font-size: 0.9em;
}
.store_clipboard_box {
    width: 220px;
    border-radius: 30px;
    background-color: rgba(241, 242, 243, 1);
    padding: 10px 5px;
    font-size: 0.8em;
    text-align: center;
}
.store_shared_close {
    border: none;
    background-color: inherit;
}

/* buy button click */
.store_buy_option_background {
    position: absolute;
    background-color: rgba(115, 122, 130, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 101;
}

.store_buy_option_box {
    position: absolute;
    bottom: 0;
    background-color: white;
    width: 100%;
    height: auto;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}
.store_buy_option_box_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5em 20px 0 20px;
}
.store_buy_option_box_header>div:nth-of-type(1) {
    font-size: 1.2em;
}
.store_option_select_box {
    padding: 0 20px;
    width: 100%;
    /* max-height: 160px; */
    overflow-y: auto;
}

.store_buy_option_select {
    width: 100%;
    height: 50px;
    margin: 10px 0;
}
.store_buy_option_select :global(.ant-select-selection-placeholder) {
    color: black !important;
}
.store_final_option_select_box {
    width: 100%;
    height: 180px;
    overflow-y: scroll;
    background-color: rgba(248, 248, 248, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.store_final_option_select {
    width: 90%;
    background-color: white;
    border-radius: 12px;
    padding: 20px;
    margin: 10px 0;
}
.store_final_option_count_btn {
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 50%;
    color : white;
    background-color: rgba(223, 224, 226, 1);
    line-height: 25px;
    transition: all 0.3s;
}
.store_final_option_count_btn:active {
    background-color: rgba(255, 3, 102, 1);
}
.store_final_option_count_span {
    display: block;
    width: 50px;
    text-align: center;
}
.store_final_option_select_close {
    border: none;
    background-color: inherit;
}
.store_cart_add_popup {
    height: 25vh;
}
.store_cart_add_title {
    font-size: 1.2em;
    padding: 20px;
}
.store_cart_add_content {
    font-size: 0.9em;
    color: rgba(115, 122, 130, 1);
    padding-bottom: 15px;
}
.store_cart_add_btn_box button {
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 8px;
    font-size: 0.9em;
}
.store_cart_add_btn_box button:nth-of-type(2) {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.store_sub_category_swiper :global(.swiper-slide) {
    width: auto !important; /* 버튼의 내용에 따라 크기가 조정되도록 설정 */
    flex: 0 0 auto; /* 플렉스박스 아이템이 줄어들지 않도록 설정 */
}
.store_sub_category_box {
    padding: 0 5px;
    margin-top: 5.5px;
}
.store_no_review_box {
    width: 100%;
    padding: 20px;
    text-align: center;
    font-size: 0.9em;
}

/* 버튼의 스타일 */
.store_sub_category {
    width: 100%;
    height: 40px;
    text-align: center;
    display: flex;
    justify-content: center; /* 버튼을 중앙 정렬 */
    align-items: center; /* 수직 중앙 정렬 */
}

.store_sub_category button {
    border: none;
    background-color: rgba(241, 241, 241, 1);
    font-size: 0.95em;
    border-radius: 30px;
    padding: 5px 10px;
    white-space: nowrap; /* 텍스트가 한 줄에 유지되도록 설정 */
    overflow: hidden; /* 넘치는 부분을 숨김 */
    text-overflow: ellipsis; /* 넘치는 텍스트를 말줄임표로 표시 */
    max-width: 120px; /* 최대 너비 설정으로 인해 너무 긴 버튼을 줄임 */
    margin: 0 2px; /* 버튼 간 간격 설정 */
}

/* 활성화된 버튼의 스타일 */
.store_sub_category_active {
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: white;
}
.store_rental_product_list {
    width: 100%;
    height: 70vh;
    overflow-y: scroll;
    position: relative;
    padding: 0 10px;
}
.store_rental_product_list>div {
    margin-bottom: 100px;
}
.store_rental_product_list_header {
    width: 100%;
}
.store_rental_product_list_header>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 10px 20px;
}
.store_payment_orderer_info_box {
    width: 100%;
}
.store_payment_orderer_info_box>div:nth-of-type(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.store_payment_orderer_info_box>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
    font-size: 0.9em;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    transition: all 0.2s;
}
.store_payment_orderer_info_box>div:nth-of-type(1) button:hover,
.store_payment_orderer_info_box>div:nth-of-type(1) button:active {
    color: rgba(255, 3, 102, 1);
    border-bottom: 1px solid rgba(255, 3, 102, 1);
}
.store_payment_orderer_info_box>div:nth-of-type(2)>div {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    padding: 5px 0;
}
.store_payment_orderer_info_box>div:nth-of-type(2)>div>div:nth-of-type(1) {width: 20%; color: rgba(141, 147, 154, 1);}
.store_payment_orderer_info_box>div:nth-of-type(2)>div>div:nth-of-type(2) {width: 80%;}

.store_payment_delivery_info_box {
    width: 100%;
    padding: 1em;
}
.store_payment_delivery_info_box>div:nth-of-type(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.store_payment_delivery_info_box>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    font-size: 0.9em;
    transition: all 0.2s;
}
.store_payment_delivery_info_box>div:nth-of-type(1) button:hover,
.store_payment_delivery_info_box>div:nth-of-type(1) button:active {
    color: rgba(255, 3, 102, 1);
    border-color: rgba(255, 3, 102, 1);
}
.store_payment_delivery_info_box>div:nth-of-type(2)>div {
    padding: 2px 0;
    font-size: 0.9em;
}
.store_payment_delivery_info_box>div:nth-of-type(2)>div:nth-of-type(1) {
    padding-top: 10px;
}
.store_payment_delivery_info_box>div:nth-of-type(2)>div:nth-of-type(1) span {
    background-color: rgba(255, 3, 102, 1);
    color: white;
    padding: 5px 10px;
    border-radius: 30px;
    font-size: 0.8em;
}
.store_payment_order_product {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    width: 100%;
    position: relative;
}
.store_payment_order_product>div:nth-of-type(1) {width: 20%;}
.store_payment_order_product>div:nth-of-type(2) {width: 80%; text-align: right;}

.store_delivery_change_modal_box {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: white;
    top: 0;
    left: 0;
    z-index: 100;
}
.store_delivery_change_modal_header {
    position: absolute;
    width: 100%;
    height: 50px;
    background-color: white;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
}
.store_delivery_change_modal_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.store_delivery_change_modal_header>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.store_delivery_change_modal_content {
    width: 100%;
    margin-top: 50px;
    padding: 1em;
    height: 80vh;
    overflow-y: scroll;
}
.store_delivery_change_modal_list_box {
    width: 100%;
    padding: 20px;
    border: none;
    background-color: white;
    box-shadow: 0 4px 7px 1px rgba(223, 224, 226, 1);
    border-radius: 4px;
    margin-top: 10px;
}
.store_delivery_change_modal_list_box>div:nth-of-type(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.store_delivery_change_modal_list_box>div:nth-of-type(1)>div:nth-of-type(1) {
    display: flex;
    align-items: center;
}
.store_delivery_change_modal_list_box>div:nth-of-type(1)>div:nth-of-type(1)>div:nth-of-type(1) {
    font-size: 1.2em;
    font-weight: bold;
}
.store_delivery_change_modal_list_box>div:nth-of-type(1)>div:nth-of-type(1)>div:nth-of-type(2) {
    padding-left: 10px;
}
.store_delivery_change_modal_list_box>div:nth-of-type(1)>div:nth-of-type(1)>div:nth-of-type(2) span {
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.8em;
    border-radius: 30px;
    padding: 5px 10px;
}
.store_delivery_change_modal_list_box>div:nth-of-type(1)>div:nth-of-type(2) input[type="radio"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}
  
.store_delivery_change_modal_list_box>div:nth-of-type(1)>div:nth-of-type(2) input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}
  
.store_delivery_change_modal_list_box>div:nth-of-type(1)>div:nth-of-type(2) input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}
  
.store_delivery_change_modal_list_box>div:nth-of-type(1)>div:nth-of-type(2) input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}
.store_delivery_change_modal_list_box>div:nth-of-type(2) {
    width: 100%;
    text-align: left;
    padding: 10px 0;
}
.store_delivery_change_modal_list_box>div:nth-of-type(3) {
    width: 100%;
    text-align: left;
}
.store_delivery_change_modal_list_box>div:nth-of-type(4) {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.store_delivery_change_modal_list_box>div:nth-of-type(4) button {
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    padding: 5px 10px;
    border-radius: 30px;
    font-size: 0.9em;
    transition: all 0.2s;
}
.store_delivery_change_modal_list_box>div:nth-of-type(4) button:hover {
    background-color: rgba(255, 224, 232, 1);
}
.store_delivery_change_modal_list_box>div:nth-of-type(4)>div:nth-of-type(2) {
    padding-left: 10px;
}
.store_delivery_change_modal_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
}
.store_delivery_change_modal_footer>div {
    width: 45%;
    margin: 0 10px;
}
.store_delivery_change_modal_footer>div button {
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 8px;
}
.store_delivery_change_modal_footer>div:nth-of-type(2) button {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.store_delivery_change_modal_btn_select {
    position: absolute;
    bottom: 100px;
    width: 250px;
    height: 80px;
    left: 5%;
    transition: all 0.2s;
}
.store_delivery_change_modal_btn_bubble {
    position: relative;
    box-shadow: 0 0 4px 1px rgba(238, 225, 255, 1);
	border-radius: .4em;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 80px;
}
.store_delivery_change_modal_btn_bubble::after {
    content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 18px solid transparent;
	border-top-color: rgba(248, 248, 248, 1);
	border-bottom: 0;
	margin-left: -18px;
	margin-bottom: -18px;
}
.store_delivery_change_modal_btn_bubble>div:nth-of-type(1) button,
.store_delivery_change_modal_btn_bubble>div:nth-of-type(2) button {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
    padding: 5px 10px;
    border-radius: 4px;
}

.store_delivery_update_address_search_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}
.store_delivery_update_address_search_box button {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.9em;
}
.store_payment_type_tap_btn {
    width: 100%;
    display: flex;
    align-items: center;
}
.store_payment_type_tap_btn>div:nth-of-type(1),
.store_payment_type_tap_btn>div:nth-of-type(2) {
    width: 100%;
    height: 30px;
}
.store_payment_type_tap_btn>div:nth-of-type(1) button,
.store_payment_type_tap_btn>div:nth-of-type(2) button {
    width: 100%;
    border: none;
    background-color: inherit;
    height: auto;
}
.store_payment_type_tap_btn_active {
    border-bottom: 2px solid rgba(255, 3, 102, 1);
    color: rgba(255, 3, 102, 1);
    font-weight: 500;
}
.adress_box {
    width: 100%;
}
.adress_box button{
    width: 100%;
    height: 60px;
    border-radius: 8px;
    border: none;
}
.email_auth_active {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.post_background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
}
.post_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.post_close {
    width: 100%;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    height: 40px;
}
.store_delivery_update_box {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: white;
    z-index: 100;
    top: 0;
}
.store_delivery_update_box_header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}
.store_delivery_update_box_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.store_delivery_update_box_header>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.store_delivery_update_box_content {
    width: 100%;
    margin-top: 55px;
    height: 80vh;
    overflow-y: scroll;
    padding: 1em;
}
.myinfo_phone_box {
    width: 100%;
}
.phone_number_box {
    position: relative;
    width: 100%;
}
.phone_number_box>span {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
}
.phone_number_box>input {
    padding-left: 45px;
}
.store_delivery_update_box_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    padding: 1em;
}
.store_delivery_update_box_footer button {
    border: none;
    background-color: rgba(223, 224, 226, 1);
    color: rgba(115, 122, 130, 1);
    font-size: 0.9em;
    width: 100%;
    height: 50px;
    border-radius: 8px;
}
.store_global_address_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}

.store_cart_payment_product_box {
    width: 100%;
    display: flex;
    /* align-items: center; */
    padding: 5px 15px;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.store_cart_payment_product_img_box {
    width: 20%;
    overflow: hidden;
    padding: 5px;
}
.store_cart_payment_product_img_box img {
    width: 100px;
    height: 100px;
    aspect-ratio: 100 / 100;
    object-fit: cover;
    border-radius: 4px;
}
.store_cart_payment_product__info_box {
    width: 80%;
}
.store_cart_payment_product__info_name {
    width: 100%;
    font-weight: 500;
    font-size: 0.9em;
}
.store_cart_payment_product__info_option {
    width: 100%;
    height: 50px;
    overflow-y: auto;
    font-size: 0.8em;
    color: rgba(115, 122, 130, 1);
}
.store_cart_payment_product__info_option>div {
    width: 100%;
    display: flex;
}
.store_cart_payment_product__info_option>div>div:nth-of-type(1) {
    display: flex;
    margin-right: 10px;
}
.store_cart_payment_product__info_option>div>div:nth-of-type(1)>div:nth-of-type(1) {
    margin-right: 10px;
}
.store_cart_payment_product__info_option>div>div:nth-of-type(1)>div:nth-of-type(2) {
    
}
.store_cart_payment_product__info_option>div>div:nth-of-type(2) {
    width: 80%;
}
.store_cart_payment_product__info_price {
    font-size: 0.9em;
    font-weight: 700;
    color: rgba(255, 3, 102, 1);
}
.store_cart_payment_product__info_quantity {
    display: flex;
    color: rgb(0, 119, 255);
    font-size: 0.8em;
}
.store_cart_payment_support__info_option {
    width: 100%;
    height: 50px;
    overflow-y: auto;
    font-size: 0.8em;
    color: rgba(115, 122, 130, 1);
    scroll-behavior: smooth;
}
.store_cart_payment_support__info_option > div {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.store_cart_payment_support__info_option > div > div:nth-of-type(1) {
    margin-right: 20px;
}
.store_cart_payment_support__info_option > div > div:nth-of-type(2) {}

.store_content_form_container {
    position: absolute;
    width: 100%;
    height: 100svh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}
.store_content_form_box {
    width: 80%;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
}
.store_content_form_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.store_content_form_header>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.store_content_form_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
}
.store_content_form_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.store_content_form_content {
    width: 100%;
    padding: 20px;
    max-height: 500px;
    overflow-y: auto;
}
.store_content_form_content_box {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 4px;
}
.store_content_form_content_box>div:nth-of-type(1) {
    font-size: 0.9em;
    font-weight: 600;
}
.store_content_form_content_box>div:nth-of-type(2) {
    width: 100%;
    margin-top: 5px;
}
.store_content_form_content_box>div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(169, 173, 178, 1);
    border-radius: 4px;
    padding: 10px;
    font-size: 0.9em;
}
.store_content_form_content_box>div:nth-of-type(2) input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}

.store_content_form_footer {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.store_content_form_footer>div {
    width: 45%;
}
.store_content_form_footer>div button {
    width: 100%;
    height: 45px;
    border-radius: 8px;
}
.store_content_form_footer>div:nth-of-type(1) button {
    border: none;
    background-color: rgba(223, 224, 226, 1);
}
.store_content_form_footer>div:nth-of-type(2) button {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.store_content_form_input_btn {
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translate(0, -50%);
}
.store_content_form_input_btn button {
    border: none;
    padding: 5px 10px;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
    border-radius: 4px;
}
.store_content_form_input_info {
    position: absolute;
    top: 0;
    left: 100px;
    width: 350px;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
    border-radius: 4px;
    background-color: white;
    padding: 10px;
}
.store_content_form_input_info > div {
    width: 100%;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    margin: 5px 0;
    font-size: 0.9em;
}
.store_content_form_input_info > div > div:nth-of-type(1) {
    font-weight: 600;
    color: rgba(115, 122, 130, 1);
}
.store_content_form_input_info > div > div:nth-of-type(2) {}

@media screen and ( max-width: 700px ) {
    .store_bottom_btn_box {
        position: fixed;
        width: 100%;
    }
    .store_product_detail_header {
        position: fixed;
        z-index: 100;
    }
    .store_product_detail_content {
        padding-top: 50px;
    }
}
@media screen and ( max-width: 500px ) {
    .store_bottom_btn_box {
        width: 100%;
    }
    .store_bottom_btn_left button {
        padding: 10px;
    }
    .store_bottom_btn_middle button {
        padding: 10px;
    }
    .store_bottom_btn_right button {
        /* padding: 10px 80px; */
        font-size: 0.9em;
    }
    /* .store_item_list_img_box {
        height: 155px;
    }
    .store_item_list_img_box img {
        height: 155px;
    } */
    .store_item_list_productname, .store_item_list_discount_price, .store_item_list_price {
        font-size: 0.9em;
    }
    .store_item_list_discount_price {
        padding-right: 0.5em;
    }
    .store_order_product_title {
        font-size: 0.9em;
        display: flex;
        justify-content: end;
    }
    .store_order_product_title>span:nth-of-type(1) {
        display: block;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
    }
}
@media screen and ( max-width: 400px ) {
    .store_final_option_select_box {
        height: 140px;
    }
    .store_total_price_box span {
        font-size: 1.1em;
    }
    .store_delivery_shipping_fee_box {
        font-size: 0.9em;
    }
    .store_delivery_fee_box {
        font-size: 0.9em;
    }
    .store_cart_buy_btn_box button {
        height: 50px;
        font-size: 0.9em;
    }
    .store_total_price_box {
        padding-top: 0.25rem !important;
    }
}
@media screen and ( max-width: 380px ) {
    /* .store_item_heart_box {
        top: 48%;
    } */
}
@media screen and ( max-width: 340px ) {
    /* .store_item_heart_box {
        top: 45%;
    } */
}
@media screen and ( max-width: 300px ) {
    /* .store_item_heart_box {
        top: 40%;
    } */
}