button {
    color: #222;
}
.dailyduck_my {
    background-color: rgba(248, 248, 248, 1);
    height: 100%;
    width: 100%;
}
.user_info {
    align-items: center;
    cursor: pointer;
}
.user_info_img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
}
.user_info_img img {
    width: 100%;
    height: 60px;
    object-fit: cover;
}
.user_info_con {
    width: 40%;
    font-size: 0.9em;
    padding-left: 10px;
}
.user_info div:nth-of-type(3) {
    width: 45%;
    text-align: right;
}
.button_box {
    background-color: white;
    border-radius: 12px;
    box-shadow: 2px 2px 4px 3px #f2f2f2;
}
.button_box button {
    border: none;
    background-color: inherit;
}
.button_box img {
    width: 30px;
    height: 30px;
}
.recommender {
    background-color: white;
    box-shadow: 2px 2px 4px 3px #f2f2f2;
    
}
.recommender button {
    border: none;
    background-color: inherit;
}
.recommender_user {
    color: rgba(255, 3, 102, 1);
    text-decoration: underline;
}
.my_notice_btnBox button {
    text-align: left;
    width: 100%;
    border: none;
    background-color: inherit;
    height: 60px;
    transition: all 0.3s;
}
.my_notice_btnBox button:hover {
    color: rgba(255, 3, 102, 1);
}
.my_notice_btnBox button:active {
    color: rgba(255, 3, 102, 1);
}
.my_app_version {
    height: 100px;
    background-color: inherit;
    margin-bottom: 60px;
}
.myinfo_container {
    /* position: absolute;
    top: 0; */
    width: 100%;
    /* height: 100%; */
}
.myinfo_box {
    width: 100%;
    /* height: 100%; */
}
.display_none {
    display: none;
}
.myinfo_input_box {
    width: 100%;
    height: 300px;
    padding: 30px;
}
.my_info_header {
    position: relative;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}
.my_info_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.my_info_header>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.my_info_header>div:nth-of-type(2) {
    font-size: 1.1em;
}
.my_info_content {
    width: 100%;
    height: 90vh;
    overflow-y: scroll;
    /* margin-top: 40px; */
}
.user_img_box button {
    border: none;
    background-color: inherit;
    color: rgba(255, 3, 102, 1);
}
.user_img_box img {
    border-radius: 50%;
}
.nick_name_btn_box {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
}
.nick_name_btn_box>div:nth-of-type(1) {width: 100%;}
.nick_name_btn_box>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.nick_name_btn_box>div:nth-of-type(2) button {
    width: 100%;
    border-radius: 8px;
    border: none;
    padding: 5px 10px;
    font-size: 0.9em;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    
}
.nick_name_change_text {
    font-size: 0.9em;
    padding-top: 5px;
}
.nick_name_change_btn_box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.nick_name_change_btn_box>div:nth-of-type(1) {width: 50%; margin: 10px 5px}
.nick_name_change_btn_box>div:nth-of-type(2) {width: 50%; margin: 10px 5px}
.nick_name_change_btn_box>div:nth-of-type(1) button,
.nick_name_change_btn_box>div:nth-of-type(2) button {
    width: 100%;
    border: none;
    height: 50px;
    border-radius: 8px;
}
.nick_name_change_btn_active {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.nick_name_check_txt {
    font-size: 0.9em;
    padding: 5px 0;
}
.email_btn_box {
    width: 100%;
    position: relative;
}
.email_btn_box div:nth-of-type(1) {
    width: 100%;
}
.email_btn_box div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.email_btn_box button {
    width: 100%;
    border-radius: 8px;
    border: none;
    padding: 5px 10px;
    font-size: 0.9em;
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.email_btn_box2 button {
    width: 100%;
    height: 50px;
    border-radius: 12px;
    border: none;
}
.email_auth_active {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.loading_page {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.1);
    top: 0;
    left: 0;
    z-index: 9999;
}
.loading_page>div {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.loading_page>div img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}
.email_auth_info_resend {
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
.email_auth_info_resend>div:nth-of-type(2) {
    padding-left: 10px;
}
.email_auth_info_resend>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
    color: rgba(255, 3, 102, 1);
    text-decoration: underline;
    font-weight: 500;
    font-size: 1.1em;
    height: 20px;
}
.email_auth_info_spam_text {
    font-size: 0.9em;
    color: rgba(114, 147, 255, 1);
}
.password_btn_box {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
}
.password_btn_box div:nth-of-type(1) {
    width: 100%;
}
.password_btn_box div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.password_btn_box button {
    width: 100%;
    border-radius: 8px;
    border: none;
    padding: 5px 10px;
    font-size: 0.9em;
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.password_btn_box>div:nth-of-type(1)>input {
    padding-right: 80px;
}
.pwChange_btnBox div {
    width: 100%;
}
.pwChange_btnBox button {
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 12px;
}
.myinfo_w20 {
    width: 20% !important;
}
.myinfo_w30 {
    width: 30% !important;
}
.marginB {
    margin-bottom: 25px;
}
.adress_box {
    width: 100%;
}
.adress_box button{
    width: 100%;
    height: 60px;
    border-radius: 8px;
    border: none;
}
.logout_remove_btnBox {
    /* height: 130px; */
    /* margin-bottom: 80px; */
    background-color: white;
}
.logout_remove_btnBox button {
    width: 100%;
    background-color: inherit;
    border: none;
    text-align: left;
    height: 50px;
}
.post_background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}
.post_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.post_close {
    width: 100%;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    height: 40px;
}
.myfavority_total_box {
    border-radius: 12px;
    box-shadow: none !important;
    border: 1px solid rgba(223, 224, 226, 0.6) !important;
}
.myfavority_img_box {
    /* width: 15%; */
    width: 60px;
    height: auto;
    overflow: hidden;
    border-radius: 50%;
}
.myfavority_img_box img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 500 / 500;
}
.myfavority_name_dday_box {
    width: 55%;
    display: flex;
    padding: 0 10px;
    vertical-align: middle;
}
.myfavority_name_box {
    width: 35%;
    display: flex;
    align-items: center;
    font-weight: 500;
}
.myfavority_dday_box {
    width: 65%;
    display: flex;
    align-items: center;
    font-size: 0.9em
}
.myfavority_update_box {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center
}
.myfavority_update_box button {
    background-color: inherit;
    border: none;
    color: rgba(115, 122, 130, 1);
    text-decoration: underline;
}
.myfavority_delete_box {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center
}
.myfavority_delete_box button {
    border: none;
    color: white;
    border-radius: 50%;
}
.myfavority_container {
    position: relative;
    width: 100%;
    height: 100%;
}
.my_favority_content {
    width: 100%;
    height: calc(100% - 260px);
    overflow-y: auto;
}
.favority_add_btn {
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 0;
    padding-bottom: 20px;
    overflow: hidden;
}
.favority_add_btn>button {
    width: 100%;
    height: 50px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 8px;
    font-size: 0.9em;
}
.myfavority_box {
    width: 100%;
    height: auto;
}
.my_favority_header {
    position: relative;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.my_favority_info_title {
    width: 100%;
    height: 150px;
}
.my_favority_add_container {
    width: 100%;
    height: 100vh;
}
.my_favority_add_header {
    width: 100%;
    height: 50px;
    position: relative;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.my_favority_add_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.my_favority_add_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.my_favority_add_search_box {
    position: relative;
    width: 100%;
    padding: 20px;
}
.my_favority_add_search_box span {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
    color: rgba(169, 173, 178, 1);
}
.my_favority_add_search_box input {
    width: 100%;
    height: 50px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 8px;
    padding: 0 10px 0 40px;
    font-size: 0.9em;
}
.my_favority_add_search_box input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.my_favority_add_item_box {
    width: 100%;
    max-height: 700px;
    overflow-y: scroll;
    padding: 20px 20px 50px 20px;
}
.my_favority_add_item_box>div>div {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid rgba(223, 224, 226, 1);
    margin: 0 5px 5px 5px;
    padding: 40px 0;
    border-radius: 4px;
}
.my_favority_add_item_box>div>div>div:nth-of-type(1) {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
}
.my_favority_add_item_box>div>div>div:nth-of-type(1) img {
    width: 100%;
    aspect-ratio: 500 / 500;
    object-fit: cover;
}
.list_select {
    border: 1px solid rgba(255, 3, 102, 1) !important;
}
.my_favority_add_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}
.my_favority_add_footer_select_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0;
}
.my_favority_add_footer_select_btn>div:nth-of-type(1) {
    color: rgba(255, 3, 102, 1);
    font-weight: 500;
}
.my_favority_add_footer_select_btn>div:nth-of-type(2) {
    width: 100%;
    padding: 0 20px;
}
.my_favority_add_footer_select_btn>div:nth-of-type(2) button {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.my_favority_add_footer_favority_request {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0;
}
.my_favority_add_footer_favority_request>div {
    width: 100%;
    text-align: center;
    font-size: 0.9em;
    color: rgba(169, 173, 178, 1);
}
.my_favority_add_footer_favority_request>div>div {
    width: 100%;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.my_favority_add_footer_favority_request>div>div:nth-of-type(1),
.my_favority_add_footer_favority_request>div>div:nth-of-type(2) {
    width: 100%;
    background-color: white;
}
.my_favority_add_footer_favority_request>div:nth-of-type(1)>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.my_favority_add_footer_favority_request>div:nth-of-type(1)>div:nth-of-type(2) {
    width: 100%;
}
.my_favority_add_footer_favority_request>div:nth-of-type(1)>div:nth-of-type(2) button {
    border: none;
    width: 100%;
    height: 50px;
    border: 4px;
}
.my_favority_add_request_container {
    width: 100%;
    height: 100%;
}
.my_favority_add_request_box {
    width: 100%;
    height: auto;
}
.my_favority_add_request_header {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
}
.my_favority_add_request_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0, -50%);
}
.my_favority_add_request_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.my_favority_add_request_header>div:nth-of-type(3) {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
}
.my_favority_add_request_header>div:nth-of-type(1) button,
.my_favority_add_request_header>div:nth-of-type(3) button {
    border: none;
    background-color: inherit;
}
.my_favority_add_request_content {
    padding: 20px;
}
.my_favority_add_request_footer {
    position: absolute;
    bottom: 20px;
    padding: 0 20px;
    width: 100%;
}
.my_favority_add_request_footer button {
    border: none;
    width: 100%;
    height: 60px;
    border-radius: 4px;
    color: gray;
}
.favority_add_request_container_modal {

}
.favority_add_request_container_modal_title {
    font-size: 1.1em;
    font-weight: 500;
    padding: 20px 0;
}
.favority_add_request_container_modal_btn {
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.favority_add_request_container_modal_btn>button:nth-of-type(1) {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    font-size: 0.9em;
    background-color: inherit;
    border-radius: 4px;
}
.favority_add_request_container_modal_btn>button:nth-of-type(2) {
    width: 100%;
    height: 40px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
    border-radius: 4px;
}
.favority_add_request_container_modal_single_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.favority_add_request_container_modal_single_btn button {
    width: 100%;
    height: 40px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
}

.my_favority_data_container {
    width: 100%;
    height: auto;
    overflow-y: scroll;
    padding: 0 20px;
}
.my_favority_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.my_favority_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.myinfo_phone_box {
    width: 100%;
}
.phone_number_box {
    position: relative;
    width: 80%;
}
.phone_number_box>span {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
}
.phone_number_box>input {
    padding-left: 45px;
}
.myinfo_phone_btn_box {
    width: 20%;
}
.myinfo_phone_btn_box>button {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
}
.myinfo_authnumber_btn_active {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.myinfo_authNumber_re {
  border: none;
  background-color: inherit;
  color: rgba(255, 3, 102, 1);
  text-decoration: underline;
  font-weight: bold;
}
.favority_dday_background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
}
.favority_dday_container {
    position: absolute;
    width: 100%;
    height: 40%;
    bottom: 0;
    background-color: white;
    z-index: 1000;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}
.favority_dday_add_container {
    width: 100%;
    height: 100vh;
}
.favority_dday_add_header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    overflow: hidden;
}
.favority_dday_add_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.favority_dday_add_header>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.favority_dday_add_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.favotiry_dday_add_selecteditem_box {
    width: 100%;
    max-height: 700px;
    overflow-y: scroll;
}
.favority_dday_add_footer {
    position: absolute;
    bottom: 20px;
    width: 100%;
    padding: 0 20px;
}
.favority_update_title {
    font-size: 1.4em;
}
.favority_update_close_btn {
    border: none;
    background-color: inherit;
}
.favority_dday_update_input {
    width: 100%;
    height: 60px;
    padding-left: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
}
.favority_dday_update_input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.favority_dday_update_text {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0, -50%);
    color: rgba(255, 3, 102, 1);
}
.favority_update_btn>button {
    width: 100%;
    height: 60px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 12px;
}
.toast_container_bottom_center {
    bottom: 60px !important;
    border: 1px solid red;
}
.favority_delete_confirm {
    width: 100% !important;
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
    height: 50px;
}
.favority_delete_cancle {
width: 100% !important;
background-color: rgba(223, 224, 226, 1) !important;
color: black !important;
height: 50px;
}
.order_date_select, .order_state_select {
    height: 50px;
}
.order_date_select select, .order_state_select select {
    height: 100%;
    padding-left: 10px;
}
.order_date_select select:optional {
    text-align: center;
}
.orderButtonClass {
    border: none;
    background-color: inherit;
}
.reservationButtonClass {
    border: none;
    background-color: inherit;
}
.click_active {
    border-bottom: 3px solid rgba(255, 3, 102, 1) !important;
}
.myorderdelivery_container {
    /* position: relative; */
    width: 100%;
    /* margin-bottom: 6em !important; */
}
.order_img_box {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 8px;
}
.order_img_box>img {
    width: 100%;
    height: 120px;
    object-fit: cover;
}
.order_product_info_box {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.order_product_quantity_cnt {
    font-size: 0.9em;
    color: rgba(115, 122, 130, 0.5);
}
.order_product_option_info {
    width: 100%;
    height: 30px;
    overflow-y: scroll;
}
.order_product_option_info::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 5px;
}
.order_product_option_info::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.order_product_option_info::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}

.myorderdetail_container {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(248, 248, 248, 1);
    top: 0;
}
.my_order_delivery_header {
    width: 100%;
}
.my_order_delivery_header>div:nth-of-type(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
}
.my_order_delivery_header>div:nth-of-type(1)>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.my_order_delivery_header>div:nth-of-type(1)>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.my_order_delivery_header>div:nth-of-type(1)>div:nth-of-type(2) {
    font-size: 1.1em;
}
.my_order_delivery_tap {
    padding-top: 10px;
    font-size: 1.05em;
}
.my_order_delivery_detail_content_box {
    width: 100%;
    height: calc(100vh - 50px - 100px);
    overflow-y: scroll;
}
.order_delivery_detail_content_date_box {
    font-size: 0.9em;
}
.my_order_exchange_content {
    width: 100%;
    height: 85vh;
    overflow-y: scroll;
}
.myorderdetail_box {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: white;
}
.myorderdetail_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
}
.myorderdetail_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.myorderdetail_header>div:nth-of-type(2) {
    font-size: 1.1em;
}
.my_order_detail_content_box {
    width: 100%;
    height: 90vh;
    overflow-y: scroll;
}
.my_order_detail_content_order_number {
    width: 100%;
    font-size: 0.9em;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.my_order_detail_content_order_number>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 0 20px;
}
.my_order_detail_content_order_number>div>div:nth-of-type(1) {color: rgba(255, 114, 153, 1);}
.my_order_detail_content_order_number>div>div:nth-of-type(2) {color: rgba(169, 173, 178, 1);}
.my_order_detail_content_payment_toggle_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background-color: inherit;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding: 5px 0;
}
.my_order_detail_content_payment_info {
    width: 100%;
    font-size: 0.9em;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.my_order_detail_content_payment_info>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
}
.my_order_detail_box {
    padding: 20px;
}
.my_order_detail_img {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 12px;
}
.my_order_detail_img>img {
    width: 100%;
    height: 120px;
    object-fit: cover;
}
.my_order_detail_info {
    width: calc(100% - 120px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 10px;
}
.my_order_detail_info>div:nth-of-type(1) {
    font-size: 0.8em;
    color: rgba(169, 173, 178, 1);
}
.my_order_detail_info>div:nth-of-type(3) {
    width: 100%;
    height: 30px;
    font-size: 0.8em;
    color: rgba(255, 3, 102, 1);
    overflow-y: scroll;
}
.my_order_detail_info>div:nth-of-type(3)::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 5px;
}
.my_order_detail_info>div:nth-of-type(3)::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.my_order_detail_info>div:nth-of-type(3)::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.my_order_detail_btn_box{
    width: 100%;
}
.my_order_detail_exchange {
    width: 100%;
}
.my_order_detail_exchange>button {
    width: 90%;
    height: 55px;
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    border-radius: 8px;
}
.my_order_detail_delivery_change {
    width: 100%;
}
.my_order_detail_delivery_change>button {
    width: 90%;
    height: 55px;
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    border-radius: 8px;
}
.my_order_detaile_title {
    width: 20%;
}
.my_order_detaile_content {
    width: 80%;
}
.payment_content_form_select {
    position: absolute;
    top: 20px;
    left: 80px;
}
.payment_content_form_select button {
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
    padding: 3px 7px;
    border-radius: 4px;
}
.payment_content_form_container {
    width: 100%;
    height: 100svh;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
}
.payment_content_form_box {
    width: 80%;
    height: auto;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
}
.payment_content_form_header {
    width: 100%;
    position: relative;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 4px 1px rgba(169, 173, 178, 1);
}
.payment_content_form_header>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.payment_content_form_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
}
.payment_content_form_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.payment_content_form_content {
    width: 100%;
    padding: 10px;
}
.payment_content_form_content_item {
    padding: 10px;
    box-shadow: 0 0 4px 1px rgba(196, 199, 202, 1);
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 0.9em;
}
.payment_content_form_content_item>div:nth-of-type(1) {
    border-bottom: 1px solid rgba(114,147,255, 0.4);
}
.payment_content_form_content_item>div:nth-of-type(2) {
    color: rgba(255, 114, 153, 1);
    font-weight: 500;
}
.my_order_delivery_check {
    width: 95%;
    height: 55px;
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    border-radius: 8px;
    font-size: 0.9em;
}
.my_order_review_check {
    width: 95%;
    height: 55px;
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    border-radius: 8px;
    color: rgba(223, 224, 226, 1);
}
.my_order_delivery_confirm{
    width: 95%;
    height: 55px;
    border: 1px solid rgba(255, 3, 102, 1);
    background-color: inherit;
    border-radius: 8px;
    color: rgba(255, 3, 102, 1);
    font-size: 0.9em;
}
.my_order_review_confirm{
    width: 95%;
    height: 55px;
    border: 1px solid rgba(255, 3, 102, 1) !important;
    background-color: inherit;
    border-radius: 8px;
    color: rgba(255, 3, 102, 1);
}
.my_order_review_check{
    width: 95%;
    height: 55px;
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    border-radius: 8px;
    color: rgba(223, 224, 226, 1);
}
.my_order_retrun_refuse_btn {
    position: absolute;
    top: 100px;
    right: 20px;
}
.my_order_retrun_refuse_btn button {
    border: 1px solid rgba(255, 114, 153, 1);
    padding: 5px 10px;
    border-radius: 4px;
    background-color: inherit;
    font-size: 0.9em;
    color: rgba(255, 114, 153, 1);
    transition: all 0.2s;
    margin-top: 2px;
}
.my_order_retrun_refuse_btn button:hover,
.my_order_retrun_refuse_btn button:active {
    border: 1px solid rgb(114, 147, 255);
    color: rgba(114,147,255, 1);
}
.my_order_return_refuse_modal {
    padding: 20px;
    font-size: 0.9em;
    color: rgba(115, 122, 130, 0.5);
}
.my_order_delivery_tracking_modal_container {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.my_order_delivery_tracking_modal_box {
    width: 60%;
    background-color: white;
    border-radius: 6px;
    height: 50%;
    overflow: hidden;
}
.my_order_delivery_tracking_modal_header {
    position: relative;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}
.my_order_delivery_tracking_modal_header>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.my_order_delivery_tracking_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
}
.my_order_delivery_tracking_modal_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.my_order_delivery_tracking_modal_content {
    width: 100%;
    height: calc(100% - 50px);
    overflow-y: auto;
}
.my_order_delivery_tracking_modal_content_top {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 0.6);
    font-size: 1.2em;
    font-weight: 200;
}
.my_order_delivery_tracking_modal_content_top > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.my_order_delivery_tracking_modal_content_middle {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid rgba(223, 224, 226, 0.6);
}
.my_order_delivery_tracking_modal_content_middle>div {
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 0.6);
    padding: 10px;
    border-radius: 4px;
    font-size: 0.9em;
    width: 100%;
}
.my_order_delivery_tracking_modal_content_middle>div>div:nth-of-type(1) {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 5px;
}
.my_order_delivery_tracking_modal_content_middle>div>div:nth-of-type(1)>div:nth-of-type(1) {
    width: 30%;
}
.my_order_delivery_tracking_modal_content_middle>div>div:nth-of-type(1)>div:nth-of-type(2) {
    width: 70%;
}
.my_order_delivery_tracking_modal_content_middle>div>div:nth-of-type(2) {
    width: 100%;
    display: flex;
    align-items: center;
}
.my_order_delivery_tracking_modal_content_middle>div>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 30%;
}
.my_order_delivery_tracking_modal_content_middle>div>div:nth-of-type(2)>div:nth-of-type(2) {
    width: 70%;
}
.my_order_delivery_tracking_bold {
    color: rgba(255, 114, 153, 1);
    font-weight: 700;
}
.my_order_delivery_tracking_modal_content_bottom {
    padding: 20px;
}
.my_order_delivery_tracking_modal_content_bottom_item {
    width: 100%;
    display: flex;
    padding: 5px 0;
    border-bottom: 1px solid rgba(255, 114, 153, 0.3);
    font-size: 0.9em;
}
.first_item {
    color: rgba(255, 3, 102, 1);
    font-weight: 500;
}
.other_items {
    color: rgba(169, 173, 178, 1);
}
.my_order_delivery_tracking_modal_content_bottom_item>div:nth-of-type(1) {
    width: 10%;
}
.my_order_delivery_tracking_modal_content_bottom_item>div:nth-of-type(2) {
    width: 90%;
}
.tracking_modal_content_bottom_location_date_state {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9em;
    padding: 5px 0;
}

.myexchange_container {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: white;
    /* top: 0; */
}
.myexchangedetail_box {
    width: 100%;
    height: 100%;
}
.my_exchange_img {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 12px;
    text-align: center;
}
.my_exchange_img>img {
    width: 100%;
    height: 120px;
    object-fit: cover;
}
.myexchange_btn {
    position: absolute;
    bottom: 60px;
    width: 100%;
}
.myexchange_btn>button {
    width: 100%;
    height: 60px;
    border: none;
    border-radius: 8px;
    color: rgba(223, 224, 226, 1);
}
.exchange_select_box {
    width: 100%;
}
.exchange_select {
    width: 100%;
    height: 50px;
}
.myrefund_container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    /* top: 0; */
}
.myrefund_box {
    width: 100%;
    height: 100%;
}
.myrefund_btn {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: white;
    /* margin-bottom: 100px; */
}
.my_refund_header {
    position: relative;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    justify-content: center;
    align-items: center;
}
.my_refund_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.my_refund_header>div:nth-of-type(2) {
    font-size: 1.1em;
}
.my_refund_method_box {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.my_refund_method_box input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.my_refund_method_box input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}

.my_refund_method_box input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}

.my_refund_method_box input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}
.my_refund_method_box>div:nth-of-type(2) {
    font-size: 0.9em;
}
.my_refund_method_box>div:nth-of-type(2)>div {
    display: flex;
    align-items: center;
    vertical-align: middle;
}
.my_refund_method_seller_refund {
    border: 1px solid rgba(223, 224, 226, 1);
    padding: 10px;
    border-radius: 4px;
}
.my_refund_method_seller_refund>div:nth-of-type(1) {
    font-size: 0.9em;
    padding: 5px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.my_refund_method_seller_refund>div:nth-of-type(1)>div:nth-of-type(2) {
    color: rgba(255, 3, 102, 1);
    font-weight: bold;
    font-size: 0.9em;
}
.my_refund_method_info_box {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 5px 0;
    font-size: 0.9em;
}
.my_refund_method_info_box>div:nth-of-type(1) {width: 30%; color: rgba(115, 122, 130, 1);}
.my_refund_method_info_box>div:nth-of-type(2) {width: 70%;}
.refund_method_delivery_select {
    width: 100%;
    height: 40px;
}
.refund_method_delivery_select :global(.ant-select-selector) {
    border: 1px solid rgba(169, 173, 178, 1) !important;
    border-radius: 4px !important;
}
.refund_method_delivery_select :global(.ant-select-selection-placeholder) {
    font-size: 0.8em;
}
.my_refund_method_buyer_refund {
    border: 1px solid rgba(223, 224, 226, 1);
    padding: 10px;
    border-radius: 4px;
}
.my_refund_method_buyer_refund>div:nth-of-type(1) {
    font-size: 0.9em;
    padding: 5px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.my_refund_method_buyer_refund>div:nth-of-type(1)>div:nth-of-type(2) {
    color: rgba(255, 3, 102, 1);
    font-weight: bold;
    font-size: 0.9em;
}
.my_refund_method_buyer_refund>div:nth-of-type(2) {
    padding: 5px 0;
}
.my_refund_method_buyer_refund>div:nth-of-type(2)>div:nth-of-type(2) {
    width: 100%;
}
.my_refund_method_buyer_refund>div:nth-of-type(2)>div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding-left: 10px;
    font-size: 0.9em;
}
.my_refund_method_buyer_refund>div:nth-of-type(3) {
    width: 100%;
    font-size: 0.9em;
    padding: 10px 0;
}
.my_refund_method_buyer_refund>div:nth-of-type(3)>div:nth-of-type(1) {
    display: flex;
    align-items: center;
}
.my_refund_method_buyer_refund>div:nth-of-type(3)>div:nth-of-type(1)>div:nth-of-type(1) {width: 20%;}
.my_refund_method_buyer_refund>div:nth-of-type(3)>div:nth-of-type(1)>div:nth-of-type(2) {width: 80%; text-align: right;}
.my_refund_method_buyer_refund>div:nth-of-type(3)>div:nth-of-type(2) {
    width: 100%;
    padding: 5px 0;
}
.my_refund_method_buyer_refund>div:nth-of-type(3)>div:nth-of-type(2) button {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    border-radius: 4px;
    font-size: 0.9em;
}

.myrefund_btn button {
    width: 100%;
    height: 60px;
    border: none;
    border-radius: 8px;
    color: rgba(223, 224, 226, 1);
    font-size: 0.9em;
}
.my_return_btn_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color : white !important;
}
.my_reservation_detail_container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 500;
    top: 0;
    left: 0;
}
.my_reservation_detail_box {
    width: 100%;
    height: auto;
    background-color: white;
}
.my_reservation_detail_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.my_reservation_detail_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.my_reservation_detail_header>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.my_reservation_detail_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.my_reservation_detail_content {
    width: 100%;
    height: calc(100vh - 50px);
    overflow-y: auto;
    padding: 20px;
}
.my_reservation_detail_content_reservation_info {
    width: 100%;
}
.my_reservation_detail_content_reservation_info>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
    border-bottom: 1px solid rgba(223, 224, 226, 0.6);
    padding-bottom: 2px;
}
.my_reservation_content_detail_info_box {
    width: 100%;
    display: flex;
    /* align-items: center; */
    padding: 10px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 0.6);
}
.my_reservation_detail_img {
    width: 25%;
    overflow: hidden;
    border-radius: 12px;
}
.my_reservation_detail_img>img {
    width: 100%;
    aspect-ratio: 500 / 500;
    object-fit: cover;
    border-radius: 12px;
}
.my_reservation_detail_info {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 10px;
}
.my_reservation_content_detail_person_box {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 0.6);
}
.my_reservation_content_detail_person_box>div:nth-of-type(1) {
    font-weight: 500;
    padding-bottom: 5px;
}
.my_reservation_content_detail_person_box>div:nth-of-type(2) {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 0.6);
    padding: 5px;
    border-radius: 4px;
}
.my_reservation_content_detail_person_box>div:nth-of-type(2)>div {
    display: flex;
    align-items: center;
}
.my_reservation_content_detail_person_box>div:nth-of-type(2)>div>div {
    padding: 5px 0;
}
.my_reservation_date_title {
    width: 25%;
}
.my_reservation_date_content {
    width: 75%;
}
.my_reservation_info_title {
    width: 25%;
}
.my_reservation_info_content {
    width: 75%;
}
.my_reservation_add_info_title {
    width: 25%;
}
.my_reservation_add_info_content {
    width: 75%;
}
.my_reservation_content_detail_date_box {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 0.6);
}
.my_reservation_content_detail_date_box>div:nth-of-type(1) {
    font-weight: 500;
    padding-bottom: 5px;
}
.my_reservation_content_detail_date_box>div:nth-of-type(2) {
    display: flex;
    align-items: center;
}
.my_reservation_content_detail_date_box>div:nth-of-type(2)>div:nth-of-type(2) {
    color: rgba(255, 114, 153, 1);
    font-weight: 600;
}
.my_reservation_option_content {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 0.6);
}
.my_reservation_option_box {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 0.6);
    padding: 10px 5px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 0.9em;
}
.my_reservation_option_content>div:nth-of-type(1) {
    font-weight: 500;
}
.my_reservation_no_option {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid rgba(223, 224, 226, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}
.my_reservation_payment_info {
    padding-top: 10px;
}
.my_reservation_payment_info_btn {
    width: 100%;
}
.my_reservation_payment_info_btn button {
    border: none;
    background-color: inherit;
    width: 100%;
    display: flex;
    justify-content: center;
    justify-content: space-between;
    font-weight: 500;
}
.my_reservation_payment_info_box {
    width: 100%;
}
.my_reservation_payment_info_box>div:nth-of-type(1) {
    width: 100%;
    border-bottom: 1px solid rgba(223, 224, 226, 0.6);
}
.my_reservation_payment_info_box>div:nth-of-type(1)>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    font-size: 0.9em;
}
.my_reservation_payment_info_box>div:nth-of-type(2) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
}
.my_reservation_payment_info_box>div:nth-of-type(2)>div:nth-of-type(2) {
    color: rgba(255, 3, 102, 1);
    font-weight: 500;
}
.gray_color {
    color: rgba(115, 122, 130, 1);
}
.font_size_09 {
    font-size: 0.9em;
}

.my_order_cancel_container {
    width: 100%;
    background-color: white;
}
.my_order_cancel_header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
}
.my_order_cancel_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.my_order_cancel_header>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.my_order_cancel_header>div:nth-of-type(2) {
    font-size: 1.1em;
}
.my_order_cancel_content {
    width: 100%;
    height: 85vh;
    overflow-y: scroll;
}
.my_order_cancel_content_product_info {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding: 20px;
}
.my_order_cancel_content_product_info>div:nth-of-type(1) {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 8px;
}
.my_order_cancel_content_product_info>div:nth-of-type(1) img {
    width: 100%;
    height: 120px;
    object-fit: cover;
}
.my_order_cancel_content_product_info>div:nth-of-type(2) {
    width: calc(100% - 120px);
    padding: 0 5px;
    font-size: 0.9em;
}
.my_order_cancel_content_product_info>div:nth-of-type(2)>div:nth-of-type(3) {
    width: 100%;
    height: 30px;
    font-size: 0.8em;
    color: rgba(196, 199, 202, 1);
    overflow-y: scroll;
}
.my_order_cancel_content_product_info>div:nth-of-type(2)>div:nth-of-type(3)::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 5px;
}
.my_order_cancel_content_product_info>div:nth-of-type(2)>div:nth-of-type(3)::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}
.my_order_cancel_content_product_info>div:nth-of-type(2)>div:nth-of-type(3)::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.my_order_cancel_content_cancellation {
    width: 100%;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding: 20px 20px 40px 20px;
}
.my_order_cancel_content_cancellation>div:nth-of-type(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}
.my_order_cancel_content_cancellation>div:nth-of-type(2) button {
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    font-size: 0.9em;
    padding: 5px 10px;
    border-radius: 4px;
}
.my_order_cancel_content_cancellation_active {
    border: 1px solid rgba(255, 3, 102, 1) !important;
    color: rgba(255, 3, 102, 1) !important;
}
.my_order_cancellation_box {
    width: 100%;
    height: 40px;
    padding: 20px 0;
}
.my_order_cancellation_box button {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    font-size: 0.9em;
    border-radius: 4px;
}
.my_order_cancellation_box input {
    border: 1px solid rgba(223, 224, 226, 1);
    width: 100%;
    height: 40px;
    border-radius: 4px;
    font-size: 0.9em;
    padding: 0 10px;
}
.my_order_cancellation_box input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.my_order_cancel_payment_info_box {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.my_order_cancel_payment_info_drop_down {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: inherit;
    border: none;
    padding: 5px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.my_order_cancel_payment_info_drop_down_info {
    width: 100%;
    font-size: 0.9em;
}
.my_order_cancel_payment_info_drop_down_info>div:nth-of-type(1) {
    width: 100%;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding: 5px 0;
}
.my_order_cancel_payment_info_drop_down_info>div:nth-of-type(2) {
    padding: 5px 0;
    font-weight: bold;
    color: rgba(255, 3, 102, 1);
}
.my_order_cancel_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    padding: 20px;
}
.my_order_cancel_footer button {
    border: none;
    width: 100%;
    height: 50px;
    border-radius: 4px;
    font-size: 0.9em;
}
.my_order_cancel_btn_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}
.my_order_cancel_payment_refund_method {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.my_order_cancel_precautions {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.my_order_cancel_precautions>div {
    width: 100%;
    padding: 5px 0;
}
.my_order_cancel_precautions button {
    border: none;
    background-color: inherit;
    font-size: 0.9em;
    transition: all 0.2s;
}
.my_order_cancel_precautions button:hover,
.my_order_cancel_precautions button:active {
    color: rgba(255, 3, 102, 1);
} 
.my_order_cancel_precautions_agree {
    display: flex;
    align-items: center;
    padding: 20px;
}
.my_order_cancel_precautions_agree input[type="checkbox"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #999;
    border-radius: 4px;
    background: white;
    position: relative;
}
  
.my_order_cancel_precautions_agree input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.my_order_cancel_precautions_agree input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}

.my_order_cancel_precautions_agree input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.my_order_cancel_precautions_agree>div:nth-of-type(2) {
    padding-left: 5px;
}
.my_order_bottom_modal {
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100vh;
}
.my_order_bottom_modal_container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50vh;
    background-color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.my_order_bottom_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.my_order_bottom_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 20px;
    right: 20px;
}
.my_order_bottom_modal_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.my_order_bottom_modal_content {
    width: 100%;
}
.my_order_bottom_modal_content>div {
    width: 100%;
    padding: 5px 20px;
}
.my_order_bottom_modal_content>div button {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    background-color: inherit;
    font-size: 0.9em;
    transition: all 0.2s;
}
.my_order_bottom_modal_content>div button:hover,
.my_order_bottom_modal_content>div button:active {
    border: 1px solid rgba(255, 3, 102, 1) !important;
}
.my_order_bottom_modal_content_active {
    border: 1px solid rgba(255, 3, 102, 1) !important;
    color : rgba(255, 3, 102, 1) !important;
}

.order_cancel_swal_popup {
    
}
.order_cancel_title {
    padding: 20px 0;
    
}
.order_cancel_sub_title {
    font-size: 0.8em;
    color: rgba(255, 114, 153, 1);
}
.order_cancel_btn_box {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100%;
}
.order_cancel_btn_box>button:nth-of-type(1) {
    width: 45%;
    border: 1px solid rgba(223, 224, 226, 1);
    height: 50px;
    background-color: inherit;
    border-radius: 8px;
    font-size: 0.9em;

}
.order_cancel_btn_box>button:nth-of-type(2) {
    width: 45%;
    border: 1px solid rgba(223, 224, 226, 1);
    height: 50px;
    background-color: inherit;
    border-radius: 8px;
    font-size: 0.9em;

}
.order_cancel_confirm_btn_box {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100%;
    padding: 20px 0 !important;
}
.order_cancel_confirm_btn_box button {
    border: none;
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
    font-size: 0.9em !important;
    width: 100%;
    height: 50px;
    border-radius: 8px;
}

/* 교환 */
.my_order_exchange_option_box {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.my_order_exchange_option_box>div:nth-of-type(2) {
    padding: 5px 0;
}
.my_order_exchange_additional_payment_amount {
    padding: 20px;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.my_order_exchange_additional_payment_amount_content {
    width: 100%;
    font-size: 0.9em;
}
.my_order_exchange_additional_payment_amount_content>div {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0;
    justify-content: space-between;
}
.my_order_exchange_additional_payment_amount_content>div>div:nth-of-type(1) {}
.my_order_exchange_additional_payment_amount_content>div>div:nth-of-type(2) { display: flex; align-items: center;}
.my_order_exchange_additional_payment_amount_content>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid rgba(255, 114, 153, 0.3);
    overflow: hidden;
}
.my_order_exchange_additional_payment_amount_content>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) img {
    width: 100%;
    object-fit: cover;
}
.my_order_exchange_additional_payment_amount_content>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2) {
    padding-left: 3px;
}
.my_order_exchange_additional_payment_amount_content>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
    display: flex;
    align-items: center;
}
.my_order_exchange_additional_payment_amount_content>div:nth-of-type(2) button span {
    font-size: 1.1em;
}
.my_order_exchange_additional_payment_amount_footer {
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    border-radius: 8px;
    padding: 10px;
}
.my_order_exchange_additional_payment_amount_footer>div:nth-of-type(2),
.my_order_exchange_additional_payment_amount_add {
    display: flex;
    align-items: center;
    font-size: 0.8em;
    color: rgba(255, 3, 102, 1);
    font-weight: bold;
    justify-content: space-between;
    padding-top: 5px;
}
.my_order_exchange_additional_payment_amount_total {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    color: rgba(255, 3, 102, 1);
    font-weight: bold;
    justify-content: space-between;
    padding-top: 5px;
    border-top: 1px solid rgba(223, 224, 226, 1);
}
.my_order_exchange_additional_payment_amount_txt {
    padding: 5px 0;
    font-size: 0.8em;
    color: rgba(115, 122, 130, 0.5);
}
.my_order_exchange_additional_payment_amount_seller {
    font-size: 0.9em;
    color: rgba(115, 122, 130, 1);
    padding: 20px 0;
    text-align: center;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}

/* 반품 */
.my_order_return_delivery_payment_info_box {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.my_order_return_delivery_payment_select {
    width: 200px;
    height: 40px;
}
.my_order_return_delivery_price_select_box {
    padding: 20px;
    width: 100%;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.my_order_return_delivery_price_select_title {
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.my_order_return_delivery_price_select_first_option {
    font-size: 0.9em;
    padding-top: 5px;
}
.my_order_return_delivery_price_select_first_option>div:nth-of-type(1) {
    border-bottom: 1px solid rgba(223, 224, 226, 0.6);
    padding-bottom: 5px;
}
.my_order_return_delivery_price_select_first_option>div:nth-of-type(1)>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 0;
}
.my_order_return_delivery_price_select_first_option>div:nth-of-type(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(255, 3, 102, 1);
    font-weight: 500;
    padding-top: 5px;
}
.my_order_return_delivery_price_select_second_option {
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 0.6);
    padding: 10px 20px;
    border-radius: 6px;
    margin-top: 5px;
    font-size: 0.9em;
}
.my_order_return_delivery_price_select_second_option>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.my_order_return_delivery_price_select_second_option>div:nth-of-type(1)>div:nth-of-type(2),
.my_order_return_delivery_price_select_second_option>div:nth-of-type(2)>div:nth-of-type(2) {
    display: flex;
    align-items: center;
}
.my_order_return_delivery_price_select_second_option>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid rgba(223, 224, 226, 0.6);
    overflow: hidden;
}
.my_order_return_delivery_price_select_second_option>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) img {
    width: 100%;
    height: 25px;
    object-fit: cover;
}
.my_order_return_delivery_price_select_second_option>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2) {
    padding-left: 5px;
}
.my_order_return_delivery_price_select_second_option>div:nth-of-type(2)>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
    width: 20px;
    height: 20px;
    overflow: hidden;
}
.my_order_return_delivery_price_select_second_option>div:nth-of-type(2)>div:nth-of-type(2) button>span {
    display: block;
    font-size: 1.2em !important;
}
.my_order_return_delivery_price_select_second_option>div:nth-of-type(4) {
    border-bottom: 1px solid rgba(223, 224, 226, 0.6);
    padding-bottom: 5px;
    color: rgba(114,147,255, 1);
    font-weight: 600;
}
.my_order_return_delivery_price_select_second_option>div:nth-of-type(5) {
    padding-top: 5px;
    color: rgba(255, 3, 102, 1);
    font-weight: 500;
}
.my_order_return_delivery_price_select_third_option {
    padding-top: 5px;
    font-size: 0.9em;
}
.my_order_return_delivery_price_select_third_option>div:nth-of-type(1) {
    border-bottom: 1px solid rgba(223, 224, 226, 0.6);
    padding-bottom: 5px;
}
.my_order_return_delivery_price_select_third_option>div:nth-of-type(1)>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 0;
}
.my_order_return_delivery_price_select_third_option>div:nth-of-type(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    color: rgba(255, 3, 102, 1);
    font-weight: 600;
}

.mycouponporint_container {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 6em !important;
}
.mycouponporint_box {
    width: 100%;
    height: 100%;
}
.my_coupon_point_header {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
}
.my_coupon_point_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.my_coupon_point_header>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.my_coupon_point_header>div:nth-of-type(2) {
    font-size: 1.1em;
}
.my_coupon_point_tap_btn {
    margin-top: 15px;
    font-size: 1.1em;
}
.my_coupon_content_box {
    width: 100%;
    height: 80vh;
    overflow-y: auto;
}
.my_point_history {
    width: 100%;
    height: 50vh;
    overflow-y: auto;
    padding: 20px;
    font-size: 0.9em;
}
.my_point_history_title {
    font-size: 1.1em;
    padding: 5px 20px;
}
.my_point_history_item {
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    margin: 5px 0;
}
.my_point_recharge_content {
    width: 100%;
    height: 85vh;
    overflow-y: auto;
}
.mycouponporint_header {
    position: relative;
    width: 100%;
    background-color: white;
    top: 0;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
}
.mycouponporint_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(-50%, -50%);
}
.mycouponporint_header>div:nth-of-type(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 50px;
    font-size: 1.1em;
}
.my_couponpoint_content {
    margin-top: 20px;
}
.coupon_button_class {
    border: none;
    background-color: inherit;
}
.point_button_class {
    border: none;
    background-color: inherit;
}
.coupon_tap_btn {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 5px;
    padding: 0 10px;
}
.coupon_tap_btn>div {
    margin: 5px;
}
.coupon_tap_btn button {
    border: none;
    /* background-color: rgba(223, 224, 226, 1); */
    font-size: 0.9em;
    padding: 5px 10px;
    border-radius: 30px;
}
.coupon_tap_btn_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}
.coupon_box {
    position: relative;
    width: 300px;
    height: 150px;
    flex-direction: column;
    background-color: white;
    border-radius: 12px;
    box-shadow: 1px 1px 6px 1px #d3d3d3;
    overflow: hidden;
}
.my_coupon_box {
    position: relative;
}
.my_coupon_use_coupon {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(223, 224, 226, 0.6);
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 3, 102, 1);
}
.my_coupon_use_coupon>div {
    width: 100%;
    background-color: white;
    padding: 5px 10px;
}
/* 리본의 기본 스타일 */
.ribbon {
    position: absolute;
    left: -5px; top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 100px;
    height: 35px;
    text-align: center;
    transform: rotate(-45deg);
    display: flex;
    justify-content: center;
    align-items: center;
}
  
  /* 리본의 위치를 조정하는 클래스 */
.ribbon_top_left {
    top: 5px;
    left: -30px;
}


  /* 쿠폰 내용 */
.my_coupon_title {
    width: 100%;
    color: rgba(255, 3, 102, 1);
    font-size: 0.9em;
}
.my_coupon_price {
    width: 80%;
    font-weight: bold;
    font-size: 2em;
}
.my_coupon_content {
    font-size: 0.9em;
}
.my_coupon_content_maximum {
    color: rgba(196, 199, 202, 1);
    font-size: 0.8em;
}
.my_point_box {
    width: 100%;
    background-color: white;
    box-shadow: 1px 1px 5px 2px rgba(223, 224, 226, 1);
    border-radius: 8px;
}
.my_point_content {
    color: rgba(255, 3, 102, 1);
    font-size: 1.3em;
    font-weight: bold;
}
.my_point_footer {
    color: rgba(223, 224, 226, 1);
    font-size: 0.8em;
}
.my_point_btn>button {
    width: 100%;
    height: 60px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 12px;
    font-size: 0.9em;
}
.btn_red_color {
    color: rgba(255, 114, 153, 1);
}
.btn_blue_color {
    color: rgba(114, 147, 255, 1);
}
.my_point_charge_title {
    font-size: 1.2em;
}
.my_point_charge_btn>button {
    border: none;
    border-radius: 25px;
    padding: 8px 12px;
}
.my_activiry_header {
    width: 100%;
    position: relative;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.my_activiry_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0, -50%);
}
.my_activiry_header>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.my_activiry_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.my_activiry_swiper_box {
    width: 100%;
    padding: 0 20px;
}
.my_activiry_swiper :global(.swiper-slide) {
    width: auto !important; /* 버튼의 내용에 따라 크기가 조정되도록 설정 */
    flex: 0 0 auto; /* 플렉스박스 아이템이 줄어들지 않도록 설정 */
}
.my_activity_detail_title {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
}
.my_activity_detail_title button {
    border: none;
    background-color: inherit;
}
.my_activity_detail_category>button {
    width: 80px;
    border: none;
    border-radius: 30px;
    font-size: 0.9em;
    padding: 5px 12px;

}
.my_activity_active {
    background-color: rgba(72, 76, 81, 1);
    color: white;
}
.my_activity_detail_participation {
    width: 100%;
    display: flex;
    background-color: white;
    box-shadow: 1px 1px 3px 2px rgba(223, 224, 226, 1);
    border-radius: 12px;
}
.my_activity_detail_participation_img {
    width: 25%;
    overflow: hidden;
    border-radius: 12px;
}
.my_activity_detail_participation_img img {
    width: 100%;
    border-radius: 12px;
}
.my_activity_detail_participation_content {
    width: 75%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.my_activity_demand_box {
    width: 100%;
}
.my_activity_demand_img {
    width: 25%;
    overflow: hidden;
}
.my_activity_demand_img img {
    width: 100px;
    height: 100px;
    border-radius: 12px;
    object-fit: cover;
}
.my_activity_demand_container {
    width: 100%;
    background-color: white;
    box-shadow: 0 0 4px 3px rgba(223, 224, 226, 1);
    border-radius: 12px;
}
.my_activity_demand_content {
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}
.my_review_container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 6em !important;
}
.my_review_header {
    position: relative;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}
.my_review_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.my_review_header>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.my_review_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.my_review_writable {
    width: 100%;
}
.my_review_writable button {
    width: 100%;
    border: none;
    background-color: inherit;
}
.my_review_written {
    width: 100%;
}
.my_review_written button {
    width: 100%;
    border: none;
    background-color: inherit;
}
.review_active {
    border-bottom: 3px solid rgba(255, 3, 102, 1);
}
.signature_color {
    color: rgba(255, 3, 102, 1);
}
.my_review_category_box button{
    border: none;
    background-color: inherit;
}
.my_review_content_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.my_review_content_left {
    width: 20%;
    overflow: hidden;
    border-radius: 12px;
}
.my_review_content_left img {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    object-fit: cover;
    border-radius: 12px;
}
.my_review_content_middle {
    width: 60%;
}
.my_review_content_middle div {
    overflow:hidden; text-overflow:ellipsis; white-space:nowrap;
}
.my_review_content_middle>div:nth-of-type(1) {font-size: 0.9em; color: rgba(141, 147, 154, 1);}
.my_review_content_middle>div:nth-of-type(2) {}
.my_review_content_middle>div:nth-of-type(3) {font-size: 0.8em;}
.my_review_content_right {
    width: 20%;
    text-align: center;
}
.my_review_content_right button {
    width: 80px;
    height: 35px;
    border: none;
    border-radius: 20px;
    font-size: 0.9em;
    transition: all 0.3s;
}
.my_review_content_right button:hover {
    color: white;
    background-color: rgba(255, 3, 102, 1);
}
.my_review_content_right button:active {
    color: white;
    background-color: rgba(255, 3, 102, 1);
}
.my_review_write_box {
    width: 100%;
    display: flex;
    align-items: center;
    box-shadow: 1px 1px 4px 1px rgba(223, 224, 226, 1);
    border-radius: 12px;
}
.my_review_write_img {
    width: 25%;
    border-radius: 12px;
    overflow: hidden;
}
.my_review_write_img img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
}
.my_review_review_text {
    overflow-y: scroll;
    width: 100%;
    height: 100px;
    word-break: break-all;
}
.my_review_write_content {
    width: 65%;
}
.my_review_write_content div {
    overflow:hidden; text-overflow:ellipsis; white-space:nowrap;
}

.my_review_write_wrap {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
}
  
.ratingText {
    color: #787878;
    font-size: 1em;
    font-weight: 400;
    border-bottom: 1px solid #787878;
    padding-top: 2px;
    margin-left: 10px;
}
  
.my_review_write_stars {
    display: flex;
    justify-content: center;
    padding-top: 5px;
}
  
.my_review_write_stars svg {
    color: #e4e4e4;
    cursor: pointer;
    margin: 0 10px;
}
  
.my_review_write_stars svg:hover {
    color: #fcc419;
}
  
.my_review_write_stars .yellowStar {
    color: #fcc419;
}
  
/* 추가된 CSS: 모든 별 뒤에 있는 별들의 색상을 변경하지 않도록 */
.my_review_write_stars svg:hover ~ svg {
    color: #e4e4e4;
}

.my_review_write_textbox {
    position: relative;
      width: 100%;
    }
.my_review_write_textbox textarea {
    width: 100%;
    border-radius: 12px;
    border: 1px solid rgba(223, 224, 226, 1);
    padding-top: 10px;
    padding-left: 10px;
    font-size: 0.9em;
}
.my_review_write_textbox textarea:focus {
    border: 1px solid rgba(255, 3, 102, 1) !important;
}
.my_review_write_success_btn {
    position: absolute;
    bottom: 20px;
    width: 100%;
    padding: 0 20px;
}
.my_review_write_success_btn button {
    width: 100%;
    height: 60px;
    background-color: rgba(255, 3, 102, 1);
    border: none;
    color: white;
    border-radius: 12px;
    transition: all 0.3s;
}
.my_review_write_success_btn button:hover {
    background-color: rgba(255, 3, 102, 0.8);
    box-shadow: 0 0 5px 1px rgba(115, 122, 130, 0.5);
}
.my_review_written_header {
    width: 100%;
    display: flex;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    align-items: center;
    justify-content: center;
    position: relative;
}
.my_review_written_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.my_review_written_header>div:nth-of-type(2) {font-size: 1.1em;}
.my_support_review_content {
    width: 100%;
    height: 90vh;
    overflow-y: auto;
}
.my_review_written_box {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.my_review_written_left {
    width: 20%;
    overflow: hidden;
    border-radius: 12px;
}
.my_review_written_left img {
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
    aspect-ratio: 1000 / 1000;
}
.my_review_written_middle {
    width: 70%;
}
.my_review_written_middle div {
    overflow:hidden; text-overflow:ellipsis; white-space:nowrap;
}
.my_review_written_middle>div:nth-of-type(1) {font-size: 0.9em; color: rgba(141, 147, 154, 1);}
.my_review_written_middle>div:nth-of-type(2) {}
.my_review_written_middle>div:nth-of-type(3) {font-size: 0.8em; max-height: 40px; overflow-y: auto;}
.my_review_written_middle>div:nth-of-type(4) {font-size: 0.9em;}
.my_review_written_right {
    width: 10%;
}
.my_review_written_right button {
    border: none;
    background-color: inherit;
}
.my_review_written_box {
    width: 100%;
    display: flex;
    align-items: center;
    box-shadow: 1px 1px 6px 1px rgba(223, 224, 226, 1);
    border-radius: 8px;
    padding: 15px;
}
.my_review_written_img {
    width: 25%;
    overflow: hidden;
    border-radius: 12px;
}
.my_review_written_img img {
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
}
.my_review_written_content {
    width: 70%;
}
.my_review_written_content>div:nth-of-type(1) {
    font-size: 0.9em;
    color: rgba(115, 122, 130, 1);
}
.my_review_written_content div {
    overflow:hidden; 
    text-overflow:ellipsis; 
    white-space:nowrap;
}
.my_review_store_written_option {
    width: 100%;
    max-height: 50px;
    overflow-y: scroll;
    font-size: 0.8em;
    color: rgba(115, 122, 130, 1);
}
.mySwiper img {
    width: 100%;
    height: 500px;
    border-radius: 12px;
    object-fit: cover;
}

.my_review_written_wrap {
    display: flex;
    flex-direction: column;
    /* padding-top: 15px; */
}
  
.my_review_written_stars {
    display: flex;
    /* justify-content: center; */
    padding-top: 5px;
}
  
.my_review_written_stars svg {
    color: #e4e4e4;
    cursor: pointer;
    margin: 0 2px;
}
.my_review_non_review {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
}
  
/* .my_review_written_stars svg:hover {
    color: #fcc419;
} */
  
.my_review_written_stars .yellowStar {
    color: #fcc419;
}
  
/* 추가된 CSS: 모든 별 뒤에 있는 별들의 색상을 변경하지 않도록 */
/* .my_review_written_stars svg:hover ~ svg {
    color: #e4e4e4;
} */

.image_preview_overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
.image_preview_modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
.modalSwiper {
    width: 500px;
    text-align: center;
}
.modal_close_btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: inherit;
    color: rgba(255, 3, 102, 1);
}

.my_wishlist_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.my_wishlist_header button {
    border: none;
    background-color: inherit;
}
.my_wishlist_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0, -50%);
}
.my_wishlist_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.my_save_type {
    width: 100%;
    padding-top: 10px;
    display: flex;
    align-items: center;
}
.my_save_type>div {
    width: 100%;
}
.my_save_type button{
    width: 100%;
    border: none;
    background-color: inherit;
    font-size: 1.2em;
}
.my_save_active {
    border-bottom: 3px solid rgba(255, 3, 102, 1);
}
.my_save_stock_cnt_zero_box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 64%;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.my_save_stock_cnt_zero {
    width: 100%;
    height: auto;
    aspect-ratio: 500 / 500;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 500;
}
.my_save_category {
    display: flex;
}
.my_save_category button {
    border: none;
    background-color: inherit;
}
.my_store_heart {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 100;
}
.my_store_heart button {
    width: 25px;
    height: 25px;
    border: none;
    background-color: inherit;
}
.my_store_heart button img {
    width: 100%;
    filter: drop-shadow(0px 0px 2px #666666);
}
.my_support_heart {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 100;
}
.my_support_heart button {
    width: 25px;
    height: 25px;
    border: none;
    background-color: inherit;
}
.my_support_heart button img {
    width: 100%;
    filter: drop-shadow(0px 0px 2px #666666);
}
.my_product_store_box {
    display: block;
    padding: 10px !important;
    transition: all 0.2s;
}
.my_product_store_box:hover,
.my_product_store_box:active {
    background-color: rgba(223, 224, 226, 0.6);
    border-radius: 4px;
}
.my_product_store_store_name {
    font-size: 0.9em;
    color: rgba(169, 173, 178, 1);
}
.my_product_store_name {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 두 줄로 제한 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* 원래는 nowrap을 사용하지만, 여러 줄 처리를 위해 normal로 설정 */
    line-height: 1.2em; /* 줄 높이 설정 */
    max-height: 2.4em; /* 줄 높이와 줄 수에 따라 최대 높이 설정 (예: 줄 높이 1.2em * 줄 수 2 = 2.4em) */
}
.my_product_store_price {
    padding: 5px 0;
    display: flex;
    align-items: center;
}
.my_product_store_img {
    width: 100%;
    height: auto;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
}
.my_product_store_img img {
    width: 100%;
    aspect-ratio: 500 / 500;
    object-fit: cover;
}
.my_store_product_discount_rate {
    font-size: 0.9em;
    color: rgba(255, 3, 102, 1);
}
.my_store_product_total_price {
    color: rgba(255, 3, 102, 1);
    font-weight: bold;
    padding-left: 5px;
}
.my_store_product_sub_price {
    color: rgba(141, 147, 154, 1);
    text-decoration: line-through;
    font-size: 0.8em;
}
.my_product_store_review_box {
    font-size: 0.9em;
}
.my_product_store_review_star img{
    width: 20px;
    height: 20px;
}
.my_product_store_review_score, .my_product_store_review_count {
    color: rgba(91, 97, 103, 1);
}
.my_shop_store_box {
    display: flex;
    width: 100%;
    align-items: center;
}
.my_shop_store_img {
    width: 15%;
    overflow: hidden;
}
.my_shop_store_img img {
    width: 100%;
    border-radius: 50%;
}
.my_shop_store_name {
    width: 70%;
}
.my_shop_store_btn {
    width: 15%;
}
.my_notice_container {
    width: 100%;
    height: 100%;
}
.my_notice_box {
    width: 100%;
    height: auto;
}
.my_notice_header {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
}
.my_notice_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.my_notice_header>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.my_notice_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.my_notice_write_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: white;
}
.my_notice_write_header {
    width: 100%;
    height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
}
.my_notice_write_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.my_notice_write_header>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.my_notice_write_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.my_notice_write_content {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    padding: 20px;
}
.my_notice_write_content>div:nth-of-type(1) {
    font-size: 1.4em;
    font-weight: 600;
    border-bottom: 1px solid rgba(223, 224, 226, 0.6);
}
.my_notice_write_content>div:nth-of-type(2) {
    padding: 10px 0;
    font-size: 0.9em;
    color: rgba(196, 199, 202, 1);
}
.my_notice_write_content>div:nth-of-type(3) {
    width: 100%;
    height: auto;
    overflow: hidden;
}
.my_notice_write_content>div:nth-of-type(3) img {
    width: 100%;
    height: auto;
}
.my_notice_box button {
    border: none;
    background-color: inherit;
}
.my_notice_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.my_notice_info>div:nth-of-type(1) {
    display: flex;
    justify-content: start;
    flex-direction: column;
    text-align: left;
}
.my_notice_info>div:nth-of-type(1)>div:nth-of-type(2) {
    font-size: 0.9em;
    color: rgba(196, 199, 202, 1);
}
.my_notice_none {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.my_customer_service_btn button {
    border: none;
    background-color: inherit;
    width: 100%;
    font-size: 1.1em;
}
.my_customer_service_active {
    border-bottom: 3px solid rgba(255, 3, 102, 1);
}
.my_customer_service_content {
    width: 100%;
    padding: 20px;
}
.my_customner_container {
    position: relative;
    width: 100%;
    height: 100%;
}
.my_customner_box {
    width: 100%;
    height: 100%;
    background-color: white;
}
.my_customner_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.my_customner_header button {
    border: none;
    background-color: inherit;
}
.my_customner_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0, -50%);
}
.my_customner_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.my_customner_tab {
    padding-top: 20px;
}
.my_customer_service_footer_btn {
    position: absolute;
    bottom: 20px;
    padding: 0 20px;
    width: 100%;
}
.my_customer_service_footer_btn button{
    width: 100%;
    height: 60px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    border-radius: 8px;
    color: white;
}
.my_customner_write_container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
}
.my_customner_write_box {
    width: 100%;
    height: 100%;
}
.my_customner_write_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.my_customner_write_header button {
    border: none;
    background-color: inherit;
}
.my_customner_write_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0, -50%);
}
.my_customner_write_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.my_customner_write_content {
    padding: 20px;
}
.my_review_write_text_counter {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: rgba(141, 147, 154, 1);
}
.my_customer_my_box {
    position: relative;
    display: flex;
    width: 100%;
}
.my_customer_my_content {
    width: 100%;
}
.my_customer_my_content button {
    width: 100%;
    text-align: left;
    padding: 12px 0;
    padding-left: 5px;
    border: none;
    background-color: inherit;
}
.my_customer_my_delete {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
}
.my_customer_my_delete button { 
    border: none;
    width: 60px;
    height: 30px;
    border-radius: 20px;
}
.my_customer_my_img_box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.my_customer_my_img_box>div {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 4px;
    margin: 0 10px;
}
.my_customer_my_img_box>div img {
    width: 100%;
    height: 80px;
    object-fit: cover;
}
.my_customer_no_data {
    width: 100%;
    padding: 40px;
    text-align: center;
}

.my_store_entry_container {
    width: 100%;
    height: 100%;
    /* margin-bottom: 6em !important; */
}
.my_store_entry_box {
    width: 100%;
    height: auto;
}
.my_store_entry_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.my_store_entry_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.my_store_entry_header>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.my_store_entry_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.my_store_entry_tab {
    padding: 20px 0 0 0;
    border-bottom: 1px solid rgba(223, 224, 226, 0.6);
}
.my_store_entry_content {
    padding: 20px;
    width: 100%;
    height: auto;
    overflow-y: auto;
}
.my_store_entry_content>div>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 600;
}
.my_store_entry_content>div>ul {
    font-size: 0.9em;
}
.my_entry_box button{
    border : none;
    width: 100%;
    background-color: inherit;
    font-size: 1.1em;
    font-weight: 500;
}
.my_entry_active {
    border-bottom: 3px solid rgba(255, 3, 102, 1);
}
.my_store_entry_list > li {
    list-style-type: disc !important;
}
.my_store_entry_footer {
    position: absolute;
    bottom: 20px;
    width: 100%;
    padding: 0 20px;
}
.my_store_entry_btn {
    width: 100%;
    /* padding-top: 100%; */
}
.my_store_entry_btn button {
    width: 100%;
    height: 60px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 12px;
    font-size: 0.9em;
}
.my_terms_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.my_terms_box {
    width: 100%;
    height: auto;
}
.my_terms_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.my_terms_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.my_terms_header>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.my_terms_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.my_terms_content {
    width: 100%;
    padding: 20px;
}
.my_terms_btn {
    border: none;
    background-color: inherit;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(223, 224, 226, 0.6);
    padding: 10px 0;
}
.my_terms_btn>div:nth-of-type(1) {
    text-align: left;
}
.my_terms_btn>div:nth-of-type(1)>div:nth-of-type(1) {
    font-weight: 500;
}
.my_terms_btn>div:nth-of-type(1)>div:nth-of-type(2) {
    font-size: 0.9em;
    color: rgba(196, 199, 202, 1);
}
.my_terms_detail_container {
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 100;
}
.my_terms_detail_box {
    width: 100%;
    height: auto;
    background-color: white;
}
.my_terms_detail_content {
    width: 100%;
    height: calc(100vh - 50px);
    overflow-y: auto;
    padding: 20px;
}
.my_terms_detail_content>div>div:nth-of-type(1) {
    font-size: 1.3em;
    font-weight: 600;
    border-bottom: 1px solid rgba(223, 224, 226, 0.6);
    padding-bottom: 5px;
}
.my_terms_detail_content>div>div:nth-of-type(2) {
    padding-top: 5px;
}
.my_service_alarm_container {
    width: 100%;
    height: 100%;
}
.my_service_alarm_box {
    width: 100%;
    height: auto;
}
.my_service_alarm_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.my_service_alarm_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.my_service_alarm_header>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.my_service_alarm_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.my_service_alarm_content {
    padding: 20px;
}
.my_service_alarm_content_service {
    width: 100%;
    padding-bottom: 10px;
    /* border-bottom: 3px solid rgba(223, 224, 226, 0.6); */
}
.my_service_alarm_content_service>div:nth-of-type(1) {
    font-size: 1.2em;
    font-weight: 500;
}
.my_service_alarm_content_service>div:nth-of-type(2)>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 0.6);
}
.my_service_alarm_content_activity {
    width: 100%;
    padding-top: 10px;
    /* border-bottom: 3px solid rgba(223, 224, 226, 0.6); */
}
.my_service_alarm_content_activity>div:nth-of-type(1) {
    font-size: 1.2em;
    font-weight: 500;
}
.my_service_alarm_content_activity>div:nth-of-type(2)>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 0.6);
}
.my_service_alarm_content_activity_sub_title {
    font-size: 0.9em;
    color: rgba(114,147,255, 1);
}
.notification_switch {
    position: relative;
    display: inline-block;
    width: 51px;
    height: 26px;
}

.notification_switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}
  
.notification_slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
  
.notification_slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
  
input:checked + .notification_slider {
    background-color: rgba(255, 3, 102, 1);
}
  
input:focus + .notification_slider {
    box-shadow: 0 0 1px rgba(255, 3, 102, 1);
}
  
input:checked + .notification_slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* 소셜 로그인 사용자 */
.my_info_social_box {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    position: relative;
}
.my_info_social_box>div:nth-of-type(1) {
    width: 70%;
}
.my_info_social_box>div:nth-of-type(1)>div:nth-of-type(2) {
    width: 100%;
    font-size: 0.85em;
    display: flex;
    align-items: center;
}
.my_info_social_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(1) {
    color: rgba(255, 3, 102, 1);
}
.my_info_social_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2) {
    padding-left: 8px;
}
.my_info_social_box>div:nth-of-type(1)>div:nth-of-type(2)>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
    color: rgba(114, 147, 255, 1);
    text-decoration: underline;
}
.my_info_social_box>div:nth-of-type(2) {
    width: 30%;
}
.my_info_social_box>div:nth-of-type(2) button {
    width: 100%;
    height: 30px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
    border-radius: 6px;
}
.my_info_social_modal {
    position: absolute;
    width: 80%;
    top: 85%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    background-color: white;
    padding: 20px;
    font-size: 0.9em;
    border-radius: 6px;
}


/* 해외 주소지 */
.my_info_address_btn {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}
.my_info_address_btn>div {
    width: 100%;
    height: 30px;
}
.my_info_address_btn button {
    width: 100%;
    font-size: 0.9em;
    border: none;
    background-color: inherit;
}
.my_info_address_active {
    border-bottom: 2px solid rgba(255, 3, 102, 1);
}
.my_info_address_active_color {
    color: rgba(255, 3, 102, 1);
}
.my_info_international_btn {
    width: 100%;
    padding: 0 1em;
}
.my_info_international_btn button {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    font-size: 0.9em;
}
.my_info_international_modal_container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
}
.my_info_international_modal_box {
    position: absolute;
    width: 90%;
    height: 30%;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    border-radius: 8px;
}
.my_info_international_modal_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    position: relative;
}
.my_info_international_modal_header>div:nth-of-type(1) {}
.my_info_international_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.my_info_international_modal_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.my_info_international_zip_code {
    padding: 0 1em;
    margin-top: 5px;
}
.my_info_international_zip_code>div:nth-of-type(1) {
    font-size: 0.9em;
}
.my_info_international_zip_code input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding: 0 10px;
    font-size: 0.9em;
}
.my_info_international_zip_code input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.my_info_international_modal_search_btn {
    padding: 1em;
}
.my_info_international_modal_search_btn button {
    width: 100%;
    height: 40px;
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    font-size: 0.9em;
    border-radius: 4px;
}
.my_info_international_country_box>div:nth-of-type(1) {
    font-size: 0.9em;
}
.my_info_international_compl_address_container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
}
.my_info_international_compl_address_box {
    position: absolute;
    width: 90%;
    height: 30%;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    border-radius: 8px;
    padding: 10px;
}
.my_info_international_compl_address_zip_code,
.my_info_international_compl_address_basic_address,
.my_info_international_compl_address_detail_address {
    width: 100%;
    font-size: 0.9em;
    margin-top: 5px;
}
.my_info_international_compl_address_zip_code input,
.my_info_international_compl_address_basic_address input,
.my_info_international_compl_address_detail_address input {
    width: 100%;
    border: 1px solid rgba(196, 199, 202, 1);
    height: 40px;
    border-radius: 4px;
    padding: 0 10px;
}
.my_info_international_compl_address_zip_code input:focus,
.my_info_international_compl_address_basic_address input:focus,
.my_info_international_compl_address_detail_address input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.my_info_international_compl_address_btn {
    width: 100%;
    margin-top: 10px;
}
.my_info_international_compl_address_btn button {
    width: 100%;
    height: 40px;
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    border-radius: 4px;
    font-size: 0.9em;
}
  
/* Rounded sliders */
.notification_slider.notification_round {
    border-radius: 34px;
}

.notification_slider.notification_round:before {
    border-radius: 50%;
}

.my_withdraw_container {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 6em !important;
}
.my_withdraw_box {
    width: 100%;
    height: 100%;
}
.my_withdraw_point_box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 0 6px 1px rgba(223, 224, 226, 1);
    border-radius: 8px;
    height: 150px;
}
.my_withdraw_point {
    font-size: 1.3em;
    color: rgba(255, 3, 102, 1);
    font-weight: bold;
}
.my_withdraw_btn button {
    width: 100%;
    height: 60px;
    border: none;
    border-radius: 12px;
}
.my_withdraw_active {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}

.back_hidden {
    overflow-y: auto !important;
    height: 100% !important;
}

.my_order_delivery_detail_active {
    display: none;
    height: auto;
}

/* StartRating.js */
.review_rating_box {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
}

.review_rating_star_box {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    border: none;
    transform: translateY(2px);
}

/* StarInput.js */
.review_star_input {
    display: none;
}
.review_star_label {
    cursor: pointer;
    font-size: 3.5rem;
    color: lightgray;
}
.review_rating_star_box input:checked ~ label, 
.review_rating_star_box label:hover, 
.review_rating_star_box label:hover ~ label {
  transition: 0.2s;
  color: orange;
}

/* 1:1 문의 작성 MySwal */
.inquiry_popup {
    border-radius: 4px !important;
}
.inquiry_popup_title {
    padding: 20px 0 0 0;
}
.inquiry_popup_btn_box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    width: 100%;
}
.inquiry_popup_btn_box button {
    width: 45%;
    height: 40px;
}
.inquiry_popup_btn_box button:nth-of-type(1){
    background-color: inherit;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
}
.inquiry_popup_btn_box button:nth-of-type(2){
    background-color: rgba(255, 114, 153, 1);
    border: none;
    color: white;
    border-radius: 4px;
}
.inquiry_popup_confirm_btn_box {
    display: flex;
    align-items: center;
    justify-content: center !important;
    width: 100%;
    padding-top: 20px;
}
.inquiry_popup_confirm_btn_box button{
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 4px;
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.quill {
    height: 40vh !important;
    overflow: auto !important;
}
.quill :global(.ql-container) {
    height: 35vh !important;
}

.my_customer_service_header {
    position: fixed;
    background-color: white;
    width: 500px;
    height: 50px;
    z-index: 10;
    top: 0;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
}
.my_customer_service_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(-50%, -50%);   
}
.my_customer_service_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2em;
}
.my_customer_service_tab {
    margin-top: 50px;
}

/* 기본배송지 변경 */
.my_info_address_popup {
}
.my_info_address_title {
    font-size: 1.1em;
    font-weight: 500;
    padding: 10px 0;
}
.my_info_address_sub_title {
    font-size: 0.8em;
    color: rgba(141, 147, 154, 1);
}
.my_info_address_confirm_btn_box {
    padding-top: 20px;
    width: 100%;
}
.my_info_address_confirm_btn_box button {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
}

/* 스토어 결제 정보 입력 */
.user_info_update_container {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}
.user_info_update_box {
    width: 70%;
    height: auto;
    background-color: white;
    border-radius: 6px;
    overflow: hidden;
}
.user_info_update_header {
    width: 100%;
    height: 40px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: end;
}
.user_info_update_header button {
    border: none;
    background-color: inherit;
    margin-right: 10px;
}
.user_info_update_content {
    width: 100%;
    max-height: 400px;
    padding: 10px 20px;
    overflow-y: auto;
}
.user_info_update_content > span {
    font-size: 0.9em;
}
.user_info_update_content > div {
    width: 100%;
    padding: 10px 0 0 0;
}
.user_info_update_content > div > div:nth-of-type(1) {
    font-size: 0.95em;
    font-weight: 500;
}
.user_info_update_content > div > div:nth-of-type(2) {
    width: 100%;
}
.user_info_update_content > div > div:nth-of-type(2) input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    font-size: 0.9em;
    padding: 10px;
}
.user_info_update_content > div > div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.user_info_update_btn {
    width: 100%;
    padding: 20px;
}
.user_info_update_btn button {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 4px;
    font-size: 0.9em;
    color: rgba(115, 122, 130, 0.5);
}
.user_info_update_active {
    background-color: rgba(255, 3, 102, 1);
    color: white !important;
}
.user_info_update_guide {
    font-size: 0.85em;
    color: rgba(255, 3, 102, 0.8);
}
.user_info_update_title {
    width: 100%;
    padding: 10px 20px;
}
.user_info_update_title > div:nth-of-type(1) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    font-weight: 700;
    padding-top: 10px;
}
.user_info_update_title > div:nth-of-type(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 5px;
    font-size: 0.9em;
    font-weight: 500;
}

@media (max-width : 500px) {
    .mycouponporint_header {
        width: 100%;
    }
    .my_customer_service_header {
        width: 100%;
    }
    .my_favority_add_item_box {
        margin: 0 0 5px 0;
        padding-bottom: 150px;
    }
    .myfavority_name_dday_box {
        width: 55%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 10px;
        vertical-align: middle;
    }
    .myfavority_name_box {
        width: 100%;
        display: flex;
        align-items: center;
        font-weight: 500;
    }
    .myfavority_dday_box {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 0.9em
    }
}

@media (max-width : 485px) {
    .my_info_social_box {
        display: inline-block;
    }
    .my_info_social_box>div:nth-of-type(1) {
        width: 100%;
    }
    .my_info_social_box>div:nth-of-type(2) {
        width: 100%;
    }
}
@media (max-width : 345px) {
    .my_info_social_box>div:nth-of-type(1) {
        font-size: 0.9em;
    }
}

@media (max-height : 830px) {
    .my_favority_add_item_box {
        padding-bottom: 200px;
    }
}