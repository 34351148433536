.message_tab_btn {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.message_tab_btn>div {
    height: 40px;
    padding: 5px 10px;
}
.message_tab_btn button {
    border: none;
    background-color: inherit;
    
}
.message_tab_btn_active {
    border-bottom: 3px solid rgba(255, 3, 102, 1) !important;
}
.message_list_content {
    display: flex;
    width: 100%;
    padding: 20px 20px;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    cursor: pointer;
    transition: all 0.2s;
}
.message_list_content:hover, .message_list_content:active {
    background-color: rgba(255, 114, 153, 0.1);
}

.message_list_no_content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 150px);
    font-size: 0.9em;
    color: rgba(115, 122, 130, 1);
}
.message_img_box {
    width: 20%;
}
.message_img_box>div {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
}
.message_img_box>div img {
    width: 100%;
    height: 80px;
    object-fit: cover;
}
.message_shop {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.message_shop>div:nth-of-type(1) {
    display: flex;
    align-items: center;
}
.message_shop>div:nth-of-type(2) {
    font-size: 0.8em;
    padding: 10px 0;
}
.message_shop>div:nth-of-type(1)>div:nth-of-type(1) {
    font-size: 0.7em;
    background-color: rgba(255, 114, 153, 0.1);
    padding: 3px 6px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 3, 102, 1);
}
.message_shop>div:nth-of-type(1)>div:nth-of-type(2) {
    margin-left: 10px;
}
.message_info {
    width: 15%;
    text-align: right;
}
.message_info>div:nth-of-type(1) {
    font-size: 0.9em;
    color: rgba(115, 122, 130, 1);
}
.message_info>div:nth-of-type(2) {
    width: 100%;
    display: flex;
    justify-content: end;
    margin: 10px 0;
}
.message_info>div:nth-of-type(2) span {
    font-size: 0.7em;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    display: block;
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 12px;
    line-height: 20px;
}


.message_chat_container {
    width: 100%;
}
.message_chat_content_header {
    position: absolute;
    width: 100%;
    height: 55px;
    top: 0;
    background-color: white;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    z-index: 10;
}
.message_chat_content_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(-50%, -50%);
}
.message_chat_content_header>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.message_chat_content_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
}
.message_chat_content_header>div:nth-of-type(2)>div:nth-of-type(1) {
    font-size: 0.7em; 
    background-color: rgba(255, 114, 153, 0.1); 
    color: rgba(255, 3, 102, 1); 
    padding: 3px 5px; 
    border-radius: 4px;
}
.message_chat_content_header>div:nth-of-type(2)>div:nth-of-type(2) {font-size: 1.2em}
.message_chat_content_header>div:nth-of-type(3) {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
}
.message_chat_content_header>div:nth-of-type(3) button {
    border: none;
    background-color: inherit;
}
.message_chat_content {
    width: 100%;
    margin-top: 55px;
    padding: 20px;
    margin-bottom: 50px;
    position: relative;
}
.message_chat_content_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 70px;
    background-color: white;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    padding: 10px;
    display: flex;
    align-items: center;
    z-index: 10;
}
.message_chat_content_send_box {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: center;
}
.message_chat_content_send_box>div:nth-of-type(1) {width: 10%;}
.message_chat_content_send_box>div:nth-of-type(2) {width: 80%;}
.message_chat_content_send_box>div:nth-of-type(2) input {
    width: 100%;
    border-radius: 24px;
    border: 1px solid rgba(223, 224, 226, 1);
    height: 45px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 0.9em;
}
.message_chat_content_send_box>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.message_chat_content_send_box>div:nth-of-type(3) {width: 10%;}
.message_chat_content_send_box>div:nth-of-type(1) button, .message_chat_content_send_box>div:nth-of-type(3) button {
    background-color: inherit;
    border: none;
    padding: 5px;
}
.message_chat_sender_box {
    display: flex;
    justify-content: end;
    margin: 30px 0;
}
.message_chat_sender_box>div:nth-of-type(2) {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
}
.message_chat_sender_box>div:nth-of-type(2) img {
    width: 100%;
    height: 60px;
    object-fit: cover;
}
.message_chat_sender {
    max-width: 250px;
    position: relative;
    padding: 5px 10px;
    background: #fff1a2;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin-right: 20px;
    font-size: 0.9em;
    white-space: normal;  /* 줄바꿈이 가능하도록 수정 */
    word-wrap: break-word;  /* 단어가 너무 길면 줄바꿈 */
    overflow-wrap: break-word;  /* 단어가 너무 길면 줄바꿈 */
    display: flex;
    align-items: center;
}
.message_chat_sender>div {
    word-break: break-all;
}

.message_chat_sender:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 10px 0 10px 15px;
    border-color: transparent #fff1a2;
    display: block;
    width: 0;
    z-index: 1;
    right: -15px;
    top: 12px;
}

.message_chat_no_data {
    width: 100%;
    padding: 30px 0;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.message_chat_recipient_box {
    display: flex;
    justify-content: start;
    margin: 30px 0;
}
.message_chat_recipient_box>div:nth-of-type(1) {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
}
.message_chat_recipient_box>div:nth-of-type(1) img {
    width: 100%;
    height: 60px;
    object-fit: cover;
}
.message_chat_recipient {
    max-width: 250px;
    position: relative;
    padding: 5px 10px;
    background: rgba(223, 224, 226, 1);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin-left: 20px;
    font-size: 0.9em;
    white-space: normal;  /* 줄바꿈이 가능하도록 수정 */
    word-wrap: break-word;  /* 단어가 너무 길면 줄바꿈 */
    overflow-wrap: break-word;  /* 단어가 너무 길면 줄바꿈 */
    display: flex;
    align-items: center;
}
.message_chat_recipient>div {
    word-break: break-all;
}
.message_chat_recipient:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent rgba(223, 224, 226, 1);
    display: block;
    width: 0;
    z-index: 1;
    left: -15px;
    top: 12px;
}
.scroll_to_bottom_btn {
    position: absolute;
    bottom: 100px;
    z-index: 100;
}
.message_chat_preview {
    width: 100%;
    margin-bottom: 80px;
    text-align: center;
    padding: 10px;
    border-top: 1px solid rgba(223, 224, 226, 1);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.message_chat_preview_cancel {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background-color: inherit;
}
.message_chat_preview_file img{
    width: 80px;
    height: 80px;
}
.message_chat_preview_file span {
    font-size: 0.8em;
}
.message_img_rander {
    position: relative;
    padding: 20px 30px;
}
.message_img_rander>div:nth-of-type(1) img {
    max-width: 100px;
}
.message_img_rander>div:nth-of-type(2) {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 35px;
    height: 35px;
    }
.message_img_rander>div:nth-of-type(2) button {
    width: 35px;
    height: 35px;
    border: none;
    background-color: inherit;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    background-color: white;
    transition: all 0.2s;
}
.message_img_rander>div:nth-of-type(2) button:hover {
    background-color: rgba(255, 3, 102, 0.1);
}
.message_file_rander {
    position: relative;
    padding: 20px 30px;
    text-align: center;
}
.message_file_rander>div:nth-of-type(1) img{
    width: 60px;
    height: 60px;
}
.message_file_rander>div:nth-of-type(2) {
    font-size: 0.8em;
}
.message_file_rander>div:nth-of-type(3) {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 35px;
    height: 35px;
}
.message_file_rander>div:nth-of-type(3) button {
    width: 35px;
    height: 35px;
    border: none;
    background-color: inherit;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    background-color: white;
    transition: all 0.2s;
}
.message_payment_box {
    padding: 10px;
}
.message_payment_title {
    font-size: 0.9em;
}
.message_payment_product {
    display: flex;
}
.message_payment_product img {
    width: 60px;
    height: 60px;
    border-radius: 4px;
}
.message_payment_service {
    text-align: left;
    padding: 5px 0;
}
.message_payment_service>div:nth-of-type(1) {
    font-weight: bold;
}
.message_payment_service>div:nth-of-type(2) {
    font-size: 0.9em;
}
.message_payment_price {
    text-align: left;
    padding: 5px 0;
}
.message_payment_price>div:nth-of-type(1) {
    font-weight: bold;
}
.message_payment_price>div:nth-of-type(2) {
    font-size: 0.9em;
}
.message_payment_btn {
    width: 100%;
}
.message_payment_btn button {
    width: 100%;
    border: 1px solid rgba(255, 3, 102, 1);
    background-color: inherit;
    height: 40px;
    border-radius: 4px;
    transition: all 0.2s;
}
.message_payment_btn button:hover {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}

.preview_modal_container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}
  
.preview_modal_box {
    position: relative;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    max-width: 90%;
    max-height: 90%;
    overflow: hidden;
}
  
.preview_close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
}
.preview_modal_content {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
} 
.preview_image {
    width: 50%;
}

@media (max-width : 450px) {
    .message_img_box>div {
        width: 60px;
        height: 60px;
        overflow: hidden;
        border-radius: 50%;
    }
    .message_img_box>div img {
        width: 100%;
        height: 60px;
        object-fit: cover;
    }
    .message_shop {
        padding-left: 5px;
    }
}
  