.top_popup_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
}
.top_popup_box {
    position: absolute;
    width: 100%;
    height: 50vh;
    background-color: white;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    top: 0;
}
.top_popup_header {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}
.top_popup_header button {
    border: none;
    background-color: inherit;
    font-size: 0.9em;
    transition: all 0.3s;
}
.top_popup_header>div:nth-of-type(1) button:hover {
    opacity: 0.7;
}

.middle_popup_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
}
.middle_popup_box {
    position: absolute;
    width: 100%;
    height: 50vh;
    background-color: white;
    border-radius: 12px;
    top: 50%;
    transform: translate(0, -50%) !important;
}
.middle_popup_header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}
.middle_popup_header button {
    border: none;
    background-color: inherit;
    font-size: 0.9em;
    transition: all 0.3s;
}
.middle_popup_header>div:nth-of-type(1) button:hover {
    opacity: 0.7;
}

.bottom_popup_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
}
.bottom_popup_box {
    position: absolute;
    width: 100%;
    height: 70%;
    background-color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    bottom: 0;
    overflow: hidden;
}
.bottom_popup_header {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: white;
}
.bottom_popup_header button {
    border: none;
    background-color: inherit;
    font-size: 0.8em;
    transition: all 0.3s;
}
.bottom_popup_header>div:nth-of-type(1) button:hover {
    opacity: 0.7;
}
.bottom_popup_content {
    width: 100%;
    height: calc(70vh - 50px);
    overflow: hidden;
}
.bottom_popup_content > span {
    display: block;
    width: 100%;
}
.bottom_popup_content img {
    width: 100%;
    height: calc(70vh - 50px);
    object-fit: fill;
    aspect-ratio: 500 / 500;
}
@media (max-width: 700px) {
}
@media (max-width: 500px) {
    .bottom_popup_box {
        height: 50vh;
    }
    .bottom_popup_content {
        width: 100%;
        height: calc(50vh - 50px);
        overflow: hidden;
    }
    .bottom_popup_content img {
        width: 100%;
        height: calc(50vh - 50px);
        object-fit: fill;
        aspect-ratio: 500 / 500;
    }
}