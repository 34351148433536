.cart_container {
    position: relative;
    width: 100%;
    height: 100%;
}
.cart_header {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
}
.cart_header_back {
    position: absolute;
    top: 1.1em;
    left: 1em;
}
.cart_header_back button {
    border: none;
    background-color: inherit;
}
.cart_header_title {
    font-size: 1.2em;
}
.cart_address_select {
    display: flex;
    width: 100%;
    align-items: center;
    border: none;
    background-color: inherit;
}
.cart_address_select_left {
    width: 80%;
    font-size: 0.9em;
}
.cart_address_select_left>div:nth-of-type(2)>div:nth-of-type(2) {
    font-size: 1.1em;
    padding-top: 8px;
    text-align: left;
}
.cart_address_select_right {
    width: 20%;
    text-align: right;
}
.cart_address_select_delivery_add_btn {
    padding: 5px 10px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 4px;
}
.cart_address_select_default {
    width: 90px;
    padding: 2px 10px;
    background-color: rgba(241, 242, 243, 1);
    color: rgba(115, 122, 130, 1);
    font-size: 0.9em;
    border-radius: 4px;
}
.cart_select_input_label_box {
    font-size: 0.9em;
}
.cart_select_con {
    border-bottom: 8px solid rgba(248, 248, 248, 1);
}
.cart_select_box {
    display: flex;
}
.cart_select_input_box input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 100%;
    background: white;
    position: relative;
}
  
.cart_select_input_box input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}
  
.cart_select_input_box input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}
  
.cart_select_input_box input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}

.cart_select_btn_box button{
    border: none;
    background-color: inherit;
    text-decoration: underline;
    color: rgba(115, 122, 130, 1);
    font-size: 0.9em;
}
.cart_list_shop_info_button {
    display: flex;
    align-items: center;
    border: none;
    background-color: inherit;
}
.cart_list_shop_info_button>div:nth-of-type(1) {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
}
.cart_list_shop_info_button img {
    width: 100%;
    height: 30px;
    object-fit: cover;
}
.cart_list_shop_info_button div:nth-of-type(2) {
    height: 27px;
    line-height: 30px;
}
.cart_more_btn {
    width: 100%;
    height: 30px;
    border: none;
    background-color: rgba(248, 248, 248, 1);
    font-size: 0.9em;
}
/* 스토어 카트 */
.store_cart_item_box {
    width: 100%;
}
.store_cart_item_box>div {
    position: relative;
}
.store_cart_list_prdocut_box {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(248, 248, 248, 1);
    padding: 10px 0 20px 0;
}
.store_cart_list_prdocut_input {
    width: 5%;
}
.store_cart_list_prdocut_input input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 100%;
    background: white;
    position: relative;
}
  
.store_cart_list_prdocut_input input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}
  
.store_cart_list_prdocut_input input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}
  
.store_cart_list_prdocut_input input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.store_cart_list_prdocut_img {
    width: 130px;
    padding: 0 10px;
}
.store_cart_list_prdocut_img div {
    width: 100%;
    height: auto;
    overflow: hidden;
}
.store_cart_list_prdocut_img img{
    width: 100px;
    height: 100px;
    aspect-ratio: 100 / 100;
    object-fit: cover;
    border-radius: 8px;
}
.store_cart_list_prdocut_info {
    width: calc(80% - 130px);
}
.store_cart_list_product_info_title {
    padding-right: 60px;
}
.store_cart_list_product_info_title>div:nth-of-type(2) {
    font-size: 0.8em;
    color: rgba(169, 173, 178, 1);
}
.store_cart_list_product_info_price {
    font-size: 1.1em;
    font-weight: bold;
    padding: 10px 0;
}
.store_cart_list_product_info_btn {
    position: absolute;
    top: 20px;
    right: 80px;
    width: 100px;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.store_cart_list_product_info_btn button {
    width: 30%;
    border: none;
    background-color: inherit;
    font-size: 1.2em;
}
.store_cart_list_product_info_btn span {
    width: 40%;
    text-align: center;
    font-size: 1em;
}
.store_cart_list_product_info_btn input {
    border: none;
    background-color: inherit;
    width: 30px;
    border-radius: 4px;
    text-align: center;
    font-size: 0.9em;
}
.store_cart_list_product_info_btn input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.store_cart_list_product_delete {
    width: 15%;
}
.store_cart_list_product_delete {
    text-align: right;
}
.store_cart_list_product_delete button {
    border: none;
    background-color: inherit;
}
/* ---------- */
.cart_item_box {
    width: 100%;
}
.cart_item_info_box {
    width: 100%;
    display: flex;
    padding: 5px 20px 5px 20px;
}
.cart_item_info_box_img {
    width: 130px;
    padding: 10px;
}
.cart_item_info_box_img img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    aspect-ratio: 100 / 100;
    border-radius: 6px;
}
.cart_item_info_box_info {
    width: calc(100% - 130px);
    padding: 11px 5px;
}
.cart_list_prdocut_box {
    display: flex;
    width: 100%;
    font-size: 0.8em;
    color: rgba(169, 173, 178, 1);
}
.cart_list_prdocut_input {
    width: 10%;
}
.cart_list_prdocut_img {
    width: 20%;
}
.cart_list_prdocut_img div {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding: 10px;
}
.cart_list_prdocut_img img{
    width: 100%;
    height: auto;
    aspect-ratio: 60 / 60;
    object-fit: cover;
    border-radius: 6px;
}
.cart_list_prdocut_info {
    width: 80%;
    display: flex;
}
.cart_list_product_delete {
    width: 10%;
}
.cart_list_prdocut_input input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 100%;
    background: white;
    position: relative;
}
  
.cart_list_prdocut_input input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}
  
.cart_list_prdocut_input input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}
  
.cart_list_prdocut_input input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.cart_list_shop_info_box input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 100%;
    background: white;
    position: relative;
}
  
.cart_list_shop_info_box input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}
  
.cart_list_shop_info_box input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}
  
.cart_list_shop_info_box input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}

.cart_list_product_info_title {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 두 줄로 제한 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* 원래는 nowrap을 사용하지만, 여러 줄 처리를 위해 normal로 설정 */
    line-height: 1.2em; /* 줄 높이 설정 */
    max-height: 2.4em; /* 줄 높이와 줄 수에 따라 최대 높이 설정 (예: 줄 높이 1.2em * 줄 수 2 = 2.4em) */
}
.cart_list_product_info_basic_price {
    font-size: 0.8em;
    color: rgba(169, 173, 178, 1);
    padding-top: 5px;
}
.cart_list_product_info_total_price {
    font-size: 1.2em;
    font-weight: 500;
    color: rgb(56, 56, 56);
    padding-top: 10px;
}
.cart_list_product_info_price {
    font-weight: bold;
}
.cart_list_product_info_btn {
    width: 100px;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cart_list_product_info_btn button {
    width: 30%;
    border: none;
    background-color: inherit;
    font-size: 1.2em;
}
.cart_list_product_info_btn span {
    width: 40%;
    text-align: center;
    font-size: 1em;
}
.cart_list_product_delete {
    text-align: right;
}
.cart_list_product_delete button {
    border: none;
    background-color: inherit;
}

.cart_payment_content {
    border-bottom: 1px solid rgba(248, 248, 248, 1);
}
.cart_payment_title {
    font-size: 1.1em;
    font-weight: bold;
}
.cart_payment_content_info_box {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}
.cart_payment_content_info_box div:nth-of-type(1) {
    color: rgba(115, 122, 130, 1);
}
.cart_payment_total {
    display: flex;
    justify-content: space-between;
}
.cart_payment_total div:nth-of-type(1) {
    color: rgba(115, 122, 130, 1);
}
.cart_payment_total div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: bold;
}
.cart_payment_info {
    font-size: 0.8em;
    color: rgba(115, 122, 130, 1);
    text-align: right;
}
.cart_payment_btn {
    /* position: fixed; */
    width: 100%;
    height: 50px;
    /* bottom: 0; */
    background-color: white;
    /* margin-bottom: 10px; */
}
.cart_payment_btn button {
    width: 100%;
    height: 50px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 8px;
}
.cart_date_range_box {
    position: absolute;
    top: 20px;
    right: 100px;
}
.cart_date_input {
    border: 1px solid rgba(223, 224, 226, 1);
    padding: 5px 0;
    width: 200px;
    font-size: 0.8em;
    border-radius: 8px;
    color: rgba(115, 122, 130, 1);
    position: relative;
    text-align: center;
}
.cart_date_input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.cart_date_popup {
    position: absolute;
    width: 500px;
    left: -100px;
    display: flex;
    align-items: center;
    top: 35px;
}
.cart_date_popup input {
    font-size: 0.8em;
    color: rgba(115, 122, 130, 1);
    padding: 3px 8px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
}
.cart_date_popup input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.cart_date_change_btn {
    font-size: 0.8em;
    border: 1px solid rgba(255, 3, 102, 1);
    color: rgba(255, 3, 102, 1);
    background-color: inherit;
    padding: 2px 5px;
    border-radius: 4px;
    margin-left: 2px;
    transition: all 0.2s;
}
.cart_date_change_btn:hover,
.cart_date_change_btn:active {
    color: white;
    background-color: rgba(255, 3, 102, 1);
}
.cart_delete_btn {
    position: absolute;
    top: 20px;
    right: 20px;
}
.cart_delete_btn button {
    border: 1px solid rgba(115, 122, 130, 1);
    background-color: inherit;
    padding: 5px;
    border-radius: 4px;
    transition: all 0.2s;
}
.cart_delete_btn button>span {
    color: rgba(115, 122, 130, 1);
    transition: all 0.2s;
}
.cart_delete_btn button>span:hover,
.cart_delete_btn button>span:active {
    color: rgba(255, 3, 102, 1);
}
.cart_delete_btn button:hover,
.cart_delete_btn button:active {
    border: 1px solid rgba(255, 3, 102, 1);
}
.cart_tap_btn {
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.cart_tap_btn>div {
    margin: 0px 3px;
    padding: 5px 10px;
}
.cart_tap_btn>div button {
    border: none;
    background-color: inherit;
    font-size: 0.9em;
}
.cart_tab_btn_active {
    border-bottom: 2px solid rgba(255, 3, 102, 1) !important;
}
.cart_tab_btn_active_color {
    color: rgba(255, 3, 102, 1) !important;
}
.cart_render_box {
    width: 100%;
    height: 50vh;
    overflow-y: auto;
}
.cart_no_item_container {
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.cart_no_item_box>div:nth-of-type(1) {
    width: 100%;
}
.cart_no_item_box>div:nth-of-type(1) img {
    width: 150px;
}
.cart_no_item_box>div:nth-of-type(2) {
    width: 100%;
    padding: 20px 0 10px 0;
    font-size: 1.2em;
    font-weight: bold;
}
.cart_no_item_box>div:nth-of-type(3) {
    font-size: 0.9em;
    color: rgba(115, 122, 130, 1);
}
.cart_no_item_box>div:nth-of-type(4) {
    padding: 30px 0;
}
.cart_no_item_box>div:nth-of-type(4) button {
    border: none;
    background-color: rgba(36, 36, 36, 1);
    border-radius: 30px;
    width: 200px;
    height: 50px;
    color: white;
    margin: 10px 0;
}
.cart_loading_back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(36, 36, 36, 0.1);
}
.cart_loading_back>div {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.cart_loading_back>div img {
    width: 100px;
    height: 100px;
    object-fit: contain;
}
.cart_footer {
    box-shadow: 0 0 4px 1px rgba(241, 242, 243, 1);
    padding: 10px 15px 0 15px;
}

.cart_content_input_btn {
    position: absolute;
    top: 20px;
    right: 80px;
}
.cart_content_input_btn button {
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: rgba(255, 3, 102, 1);
    border-radius: 4px;
    font-size: 0.8em;
    padding: 3px 7px;
    color: white;
}
.cart_content_form_container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}
.cart_content_form_box {
    width: 70%;
    max-height: 500px;
    border-radius: 4px;
    background-color: white;
    overflow: hidden;
}
.cart_content_form_header {
    width: 100%;
    height: 50px;
    position: relative;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}
.cart_content_form_header>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.cart_content_form_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
}
.cart_content_form_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.cart_content_form_content {
    width: 100%;
    padding:  20px;
    height: calc(100% - 50px);
    overflow-y: auto;
}
.cart_content_form_content>div {
    width: 100%;
    box-shadow: 0 0 4px rgba(223, 224, 226, 1);
    margin: 10px 0;
    padding: 10px;
    font-size: 0.9em;
}
.cart_content_form_content>div>div:nth-of-type(1) {
    font-weight: 600;
    color: rgba(115, 122, 130, 1);
}
.cart_content_form_content>div>div:nth-of-type(2) {}

/* 디폴트 스타일 */
.cart_border_bottom {
    border-bottom: 8px solid rgba(248, 248, 248, 1);
    position: relative;
}
.cart_gray_color{
    color: rgba(169, 173, 178, 1);
}
.cart_bold {
    font-weight: bold;
}

@media (max-width: 500px) {
    .store_cart_list_product_info_title {
        padding-right: 0;
    }
    .store_cart_list_product_info_btn {
        right: 5%;
        top: 60%;
    }
}
@media (max-width: 425px) {
    .cart_date_range_box {
        position: static;
        padding: 0 20px 10px 20px;
    }
}
@media (max-width: 420px) {
    .cart_list_prdocut_img {
        width: 30%;
    }
    .cart_list_prdocut_info {
        width: 50%;
    }
}