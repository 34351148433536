.email_login_container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}
.email_login_box {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: white;
}
.email_login_back {
    border: none;
    background-color: inherit;
}
.email_login_body {
    width: 100%;
    height: 450px;
}
.password_login, .email_login {
    width: 100%;
    height: 60px;
    border-radius: 8px;
    border: 1px solid rgba(223, 224, 226, 1);
    padding-left: 10px;
}
.password_login::placeholder, .email_login::placeholder {
    color: rgba(169, 173, 178, 1);
}
.password_eye_btn {
    color: rgba(115, 122, 130, 1);
}
.pw_email_search {
    color: rgba(115, 122, 130, 1);
}
.pw_email_search button {
    border: none;
    background-color: inherit;
    color: rgba(115, 122, 130, 1)
}
.email_login_btn {
    width: 100%;
    height: 60px;
    background-color: rgba(241, 242, 243, 1);
    color: rgba(169, 173, 178, 1);
    border-radius: 8px;
    border: none;
}
.Toastify__toast-container {
    width: 100% !important;
}
.Toastify__toast-theme--light {
    background: rgba(19, 20, 22, 0.85) !important;
    color: white !important;
}
.favority_login_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}
.email_login_join {
    border: none;
    background-color: inherit;
    text-decoration: underline;
    color: rgba(115, 122, 130, 1);
    transition: all 0.3s;
}
.email_login_join:hover {
    color: rgba(255, 3, 102, 1);
}
.email_login_join:active {
    color: rgba(255, 3, 102, 1);
}
.emailsearch_back {
    border: none;
    background-color: inherit;
}
.email_search_container {
    width: 100%;
    height: 100vh;
}
.email_search_box {
    width: 100%;
    height: 100%;
}
.email_search_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.email_search_header button {
    border: none;
    background-color: inherit;
}
.email_search_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0, -50%);
}
.email_search_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}

.cer_btn_box button {
    width: 100%;
    height: 60px;
    border: none;
    background-color: rgba(223, 224, 226, 1);
    border-radius: 8px;
    color: rgba(115, 122, 130, 1);
}
.cer_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}
.emailsearch_input_box {
    margin: 15px 20px;
}
.emailsearch_input_box span {
    color: rgba(91, 97, 103, 1);
}
.cer_span {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}
.account_search_container {
    width: 100%;
    height: 100vh;
    position: relative;
}
.account_search_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.account_search_header button {
    border: none;
    background-color: inherit;
}
.account_search_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0, -50%);
}
.account_search_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.account_user_info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    color: rgba(72, 76, 81, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.account_user_info_no_data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    color: rgba(72, 76, 81, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.account_user_info>div:nth-of-type(1) {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
}
.account_user_info>div:nth-of-type(1) img {
    width: 100%;
    aspect-ratio: 500 / 500;
    object-fit: cover;
}
.account_user_info>div:nth-of-type(2) {
    width: 50%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    padding: 10px;
    border-radius: 4px;
    margin-top: 20px;
    font-size: 0.9em;
}
.account_user_info>div:nth-of-type(2)>div {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.account_user_info>div:nth-of-type(2)>div:nth-of-type(1)>div:nth-of-type(1) {
    width: 30%;
}
.account_user_info>div:nth-of-type(2)>div:nth-of-type(1)>div:nth-of-type(2) {
    width: 50%;
    color: rgba(255, 3, 102, 1);
    font-weight: 500;
}
.account_user_info>div:nth-of-type(2)>div:nth-of-type(1)>div:nth-of-type(3) {
    width: 20%;
}
.account_user_info>div:nth-of-type(2)>div:nth-of-type(1)>div:nth-of-type(3) button {
    width: 100%;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
    height: 25px;
    border-radius: 4px;
}

.account_user_info>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(1),
.account_user_info>div:nth-of-type(2)>div:nth-of-type(3)>div:nth-of-type(1) {
    width: 30%;
}
.account_user_info>div:nth-of-type(2)>div:nth-of-type(2)>div:nth-of-type(2),
.account_user_info>div:nth-of-type(2)>div:nth-of-type(3)>div:nth-of-type(2) {
    width: 70%;
    color: rgba(255, 3, 102, 1);
    font-weight: 500;
}
.account_user_info_no_data>div:nth-of-type(2) {
    text-align: center;
    padding-top: 10px;
}
.account_user_info_no_data>div:nth-of-type(2)>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
    color: rgba(255, 3, 102, 1);
}
.email_success {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.password_search_container {
    width: 100%;
    height: 100vh;
    position: relative;
}
.password_search_box {
    width: 100%;
    height: 100%;
}
.password_search_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.password_search_header button {
    border: none;
    background-color: inherit;
}
.password_search_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0, -50%);
}
.password_search_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.es_password_search, .es_login {
    width: 45%;
    margin: 0 5px;
}
.es_password_searchBtn, .es_login_btn {
    width: 100%;
    height: 60px;
    border-radius: 8px;
    border: none;
}
.es_login_btn {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.socitial_success {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.socitial_success button {
    width: 100%;
    height: 60px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 8px;
}
.password_change_successBox {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.password_change_successBox>div {
    width: 100%;
}
.password_change_successBox button {
    width: 100%;
    height: 60px;
    border: none;
    color: rgba(169, 173, 178, 1);
    border-radius: 8px;
}
.password_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}