.event_top_box {
    display: flex;
}
.event_top_box button {
    border: none;
    background-color: inherit;
}
.event_top_box div:nth-of-type(2) {
    color: rgba(169, 173, 178, 1);
}
.event_top_box div:nth-of-type(1) button, .event_top_box div:nth-of-type(3) button {
    color: rgba(169, 173, 178, 1);
}
.top_btn_active {
    color: black !important;
    font-weight: bold;
    border-bottom: 3px solid rgba(255, 3, 102, 1) !important;
}
.event_sub_category_swiper :global(.swiper-slide) {
    width: auto !important; /* 버튼의 내용에 따라 크기가 조정되도록 설정 */
    flex: 0 0 auto; /* 플렉스박스 아이템이 줄어들지 않도록 설정 */
}
.event_middle_tab_btn_box {
    padding: 0 5px;
    margin-top: 5.5px;
}
.event_middle_tab_btn_box button {
    border: none;
    padding: 5px 12px;
    border-radius: 30px;
    font-size: 0.9em;
}
.middle_tab_active {
    background-color: rgba(72, 76, 81, 1) !important;
    color: white !important;
}
.event_centent_box {
    width: 100%;
    display: flex;
    align-items: center;
    border: none;
    background-color: inherit;
    text-align: left;
    transition: all 0.2s;
    padding: 10px;
    border-radius: 12px;
}
.event_centent_box:hover,
.event_centent_box:active {
    background-color: rgba(255, 114, 153, 0.1);
}
.event_content_img_box {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 12px;
}
.event_content_img_box img {
    width: 100%;
    height: 100px;
    object-fit: cover;
}
.event_content_title_box {
    font-size: 1.1em;
    font-weight: bold;
    padding: 3px 0;
}
.event_content_sub_title_box {
    color: rgba(255, 114, 153, 0.7);
}
.event_content_info_box {
    width: calc(100% - 100px) !important;
    padding-left: 10px;
    font-size: 0.9em;
    color: rgba(19, 20, 22, 1);
}
.event_content_date_box {
    font-size: 0.8em;
    color: rgba(48, 51, 54, 1);
}
.event_content_open_wait {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(169, 173, 178, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    border-radius: 4px;
    color: rgba(255, 3, 102, 1);
    font-size: 0.9em;
}
.event_content_no_event {
    width: 100%;
    text-align: center;
    padding: 50px 0;
}

/* vote */
.event_vote_container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: flex-end;
    z-index: 1000;
}
.event_vote_box {
    width: 100%;
    height: 90%;
    background-color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    overflow: hidden;
}
.event_vote_box_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(233, 236, 242, 1);
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0 20px;
}
.event_vote_box_header button {
    border: none;
    background-color: inherit;
}
.event_vote_top_content {
    width: 100%;
    padding: 20px;
    height: calc(90% - 110px);
    overflow-y: auto;
}
.event_vote_top_title {
    font-size: 1.3em;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 두 줄로 제한 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* 원래는 nowrap을 사용하지만, 여러 줄 처리를 위해 normal로 설정 */
    line-height: 1.1em; /* 줄 높이 설정 */
    max-height: 2.2em; /* 줄 높이와 줄 수에 따라 최대 높이 설정 (예: 줄 높이 1.2em * 줄 수 2 = 2.4em) */
}
.event_vote_top_sub_title {
    color: rgba(169, 173, 178, 1);
    font-size: 0.9em;
    padding-bottom: 10px;
}
.event_vote_content {
    box-shadow: 0 0 6px 3px rgba(169, 173, 178, 0.3);
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
}
.event_vote_content_one{
    width: 10%;
    text-align: center;
}
.event_vote_content_two{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.event_vote_content_three{
    width: 55%;
}
.event_vote_content_four{
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: end;
}
.event_vote_content_four input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 100%;
    background: white;
    position: relative;
}
  
.event_vote_content_four input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}
  
.event_vote_content_four input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}
  
.event_vote_content_four input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.event_vote_content_four input[type="radio"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}
  
.event_vote_content_four input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}
  
.event_vote_content_four input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}
  
.event_vote_content_four input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}
.event_vote_content_two>div {
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 50%;
}
.event_vote_content_two>div img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 100 / 100;
}
.event_vote_content_three div:nth-child(2) {
    color: rgba(169, 173, 178, 1);
    font-size: 0.9em;
}
.event_vote_content_three div:nth-child(3) {
    color: rgba(255, 114, 153, 1);
    font-size: 0.9em;
}
.event_vote_footer {
    width: 100%;
    bottom: 0;
    padding: 1em;
    background-color: white;
}
.event_vote_footer span {
    font-size: 0.85em;
    color: rgba(169, 173, 178, 1);
}
.event_vote_footer button {
    border: none;
    width: 100%;
    height: 45px;
    border-radius: 8px;
    color: rgba(169, 173, 178, 1);
}
.vote_footer_btn_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}

.event_vote_popup {
    height: 25vh;
}
.event_vote_popup_title {
    font-size: 1.2em;
    font-weight: bold;
    padding: 10px 0;
}
.event_vote_popup_content {
    font-size: 0.9em;
    color: rgba(169, 173, 178, 1);
    padding-bottom: 10px;
}
.event_vote_popup_btn_box button {
    width: 100%;
    height: 60px;
    border: none;
    border-radius: 8px;
}
.event_vote_popup_btn_box button:nth-of-type(2) {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.event_vote_participate_box {
    width: 100%;
}
.event_vote_participate_box_drop_btn {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background-color: inherit;
}
.event_vote_participate_box_item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.event_vote_participate_img_box {
    cursor: pointer;
}
.event_vote_participate_box_item>div:nth-of-type(1) {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 0.9em;
    position: relative;
}
.event_vote_participate_box_item>div:nth-of-type(1) img {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 100 / 100;
    border-radius: 50%;
}
.event_vote_participate_box_item>div:nth-of-type(1) div {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    aspect-ratio: 100 / 100;
    font-weight: 500;
    text-shadow: 0 0 4px 1px rgba(233, 236, 242, 1);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.event_vote_participate_box_item>div:nth-of-type(1) div > div {
    position: absolute;
    padding: 0 10px;
    color: white;
    width: 100%; /* 적절히 제한된 크기 */
    height: 100%;
    aspect-ratio: 1 / 1;
    font-weight: 500;
    text-shadow: 0 0 4px 1px rgba(233, 236, 242, 1);
    text-align: center; /* 텍스트 중앙 정렬 */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.event_vote_participate_box_item>div:nth-of-type(1) div > div span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block; /* block으로 변경 */
}
.event_vote_participate_box_item>div:nth-of-type(2) {
    width: 80%;
    padding: 0 20px;
}
.event_vote_participate_box_item>div:nth-of-type(3) {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: end;
    flex-direction: column;
    font-size: 0.9em;
}
.event_vote_participate_img_modal {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
}
.event_vote_participate_img_modal_box {
    width: 300px;
}
.event_vote_participate_img_modal_header {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: end;
}
.event_vote_participate_img_modal_header > button {
    border: none;
    background-color: inherit;
    color: white;
}
.event_vote_participate_img_modal_content {
    width: 100%;
    height: calc(100% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.event_vote_participate_img_modal_content > div:nth-of-type(1) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.event_vote_participate_img_modal_content > div:nth-of-type(1) img {
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    aspect-ratio: 500 / 500;
}
.event_vote_participate_img_modal_content > div:nth-of-type(2) {
    font-size: 1.2em;
    font-weight: 500;
    color: white;
}
.vote_bar_container {
    width: 100%;
    height: 20px;
    background-color: rgba(241, 242, 243, 1); /* 배경색 */
    border-radius: 5px;
    overflow: hidden;
}
.vote_bar {
    height: 100%;
    background-color: rgba(255, 3, 102, 1); /* 채워지는 색상 */
    transition: width 0.3s ease-in-out; /* 애니메이션 */
}
.event_vote_coupon_box {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
}
.event_vote_coupon_create {
}
.event_vote_coupon_create button {
    border: 1px solid rgba(233, 236, 242, 1);
    background-color: inherit;
    border-radius: 4px;
    font-size: 0.9em;
    padding: 5px 10px;
}
.event_vote_coupon_create button:hover,
.event_vote_coupon_create button:active {
    color: white;
    background-color: rgba(255, 3, 102, 1);
}
.event_vote_coupon_input {
    padding-left: 10px;
}
.event_vote_coupon_input button {
    border: 1px solid rgba(233, 236, 242, 1);
    background-color: inherit;
    border-radius: 4px;
    font-size: 0.9em;
    padding: 5px 10px;
}
.event_vote_coupon_input button:hover,
.event_vote_coupon_input button:active {
    color: white;
    background-color: rgb(3, 121, 255);
}
.event_coupon_modal_container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 500;
}
.event_coupon_modal_box {
    width: 50%;
    height: auto;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
}
.event_coupon_modal_header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0 0 4px 1px rgba(233, 236, 242, 1);
}
.event_coupon_modal_header>div:nth-of-type(1) {
    font-weight: 500;
}
.event_coupon_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.event_coupon_modal_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.event_coupon_modal_content {
    padding: 10px;
}
.event_coupon_modal_content > div:nth-of-type(1) {
    font-size: 0.8em;
    padding-bottom: 5px;
    color: rgba(169, 173, 178, 1);
}
.event_coupon_modal_content_add_check {
    position: relative;
    width: 100%;
    margin-bottom: 5px;
}
.event_coupon_modal_content_add_check input {
    width: 100%;
    height: 35px;
    border: 1px solid rgba(169, 173, 178, 1);
    border-radius: 4px;
    padding: 10px;
    font-size: 0.9em;
}
.event_coupon_modal_content_add_check input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.event_coupon_modal_content_add_check button {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translate(0, -50%);
    font-size: 0.9em;
    padding: 3px 5px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 4px;
}
.event_coupon_modal_content_check_text {
    font-size: 0.8em;
    padding-bottom: 10px;
}
.event_coupon_modal_content_btn_box {
    width: 100%;
}
.event_coupon_modal_content_btn_box button {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 4px;
    font-size: 0.9em;
}
.event_coupon_active {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}

/* RadioButton.css */

.styledLabel {
    display: flex;
    align-items: center;
}

.styledLabel:hover {
    cursor: pointer;
}

.styledLabel > span {
    min-width: fit-content;
    padding: 0;
    font-family: NotoSans; /* fonts.NotoSans 값을 실제 폰트 이름으로 대체 */
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: grey; /* colors.greyColor2 값을 실제 색상 코드로 대체 */
}

.styledRadio {
    appearance: none;
    margin: 0 11px 0 0;
    width: 23px;
    height: 23px;
    border: 1.5px solid grey; /* colors.greyColor2 값을 실제 색상 코드로 대체 */
    border-radius: 50%;
}

.styledRadio:hover {
    cursor: pointer;
}

.styledRadio:checked {
    background-color: rgba(255, 3, 102, 1); /* 배경 색상 설정 */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 1 18 18"><path fill="%23ffffff" d="M7.5 14.1L3.3 9.9l-1.4 1.4 5.6 5.6 9.6-9.6-1.4-1.4z"/></svg>');
    background-repeat: no-repeat;
    background-position: center 40%; /* 체크 표시를 중앙에 배치 */
    border: none; /* 선택된 상태에서 테두리 제거 */
}


.styledRadio:checked:disabled {
    /* background: center url("") no-repeat; */
    border: 1px solid black;
}

.styledRadio:disabled + span,
.styledRadio:disabled:hover {
    cursor: default;
}

.styledRadio:checked ~ span {
    color: #000000;
}



/* raffle */
.event_raffle_container {
    width: 100%;
    height: 100%;
}
.event_product_detail_header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    top: 0;
    z-index: 10;
    background-color: white;
    box-shadow: 0 0 4px 1px rgba(241, 242, 243, 1);
}
.event_product_detail_header_left {
    width: 100%;
    text-align: left;
    padding-left: 20px;
}
.event_product_detail_header_left button{
    border: none;
    background-color: inherit;
    /* color: white; */
}
.event_product_detail_header_right {
    width: 100%;
    text-align: right;
    padding-right: 20px;
}
.event_product_detail_header_right button{
    border: none;
    background-color: inherit;
    /* color: white; */
}
.event_product_detail_img_box {
    margin-top: 50px;
}

.event_raffle_swiper {
    width: 100%;
    height: 500px;
    overflow: hidden;
}
.event_raffle_swiper img {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.event_raffle_info_shop_box {
    color : rgba(169, 173, 178, 1);
    font-size: 0.9em;
}
.event_raffle_info_product_box {
    font-size: 1.3em;
    padding-top: 40px;
}
.event_raffle_info_price_box {
    font-size: 1.1em;
    padding: 5px 0;
    font-weight: bold;
}
.event_raffle_info_people_box {
    color: rgba(255, 3, 102, 1);
    font-size: 0.9em;
    padding: 5px 0;
}

.event_raffle_info_date_box {
    display: flex;
    padding: 5px 0;
}
.event_raffle_info_date_box div:nth-of-type(1){
    width: 30%;
}
.event_raffle_info_date_box div:nth-of-type(2){
    width: 70%;
    color: rgba(91, 97, 103, 1);
    font-size: 0.9em;
}

.event_spare_buy_date_box button {
    border: none;
    background-color: inherit;
    text-decoration: underline;
    color: rgba(169, 173, 178, 1);
    padding-left: 10px;
    transition: all 0.3s;
}
.event_spare_buy_date_box button:hover, .event_spare_buy_date_box button:active {
    color: rgba(255, 3, 102, 1);
}
.event_spare_ment_box {
    color: rgba(91, 97, 103, 1);
    padding: 10px 0;
}
.event_raffle_content_box {
    width: 100%;
    overflow: hidden;
    margin-bottom: 100px;
    padding: 20px 20px 100px 20px;
}
.event_raffle_content_box>div {
    width: 100%;
    overflow: hidden;
}
.event_raffle_content_box>div img {
    width: 100%;
    height: auto;
}
.event_raffle_footer_box {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 60px;
    z-index: 10;
    background-color: white;
    padding: 5px;
}
.event_raffle_footer_box button {
    width: 100%;
    height: 50px;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border: none;
    border-radius: 8px;
}
.event_raffle_footer_participation {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    background-color: rgba(233, 236, 242, 1);
    color: rgba(169, 173, 178, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.event_raffle_detail_checkbox input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 100%;
    background: white;
    position: relative;
}
  
.event_raffle_detail_checkbox input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}
  
.event_raffle_detail_checkbox input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}
  
.event_raffle_detail_checkbox input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}

.event_raffle_detail_info_box {
    border-bottom: 5px solid rgba(248, 248, 248, 1);
}
.event_raffle_detail_title {
    font-size: 1.4em;
    font-weight: bold;
}
.event_raffle_detail_sub_title {
    color: rgba(91, 97, 103, 1);
}
.event_raffle_detail_option_btn {
    width: 100%;
    height: 60px;
    border: 1px solid rgba(233, 236, 242, 1);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    background-color: inherit;
    align-items: center;
}
.event_raffle_detail_option_btn div:nth-of-type(1) {
    color: rgba(169, 173, 178, 1);
}
.event_raffle_info_list_box li {
    list-style-type: disc;
    padding: 10px 0;
    color: rgba(91, 97, 103, 1);
}
.event_raffle_detail_checkbox {
    display: flex;
}
.event_raffle_detail_payment {
    position: fixed;
    width: 500px;
    bottom: 0;
    background-color: white;
}
.event_raffle_detail_payment button {
    width: 100%;
    height: 60px;
    border: none;
    border-radius: 8px;
    color: rgba(169, 173, 178, 1);
}

/* plan */
.event_plan_haeader {
    position: absolute;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(241, 242, 243, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: white;
}
.event_plan_haeader>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.event_plan_haeader>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.event_plan_haeader>div:nth-of-type(2) {
    font-size: 1.1em;
}

.event_plan_box {
    width: 100%;
    margin-top: 50px;
}
.event_plan_content {
    width: 100%;
    padding: 20px;
}

.event_plan_content img {
    width: 100%;
}
.event_plan_content_btn_box {
    width: 100%;
}
.event_plan_content_btn_box button {
    width: 100%;
    border : none;
    background-color: inherit;
    font-size: 0.9em;
    height: 40px;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.event_plan_product_item {
    padding: 10px;
    border-radius: 8px;
    transition: all 0.2s;
    cursor: pointer;
}
.event_plan_product_item:hover,
.event_plan_product_item:active {
    background-color: rgba(248, 248, 248, 1);
}

.event_plan_product_item>div:nth-of-type(1) {
    width: 100%;
    height: auto;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}
.event_plan_product_item>div:nth-of-type(1) img {
    width: 100%;
    aspect-ratio: 500 / 500;
    object-fit: cover;
}
.event_plan_product_item>div:nth-of-type(2) {
    font-size: 0.9em;
    padding-top: 3px;
    color: rgba(169, 173, 178, 1);
}
.event_plan_product_item>div:nth-of-type(3) {
    font-weight: bold;
    /* font-size: 1.4em; */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 두 줄로 제한 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* 원래는 nowrap을 사용하지만, 여러 줄 처리를 위해 normal로 설정 */
    line-height: 1.2em; /* 줄 높이 설정 */
    max-height: 2.4em; /* 줄 높이와 줄 수에 따라 최대 높이 설정 (예: 줄 높이 1.2em * 줄 수 2 = 2.4em) */
}
.event_plan_product_item>div:nth-of-type(4) {
    display: flex;
    align-items: center;
    padding-top: 5px;
}
.event_plan_product_item_event_rate,
.event_plan_product_item_discount_rate {
    color: rgba(255, 3, 102, 1);
}
.event_plan_product_item_event_price {
    padding-left: 5px;
}
.event_plan_product_item_event_principal {
    font-size: 0.9em;
    padding-left: 5px;
    color: rgba(169, 173, 178, 0.3);
    text-decoration: line-through;
}
.event_plan_title {
    padding: 10px 20px 0 20px;
    width: 100%;
    text-align: center;
}
.event_plan_event_type {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0 10px;
}
.event_plan_event_type>div {
    border: 1px solid rgba(255, 114, 153, 0.7);
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 0.9em;
    color: rgba(255, 114, 153, 1);
}

/* event comment */
.event_comment_container {
    width: 100%;
    height: 100%;
}
.event_comment_img_box {

}
.event_comment_swiper {
    width: 100%;
    height: auto;
    overflow: hidden;
}
.event_comment_swiper img {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 700 / 700;
}
.event_comment_info_title_box {
    width: 100%;
    font-size: 1.2em;
    font-weight: 500;
}
.event_comment_info_sub_title_box {
    width: 100%;
    font-size: 0.9em;
    font-weight: 300;
}
.event_comment_info_people_box {
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid rgba(233, 236, 242, 1);
}
.event_comment_info_date_box {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.event_comment_info_date_box>div:nth-of-type(1) {
    width: 30%;
    color: rgba(169, 173, 178, 1);
}
.event_comment_info_date_box>div:nth-of-type(2) {
    width: 70%;
}
.event_comment_content_box {
    width: 100%;
    overflow: hidden;
    margin-bottom: 100px;
    padding: 10px 20px 100px 20px;
}
.event_comment_content_box>div {
    width: 100%;
    overflow: hidden;
}
.event_comment_content_box>div img {
    height: auto;
}
.event_comment_footer_box {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: white;
    z-index: 1000;
}
.event_comment_no_login {
    width: 100%;
}
.event_comment_no_login button {
    width: 100%;
    height: 50px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
}
.event_comment_input_box {
    width: 100%;
    position: relative;
    padding: 0;
}
.event_comment_input_box textarea {
    width: 100%;
    /* min-height: 45px; */
    max-height: 100px;
    box-shadow: 0 0 4px 1px rgba(233, 236, 242, 1);
    border-radius: 4px;
    border: none;
    padding-right: 50px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    resize: none;
    box-sizing: border-box;
}
.event_comment_input_box textarea:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.event_comment_input_box button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    border: none;
    padding: 5px;
    background-color: rgba(255, 3, 102, 1);
    border-radius: 4px;
}
.event_comment_input_box button > span {
    color: white;
}

.event_comment_input_secret {
    position: absolute;
    top: -30px;
    right: 10px;
    display: flex;
    align-items: center;
}
.event_comment_input_secret label {
    padding-left: 5px;
}
.event_comment_input_secret input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 100%;
    background: white;
    position: relative;
}
  
.event_comment_input_secret input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}
  
.event_comment_input_secret input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);
}
  
.event_comment_input_secret input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}

.event_comment_paticification_btn {
    width: 100%;
}
.event_comment_paticification_btn button {
    width: 100%;
    height: 45px;
    border: none;
    color: rgba(19, 20, 22, 1);
}
.event_comment_paticification_txt {
    width: 100%;
    height: 45px;
    background-color: rgba(233, 236, 242, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.event_comment_modal_container {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 500;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.event_comment_modal_box {
    width: 100%;
    max-height: 100%;
    background-color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    overflow: hidden;
    transition: height 0.3s ease;
}
.event_comment_modal_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(233, 236, 242, 1);
    display: flex;
    align-items: center;
    justify-content: start;
    position: relative;
}
.event_comment_modal_header > div:nth-of-type(1) {
    padding-left: 20px;
    display: flex;
    align-items: center;
}
.event_comment_modal_header > div:nth-of-type(1) > span:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.event_comment_modal_header > div:nth-of-type(1) > span:nth-of-type(2) {
    font-size: 0.9em;
    padding-left: 5px;
    color: rgba(169, 173, 178, 1);
}
.event_comment_modal_header > div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
}
.event_comment_modal_header > div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.event_comment_modal_content {
    padding: 10px 20px;
    width: 100%;
    height: calc(100% - 50px);
    overflow-y: auto;
    padding-bottom: 150px;
}
.event_comment_modal_content_info {
    width: 100%;
    border-bottom: 1px solid rgba(248, 248, 248, 1);
    display: flex;
    padding: 10px 0;
}
.event_comment_modal_content_info button {
    border: none;
    background-color: inherit;
}
.event_comment_modal_content_info button span {
    color: rgba(169, 173, 178, 1);
}
.event_comment_modal_content_info_img {
    width: 60px;
    overflow: hidden;
}
.event_comment_modal_content_info_img > div {
    width: 100%;
    height: 50px;
    overflow: hidden;
}
.event_comment_modal_content_info_img > div img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    aspect-ratio: 100 / 100;
    border-radius: 50%;
}
.event_comment_modal_content_info_data {
    width: calc(100% - 90px);
}
.event_comment_modal_content_info_data > div:nth-of-type(1) {
    display: flex;
    align-items: flex-end;
}
.event_comment_modal_content_info_data > div:nth-of-type(1) > span:nth-of-type(1) {
    font-weight: 500;
}
.event_comment_modal_content_info_data > div:nth-of-type(1) > span:nth-of-type(2) {
    font-size: 0.9em;
    font-weight: 300;
    color: rgba(169, 173, 178, 1);
    padding-left: 10px;
}

.event_comment_modal_content_info_data > div:nth-of-type(2) {
    font-size: 0.95em;
}
.event_comment_modal_content_info_data > div:nth-of-type(3) {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.event_comment_modal_content_info_data > div:nth-of-type(3) > div {
    margin-right: 15px;
    display: flex;
    align-items: center;
}
.event_comment_modal_content_info_data > div:nth-of-type(3) > div > span {
    font-size: 0.8em;
    padding-left: 5px;
}
.event_comment_modal_content_info_data > div:nth-of-type(3) button {
    display: flex;
    align-items: center;
}
.event_comment_modal_content_info_data > div:nth-of-type(3) button span {
    font-size: 1.2em;
}
.event_comment_modal_content_info_more {
    width: 30px;
    display: flex;
    align-items: start;
    justify-content: end;
    position: relative;
}
.event_comment_secret_box {
    display: flex;
    align-items: center;
}
.event_secret_icon {
    font-size: 1em;
    color: rgba(169, 173, 178, 1);
}
.event_comment_detail_container {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.event_comment_detail_box {
    width: 50%;
    height: 80%;
    background-color: white;
    overflow: hidden;
    border-radius: 4px;
}
.event_comment_detail_header {
    width: 100%;
    height: 40px;
    box-shadow: 0 0 4px 1px rgba(233, 236, 242, 1);
    display: flex;
    align-items: center;
    justify-content: end;
}
.event_comment_detail_header > button {
    margin-right: 15px;
    border: none;
    background-color: inherit;
}
.event_comment_detail_content {
    width: 100%;
    height: calc(100% - 40px);
    overflow-y: auto;
    overflow-x: hidden;
}
.event_comment_more_options {
    position: absolute;
    background-color: white;
    box-shadow: 0 0 4px 1px rgba(233, 236, 242, 1);
    border-radius: 4px;
    padding: 0 5px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 30px;
}
.event_comment_more_options > button:nth-of-type(1) {
    width: 100%;
    background-color: #F79E05;
    margin: 5px 0;
    font-size: 0.9em;
    color: white;
    padding: 3px 0;
    border-radius: 4px;
}
.event_comment_more_options > button:nth-of-type(2) {
    width: 100%;
    background-color: rgba(255, 3, 102, 1);
    margin: 5px 0;
    font-size: 0.9em;
    color: white;
    padding: 3px 0;
    border-radius: 4px;
}
.event_comment_user_info_container {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.event_comment_user_info_box {
    width: 500px;
    height: 80%;
    background-color: white;
    border-radius: 6px;
    overflow: hidden;
}
.event_comment_user_info_header {
    width: 100%;
    height: 40px;
    box-shadow: 0 0 4px 1px rgba(233, 236, 242, 1);
    display: flex;
    align-items: center;
    justify-content: end;
}
.event_comment_user_info_header button {
    border: none;
    background-color: inherit;
    margin-right: 15px;
}
.event_comment_user_info_content {
    width: 100%;
    height: calc(100% - 40px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
}
.event_comment_user_info_content_profile {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.event_comment_user_info_content_profile > div {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
}
.event_comment_user_info_content_profile > div img {
    width: 100%;
    aspect-ratio: 1000 / 1000;
    object-fit: cover;
}
.event_comment_user_info_content_detail {
    margin-top: 30px;
}
.event_comment_user_info_content_detail > div {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(233, 236, 242, 1);
    font-size: 0.9em;
}
.event_comment_user_info_content_detail > div > div:nth-of-type(1) {
    width: 30%;
    font-weight: 500;
}
.event_comment_user_info_content_detail > div > div:nth-of-type(2) {
    width: 70%;
}
.event_comment_user_info_content_comment {
    padding: 10px 0;
}
.event_comment_user_info_content_comment > div:nth-of-type(2) {
    box-shadow: 0 0 4px 1px rgba(233, 236, 242, 1);
    padding: 5px;
    font-size: 0.9em;
    border-radius: 4px;
    margin-top: 5px;
}

/*  */
.event_comment_modal_reply_info {
    width: 100%;
    border-bottom: 1ps solid rgba(248, 248, 248, 1);
    display: flex;
    padding: 10px 0 10px 60px;
}
.event_comment_modal_reply_info button {
    border: none;
    background-color: inherit;
}
.event_comment_modal_reply_info button span {
    color: rgba(169, 173, 178, 1);
}
.event_comment_modal_reply_info_img {
    width: 10%;
    overflow: hidden;
}
.event_comment_modal_reply_info_img > div {
    width: 100%;
    height: 50px;
    overflow: hidden;
}
.event_comment_modal_reply_info_img > div img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    aspect-ratio: 100 / 100;
    border-radius: 50%;
}
.event_comment_modal_reply_info_data {
    width: 80%;
}
.event_comment_modal_reply_info_data > div:nth-of-type(1) {
    display: flex;
    align-items: flex-end;
}
.event_comment_modal_reply_info_data > div:nth-of-type(1) > span:nth-of-type(1) {
    font-weight: 500;
}
.event_comment_modal_reply_info_data > div:nth-of-type(1) > span:nth-of-type(2) {
    font-size: 0.9em;
    font-weight: 300;
    color: rgba(169, 173, 178, 1);
    padding-left: 10px;
}

.event_comment_modal_reply_info_data > div:nth-of-type(2) {
    font-size: 0.95em;
}
.event_comment_modal_reply_info_data > div:nth-of-type(3) {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.event_comment_modal_reply_info_data > div:nth-of-type(3) > div {
    margin-right: 15px;
    display: flex;
    align-items: center;
}
.event_comment_modal_reply_info_data > div:nth-of-type(3) > div > span {
    font-size: 0.8em;
    padding-left: 5px;
}
.event_comment_modal_reply_info_data > div:nth-of-type(3) button {
    display: flex;
    align-items: center;
}
.event_comment_modal_reply_info_data > div:nth-of-type(3) button span {
    font-size: 1.2em;
}
.event_reply_input_box {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}
.event_reply_input_box textarea {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid rgba(233, 236, 242, 1);
    padding: 10px;
    font-size: 0.9em;
}
.event_reply_input_box textarea:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.event_reply_input_box button {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translate(0, -50%);
    padding: 5px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    border-radius: 4px;
}
.event_reply_input_box button>span {
    color: white;
}
.event_like_active span {
    color: rgba(255, 3, 102, 1) !important;
}
.best_comment {
    box-shadow: 0 0 4px 1px rgba(255, 114, 153, 1);
    padding: 10px !important;
}
.event_comment_modal_best_content_info {
    width: 100%;
    border-bottom: 1px solid rgba(248, 248, 248, 1);
    padding: 10px 0;
    position: relative;
    border-radius: 4px;
}
.event_comment_modal_best_content_top {
    width: 100%;
    display: flex;
}
.event_comment_modal_best_content_ribbon {
    position: absolute;
    right: -5px; top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px; height: 75px;
    text-align: right;
}
.event_comment_modal_best_content_ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #79A70A;
    background: linear-gradient(#F79E05 0%, #8F5408 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px; right: -21px;
}
.event_comment_modal_best_content_ribbon span::before {
    content: "";
    position: absolute; left: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid #8F5408;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #8F5408;
}
.event_comment_modal_best_content_ribbon span::after {
    content: "";
    position: absolute; right: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #8F5408;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #8F5408;
}
.event_comment_modal_best_content_top button {
    border: none;
    background-color: inherit;
}

/* option select */
.event_raffle_option_background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 200;
    background-color: rgba(72, 76, 81, 0.3);
}
.event_raffle_option_box {
    position: fixed;
    bottom: 0;
    width: 500px;
    height: 80%;
    background-color: white;
    border: 1px solid blue;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.event_raffle_option_select {
    width: 100%;
    height: 55px;
}
.event_payment_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}
.event_main_banner {
    padding-top: 5px;
    width: 100%;
    height: auto;
    overflow: hidden;
}
.event_main_banner img {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 700 / 700;
}
.main_banner_swiper :global(.swiper-pagination) {
    display: none;
}


/* 결제창 */
.event_orderer_info_box {
    width: 100%;
    padding: 5px 0;
}
.event_orderer_info_box div:nth-of-type(1){
    width: 15%;
    color: rgba(169, 173, 178, 1);
}
.event_orderer_info_box div:nth-of-type(2){
    width: 85%;
}
.event_delivery_change_box button {
    border: none;
    background-color: inherit;
    text-decoration: underline;
    transition: all 0.3s;
}
.event_delivery_change_box button:hover {
    color: rgba(255, 3, 102, 1);
}
.event_delivery_content_box div {
    padding: 3px 0;
}
.event_delivery_content_box div:nth-of-type(1)>span {
    background-color: rgba(241, 242, 243, 1);
    border-radius: 30px;
    padding: 5px 10px;
    font-size: 0.7em;
}
.event_payment_product_info_box {
    width: 100%;
    align-items: center;
}
.event_payment_img {
    width: 25%;
}
.event_payment_img div {
    width: 90px;
    height: 90px;
    overflow: hidden;
    border-radius: 8px;
}
.event_payment_img>div>img {
    width: 100%;
    height: 90px;
    object-fit: cover;
}
.event_payment_title {
    width: 75%;
}
.event_payment_title div:nth-of-type(1){
    font-size: 0.8em;
    color: rgba(169, 173, 178, 1);
}
.event_payment_title div:nth-of-type(2){}


.event_container {
    width: 100%;
    height: 75vh;
    overflow-y: auto;
}
.event_stamp_img_box {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
}
.event_stamp_img_box img {
    width: 100%;
    height: 50px;
    object-fit: cover;
}
.event_stamp_add_box {
    border: none;
    background-color: inherit;
    text-decoration: underline;
    color: rgba(169, 173, 178, 1);
}
.event_stamp_footer_ment {
    text-align: center;
    color: rgba(255, 3, 102, 1);
}
.event_attendance_btn {
    width: 100%;
    height: 60px;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border: none;
    border-radius: 8px;
}
.event_attendance_btn_check {
    width: 100%;
    height: 60px;
    background-color: rgba(169, 173, 178, 1);
    color: white;
    border: none;
    border-radius: 8px;
}
.event_middle_stamp_add_modal_container {
    width: 100%;
    height: 100vh;
    background-color: rgba(169, 173, 178, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    margin-bottom: 500px;
}
.event_middle_stamp_add_modal_box {
    position: absolute;
    width: 100%;
    height: 50%;
    background-color: white;
    bottom: 0;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
}
.event_middle_stamp_add_modal_header {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    height: 50px;
    justify-content: center;
}
.event_middle_stamp_add_modal_header>div:nth-of-type(1) {
    font-size: 1.1em;
}
.event_middle_stamp_add_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.event_middle_stamp_add_modal_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.event_middle_stamp_add_modal_content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30vh;
    flex-direction: column;
    position: relative;
}
.event_middle_stamp_add_modal_content>div:nth-of-type(1) {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
}
.event_middle_stamp_add_modal_content>div:nth-of-type(1) img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.event_middle_stamp_add_modal_content>div:nth-of-type(2) {
    width: 120px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px 0;
}
.event_middle_stamp_add_modal_content>div:nth-of-type(2)>div {
    width: 40%;
}
.event_middle_stamp_add_modal_content>div:nth-of-type(2) button {
    width: 100%;
    height: 30px;
    border: 1px solid rgba(255, 114, 153, 1);
    background-color: inherit;
    font-size: 0.9em;
    border-radius: 4px;
    color: rgba(255, 114, 153, 1);
}
.event_middle_stamp_add_modal_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    margin: 10px 0;
}
.event_middle_stamp_add_modal_footer button {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
}
.event_middle_stamp_add_modal_info {
    padding: 0 40px;
    font-size: 0.8em;
    color: rgba(255, 114, 153, 1);
}
.event_middle_attend_check_modal_container {
    width: 100%;
    height: 100vh;
    background-color: rgba(169, 173, 178, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
}
.event_middle_attend_check_modal_box {
    position: absolute;
    width: 80%;
    height: 20%;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
}
.rolling_numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    overflow: hidden;
}

.number {
    font-size: 1rem;
    animation: roll 0.1s linear infinite;
}

.event_middle_attend_check_number_box {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.event_middle_attend_check_modal_footer {
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding: 0 20px;
}
.event_middle_attend_check_modal_footer button {
    width: 100%;
    height: 50px;
    border: none;
    background-color: rgba(233, 236, 242, 1);
    color: white;
    font-size: 0.9em;
    border-radius: 4px;
}
.event_middle_attend_check_modal_footer_active {
    background-color: rgba(255, 3, 102, 1) !important;
}
.event_attend_check_link_box {
    position: absolute;
    bottom: 10%;
    left: 0;
    width: 100%;
}
.event_attendance_link_btn {
    width: 100%;
    height: 40px;
    border: none;
    background-color: rgba(255, 114, 153, 0.7);
    color: white;
    font-size: 0.9em;
}
.event_attendency_popup {

}
.event_attendency_title {
    font-size: 1.1em !important;
    font-weight: 500;
    padding-bottom: 5px;
}
.event_attendency_sub_title {
    font-size: 0.9em !important;
    font-weight: 300;
    color: rgba(255, 3, 102, 1);
    padding-bottom: 10px;
}
.event_attendency_btn_box {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.event_attendency_btn_box>button:nth-of-type(1) {
    border: 1px solid rgba(233, 236, 242, 1);
    background-color: inherit;
    width: 100%;
    height: 40px;
    font-size: 0.9em;
    border-radius: 4px;
}
.event_attendency_btn_box>button:nth-of-type(2) {
    width: 100%;
    height: 40px;
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    font-size: 0.9em;
    border-radius: 4px;
}
.event_notification_tab {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0;
}
.event_notification_tab>div {
    padding: 0 10px;
    font-size: 0.9em;
}
.event_notification_tab button {
    border: none;
    background-color: inherit;
}
.event_notice_announce_active {
    border-bottom: 2px solid rgba(255, 114, 153, 1);
}
.event_notification_content {
    width: 100%;
    height: 60vh;
    overflow-y: auto;
}
.event_notice_announce_all_box {
    width: 100%;
}
.event_notice_announce_all_box>div:nth-of-type(1) {
    padding: 20px 0;
    font-size: 1.1em;
}
.event_notice_announce_notification_box {
    border-bottom: 1px solid rgba(233, 236, 242, 1);
    padding-bottom: 20px;
}
.event_notice_announce_no_item_box {
    font-size: 0.9em;
    text-align: center;
    padding: 20px 0;
}
.event_notice_announce_announcement_box {
    padding-top: 20px;
}
.event_notification_item_box {
    width: 100%;
    max-height: 250px;
    overflow: hidden;
}
.event_notification_item {
    border-bottom: 1px solid rgba(233, 236, 242, 1);
    padding: 15px 5px;
    cursor: pointer;
}
.event_notification_item:hover,
.event_notification_item:active {
    background-color: rgba(241, 242, 243, 1);
    border-radius: 4px;
}
.event_notification_item>div:nth-of-type(1) {padding: 5px 0; color: rgba(19, 20, 22, 1); font-size: 0.9em;}
.event_notification_item>div:nth-of-type(2) {color: rgba(169, 173, 178, 1); font-size: 0.8em;}
.event_notification_more_btn {
    width: 100%;
    padding: 5px;
}
.event_notification_more_btn button {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: none;
    background-color: rgba(233, 236, 242, 1);
    font-size: 0.9em;
    transition: all 0.2s;
}
.event_notification_more_btn button:hover,
.event_notification_more_btn button:active {
    background-color: rgba(255, 114, 153, 0.2);
}
.event_announcement_item_box {
    width: 100%;
    max-height: 250px;
    overflow: hidden;
}
.event_announcement_item {
    border-bottom: 1px solid rgba(233, 236, 242, 1);
    padding: 15px 5px;
    cursor: pointer;
}
.event_announcement_item:hover,
.event_announcement_item:active {
    background-color: rgba(241, 242, 243, 1);
    border-radius: 4px;
}
.event_announcement_item>div:nth-of-type(1) {padding: 5px 0; color: rgba(19, 20, 22, 1); font-size: 0.9em;}
.event_announcement_item>div:nth-of-type(2) {color: rgba(169, 173, 178, 1); font-size: 0.8em;}
.event_notification_more_btn {
    width: 100%;
    padding: 5px;
}
.event_announcement_more_btn button {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: none;
    background-color: rgba(233, 236, 242, 1);
    font-size: 0.9em;
    transition: all 0.2s;
}
.event_announcement_more_btn button:hover,
.event_announcement_more_btn button:active {
    background-color: rgba(255, 114, 153, 0.2);
}
.event_announcement_tab {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.9em;
    margin: 5px 0;
}
.event_announcement_tab>div {
    margin: 0 5px;
}
.event_announcement_tab>div button {
    border: none;
    background-color: rgba(233, 236, 242, 1);
    border-radius: 30px;
    padding: 5px 10px;
}
.event_announcement_tab_active {
    background-color: rgba(255, 114, 153, 1) !important;
    color: white !important;
}

/* 이벤트 스토어 상품 더보기 */
.event_store_more_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(233, 236, 242, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.event_store_more_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.event_store_more_header>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.event_store_more_header>div:nth-of-type(2) {font-size: 1.1em;}
.event_store_more_content {
    width: 100%;
    height: 90vh;
    overflow-y: auto;
}
.event_store_more_last_txt {
    width: 100%;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* 공지 상세 */
.notification_header {
    position: relative;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(233, 236, 242, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}
.notification_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.notification_header>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.notification_header>div:nth-of-type(2) {
    font-size: 1.1em;
}
.notification_content {
    width: 100%;
    height: calc(100svh - 50px);
    overflow-y: auto;
}
.notification_content_title {
    padding: 20px 20px 30px 20px;
    font-size: 1.1em;
    font-weight: bold;

}
.notification_content_date {
    padding: 0 20px 20px 20px;
    font-size: 0.9em;
    color: rgba(169, 173, 178, 1);
    border-bottom: 1px solid rgba(233, 236, 242, 1);
}
.notification_content_info {
    padding: 20px;
}
.notification_content_info img {
    width: 100%;
    height: auto;
}

/* 공지 발표 */
.announcement_header {
    position: relative;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(233, 236, 242, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}
.announcement_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.announcement_header>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.announcement_header>div:nth-of-type(2) {
    font-size: 1.1em;
}
.announcement_content {
    width: 100%;
    height: 90vh;
    overflow-y: auto;
}
.announcement_content_title {
    padding: 20px 20px 30px 20px;
    font-size: 1.1em;
    font-weight: bold;

}
.announcement_content_date {
    padding: 0 20px 20px 20px;
    font-size: 0.9em;
    color: rgba(169, 173, 178, 1);
    border-bottom: 1px solid rgba(233, 236, 242, 1);
}
.announcement_content_info {
    padding: 20px;
}
.announcement_content_info img {
    width: 100%;
}
.announcement_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}
.announcement_footer>div {
    width: 100%;
}
.announcement_footer>div button {
    width: 100%;
    height: 50px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
}

.support_content_price_rate_star_box {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  .support_content_price_rate_star_box>div:nth-of-type(1) {
    display: flex;
    align-items: center;
  }
  .support_content_price_rate_star_box>div:nth-of-type(1)>div:nth-of-type(1) {
    width: 20px;
    height: 20px;
    overflow: hidden;
  }
  .support_content_price_rate_star_box>div:nth-of-type(1)>div:nth-of-type(1) img {
    width: 100%;
    height: 20px;
    object-fit: contain;
  }
  .support_content_price_rate_star_box>div:nth-of-type(1)>div:nth-of-type(2) {
    font-size: 0.9em;
    color: rgba(169, 173, 178, 1);
  }
  .support_content_price_rate_star_box>div:nth-of-type(2) {
    padding-left: 10px;
    font-size: 0.9em;
    color: rgba(169, 173, 178, 1);
  }
  .support_content_price_rate_star_box>div:nth-of-type(3) {
    padding-left: 10px;
    display: flex;
    align-items: center;
    color: rgba(255, 3, 102, 1);
    font-size: 1.1em;
    font-weight: 700;
  }

@keyframes roll {
    0% { transform: translateY(0); }
    100% { transform: translateY(-100%); }
}

@media (max-width : 700px) {
    .event_raffle_footer_box {
        position: fixed;
    }
}
@media (max-width : 480px) {
    .event_vote_content_two {
        width: 25%;
    }
    .event_vote_content_four {
        width: 10%;
    }
}

@media (max-width : 400px) {
    .event_middle_tab_btn_box button {
        border: none;
        padding: 3px 10px;
        border-radius: 30px;
        font-size: 0.8em;
    }
}
@media (max-width : 390px) {
    .event_vote_content_two > div {
        width: 60px;
        height: 60px;
    }
}
@media (max-width : 350px) {
    .event_middle_tab_btn_box button {
        border: none;
        padding: 2px 9px;
        border-radius: 30px;
        font-size: 0.75em;
    }
}

@media screen and (max-height : 748px){
    .event_attend_check_link_box {
        bottom: 12%;
    }
}
@media screen and (max-height : 670px){
    .event_attend_check_link_box {
        bottom: 13%;
    }
}