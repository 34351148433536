.smiming_select_btn {
    width: 100%;
    display: flex;
}
.smiming_select_btn.fixed {
    width: 500px;
    position: fixed;
    top: 0; /* 상단에 고정 */
    background-color: white;
    /* 필요한 추가 스타일 */
}
.smiming_select_btn button {
    width: 100%;
    height: 40px;
    border : none;
    background-color: inherit;
    font-size: 0.8em;
}
.active_btn {
    border-bottom: 3px solid rgba(255, 3, 102, 1);
}
.smiming_chart_box_left {
    width: 10%;
}
.smiming_chart_box_middle {
    width: 25%;
    border-radius: 12px;
    overflow: hidden;
}
.smiming_chart_box_middle img {
    width: 100%;
    object-fit: cover;
}
.smiming_chart_box_right {
    width: 65%;
}
.smiming_chart_box_right div:nth-of-type(1){
    font-size: 0.7em;
    color: rgba(115, 122, 130, 1);
}
.smiming_chart_box_right div:nth-of-type(2){
    font-size: 0.9em;
}
.smiming_chart_box_right div:nth-of-type(3){
    font-size: 0.7em;
}
.smiming_no_favority_data {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}