
  
.seller_container {
    width: 100%;
    min-height: 100vh; /* 최소 높이를 뷰포트 높이로 설정 */
    position: relative; /* 상대 위치 지정 */
}
.seller_container li{
    list-style: disc;
}
.seller_header {
    position: fixed;
    width: 100%;
    z-index: 1001;
    top: 0;
}
.seller_header_background {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    background-color: rgba(255, 239, 243, 1);
}

.seller_header_logo img {
    width: 115px;
    height: 23px;
}

.seller_header_title {
    display: flex;
}

.seller_header_menu_btn {
    background-color: inherit;
    border: none;
    display: none;
}
.seller_edit_move_title {
    width: 100%;
    font-size: 1.1em;
    font-weight: 500;
}
.seller_edit_move_sub_title {
    font-size: 0.8em;
    color: rgba(115, 122, 130, 1);
}
.seller_edit_move_btn {
    width: 100%;
    padding: 20px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.seller_edit_move_btn>button:nth-of-type(1) {
    width: 40%;
    height: 45px;
    border: 1px solid rgba(169, 173, 178, 1);
    background-color: inherit;
    color: black;
    margin: 0 5px;
}
.seller_edit_move_btn>button:nth-of-type(2) {
    width: 40%;
    height: 45px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    margin: 0 5px;
}
.seller_login_box {
    width: 375px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 8px;
}
.seller_login_box img {
    width: 161px;
    height: 31px;
}
.seller_login_box_header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.seller_login_box_header>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.seller_login_box_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.seller_login_box_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.seller_login_box_content {
    width: 100%;
    padding: 20px;
}
.seller_login_box_footer {
    width: 100%;
    padding: 20px;
}
.seller_login_input {
    width: 100%;
    height: 50px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 8px;
    padding-left: 10px;
}
.seller_login_input::placeholder {
    font-size: 0.9em;
    color: rgba(169, 173, 178, 1);
}
.seller_login_join_pw_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}
.seller_login_join_pw_box>div:nth-of-type(2) {
    display: flex;
    align-items: end;
    justify-content: end;
    flex-direction: column;
}
.seller_login_check_box input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 3px;
    background: #fff;
    position: relative;
}
  
.seller_login_check_box input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid rgba(255, 3, 102, 1);
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}
  
.seller_login_check_box input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
}
  
.seller_login_check_box input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.seller_login_pwchange_btn {
    display: flex;
    align-items: center;
}
.seller_login_pwchange_btn button{
    border: none;
    background-color: inherit;
    color: rgba(115, 122, 130, 1);
}
.seller_login_btn {
    width: 100%;
    padding: 10px 20px 20px 20px;
}
.seller_login_btn button{
    width: 100%;
    height: 50px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
    border-radius: 4px;
}
.seller_idpw_check {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: rgba(255, 239, 243, 1);
    border-radius: 12px;
}
.seller_idpw_check img {
    width: 12px;
    height: 12px;
}
.seller_idpw_check span {
    font-size: 0.8em;
    color: rgba(255, 92, 92, 1);
}
.h_400 {
    height: 430px !important;
}
.h_480 {
    height: 480px !important;
}
.seller_btn {
    width: 100%;
    height: 50px;
    background-color: rgba(255, 3, 102, 1) !important;
}
.seller_swal_title {
    font-size: 1.4em !important;
}
.seller_popup {
    width: 400px;
}

.seller_content_container {
    width: 100%;
    overflow: hidden;
}

/* Seller Side */
.seller_side_container {
    position: fixed;
    margin-top: 70px;
    width: 250px;
    height: 100%;
    box-shadow: 0 0 6px 1px rgba(248, 248, 248, 1);
    font-size: 0.8em;
    overflow-y: scroll;
}
.seller_side_profile {
    position: fixed;
    width: 250px;
    background-color: white;
    z-index: 100;
}
.seller_side_menu {
    height: 100%;
    overflow-y: auto;
    margin-top: 100px;
    margin-bottom: 100px;
}
.seller_side_menu>div {
    height: 100%;
}

/* Seller right Side */
.seller_right_container {
    margin-top: 70px;
    margin-left: 250px;
    /* width: 100%; */
    overflow: hidden;
}
/* Seller dashboard */
.seller_dashboard_container {
    margin-top: 70px;
    margin-left: 250px;
    /* width: 100%; */
    height: 100%;
    position: relative;
}
.seller_menu_icon_box {
    width: 24px;
    height: 24px;
}
.seller_menu_icon_box img {
    width: 100%;
    object-fit: cover;
}
.seller_menu_btn {
    position: relative;
    width: 100%;
    border-radius: 12px;
    border: none;
    background-color: inherit;
    padding: 0.8em;
}
.seller_menu_name_box span {
    line-height: 24px; /* 이미지의 높이와 일치 */
}
.seller_menu_active {
    background-color: rgba(255, 239, 243, 1) !important;
}
.seller_allow_icon {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translate(0, -50%);
}


/* 대시보드 */
.seller_dashboard_tab_btn {
    width: 100%;
    display: flex;
}
.seller_dashboard_tab_btn button {
    width: 100%;
    border: none;
    background-color: inherit;
}
.seller_dashboard_tab_active {
    border-bottom: 3px solid rgba(255, 114, 153, 1);
}
.seller_border_bottom_color {
    border-bottom: 2px solid rgba(255, 224, 232, 1);
}
.seller_dashboard_info_box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    box-shadow: 0 0 6px 1px rgba(223, 224, 226, 1);
    border-radius: 12px;
    font-size: 0.9em;
}
.seller_dashboard_visit_box, .seller_dashboard_order_box, .seller_dashboard_revenue_box {
    width: 33.33%;
}
.seller_dashboard_current_box {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.seller_dashboard_current_order, .seller_dashboard_current_cancel, .seller_dashboard_current_review {
    width: 32%;
    box-shadow: 0 0 6px 1px rgba(223, 224, 226, 1);
    border-radius: 12px;
    font-size: 0.9em;
}
.dashboard_calendar_data_box {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    text-align: left;
    padding: 0 10px;
}
.dashboard_calendar_data_box>div:nth-of-type(1) {
    color: rgba(255, 3, 102, 1);
    font-weight: 500;
}
.dashboard_calendar_data_box>div:nth-of-type(2) {
    font-size: 0.9em;
}
.dashboard_calendar_no_data_box {

}
.seller_date_select {
    position: absolute;
    right: 0;
    top: 100%;
    box-shadow: 0 0 6px 1px rgba(223, 224, 226, 1);
}
.seller_footer_btn_box button{
    border: none;
    background-color: inherit;
}

.seller_dashboard_support_current_box {
    display: flex;
    width: 100%;
}
.seller_board_toggle_menu_btn {
    width: 100%;
    background-color: inherit;
    border: none;
    text-align: left;
    color: rgba(169, 173, 178, 1)
}

/* board */
.seller_board_box {
    width: 100%;
    display: flex;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    cursor: pointer;
    transition: all 0.2s;
    font-size: 0.9em;
    color: rgba(19, 20, 22, 1);
}
.seller_board_box:hover {
    background-color: rgba(255, 3, 102, 0.1);
}
.seller_board_no_box {
    width: 30%;
}
.seller_board_title_box {
    width: 40%; overflow:hidden; text-overflow:ellipsis; white-space:nowrap;
}
.seller_board_date_box {
    width: 30%;
    text-align: right;
}
.seller_board_content_box {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 0.9em;
    background-color: rgba(248, 248, 248, 1);
}
.seller_board_content_info {
    width: 70%;
}
.seller_signature_color {
    color: rgba(255, 3, 102, 1) !important;
}
.seller_store_info_textbox {
    position: relative;
      width: 100%;
}
.seller_shop_detail_preview {
    width: 100%;
    height: 300px;
    overflow: hidden;
}
.seller_shop_detail_preview img {
    width: 100%;
    height: auto;
    object-fit: cover;
}
.seller_shop_detail_btn {
    position: absolute;
    bottom: -40px;
    width: 100%;
}
.seller_shop_detail_btn button {
    background-color: rgba(248, 248, 248, 0.8);
    border: none;
    width: 100%;
    height: 40px;
    transition: all 0.3s;
}
.seller_shop_detail_btn button:hover {
    opacity: 0.3;
}
.seller_shop_detail_height_auto {
    height: auto !important;
}
.seller_store_info_textbox textarea {
    width: 100%;
    border-radius: 12px;
    border: 1px solid rgba(223, 224, 226, 1);
    padding-top: 10px;
    padding-left: 10px;
    font-size: 0.9em;
}
.seller_store_info_textbox textarea:focus {
    border: 1px solid rgba(255, 3, 102, 1) !important;
}
.seller_settlement_left {
    width: 30%;
    font-size: 0.9em;
}
.seller_settlement_middle {
    width: 40%;
}
.seller_settlement_middle input {
    width: 100%;
    height: 50px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 8px;
    padding-left: 10px;
    font-size: 0.9em;
}
.seller_settlement_middle input:focus,
.seller_settlement_middle textarea:focus {
    border: 1px solid rgba(255, 3, 102, 1) !important;
    outline: none;
}
.seller_settlement_middle textarea {
    width: 100%;
    resize: none;
    height: 200px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 5px;
}
.seller_settlement_right {
    width: 30%;
    font-size: 0.8em;
    color: rgba(169, 173, 178, 1)
}
.seller_settlement_store_profile_img {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid rgba(223, 224, 226, 1);
}
.seller_settlement_store_profile_img>img {
    width: 150px;
    height: 150px;
    object-fit: cover;
}
.seller_settlement_default_img {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.seller_settlement_store_banner_img {
    width: 100%;
    height: 150px;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid rgba(223, 224, 226, 1);
}
.seller_settlement_store_banner_img>img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}
.seller_settlement_save_btn button{
    width: 400px;
    height: 50px;
    border-radius: 8px;
    border: none;
    color: rgba(169, 173, 178, 1);
}

.seller_store_review_img_box img {
    width: 80px;
    height: 80px;
}
.seller_store_review_content_box {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.seller_store_review_box {
    cursor: pointer;
    transition: all 0.2s;
}
.seller_store_review_box:hover {
    background-color: rgba(255, 224, 232, 0.2);
}
.seller_review_modal_container {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(19, 20, 22, 0.7);
    z-index: 100;
    top: 0;
    left: 0;
}
.seller_review_modal {
    position: absolute;
    width: 80%;
    height: 856px;
    background-color: white;
    border-radius: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.seller_store_review_left{
    width: 20%;
}
.seller_store_review_right {
    width: 80%;
}
.seller_store_review_product_imgbox img {
    width: 80px;
    height: 80px;
    border-radius: 8px;
}
.seller_store_review_img {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 8px;
}
.seller_store_review_img img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}
.seller_store_review_right textarea {
    width: 100%;
    height: 8em;
    border: 1px solid rgba(223, 224, 226, 1);
    resize: none;
    border-radius: 8px;
    padding-left: 10px;
    padding-top: 10px;
}
.seller_store_review_right textarea:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.seller_store_review_close {
    cursor: pointer;
}
.seller_store_review_btn button {
    width: 300px;
    height: 50px;
    border-radius: 8px;
    border: none;
}
.success_seller_review_popup {
    width: 400px;
}
.seller_store_review_active {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.success_seller_review_title {
    font-size: 1.1em;
}
.success_seller_review_text {
    font-size: 1em !important;
}
.success_seller_review_btn {
    width: 100%;
    height: 60px;
    background-color: rgba(255, 3, 102, 1) !important;
}
.success_seller_review_actions {
    margin: 0 !important;
}

.seller_shop_left {
    width: 30%;
    font-size: 0.9em;
}
.seller_shop_middle {
    width: 40%;
}
.seller_shop_middle input {
    width: 100%;
    height: 50px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 8px;
    padding-left: 10px;
    font-size: 0.9em;
}
.seller_shop_total {
    width: 100%;
    font-size: 0.9em;
    color: rgba(169, 173, 178, 1);
}
.seller_bank_select_modal_container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(19, 20, 22, 0.2);
    width: 100%;
    height: 100%;
    z-index: 999;
}
.seller_bank_select_modal_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 12px;
    width: 50vw;
    height: 50vh;
}
.seller_bank_select_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    position: relative;
}
.seller_bank_select_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
}
.seller_bank_select_modal_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.seller_bank_select_modal_tab {
    display: flex;
    width: 100%;
    padding: 10px 0;
}
.seller_bank_select_modal_tab>div {
    width: 100%;
}
.seller_bank_select_modal_tab>div button {
    width: 100%;
    border: none;
    background-color: inherit;
}
.bank_select_active {
    border-bottom: 3px solid rgba(255, 3, 102, 1) !important;
}
.seller_bank_select_modal_content {
    padding: 20px;
}
.seller_bank_select_modal_bank_box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    height: 35vh;
    overflow-y: scroll;
}
.seller_bank_select_modal_bank_box::-webkit-scrollbar {
    width: 5px;  /* 스크롤바의 너비 */
    height: 5px;
}
.seller_bank_select_modal_bank_box::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.seller_bank_select_modal_bank_box::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.seller_bank_select_modal_bank_box>button {
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    display: flex;
    align-items: center;
    font-size: 0.7em;
    margin: 3px;
    padding: 5px;
    border-radius: 8px;
    transition: all 0.2s;
}
.seller_bank_select_modal_bank_box>button:hover,
.seller_bank_select_modal_bank_box>button:active {
    border: 1px solid rgba(255, 3, 102, 1);
}
.seller_bank_select_modal_bank_box>button>div:nth-of-type(1) {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid rgba(255, 114, 153, 0.3);
}
.seller_bank_select_modal_bank_box>button>div:nth-of-type(1) img {
    width: 100%;
    object-fit: cover;
}
.seller_bank_select_modal_bank_box>button>div:nth-of-type(2) {
    padding-left: 2px;
}
.seller_shop_setting_bank_select_btn {
    width: 100%;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 8px;
    height: 40px;
    font-size: 0.9em;
    background-color: inherit;
}
.seller_shop_setting_bank_select_btn.seller_bank_no_data {
    text-align: center;
}
.seller_shop_setting_bank_select_btn.seller_bank_data {
    text-align: center;
}
.seller_shop_setting_bank_select_btn.seller_bank_data>div {
    display: flex;
    align-items: center;
    justify-content: center;
}
.seller_shop_setting_bank_select_btn.seller_bank_data>div>div:nth-of-type(1) {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid rgba(255, 114, 153, 0.3);
}
.seller_shop_setting_bank_select_btn.seller_bank_data>div>div:nth-of-type(2) {
    padding-left: 2px;
}
.seller_shop_setting_bank_select_btn.seller_bank_data>div>div:nth-of-type(1) img  {
    width: 100%;
    object-fit: cover;
}

.shop_bank_select {
    width: 100%;
    height: 50px;
}
.seller_date_close {
    position: absolute;
    top: 25px;
    right: 20px;
}
.seller_date_close button {
    border: none;
    background-color: inherit;
}
.seller_date_title {
    text-align: left;
    font-size: 0.9em;
    color: rgba(255, 114, 153, 1);
}
.seller_date_popup {
    width: 80%;
    height: 80vh;
    border-radius: 12px !important;
}
.seller_date_selectedate_box {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.seller_date_selectedate_img_box {
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.seller_date_selectedate_img_box>div:nth-of-type(2) {
    width: 100%;
    height: 300px;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
}
.seller_event_delete {
    width: 100px;
    height: 50px;
    border-radius: 4px;
    border: none;
    font-size: 0.8em;
}
.seller_event_confirm {
    width: 100px;
    height: 50px;
    border-radius: 4px;
    border: none;
    font-size: 0.8em;
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.seller_event_title {
    font-size: 0.9em;
    color: rgba(255, 114, 153, 1);
    text-align: left;
}
.seller_event_title>div:nth-of-type(2) {
    display: flex;
    align-items: center;

}
.seller_date_selectedate_text {
    width: 15%;
    text-align: left;
    font-size: 0.9em;
}
.seller_date_selectedate_date {
    width: 85%;
    text-align: left;
}
.seller_date_input {
    width: 100%;
    height: 50px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding: 10px;
    font-size: 0.9em;
}
.seller_date_input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.seller_date_textarea {
    width: 100%;
    height: 250px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding: 10px;
    font-size: 0.9em;
}
.seller_event_img_select {
    border: 1px solid rgba(255, 3, 102, 1);
    border-radius: 4px;
    background-color: inherit;
    color: rgba(255, 3, 102, 1);
    width: 100%;
    height: 50px;
    font-size: 0.8em;
}
.seller_date_textarea:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.seller_date_html_container {
    line-height: inherit !important;
}
.seller_date_confirm, .seller_date_cancel {
    width: 300px !important;
    height: 50px !important;
    border: none !important;
    border-radius: 8px;
    margin: 0 12px;
    font-size: 0.9em;
}
.seller_date_confirm {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.seller_date_success_header {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 25px;
}
.seller_date_success_header_btn {
    position: absolute;
    top: 25px;
    right: 20px;
}
.seller_date_success_header_btn button {
    border: none;
    background-color: inherit;
}
.seller_date_success_popup {
    width: 80%;
    height: 50vh;
}
.seller_date_success_title {
    text-align: left;
    font-size: 0.9em;
    color: rgba(255, 114, 153, 1);
}
.seller_date_success_btn {
    padding-top: 50px;
}
.seller_date_success_btn button {
    width: 300px;
    height: 50px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 8px;
}
.seller_calendar_modal_container {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    z-index: 1500;
    display: flex;
    align-items: center;
    justify-content: center;
}
.seller_calendar_modal_box {
    width: 50%;
    height: 50%;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
}
.seller_calendar_modal_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0 20px;
}
.seller_calendar_modal_header button {
    border: none;
    background-color: inherit;
}
.seller_calendar_modal_content {
    width: 100%;
    height: calc(100% - 80px);
    padding: 20px;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.seller_calendar_modal_content > div {
    width: 50%;
}
.seller_calendar_modal_content > div img {
    width: 100%;
    height: auto;
}
.seller_calendar_modal_footer {
    width: 100%;
    height: 30px;
}
.seller_calendar_modal_footer button {
    width: 100%;
    height: 30px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
}

.seller_store_date_item_select {
    width: 250px;
    height: 30px;
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    border-radius: 4px;
}
.seller_store_date_item_select div:nth-of-type(1) {
    width: 90%;
    text-align: left;
    overflow:hidden; 
    text-overflow:ellipsis; 
    white-space:nowrap;
    padding-left: 5px;
}
.seller_store_date_item_select div:nth-of-type(2) {
    width: 10%;
}
.seller_store_date_item_box {
    position: absolute;
    width: 550px;
    top: 100%;
    right: 0;
    background-color: white;
    box-shadow: 0 0 6px 3px rgba(223, 224, 226, 1);
    border-radius: 12px;
    margin-top: 10px;
    z-index: 10;
}
.seller_store_date_itemlist_box {
    display: flex;
    align-items: center;
    margin: 12px;
}

.seller_store_date_itemlist_box input {
    align-items: center;
    vertical-align: middle;
}
.seller_store_date_itemlist_box input[type="radio"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}
  
.seller_store_date_itemlist_box input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}
  
.seller_store_date_itemlist_box input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}
  
.seller_store_date_itemlist_box input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}
.seller_store_date_itemlist_box div:nth-of-type(1){
    width: 5%;
}
.seller_store_date_itemlist_box div:nth-of-type(2){
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 12px;
}
.seller_store_date_itemlist_box div:nth-of-type(2) img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 12px;
}
.seller_store_date_itemlist_box div:nth-of-type(3){
    width: 75%;
}
.seller_calander {
    margin-bottom: 100px;
}
.seller_calander > :global(.react-calendar) {
    height: auto !important;
}
.seller_store_regular_box {
    width: 100%;
    box-shadow: 0 0 6px 3px rgba(223, 224, 226, 1);
    border-radius: 12px;
}
.seller_stroe_regular_update_box {
    border-bottom: 1px solid rgba(255, 114, 153, 1);
}
.seller_stroe_regular_update_box button {
    background-color: inherit;
    width: 80px;
    height: 30px;
    font-size: 0.9em;
    border-radius: 6px;
    border: 1px solid rgba(255, 114, 153, 1);
}
.seller_gray_color {
    color: rgba(115, 122, 130, 1);
}
.seller_signature_color2 {
    color: rgba(255, 114, 153, 1);
}

.seller_regular_add_btn_box {
    display: flex;
    align-items: center;
    vertical-align: middle;
    height: 30px;
    cursor: pointer;
    transition: all 0.3s;
}
.seller_regular_add_btn_box:hover {
    opacity: 0.5;
}
.seller_regular_add_text {
    width: 100%;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -40%);
    color: rgba(255, 114, 153, 1);
}
.seller_regular_select_btn {
    width: 150px;
    height: 50px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 8px;
    padding-left: 5px;
}

.seller_regular_select_btn::-ms-expand { 
	display: none;
}
.seller_regular_select_btn {
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.seller_regular_select_btn {
    width: 150px;
    height: 35px;
    background: url('https://icons.veryicon.com/png/o/miscellaneous/rookie-30-official-icon-library/arrow-list-expand-1.png') calc(100% - 5px) center no-repeat;
    background-size: 20px;
    padding: 5px 30px 5px 10px;
    border-radius: 4px;
    outline: 0 none;
  }
.seller_regular_select_btn option {
    height: 30px !important;
}
.seller_regular_select_remove {
    border: none;
    width: 35px;
    height: 35px;
    background-color: inherit;
    border-radius: 50%;
}
.seller_date_save_popup {
    width: 30%;
    height: 20vh;
}
.seller_date_save_title {
    font-weight: bold;
}
.seller_date_save_content {
    font-size: 0.8em;
    padding: 5px 20px;
}
.seller_date_save_btn button {
    width: 100%;
    height: 50px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    border-radius: 12px;
    color: white;
    font-size: 0.9em;
}
.seller_sales_container {
    display: flex;
    align-items: center;
    width: 100%;
}
.seller_sales_left {
    width: 30%;
    font-size: 0.9em;
}
.seller_sales_right {
    width: 50%;
}
.seller_faq_right {
    width: 70%;
}
.seller_faq {
    width: 100%;
}
.seller_sales_right input, .seller_faq_right input, .seller_faq input{
    width: 100%;
    height: 50px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 8px;
    margin: 3px 0;
    padding-left: 40px;
    font-size: 0.9em;
}
.seller_sales_right input:focus {
    border: 1px solid rgba(255, 114, 153, 1) !important;
}
.seller_sales_right span {
    color: rgba(255, 3, 102, 1);
}
.seller_sales_faq_box {
    width: 70%;
}
.seller_sales_right_Q {
    width: 100%;
    position: relative;
}
.seller_sales_right_Q span {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.seller_sales_right_A {
    width: 100%;
    position: relative;
}
.seller_sales_right_A span {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.seller_sales_question_add_btn {
    width: 100%;
    text-align: center;
}
.seller_sales_question_add_btn button {
    width: 300px;
    height: 50px;
    border: none;
    border-radius: 12px;
}
.seller_sales_remove_btn {
    width: 80px;
    height: 40px;
    background-color: inherit;
    border: 1px solid rgba(223, 224, 226, 1);
    font-size: 0.9em;
    border-radius: 8px;
    margin-left: 10px;
    transition: all 0.2s;
}
.seller_sales_remove_btn:hover {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.seller_sales_faq_top {
    display: flex;
    width: 100%;
    text-align: center;
}
.seller_sales_faq_left {
    width: 20%;
}
.seller_sales_faq_middle {
    width: 70%;
}
.seller_sales_faq_right {
    width: 10%;
}
.seller_sales_faq_remove_box {
    /* position: absolute;
    top: 45%;
    right: -30%;
    transform: translate(-50%, -50%); */
    width: 30%;
    text-align: center;
}
.seller_sales_faq_qa {
    display: flex;
    align-items: center;
}
.seller_sales_faq_qa_left {
    width: 20%;
}
.seller_sales_faq_qa_middle {
    width: 70%;
    padding: 20px;
}
.seller_sales_faq_qa_middle_box {
    width: 100%;
    background-color: rgba(248, 248, 248, 1);
    padding: 20px;
    font-size: 0.9em;
    border-radius: 12px;
}
.seller_sales_faq_qa_right {
    width: 10%;
}
.seller_sales_faq_qa_right button {
    width: 80px;
    height: 30px;
    border-radius: 5px;
    background-color: inherit;
}
.seller_sales_faq_qa_right>div:nth-of-type(1) button {
    border: 1px solid rgba(255, 114, 153, 1);
    color: rgba(255, 114, 153, 1);
}
.seller_sales_faq_qa_right>div:nth-of-type(2) button {
    border: 1px solid rgba(196, 199, 202, 1);
    color: rgba(115, 122, 130, 1);

}
.seller_qa_input {
    width: 100%;
    height: 50px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 8px;
    margin: 3px 0;
    padding-left: 40px;
    font-size: 0.9em;
}
.seller_sales_right_Q span, .seller_sales_right_A span {
    color: rgba(255, 114, 153, 1);
}
.seller_sales_remove_btn_box button:nth-of-type(1) {
    width: 100%;
    height: 50px;
    border: none;
    background-color: rgba(241, 242, 243, 1);
    border-radius: 12px;
    color: black;
    font-size: 0.9em;
}
.seller_sales_remove_btn_box button:nth-of-type(2) {
    width: 100%;
    height: 50px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    border-radius: 12px;
    color: white;
    font-size: 0.9em;
}

/* edit */
.seller_edit_product_name_box {
    display: flex;
    align-items: center;
    width: 100%;
}
.seller_edit_left {
    width: 30%;
    font-size: 0.9em;
}
.seller_edit_right {
    width: 70%;
    display: flex;
    align-items: center;
}
.seller_edit_three_left {
    width: 33%;
    font-size: 0.9em;
}
.seller_edit_three_middle {
    width: 33%;
    font-size: 0.9em;
}
.seller_edit_three_right {
    width: 34%;
    font-size: 0.8em;
    color: rgba(196, 199, 202, 1);
}
.seller_edit_input_w100 {
    width: 100%;
    height: 50px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 8px;
    padding-left: 10px;
    font-size: 0.9em;
}
.seller_edit_textarea_w100 {
    width: 100%;
    height: 200px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 8px;
    padding: 10px;
    font-size: 0.9em;
}
.seller_edit_input_w100:focus,
.seller_edit_textarea_w100:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.seller_edit_product_info {
    position: relative;
}
.seller_edit_product_info_txt_cnt {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(115, 122, 130, 0.8);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
}
.seller_edit_select {
    width: 100%;
    height: 50px;
}

.seller_edit_three_middle input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 3px;
    background: #fff;
    position: relative;
}
  
.seller_edit_three_middle input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}
  
.seller_edit_three_middle input[type="checkbox"]:checked {
    background-color: rgba(255, 3, 102, 1);
    border-color: rgba(255, 3, 102, 1);
}
  
.seller_edit_three_middle input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.seller_edit_product_min_date_box {
    width: 100%;
    display: flex;
    align-items: center;
}
.seller_edit_product_min_date_left {
    width: 30%;
    font-size: 0.9em;
}
.seller_edit_product_min_date_right {
    width: 70%;
    font-size: 0.9em;
    display: flex;
    align-items: start;
    flex-direction: column;
}
.seller_edit_product_min_date_right>div:nth-of-type(1) {
    width: 100%;
    display: flex;
    align-items: center;
}
.seller_edit_product_min_date_right>div:nth-of-type(1)>div:nth-of-type(1) {
    display: flex;
    align-items: center;
}
.seller_edit_product_min_date_right>div:nth-of-type(1)>div:nth-of-type(2) {
    display: flex;
    align-items: center;
    padding-left: 20px;
}
.seller_edit_product_min_date_right input[type="radio"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}
  
.seller_edit_product_min_date_right input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}
  
.seller_edit_product_min_date_right input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}
  
.seller_edit_product_min_date_right input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}

.seller_edit_product_min_date_right>div:nth-of-type(1)>div:nth-of-type(1) label,
.seller_edit_product_min_date_right>div:nth-of-type(1)>div:nth-of-type(2) label {
    padding-left: 3px;
}
.seller_edit_product_min_date_input_box {
    width: 100%;
    display: flex;
    align-items: center;
}
.seller_edit_product_min_date_input_box input {
    width: 50px;
    border: 1px solid rgba(223, 224, 226, 1);
    padding: 5px;
    border-radius: 4px;
    text-align: center;
}
.seller_edit_product_min_date_input_box input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.seller_edit_product_min_date_input_box label {
    padding-left: 5px;
}
.seller_edit_option_add_btn {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 150px;
    height: 50px;
    border: 1px solid rgba(255, 3, 102, 1);
    background-color: inherit;
    color: rgba(255, 3, 102, 1);
    border-radius: 8px;
    transition: all 0.3s;
}
.seller_edit_option_add_btn:hover {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.seller_edit_option_reset_btn {
    width: 300px;
    height: 50px;
    border: none;
    border-radius: 8px;
}
.seller_edit_img_box {
    display: flex;
    justify-content: center;
    width: 150px;
    height: 150px;
    align-items: center;
    border: 1px solid rgba(241, 242, 243, 1);
    border-radius: 8px;
    overflow: hidden;
}
.seller_edit_img_box .representativeImg {
    width: 100%;
    height: 150px;
    object-fit: cover;
}
.seller_edit_listimg_add {
    width: 70px;
    height: 30px;
    border: 1px solid rgba(169, 173, 178, 1);
    background-color: inherit;
    border-radius: 5px;
}
.seller_edit_listimg_cancel {
    width: 70px;
    height: 30px;
    border: 1px solid rgba(169, 173, 178, 1);
    background-color: inherit;
    border-radius: 5px;
}
.seller_edit_imgadd_reset_btn {
    width: 300px;
    height: 50px;
    border: none;
    border-radius: 8px;
}
.seller_edit_img_left {
    width: 66%;
}
.seller_edit_img_right {
    width: 34%;
}
.seller_edit_img_right span {
    font-size: 0.8em;
    color: rgba(169, 173, 178, 1);
}
.seller_edit_faq_select_btn {
    width: 300px;
    height: 50px;
    border: none;
    border-radius: 8px;
}
.seller_edit_right input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 3px;
    background: #fff;
    position: relative;
}
  
.seller_edit_right input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}
  
.seller_edit_right input[type="checkbox"]:checked {
    background-color: rgba(255, 3, 102, 1);
    border-color: rgba(255, 3, 102, 1);
}
  
.seller_edit_right input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.seller_edit_faq_add_btn {
    width: 300px;
    height: 50px;
    border: none;
    border-radius: 8px;
}
.seller_edit_content_inputform_btn {
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 8px;
}
.seller_edit_produt_add_btn {
    width: 350px;
    height: 50px;
    border: none;
    color: rgba(196, 199, 202, 1);
    border-radius: 8px;
}
.seller_edit_produt_add_btn_active {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.seller_edit_upload_left {
    width: 15%;
}
.seller_edit_upload_middle {
    position: absolute;
    top: -14px;
    right: 10px;
}
.seller_edit_upload_right {
    width: 35%;
    font-size: 0.8em;
    color: rgba(196, 199, 202, 1);
}
.seller_edit_upload_btn {
    border: 1px solid rgba(255, 114, 153, 1);
    color: rgba(255, 114, 153, 1);
    background-color: inherit;
    width: 35px;
    height: 35px;
    border-radius: 8px;
    font-size: 0.9em;
    transition: all 0.3s;
}
.seller_edit_upload_btn:hover {
    background-color: rgba(255, 114, 153, 1);
    color: white;
}
.seller_edit_upload_input {
    border: 1px solid rgba(196, 199, 202, 1);
    color: rgba(196, 199, 202, 1);
    width: 300px;
    height: 40px;
    border-radius: 8px;
    margin-left: 15px;
}
.seller_edit_option_select_add_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
}
.seller_edit_option_select_add_btn button {
    border: 1px solid rgba(196, 199, 202, 1);
    background-color: inherit;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
}
.seller_edit_option_select_add_btn button:hover {
    background-color: rgba(115, 122, 130, 1);
}
.seller_edit_option_select_add_btn button span {
    font-size: 2em;
    color: rgba(223, 224, 226, 1);
}
.seller_edit_option_select {
    box-shadow: 0 0 4px 1px rgba(196, 199, 202, 1);
    padding: 15px;
    position: relative;
    margin: 10px 0;
    border-radius: 4px;
}
.seller_edit_option_select_close {
    position: absolute;
    top: 10px;
    right: 10px;
}
.seller_edit_option_select_close button {
    border: none;
    background-color: inherit;
    color: rgba(169, 173, 178, 1);
}
.seller_edit_option_select_add {
    display: flex;
    align-items: center;
}
.seller_edit_option_select_type {
    width: 150px;
}
.seller_edit_option_select_type :global(.ant-select-selector) {
    border-radius: 4px;
    border: 1px solid rgba(196, 199, 202, 1);
}
.seller_edit_option_select_add>div:nth-of-type(1) {
    font-size: 0.9em;
    margin-right: 1em;
}
.seller_edit_option_select_add>div:nth-of-type(3),
.seller_edit_option_select_add>div:nth-of-type(4) {
    font-size: 0.9em;
}
.seller_edit_option_select_add input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 3px;
    background: #fff;
    position: relative;
}
  
.seller_edit_option_select_add input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid rgba(255, 3, 102, 1);
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}
  
.seller_edit_option_select_add input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
}
  
.seller_edit_option_select_add input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.seller_edit_option_select_short_answer {
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.seller_edit_option_select_short_answer>div:nth-of-type(1) {
    margin-right: 30px;
    
}
.seller_edit_option_select_short_answer>div:nth-of-type(2) {
    font-size: 0.9em;
    margin-right: 30px;
}
.seller_edit_option_select_short_answer>div:nth-of-type(3) {
    font-size: 0.9em;
    position: relative;
}
.seller_edit_option_select_short_answer>div:nth-of-type(3)>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(-50%, -50%);
}
.seller_edit_option_select_short_answer>div:nth-of-type(1) input {
    width: 300px;
    height: 40px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    font-size: 0.9em;
    padding-left: 10px
}
.seller_edit_option_select_short_answer>div:nth-of-type(3) input {
    width: 100px;
    height: 40px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    font-size: 0.9em;
    text-align: right;
    padding-left: 20px;
    padding-right: 10px;
}
.seller_edit_option_select_short_answer>div:nth-of-type(1) input:focus, 
.seller_edit_option_select_short_answer>div:nth-of-type(3) input:focus {
    outline: none;
    border-bottom: 1px solid rgba(255, 114, 153, 1);
}

.seller_edit_option_select_single_selection {
    padding: 10px 0;
}
.seller_edit_option_select_single_selection>div:nth-of-type(1) input {
    width: 300px;
    height: 40px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    font-size: 0.9em;
    padding-left: 10px
}
.seller_edit_option_select_single_selection>div:nth-of-type(1) input:focus {
    outline: none;
    border-bottom: 1px solid rgba(255, 114, 153, 1);
}
.seller_edit_option_select_single_selection_add {
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.seller_edit_option_select_single_selection_add>div:nth-of-type(1) {
    margin-right: 30px;
}
.seller_edit_option_select_single_selection_add>div:nth-of-type(2) {
    font-size: 0.9em;
    margin-right: 30px;
}
.seller_edit_option_select_single_selection_add>div:nth-of-type(3) {
    font-size: 0.9em;
    position: relative;
}
.seller_edit_option_select_single_selection_add>div:nth-of-type(3)>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(-50%, -50%);
}
.seller_edit_option_select_single_selection_add>div:nth-of-type(1) input {
    width: 300px;
    height: 40px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    font-size: 0.9em;
    padding-left: 10px
}
.seller_edit_option_select_single_selection_add>div:nth-of-type(3) input {
    width: 100px;
    height: 40px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    font-size: 0.9em;
    text-align: right;
    padding-left: 20px;
    padding-right: 10px;
}
.seller_edit_option_select_single_selection_add>div:nth-of-type(1) input:focus, 
.seller_edit_option_select_single_selection_add>div:nth-of-type(3) input:focus {
    outline: none;
    border-bottom: 1px solid rgba(255, 114, 153, 1);
}
.seller_edit_option_select_single_selection_add>div:nth-of-type(4) button {
    border: none;
    background-color: inherit;
    color: rgba(255, 3, 102, 1);
}

.seller_edit_option_select_single_selection_add_btn button {
    border: none;
    background-color: inherit;
    font-size: 0.9em;
    color: rgb(0, 119, 255);
    transition: all 0.2s;
}
.seller_edit_option_select_single_selection_add_btn button:hover {
    color: rgba(255, 114, 153, 1);
}

.seller_edit_option_select_date {
    padding: 10px 0;
}
.seller_edit_option_select_date>div:nth-of-type(1) input {
    width: 300px;
    height: 40px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    font-size: 0.9em;
    padding-left: 10px
}
.seller_edit_option_select_date>div:nth-of-type(1) input:focus {
    outline: none;
    border-bottom: 1px solid rgba(255, 114, 153, 1);
}
.seller_edit_option_select_date>div:nth-of-type(2) {
    font-size: 0.9em;
}
.seller_edit_option_select_date>div:nth-of-type(2) input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 3px;
    background: #fff;
    position: relative;
}
  
.seller_edit_option_select_date>div:nth-of-type(2) input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid rgba(255, 3, 102, 1);
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}
  
.seller_edit_option_select_date>div:nth-of-type(2) input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
}
  
.seller_edit_option_select_date>div:nth-of-type(2) input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}

.seller_edit_option_select_file_upload {
    padding: 10px 0;
}
.seller_edit_option_select_file_upload>div:nth-of-type(1) input {
    width: 300px;
    height: 40px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    font-size: 0.9em;
    padding-left: 10px
}
.seller_edit_option_select_file_upload>div:nth-of-type(1) input:focus {
    outline: none;
    border-bottom: 1px solid rgba(255, 114, 153, 1);
}
.seller_edit_option_select_file_upload>div:nth-of-type(2) {
    font-size: 0.8em;
    color: rgba(196, 199, 202, 1);
}
.seller_edit_produt_faq_add_modal_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.seller_edit_produt_faq_add_modal_box {
    width: 80%;
    height: 80%;
    background-color: white;
    border-radius: 6px;
    overflow: hidden;
    padding-bottom: 20px;
}
.seller_edit_produt_faq_add_modal_box .seller_dashboard_container {
    margin: 0;
}
.seller_edit_produt_faq_add_modal_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0 20px;
}
.seller_edit_produt_faq_add_modal_header button {
    border: none;
    background-color: inherit;
}
.seller_edit_produt_faq_add_modal_content {
    width: 100%;
    height: calc(100% - 50px);
    overflow-y: auto;
}
.seller_edit_produt_faq_select_modal_container {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.seller_edit_produt_faq_select_modal_box {
    width: 80%;
    height: 80%;
    background-color: white;
    border-radius: 6px;
}
.seller_edit_produt_faq_select_modal_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.seller_edit_produt_faq_select_modal_header>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.seller_edit_produt_faq_select_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
}
.seller_edit_produt_faq_select_modal_header button {
    border: none;
    background-color: inherit;
}
.seller_edit_produt_faq_select_modal_content {
    width: 100%;
    height: calc(100% - 110px);
    overflow-y: auto;
    padding: 20px;
}
.seller_edit_produt_faq_select_modal_content_item {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    padding: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 8px;
}
.seller_edit_produt_faq_select_modal_content_item > div:nth-of-type(1) {width: 10%;}
.seller_edit_produt_faq_select_modal_content_item input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 3px;
    background: #fff;
    position: relative;
}
  
.seller_edit_produt_faq_select_modal_content_item input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid rgba(255, 3, 102, 1);
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}
  
.seller_edit_produt_faq_select_modal_content_item input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
}
  
.seller_edit_produt_faq_select_modal_content_item input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}

.seller_edit_produt_faq_select_modal_content_item > div:nth-of-type(2) {width: 90%;}
.seller_edit_produt_faq_select_modal_content_question,
.seller_edit_produt_faq_select_modal_content_answer {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.9em;
    padding: 10px 0;
}
.seller_edit_produt_faq_select_modal_content_question>div:nth-of-type(1),
.seller_edit_produt_faq_select_modal_content_answer>div:nth-of-type(1) {
    width: 10%;
    font-weight: 500;
}
.seller_edit_produt_faq_select_modal_content_question>div:nth-of-type(2),
.seller_edit_produt_faq_select_modal_content_answer>div:nth-of-type(2) {
    width: 90%;
}
.seller_edit_produt_faq_select_modal_footer {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.seller_edit_produt_faq_select_modal_footer > div {
    margin: 0 10px;
}
.seller_edit_produt_faq_select_modal_footer > div:nth-of-type(1) button {
    width: 150px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    font-size: 0.9em;
}
.seller_edit_produt_faq_select_modal_footer > div:nth-of-type(2) button {
    width: 150px;
    height: 50px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-size: 0.9em;
    border-radius: 4px;
}
.seller_edit_faq_select_item_box {
    width: 100%;
    display: flex;
}
.seller_edit_faq_select_item_title {
    width: 30%;
}
.seller_edit_faq_select_item_content {
    width: 70%;
}
.seller_edit_faq_select_item_content_item {
    width: 70%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 0.9em;
    position: relative;
}
.seller_edit_faq_select_item_content_item_question,
.seller_edit_faq_select_item_content_item_answer {
    width: 100%;
    display: flex;
    align-items: center;
}
.seller_edit_faq_select_item_content_item_question>div:nth-of-type(1),
.seller_edit_faq_select_item_content_item_answer>div:nth-of-type(1) {
    width: 10%;
    font-weight: 500;
}
.seller_edit_faq_select_item_content_item_question>div:nth-of-type(2),
.seller_edit_faq_select_item_content_item_answer>div:nth-of-type(2) {
    width: 90%;
    color: rgba(255, 114, 153, 1);
}
.seller_edit_faq_select_item_content_item_delete {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
}
.seller_edit_faq_select_item_content_item_delete button {
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 50%;
    background-color: inherit;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.seller_edit_faq_select_item_content_item_delete span {
    font-size: 1.1em;
}
.seller_edit_google_address_search_box {
    width: 100%;
    display: flex;
    margin-top: 10px;
}
.seller_edit_google_address_search_box input {
    width: 200px;
    height: 40px;
    border: 1px solid rgba(169, 173, 178, 1);
    border-radius: 4px;
    font-size: 0.9em;
    padding: 10px;
}
.seller_edit_google_address_search_box input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.seller_edit_google_address_search_box button {
    font-size: 0.9em;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    margin-left: 3px;
}
.seller_edit_google_address_detail_box {
    width: 100%;
    margin-top: 5px;
}
.seller_edit_google_address_detail_box input {
    width: 200px;
    height: 40px;
    border: 1px solid rgba(169, 173, 178, 1);
    border-radius: 4px;
    font-size: 0.9em;
    padding: 10px;
}
.seller_edit_google_address_detail_box input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}

.seller_product_state_box {
    display: flex;
    justify-content: space-between;
}
.seller_product_state {
    display: flex;
    position: relative;
}
.seller_product_state_change_btn {
    display: none;
}
.seller_product_state_width {
    display: none;
}
.seller_product_state_desktop {
    display: flex;
}
.seller_product_state_btn {
    border: 1px solid rgba(196, 199, 202, 1);
    font-size: 0.9em;
    background-color: inherit;
    border-radius: 4px;
    padding: 2px 6px;
}
.seller_product_state_btn:active {
    background-color: rgba(255, 114, 153, 1);
    color: white;
    border: 1px solid rgba(255, 114, 153, 1);
}
.seller_product_export_btn {
    border: 1px solid rgba(196, 199, 202, 1);
    font-size: 0.9em;
    background-color: inherit;
    border-radius: 4px;
    color: rgba(169, 173, 178, 1);
}
.seller_product_export_btn:active {
    color: rgba(19, 20, 22, 0.7);
}
.seller_product_search_input {
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    font-size: 0.9em;
    padding-right: 25px;
    padding-left: 5px;
}
.seller_product_search_icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    font-size: 1.3em;
}
.seller_product_search_input:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1) !important;
}
.seller_product_item_header {
    display: flex;
    text-align: center;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    font-size: 0.9em;
}
.seller_product_item_content {
    display: flex;
    text-align: center;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    font-size: 0.9em;
    align-items: center;
    padding: 15px 0;
    cursor: pointer;
}
.seller_product_item_content:hover {
    background-color: rgba(241, 242, 243, 1);
}
.seller_product_item_one {
    width: 5%;
}
.seller_product_item_two {
    width: 5%;
}
.seller_product_item_three {
    width: 10%;
}
.seller_product_item_four {
    width: 10%;
}
.seller_product_item_five {
    width: 35%;
}
.seller_product_item_six {
    width: 15%;
}
.seller_product_item_seven {
    width: 10%;
}
.seller_product_item_eight {
    width: 10%;
}
.seller_product_item_content_info_box {
    display: flex;
}
.seller_product_item_content_info_box img {
    width: 60px;
    height: 60px;
    border-radius: 4px;
}
.seller_product_item_content_info_box>div:nth-of-type(2)>div:nth-of-type(2) {
    color: rgba(169, 173, 178, 1);
    font-size: 0.9em;
}

.seller_product_item_one input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 3px;
    background: #fff;
    position: relative;
}
  
.seller_product_item_one input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid rgba(255, 3, 102, 1);
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}
  
.seller_product_item_one input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
}
  
.seller_product_item_one input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}

.seller_product_popup {
    width: 80%;
    height: 60vh;
    border-radius: 12px !important;
}

/* 셀러 상품관리 디테일 */
.seller_product_detail_container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(115, 122, 130, 0.3);
    z-index: 999;
}
.seller_product_detail_container>div {
    width: 80%;
    height: 80vh;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    overflow-y: auto;
}
.seller_product_detail_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.seller_product_detail_header>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.seller_product_detail_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
}
.seller_product_detail_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.seller_product_detail_header>div:nth-of-type(2) span {
    font-size: 1.7em;
}
.seller_product_detail_content {
    width: 100%;
    height: calc(100% - 110px);
    overflow-y: auto;
}
.seller_product_detail_footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(241, 242, 243, 1);
    padding-top: 10px;
}
.seller_edit_produt_cancel_btn {
    width: 180px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    margin: 0 5px;
    transition: all 0.2s;
    font-size: 0.9em;
}
.seller_edit_produt_cancel_btn:hover {
    background-color: rgba(255, 3, 102, 1);
    color: white;
}
.seller_edit_produt_update_btn {
    width: 180px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    margin: 0 5px;
    transition: all 0.2s;
    font-size: 0.9em;
}
.seller_edit_produt_update_btn:hover {
    background-color: rgb(92, 168, 255);
    color: white;
}
.seller_seller_cancel_title {
    font-size: 1.1em;
}
.seller_seller_cancel_sub_title {
    font-size: 0.8em;
}
.seller_seller_cancel_btn_box {
    display: flex;
    justify-content: center !important;
}
.seller_seller_cancel_btn_box button {
    width: 40%;
    height: 40px;
    border-radius: 4px;
    border: none;
}
.seller_seller_cancel_btn_box button:nth-of-type(1) { font-size: 0.9em; background-color: rgba(255, 3, 102, 1); color: white;}
.seller_seller_cancel_btn_box button:nth-of-type(2) { font-size: 0.9em;}

.seller_update_popup {
    
}
.seller_update_title {
    font-size: 1.1em;
}
.seller_update_sub_title {
    font-size: 0.8em;
}
.seller_confirm_btn_box button{
    width: 100%;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    height: 40px;
    border-radius: 4px;
}
.seller_reservation_item_container {
    width: 100%;
    overflow-x: scroll;
}
.seller_reservation_item_container::-webkit-scrollbar {
    width: 10px;  /* 스크롤바의 너비 */
    height: 5px;
}
.seller_reservation_item_container::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
    border-radius: 2px;
}

.seller_reservation_item_container::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
}
.seller_reservation_item_container>div {
    min-width: 1200px;
}
.seller_reservation_item_header {
    display: flex;
    text-align: center;
    font-size: 0.9em;
}
.seller_reservation_item_content {
    display: flex;
    text-align: center;
    font-size: 0.8em;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
}
.seller_reservation_item_content:hover {
    background-color: rgba(241, 242, 243, 1);
}
.seller_order_content_user_info {
    position: absolute;
    bottom: 10px;
    left: 70px;
}
.seller_order_content_user_info button {
    border: none;
    background-color: rgba(223, 224, 226, 1);
    padding: 3px 7px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}
.seller_order_content_user_info button>span {
    font-size: 1.4em;
}
.seller_reservation_item_one {
    width: 2%;
}
.seller_reservation_item_two {
    width: 15%;
}
.seller_reservation_item_three {
    width: 8%;
}
.seller_reservation_item_four {
    width: 7%;
}
.seller_reservation_item_five {
    width: 35%;
}
.seller_reservation_item_six {
    width: 13%;
}
.seller_reservation_item_seven {
    width: 15%;
}
.seller_reservation_item_eight {
    width: 10%;
}
.seller_reservation_item_search {
    width: 100%;
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    height: 40px;
    border-radius: 4px;
    transition: all 0.2s;
}
.seller_reservation_item_search:hover, .seller_reservation_item_search:active {
    background-color: rgba(255, 114, 153, 1) !important;
    color: white !important;
}
.seller_reservation_item_total_price {
    font-weight: bold;
}
.seller_reservation_item_payment_info {
    color: rgba(196, 199, 202, 1)
}
.reservation_item_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(115, 122, 130, 0.3);
    z-index: 100;
}
.reservation_item_modal_content {
    position: absolute;
    width: 80vh;
    height: 80vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
    padding: 20px;
}
.reservation_item_modal_close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}
.reservation_item_modal_title {
    width: 100%;
    text-align: center;
    font-size: 1.2em;
}
.seller_reservation_item_modal_option_box {
    display: flex;
    align-items: center;
    border: 1px solid rgba(241, 242, 243, 1);
    border-radius: 4px;
    margin: 5px 0;
}
.seller_reservation_item_modal_option_box>div:nth-of-type(1) {width: 80%;}
.seller_reservation_item_modal_option_box>div:nth-of-type(2) {width: 20%; text-align: center;}
.seller_reservation_item_modal_option_name {
    border-bottom: 1px solid rgba(241, 242, 243, 1);
}
.seller_reservation_item_modal_option_name,
.seller_reservation_item_modal_option_value {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 0.9em;
}
.seller_reservation_item_modal_option_name>div:nth-of-type(1),
.seller_reservation_item_modal_option_value>div:nth-of-type(1) {
    width: 20%;
}
.seller_reservation_item_modal_option_name>div:nth-of-type(2),
.seller_reservation_item_modal_option_value>div:nth-of-type(2) {
    width: 80%;
}
.seller_reservation_item_modal_option_price {
    border-left: 1px solid rgba(241, 242, 243, 1);
    font-size: 0.9em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.seller_reservation_item_modal_option_price>div:nth-of-type(1) {
    width: 50%;
}
.seller_reservation_item_modal_option_price>div:nth-of-type(1)>div:nth-of-type(1),
.seller_reservation_item_modal_option_price>div:nth-of-type(2)>div:nth-of-type(1) {
    color: rgba(169, 173, 178, 1);
}
.seller_reservation_item_modal_option_price>div:nth-of-type(1)>div:nth-of-type(2),
.seller_reservation_item_modal_option_price>div:nth-of-type(2)>div:nth-of-type(2) {
    font-size: 0.9em;
}
.seller_reservation_item_modal_option_price>div:nth-of-type(2) {
    width: 50%;
}
.seller_reservation_item_modal_option_img_box {
    display: flex;
    align-items: center;
}
.seller_reservation_item_modal_option_img_box>div {
    width: 200px;
    height: 200px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    color: rgba(196, 199, 202, 1);
}
.seller_reservation_item_modal_option_img_box>div img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}
.seller_reservation_item_modal_option_download_box {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 10;
}
.seller_reservation_item_modal_option_download_box button {
    border: 1px solid rgba(255, 114, 153, 1);
    padding: 5px;
    border-radius: 4px;
    background-color: inherit;
    transition: all 0.3s;
}
.seller_reservation_item_modal_option_download_box button:hover,
.seller_reservation_item_modal_option_download_box button:active {
    background-color: rgba(255, 114, 153, 1) !important;
    color: white !important;
}

.seller_reservation_item_content>.seller_reservation_item_five {
    text-align: left;
    display: flex;
    padding: 0 10px;
    align-items: center;
}
.seller_reservation_item_content>.seller_reservation_item_five>div:nth-of-type(1) img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 4px;
}
.seller_reservation_item_content>.seller_reservation_item_five>div:nth-of-type(2) {
    padding: 0 5px;
}

.seller_reservation_item_one input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 3px;
    background: #fff;
    position: relative;
}
  
.seller_reservation_item_one input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid rgba(255, 3, 102, 1);
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}
  
.seller_reservation_item_one input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
}
  
.seller_reservation_item_one input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.seller_reservation_popup_left {
    width: 20%;
}
.seller_reservation_popup_right {
    width: 80%;
}
.seller_reservation_popup_right textarea {
    width: 100%;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 8px;
    height: 6.25em;
    resize: none;
    padding-left: 10px;
    padding-top: 10px;
    font-size: 0.9em;
}
.seller_reservation_popup_right textarea:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.seller_reservation_success_popup {
    width: 80%;
    height: 60vh;
    border-radius: 12px !important;
}
.seller_shop_savebtn {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}

/* 운영 시간 */
.seller_store_operating_box {
    box-shadow: 0 0 4px 1px rgba(196, 199, 202, 1);
    padding: 10px;
    border-radius: 4px;
    margin-top: 15px;
}
.seller_store_operating_date_box {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    text-align: center;
}
.seller_store_operating_date_box>div:nth-of-type(1) {width: 10%;}
.seller_store_operating_date_box>div:nth-of-type(2) {width: 40%;}
.seller_store_operating_date_box>div:nth-of-type(3) {width: 20%; text-align: left;}

.seller_store_operating_time_select_box {
    display: flex;
    align-items: center;
}

.seller_store_operating_time_select {
    width: 100px;
}
.seller_store_operating_box input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 3px;
    background: #fff;
    position: relative;
}
  
.seller_store_operating_box input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid rgba(255, 3, 102, 1);
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}
  
.seller_store_operating_box input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
}
  
.seller_store_operating_box input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.seller_store_operating_add_btn {
    width: 100%;
    text-align: right;
    padding: 30px 0;
}
.seller_store_operating_add_btn button {
    width: 150px;
    height: 40px;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    font-size: 0.8em;
    border: none;
    border-radius: 4px;
}
:global(.ant-picker-ok) {
    display: flex !important;
}
:global(.ant-picker-ok button) {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
    transition: all 0.2s;
}
:global(.ant-picker-ok button:hover) {
    background-color: rgba(255, 114, 153, 1) !important;
}


/* 상점관리 - 프로필 */
.seller_shop_main_title {
    font-size: 1.2em;
}
.seller_shop_main_content {
    font-size: 0.9em;
}
.seller_representative_list_img_box {
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid rgba(248, 248, 248, 1);
    padding: 15px 0;
}

/* 상품 등록 */
.seller_edit_title {
    font-size: 1.2em;
}

/* 상품 정보 */
.seller_edit_product_info_box {
    display: flex;
    align-items: center;
    padding: 15px;
    box-shadow: 0 0 4px 1px rgba(196, 199, 202, 1);
    border-radius: 4px;
    margin-top: 10px;
    position: relative;
}
.seller_edit_product_info_delete {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: inherit;
    color: rgba(169, 173, 178, 1);
    transition: all 0.2s;
}
.seller_edit_product_info_delete:hover {
    color: rgba(255, 114, 153, 1);
}
.seller_edit_product_info_delete:active {
    color: rgba(255, 114, 153, 1);
}
.seller_edit_product_info_delete span {
    font-size: 1.6em;
}
.seller_edit_product_info_img_with_delete {
    position: relative;
}
.seller_edit_product_info_img_with_delete_btn {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    background-color: rgba(115, 122, 130, 0.3);
    border-radius: 50%;
}
.seller_edit_product_info_img_with_delete_btn span {
    color: white;
}
.seller_edit_product_info_box>div:nth-of-type(1) {width: 30%; display: flex; align-items: center; justify-content: center;}
.seller_edit_product_info_img_box {
    width: 100px;
    height: 100px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.seller_edit_product_info_img_box img {
    width: 100%;
    height: 100px;
    object-fit: cover;
}
.seller_edit_product_info_add_btn {
    width: 100%;
    height: 100%;
    border: none;
    background-color: inherit;
}
.seller_edit_product_info_img_box .seller_edit_product_info_img {
    width: 100%;
    height: 100px;
    object-fit: cover;
}
.seller_edit_product_info_box>div:nth-of-type(2) {width: 70%;}
.seller_edit_product_info_option_name {
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
.seller_edit_product_info_option_name>div:nth-of-type(1) {width: 30%;}
.seller_edit_product_info_option_name>div:nth-of-type(2) {width: 70%;}
.seller_edit_product_info_option_name>div:nth-of-type(2) input {
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    height: 35px;
    padding-left: 10px;
}
.seller_edit_product_info_option_name>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.seller_edit_product_info_price_box {
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
.seller_edit_product_info_price_box>div:nth-of-type(1) {width: 30%;}
.seller_edit_product_info_price_box>div:nth-of-type(2) {width: 70%;}

.seller_edit_product_info_price_box>div:nth-of-type(2) {
    position: relative;
}
.seller_edit_product_info_price_box>div:nth-of-type(2) input {
    width: 130px;
    height: 30px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding-left: 20px;
    font-size: 0.9em;
    text-align: right;
    padding-right: 10px;
}
.seller_edit_product_info_price_box>div:nth-of-type(2) input:focus {
    outline: none;
    border-bottom: 1px solid rgba(255, 3, 102, 1);
}
.seller_edit_product_info_price_box>div:nth-of-type(2)>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(-50%, -50%);
}
.seller_edit_product_info_option_box {
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
.seller_edit_product_info_option_box>div:nth-of-type(1) {width: 30%;}
.seller_edit_product_info_option_box>div:nth-of-type(2) {
    width: 70%;
    display: flex;
}
.seller_edit_product_info_option_box input[type="radio"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
}
  
.seller_edit_product_info_option_box input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}
  
.seller_edit_product_info_option_box input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}
  
.seller_edit_product_info_option_box input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}
.seller_edit_product_info_option_detail_box {
    padding-left: 30%;
}
.seller_edit_product_info_add_btn {
    width: 100%;
}
.seller_edit_product_info_add_btn button {
    width: 100%;
    height: 35px;
    border: none;
    background-color: rgba(223, 224, 226, 1);
    font-size: 0.9em;
    transition: all 0.2s;
}
.seller_edit_product_info_add_btn button:hover {
    background-color: rgba(223, 224, 226, 0.5);
}

/* 메세지 */
.seller_message_box {
    margin-top: 30px;
    width: 100%;
    height: 600px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    border-radius: 4px;
    display: flex;
    position: relative;
}
.seller_message_content {
    width: 80%;
    height: 100%;
    padding: 10px 30px;
    overflow-y: auto;
}
.seller_message_list {
    width: 20%;
    height: 100%;
    border-right: 1px solid rgba(241, 242, 243, 1);
    overflow-y: auto;
}
.seller_message_list_box {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid rgba(241, 242, 243, 1);
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
}
.seller_reading_count {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 0.7em;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 20px;
}
.seller_message_list_box:hover {
    background-color: rgba(255, 114, 153, 0.1);
}
.seller_message_list_box>div:nth-of-type(1) {width: 30%; overflow: hidden;}
.seller_message_list_box>div:nth-of-type(1)>div {
    width: 65px;
    height: 65px;
    overflow: hidden;
    border-radius: 50%;
}
.seller_message_list_box>div:nth-of-type(1)>div img {
    width: 100%;
    height: 65px;
    object-fit: cover;
}
.seller_message_list_box>div:nth-of-type(2) {width: 70%;}
.seller_message_list_box>div:nth-of-type(2)>div:nth-of-type(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9em;
}
.seller_message_list_box>div:nth-of-type(2)>div:nth-of-type(1)>div:nth-of-type(2) {
    font-size: 0.8em;
    color: rgba(196, 199, 202, 1);
}
.seller_message_list_box>div:nth-of-type(2)>div:nth-of-type(2) {
    font-size: 0.8em;
    padding: 5px 0;
    color: rgba(19, 20, 22, 0.7);
}
.seller_message_list_box_active {
    background-color: rgba(255, 114, 153, 0.1) !important;
}
.message_chat_sender_box {
    display: flex;
    justify-content: end;
    margin: 30px 0;
}
.message_chat_sender_box>div:nth-of-type(2) {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
}
.message_chat_sender_box>div:nth-of-type(2) img {
    width: 100%;
    height: 60px;
    object-fit: cover;
}
.message_chat_sender {
    max-width: 250px;
    position: relative;
    padding: 5px 10px;
    background: #fff1a2;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin-right: 20px;
    font-size: 0.9em;
    white-space: normal;  /* 줄바꿈이 가능하도록 수정 */
    word-wrap: break-word;  /* 단어가 너무 길면 줄바꿈 */
    overflow-wrap: break-word;  /* 단어가 너무 길면 줄바꿈 */
    display: flex;
    align-items: center;
}
.message_chat_sender>div {
    word-break: break-all;
}

.message_chat_sender:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 10px 0 10px 15px;
    border-color: transparent #fff1a2;
    display: block;
    width: 0;
    z-index: 1;
    right: -15px;
    top: 12px;
}


.message_chat_recipient_box {
    display: flex;
    justify-content: start;
    margin: 30px 0;
}
.message_chat_recipient_box>div:nth-of-type(1) {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
}
.message_chat_recipient_box>div:nth-of-type(1) img {
    width: 100%;
    height: 60px;
    object-fit: cover;
}
.message_chat_recipient {
    max-width: 250px;
    position: relative;
    padding: 5px 10px;
    background: rgba(223, 224, 226, 1);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin-left: 20px;
    font-size: 0.9em;
    white-space: normal;  /* 줄바꿈이 가능하도록 수정 */
    word-wrap: break-word;  /* 단어가 너무 길면 줄바꿈 */
    overflow-wrap: break-word;  /* 단어가 너무 길면 줄바꿈 */
    display: flex;
    align-items: center;
}
.message_chat_recipient>div {
    word-break: break-all;
}
.message_chat_recipient:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent rgba(223, 224, 226, 1);
    display: block;
    width: 0;
    z-index: 1;
    left: -15px;
    top: 12px;
}
.seller_message_input {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
}
.seller_message_input_btn {
    display: flex;
    align-items: center;
    padding: 5px 0;
}
.seller_message_input_btn button {
    padding: 5px 10px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    background-color: inherit;
    font-size: 0.8em;
    display: flex;
    align-items: center;
    transition: all 0.2s;
}
.seller_message_input_btn button:hover {
    background-color: rgba(255, 114, 153, 0.1);
}
.seller_message_input_send {
    display: flex;
    align-items: center;
    width: 100%;
}
.seller_message_input_send>div:nth-of-type(1) {width: 90%;}
.seller_message_input_send>div:nth-of-type(1) input {
    width: 100%;
    height: 45px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 0 10px;
    font-size: 0.9em;
}
.seller_message_input_send>div:nth-of-type(1) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.seller_message_input_send>div:nth-of-type(2) {width: 10%; padding: 0 10px;}
.seller_message_input_send>div:nth-of-type(2) button {
    border: none;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    font-size: 0.8em;
    width: 100%;
    height: 45px;
    border-radius: 4px;
}
.scroll_to_bottom_btn {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%);
    z-index: 100;
}
.scroll_to_bottom_btn button {
    border: none;
    background-color: inherit;
}
.scroll_to_bottom_btn button>span {
    font-size: 2em;
}
.message_img_rander {
    position: relative;
    padding: 20px 30px;
}
.message_img_rander>div:nth-of-type(1) img {
    max-width: 100px;
}
.message_img_rander>div:nth-of-type(2) {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 35px;
    height: 35px;
    }
.message_img_rander>div:nth-of-type(2) button {
    width: 35px;
    height: 35px;
    border: none;
    background-color: inherit;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    background-color: white;
    transition: all 0.2s;
}
.message_img_rander>div:nth-of-type(2) button:hover {
    background-color: rgba(255, 3, 102, 0.1);
}
.message_file_rander {
    position: relative;
    padding: 20px 30px;
    text-align: center;
}
.message_file_rander>div:nth-of-type(1) img{
    width: 60px;
    height: 60px;
}
.message_file_rander>div:nth-of-type(2) {
    font-size: 0.8em;
}
.message_file_rander>div:nth-of-type(3) {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 35px;
    height: 35px;
}
.message_file_rander>div:nth-of-type(3) button {
    width: 35px;
    height: 35px;
    border: none;
    background-color: inherit;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    background-color: white;
    transition: all 0.2s;
}
.message_chat_preview {
    width: 100%;
    text-align: center;
    padding: 10px;
    border-top: 1px solid rgba(223, 224, 226, 1);
    border-left: 1px solid rgba(223, 224, 226, 1);
    border-right: 1px solid rgba(223, 224, 226, 1);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.message_chat_preview_cancel {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background-color: inherit;
}
.message_chat_preview_file img{
    width: 80px;
    height: 80px;
}
.message_chat_preview_file span {
    font-size: 0.8em;
}

.preview_modal_container {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}
.preview_modal_box {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    position: relative;
}
.preview_close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
}
.preview_image {
    max-width: 100%;
    max-height: 80vh;
}
  

/* 결제 팝업 */
.popup_container {
    position: absolute;
    background-color: rgba(115, 122, 130, 0.3);
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
}
.popup {
    position: absolute;
    width: 60vh;
    height: 55vh;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
}
.popup_memo {
    margin-top: 30px;
    font-size: 0.8em;
    padding: 20px;
    width: 100%;
    background-color: rgb(255, 254, 186);
    border-radius: 4px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
}
.popup_close {
    position: absolute;
    top: 10px;
    right: 10px;
}
.popup_close button {
    border: none;
    background-color: inherit;
}
.popup_service_title {
    width: 100%;
}
.popup_service_title input {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    border: 1px solid rgba(196, 199, 202, 1);
    padding-left: 10px;
}
.popup_service_title input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.popup_select {
    width: 100%;
    height: 40px;
    font-size: 0.8em;
    padding-left: 10px;
    border-radius: 4px;
    border: 1px solid rgba(196, 199, 202, 1);
}
.popup_service_price {
    width: 100%;
    position: relative;
}
.popup_service_price input {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    border: 1px solid rgba(196, 199, 202, 1);
    padding-left: 10px;
    padding-right: 30px;
    text-align: right;
}
.popup_service_price input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.popup_service_price_txt {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
}
.popup_actions {
    width: 100%;
}
.popup_actions button {
    width: 100%;
    height: 45px;
    border: none;
    font-size: 0.8em;
    border-radius: 4px;
}
.popup_actions_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}
.font_size_09 {
    font-size: 0.9em;
}
.payment_request_title {
    text-align: left;
}
.payment_request_img {
    text-align: left;
    display: flex;
    padding: 5px 0;
}
.payment_request_img img {
    width: 80px;
    height: 80px;
    border-radius: 4px;
}
.payment_request_service {
    text-align: left;
    padding: 5px 0;
}
.payment_request_service>div:nth-of-type(1) {
    font-weight: bold;
}
.payment_request_service>div:nth-of-type(2) {
    font-size: 0.9em;
}
.payment_request_price {
    text-align: left;
    padding: 5px 0;
}
.payment_request_price>div:nth-of-type(1) {
    font-weight: bold;
}
.payment_request_price>div:nth-of-type(2) {
    font-size: 0.9em;
}
.message_chat_payment_request {
    position: absolute;
    bottom: 0;
    left: 35%;
    width: 50%;
    text-align: center;
    padding: 10px;
    border-top: 1px solid rgba(223, 224, 226, 1);
    border-left: 1px solid rgba(223, 224, 226, 1);
    border-right: 1px solid rgba(223, 224, 226, 1);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 10;
}
.popup_info_box {
    height: 280px;
    overflow-y: scroll;
}
.popup_service_fee_box {
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    font-size: 0.8em;
    color: rgba(19, 20, 22, 0.7);
}
.popup_service_fee {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
}
.popup_service_fee>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.popup_service_fee>div:nth-of-type(1) button>span {
    font-size: 1.4em;
}
.popup_payment_fee {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
}
.popup_vat_fee {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
}
.popup_settlement_amount {
    border-top: 1px solid rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 5px 0;
    margin-top: 20px;
}
.product_info {
    display: flex;
}
.product_info>div:nth-of-type(1) img{
    width: 60px;
    height: 60px;
    border-radius: 4px;
    object-fit: cover;
}
.payment_request_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
}
.payment_request_btn button {
    border: 1px solid rgba(255, 3, 102, 1);
    border-radius: 4px;
    width: 100%;
    height: 40px;
    background-color: inherit;
    margin: 0 5px;
    font-size: 0.9em;
    transition: all 0.2s;
}
.payment_request_btn button:nth-of-type(1):hover {
    background-color: rgba(223, 224, 226, 0.3) !important;
}
.payment_request_btn button:nth-of-type(2):hover {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}

/* 메세지 결제내역 */
.seller_message_payment_header {
    font-size: 1.2em;
    padding: 10px 0 20px 0;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
}
.seller_message_payment_tab {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 10px;
}
.seller_message_payment_tab>div {
    width: 100%;
    height: 30px;
}
.seller_message_payment_tab>div button {
    width: 100%;
    height: 30px;
    border: none;
    background-color: inherit;
}
.seller_message_payment_tab_active {
    border-bottom: 2px solid rgba(255, 3, 102, 1);
}
.seller_message_payment_search_btn_box {
    width: 100%;
    padding: 10px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.seller_message_payment_search_btn_box>div:nth-of-type(1) {
    display: flex;
    align-items: center;
}
.seller_message_payment_search_btn_box>div:nth-of-type(1)>div {
    margin: 0 5px;
}
.seller_message_payment_search_btn_box>div:nth-of-type(1)>div button {
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    border-radius: 4px;
    font-size: 0.9em;
    padding: 5px 10px;
}
.seller_message_payment_search_btn_box>div:nth-of-type(2) input {
    width: 200px;
    height: 40px;
    border : 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    font-size: 0.9em;
    padding: 0 10px;
}
.seller_message_payment_search_btn_box>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 114, 153, 1);
}
.seller_message_payment_content {
    width: 100%;
    text-align: center;
}
.seller_message_payment_content input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 3px;
    background: #fff;
    position: relative;
}
  
.seller_message_payment_content input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid rgba(255, 3, 102, 1);
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}
  
.seller_message_payment_content input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
}
  
.seller_message_payment_content input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.seller_message_payment_content_header {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding: 5px 0;
}
.seller_message_payment_content_header>div:nth-of-type(1) {width: 5%;}
.seller_message_payment_content_header>div:nth-of-type(2) {width: 20%;}
.seller_message_payment_content_header>div:nth-of-type(3) {width: 30%;}
.seller_message_payment_content_header>div:nth-of-type(4) {width: 20%;}
.seller_message_payment_content_header>div:nth-of-type(5) {width: 25%;}
.seller_message_payment_content_info {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    font-size: 0.8em;
    padding: 10px 0;
}
.seller_message_payment_content_info>div:nth-of-type(1) {width: 5%;}
.seller_message_payment_content_info>div:nth-of-type(2) {width: 20%; font-size: 0.8em;}
.seller_message_payment_content_info>div:nth-of-type(3) {width: 30%; text-align: left;}
.seller_message_payment_content_info>div:nth-of-type(4) {width: 20%; padding: 0 10px;}
.seller_message_payment_content_info>div:nth-of-type(5) {width: 25%;}

.seller_message_payment_content_info>div:nth-of-type(3)>div:nth-of-type(1) {font-size: 0.8em; color: rgba(169, 173, 178, 1);}
.seller_message_payment_content_info>div:nth-of-type(3)>div:nth-of-type(2) {font-weight: bold;}

.seller_message_payment_content_info>div:nth-of-type(4)>div:nth-of-type(1)>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 0;
}
.seller_message_payment_content_info>div:nth-of-type(4)>div:nth-of-type(1)>div:nth-of-type(1) {
    color: rgba(255, 3, 102, 1);
    font-weight: bold;
}
.seller_message_payment_cancel_popup {

}
.seller_message_payment_cancel_title {

}
.seller_message_payment_cancel_sub_title {
    font-size: 0.8em;
    padding-bottom: 10px;
}
.seller_message_payment_cancel_btn_box {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
}
.seller_message_payment_cancel_btn_box>button:nth-of-type(1) { 
    width: 30%;
    height: 40px;
    border-radius: 4px;
    border: none;
    background-color: rgba(203, 223, 253, 1);
    font-size: 0.9em;
}
.seller_message_payment_cancel_btn_box>button:nth-of-type(1):hover,
.seller_message_payment_cancel_btn_box>button:nth-of-type(1):active {
    opacity: 0.7;
}
.seller_message_payment_cancel_btn_box>button:nth-of-type(2) { 
    width: 30%;
    height: 40px;
    border-radius: 4px;
    border: none;
    background-color: rgba(255, 224, 232, 1);
    font-size: 0.9em;
}
.seller_message_payment_cancel_btn_box>button:nth-of-type(2):hover,
.seller_message_payment_cancel_btn_box>button:nth-of-type(2):active {
    opacity: 0.7;
}

.seller_message_payment_cancel_info {
    width: 100%;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
}
.seller_message_payment_cancel_info>div {
    padding: 5px;
    text-align: left;
}
.seller_message_payment_cancel_info_content {
    font-size: 0.9em;
}
.seller_message_payment_cancel_info_content>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(3, 104, 255, 1);
}
.seller_message_payment_cancel_date {
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 5px;
    text-align: left;
}
.seller_message_payment_cancel_date>div:nth-of-type(2) {
    font-size: 0.9em;
    color: rgba(3, 104, 255, 1);
}
.seller_message_payment_partial_cancel_popup {
    width: 50%;
}

.seller_message_payment_partial_cancel_header {
    padding-bottom: 10px;
}
.seller_message_payment_partial_cancel_title {
    text-align: left;
    font-size: 0.9em;
}
.seller_message_payment_partial_cancel_sub_title {
    text-align: 0.8em;
    color: rgba(255, 3, 102, 1);
    font-size: 0.8em;
    text-align: left;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    padding-bottom: 5px;
}
.seller_message_payment_partial_modal_container {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(115, 122, 130, 0.3);
}
.seller_message_payment_partial_modal_box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 80%;
    background-color: white;
    transform: translate(-50%, -50%);
    overflow: hidden;
    border-radius: 12px;
}
.seller_message_payment_partial_modal_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.seller_message_payment_partial_modal_header>div:nth-of-type(1) {font-size: 1.1em;}
.seller_message_payment_partial_modal_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
}
.seller_message_payment_partial_modal_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.seller_message_payment_partial_modal_content {
    width: 100%;
    height: 65vh;
    overflow-y: auto;
}
.seller_message_payment_partial_modal_content_item {
    width: 100%;
    padding: 10px;
}
.seller_message_payment_partial_modal_content_item>div {
    width: 100%;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 10px;
}
.seller_message_payment_partial_modal_content_dflex {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.9em;
    padding: 5px 0;
}
.seller_message_payment_partial_modal_content_dflex input {
    width: 100px;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    font-size: 0.9em;
    padding-left: 10px;
    padding-right: 10px;
    text-align: right;
}
.seller_partial_active {
    color: rgba(255, 3, 102, 1);
}
.seller_message_payment_partial_modal_content_dflex input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.seller_message_payment_partial_modal_content_dflex>div:nth-of-type(1) {width: 20%;}
.seller_message_payment_partial_modal_content_dflex>div:nth-of-type(2) {width: 80%;}
.seller_message_payment_partial_modal_footer {
    
}
.seller_message_payment_partial_cancel_input {

}

/* 서포트 조합 옵션 */
.seller_combination_option_box {
    width: 100%;
    padding-left: 30%;
}
.seller_combination_top_option {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    margin: 10px 0 0 0;
    padding: 10px;
}
.seller_combination_top_option>div:nth-of-type(1) {
    width: 100%;
    font-size: 0.9em;
}
.seller_combination_top_option>div:nth-of-type(2) {
    padding: 5px 0;
}
.seller_combination_top_option>div:nth-of-type(2) input {
    width: 250px;
    height: 40px;
    border: 1px solid rgba(196, 199, 202, 1);
    border-radius: 4px;
    padding: 10px;
    font-size: 0.9em;
}
.seller_combination_top_option>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.seller_combination_top_option_btn {
    width: 100%;
    height: 30px;
    border: none;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    font-size: 0.9em;
}
.seller_combination_first_option_box {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    margin: 10px 0;
    padding: 10px;
    position: relative;
    border-radius: 4px;
}
.seller_combination_first_option_box>div:nth-of-type(1) {
    font-size: 0.9em;
    padding-bottom: 5px;
    font-weight: 600;
}
.seller_combination_first_option_box>div:nth-of-type(2) {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.seller_combination_first_option_box>div:nth-of-type(3) {
    width: 100%;
    display: flex;
    align-items: center;
}
.seller_combination_first_option_box>div:nth-of-type(2)>div:nth-of-type(1),
.seller_combination_first_option_box>div:nth-of-type(3)>div:nth-of-type(1) {
    width: 15%;
    font-size: 0.9em;
}
.seller_combination_first_option_box>div:nth-of-type(2)>div:nth-of-type(2),
.seller_combination_first_option_box>div:nth-of-type(3)>div:nth-of-type(2) {
    width: 70%;
}
.seller_combination_first_option_box>div:nth-of-type(2)>div:nth-of-type(2) input,
.seller_combination_first_option_box>div:nth-of-type(3)>div:nth-of-type(2) input {
    width: 250px;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    font-size: 0.9em;
    padding: 10px;
}
.seller_combination_first_option_box>div:nth-of-type(2)>div:nth-of-type(2) input:focus,
.seller_combination_first_option_box>div:nth-of-type(3)>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.seller_combination_first_option_delete {
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    background-color: inherit;
}
.seller_combination_first_option_delete span {
    color: rgba(255, 3, 102, 1);
}
.seller_combination_first_option_add_group {
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-size: 0.85em;
    padding: 5px 10px;
    border: none;
    background-color: rgba(3, 104, 255, 1);
    color: white;
    border-radius: 4px;
}
.seller_combination_second_option_group_box {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    border-radius: 4px;
    margin: 10px 0;
    padding: 10px;
    position: relative;
}
.seller_combination_second_option_group_box>div:nth-of-type(1) {
    font-size: 0.9em;
    font-weight: 600;
    padding-bottom: 10px;
}
.seller_combination_second_option_group_box>div:nth-of-type(2) {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
.seller_combination_second_option_group_box>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 30%;
}
.seller_combination_second_option_group_box>div:nth-of-type(2)>div:nth-of-type(2) {
    width: 70%;
}
.seller_combination_second_option_group_box>div:nth-of-type(2)>div:nth-of-type(2) input {
    width: 200px;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 10px;
}
.seller_combination_second_option_group_box>div:nth-of-type(2)>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.seller_combination_second_option_box {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    border-radius: 4px;
    margin: 10px 0;
    padding: 10px;
    position: relative;
}
.seller_combination_second_option_box>div:nth-of-type(1) {
    font-size: 0.9em;
    padding-bottom: 5px;
    font-weight: 600;
}
.seller_combination_second_option_box>div:nth-of-type(2) {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.seller_combination_second_option_box>div:nth-of-type(3) {
    width: 100%;
    display: flex;
    align-items: center;
}
.seller_combination_second_option_box>div:nth-of-type(2)>div:nth-of-type(1),
.seller_combination_second_option_box>div:nth-of-type(3)>div:nth-of-type(1) {
    width: 15%;
    font-size: 0.9em;
}
.seller_combination_second_option_box>div:nth-of-type(2)>div:nth-of-type(2),
.seller_combination_second_option_box>div:nth-of-type(3)>div:nth-of-type(2) {
    width: 70%;
}
.seller_combination_second_option_box>div:nth-of-type(2)>div:nth-of-type(2) input,
.seller_combination_second_option_box>div:nth-of-type(3)>div:nth-of-type(2) input {
    width: 250px;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    font-size: 0.9em;
    padding: 10px;
}
.seller_combination_second_option_box>div:nth-of-type(2)>div:nth-of-type(2) input:focus,
.seller_combination_second_option_box>div:nth-of-type(3)>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.seller_combination_third_option_group_box {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    border-radius: 4px;
    margin: 10px 0;
    padding: 10px;
    position: relative;
}
.seller_combination_third_option_group_box>div:nth-of-type(1) {
    font-size: 0.9em;
    font-weight: 600;
    padding-bottom: 10px;
}
.seller_combination_third_option_group_box>div:nth-of-type(2) {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
.seller_combination_third_option_group_box>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 30%;
}
.seller_combination_third_option_group_box>div:nth-of-type(2)>div:nth-of-type(2) {
    width: 70%;
}
.seller_combination_third_option_group_box>div:nth-of-type(2)>div:nth-of-type(2) input {
    width: 200px;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 10px;
}
.seller_combination_third_option_group_box>div:nth-of-type(2)>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.seller_combination_third_option_box {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    margin: 10px 0;
    padding: 10px;
    position: relative;
    border-radius: 4px;
}
.seller_combination_third_option_box>div:nth-of-type(1) {
    font-size: 0.9em;
    padding-bottom: 5px;
    font-weight: 600;
}
.seller_combination_third_option_box>div:nth-of-type(2) {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.seller_combination_third_option_box>div:nth-of-type(3) {
    width: 100%;
    display: flex;
    align-items: center;
}
.seller_combination_third_option_box>div:nth-of-type(2)>div:nth-of-type(1),
.seller_combination_third_option_box>div:nth-of-type(3)>div:nth-of-type(1) {
    width: 15%;
    font-size: 0.9em;
}
.seller_combination_third_option_box>div:nth-of-type(2)>div:nth-of-type(2),
.seller_combination_third_option_box>div:nth-of-type(3)>div:nth-of-type(2) {
    width: 70%;
}
.seller_combination_third_option_box>div:nth-of-type(2)>div:nth-of-type(2) input,
.seller_combination_third_option_box>div:nth-of-type(3)>div:nth-of-type(2) input {
    width: 250px;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    font-size: 0.9em;
    padding: 10px;
}
.seller_combination_third_option_box>div:nth-of-type(2)>div:nth-of-type(2) input:focus,
.seller_combination_third_option_box>div:nth-of-type(3)>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.seller_combination_fourth_option_group_box {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    border-radius: 4px;
    margin: 10px 0;
    padding: 10px;
    position: relative;
}
.seller_combination_fourth_option_group_box>div:nth-of-type(1) {
    font-size: 0.9em;
    font-weight: 600;
    padding-bottom: 10px;
}
.seller_combination_fourth_option_group_box>div:nth-of-type(2) {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.9em;
}
.seller_combination_fourth_option_group_box>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 30%;
}
.seller_combination_fourth_option_group_box>div:nth-of-type(2)>div:nth-of-type(2) {
    width: 70%;
}
.seller_combination_fourth_option_group_box>div:nth-of-type(2)>div:nth-of-type(2) input {
    width: 200px;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    padding: 10px;
}
.seller_combination_fourth_option_group_box>div:nth-of-type(2)>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}
.seller_combination_fourth_option_box {
    width: 100%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    margin: 10px 0;
    padding: 10px;
    position: relative;
    border-radius: 4px;
}
.seller_combination_fourth_option_box>div:nth-of-type(1) {
    font-size: 0.9em;
    padding-bottom: 5px;
    font-weight: 600;
}
.seller_combination_fourth_option_box>div:nth-of-type(2) {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.seller_combination_fourth_option_box>div:nth-of-type(3) {
    width: 100%;
    display: flex;
    align-items: center;
}
.seller_combination_fourth_option_box>div:nth-of-type(2)>div:nth-of-type(1),
.seller_combination_fourth_option_box>div:nth-of-type(3)>div:nth-of-type(1) {
    width: 15%;
    font-size: 0.9em;
}
.seller_combination_fourth_option_box>div:nth-of-type(2)>div:nth-of-type(2),
.seller_combination_fourth_option_box>div:nth-of-type(3)>div:nth-of-type(2) {
    width: 70%;
}
.seller_combination_fourth_option_box>div:nth-of-type(2)>div:nth-of-type(2) input,
.seller_combination_fourth_option_box>div:nth-of-type(3)>div:nth-of-type(2) input {
    width: 250px;
    height: 40px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 4px;
    font-size: 0.9em;
    padding: 10px;
}
.seller_combination_fourth_option_box>div:nth-of-type(2)>div:nth-of-type(2) input:focus,
.seller_combination_fourth_option_box>div:nth-of-type(3)>div:nth-of-type(2) input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1);
}

@media (max-width: 1480px) {
    .seller_message_content {
        width: 75%;
    }
    .seller_message_list {
        width: 25%;
    }
}
@media (max-width: 1250px) {
    .seller_message_content {
        width: 70%;
    }
    .seller_message_list {
        width: 30%;
    }
}
@media (max-width: 1080px) {
    .seller_message_content {
        width: 65%;
    }
    .seller_message_list {
        width: 35%;
    }
}

@media (max-width: 1030px) {
    .seller_edit_upload_middle {
        top: 30px;
    }
    .seller_product_state_desktop {
        display: none;
    }
    .seller_product_state_width {
        position: absolute;
        display: block;
        top: 100%;
        z-index: 100;
        background-color: white;
        width: 130px;
    }
    .seller_product_state_change_btn {
        display: flex;
        align-items: center;
        font-size: 0.9em;
        cursor: pointer;
    }
    .seller_product_state_change_btn:hover {
        color: rgba(255, 114, 153, 1);
    }
    .seller_product_state_btn {
        margin: 0 !important;
        width: 100%;
    }
    .seller_product_state_btn:hover {
        background-color: rgba(255, 224, 232, 0.45);
    }
}

@media (max-width: 960px) {
    .seller_message_list_box>div:nth-of-type(1)>div {
        width: 45px;
        height: 45px;
        overflow: hidden;
        border-radius: 50%;
    }
    .seller_message_list_box>div:nth-of-type(1)>div img {
        width: 100%;
        height: 45px;
        object-fit: cover;
    }
}
@media (max-width: 820px) {
    .seller_side_container {
        /* display: none; */
        position: fixed;
        right: 0;
        background-color: white;
        width: 35%;
        z-index: 10;
        height: 100%;
        overflow-y: auto;
    }
    .seller_right_container {
        width: 100%;
        margin-left: 0;
    }
    .seller_header_menu_btn {
        display: block;
    }
    .seller_dashboard_container {
        width: 100%;
        margin-left: 0;
    }
    .seller_date_confirm {
        width: 260px !important;
    }
    .seller_date_cancel {
        width: 260px !important;
    }
    .seller_seller_date_title {
        font-size: 0.8em;
    }
    .seller_date_save_popup {
        width: 55% !important;
    }
    .seller_shop_middle {
        width: 70% !important;
    }
}

@media (max-width : 450px) {
    .seller_message_payment_tab {
        font-size: 0.9em;
    }
    .seller_message_payment_search_btn_box {
        font-size: 0.8em;
    }
    .seller_message_payment_search_btn_box>div:nth-of-type(1) {
        display: block;
    }
    .seller_message_payment_search_btn_box>div:nth-of-type(1)>div:nth-of-type(2) {
        margin-top: 5px;
    }
    .seller_message_payment_search_btn_box>div:nth-of-type(2) input {
        width: 120px;
    }
    .seller_message_payment_content_header {
        font-size: 0.9em;
    }
    .seller_message_payment_content {
        width: 100%;
        text-align: center;
        overflow-x: scroll;
    }
    .seller_message_payment_content::-webkit-scrollbar {
        width: 10px;  /* 스크롤바의 너비 */
        height: 5px;
    }
    .seller_message_payment_content::-webkit-scrollbar-thumb {
        height: 30%; /* 스크롤바의 길이 */
        background: rgba(255, 114, 153, 1); /* 스크롤바의 색상 */
        border-radius: 2px;
    }
    
    .seller_message_payment_content::-webkit-scrollbar-track {
        background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
    }
    
    .seller_message_payment_content>div {
        min-width: 700px;
    }
}