/* 회원가입 */
.email_container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 999;
}
.agree_background {
    width: 100%;
    height: 100%;
}
.agree_box {
    width: 100%;
    height: 100%;
    background-color: white;
}
.agree {
    display: flex;
    align-items: center;
    padding: 0 0 24px 0;
}
.agree input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 15px;
    background: #fff;
    position: relative;   
}
  
.agree input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    transition: all 0.3s;
    opacity: 0;
}

.agree input[type="checkbox"]:checked {
    border-color: rgba(255, 3, 102, 1);
    background-color: rgba(255, 3, 102, 1);;
}

.agree input[type="checkbox"]:checked:after {
    width: 7px;
    height: 12px;
    opacity: 1;
}
.agree_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}
.agree_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.agree_header>div:nth-of-type(1) button {
    border: none;
    background-color: inherit;
}
.agree_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.agree_info_content {
    width: 100%;
    height: calc(100vh - 50px);
    padding: 20px;
    overflow-y: auto;
}
.agree.service_agree {
    padding-top: 24px;
}
.item_center {
    display: flex;
    align-items: center;
}
.agree button {
    border: none;
    background-color: inherit;
}
.agree_btn_box {
    position: absolute;
    bottom: 34px;
    width: 100%;
}
.agree_btn_box button {
    background-color: rgba(241, 242, 243, 1);
    border : none;
    width: 100%;
    padding: 20px 0;
    border-radius: 10px;
    font-size: 1.1em;
    color: rgba(169, 173, 178, 1);
}
.agree_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}
.termofuse_background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}
.termofuse_box {
    width: 100%;
    height: 100%;
    background-color: white;
}
.join_container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
}
.join_detail_box {
    width: 100%;
    height: 100%;
    background-color: white;
}
.join_input_box {
    padding: 15px 20px;
}
.join_phone_txt {
    display: flex;
    align-items: center;
}
.join_phone_overseaes_info_btn {
    margin-left: 5px;
    border: none;
    font-size: 0.75em;
    background-color: rgba(255, 114, 153, 1);
    color: white;
    padding: 3px 8px;
    border-radius: 4px;
}
.join_support_countries_container {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.join_support_countries_box {
    width: 90%;
    height: 80%;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
}
.join_support_countries_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.join_support_countries_header>div:nth-of-type(1) {
    font-size: 1.1em;
    font-weight: 500;
}
.join_support_countries_header>div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
}
.join_support_countries_header>div:nth-of-type(2) button {
    border: none;
    background-color: inherit;
}
.join_support_countries_table {
    width: 100%;
    height: calc(100% - 50px);
    overflow-y: auto;
    padding: 20px;
}
.join_support_countries_table_header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0;
    border: 1px solid rgba(169, 173, 178, 1);
}
.join_support_countries_table_header>div:nth-of-type(1) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(169, 173, 178, 1);
}
.join_support_countries_table_header>div:nth-of-type(2) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.join_support_countries_table_content {
    width: 100%;
}
.join_support_countries_table_content>div {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid rgba(223, 224, 226, 1);
    padding: 5px 0;
    font-size: 0.9em;
    color: rgba(115, 122, 130, 1);
}
.join_support_countries_table_content>div>span:nth-of-type(1) {
    display: inline-block;
    width: 100%;
    text-align: center;
    border-right: 1px solid rgba(223, 224, 226, 1);
}
.join_support_countries_table_content>div>span:nth-of-type(2) {
    display: inline-block;
    width: 100%;
    text-align: center;
}
.pb-100p {
    padding-bottom: 100px;
}
.join_detail_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.join_detail_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
}
.join_detail_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.join_detail_header>div:nth-of-type(3) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.twitter_join_detail_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.twitter_join_detail_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
}
.twitter_join_detail_header>div:nth-of-type(1) button,
.twitter_join_detail_header>div:nth-of-type(3) button {
    border: none;
    background-color: inherit;
}
.twitter_join_detail_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.twitter_join_detail_header>div:nth-of-type(3) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.twitter_nick_name_box {
    width: 100%;
    padding: 0 20px;
}
.twitter_nick_name_box>div:nth-of-type(2)>div:nth-of-type(1) span {
    font-size: 0.8em;
}
.twitter_nick_name_box>div:nth-of-type(2)>div:nth-of-type(2) {
    font-size: 0.8em;
    color: rgba(115, 122, 130, 1);
}
.kakao_join_detail_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.kakao_join_detail_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
}
.kakao_join_detail_header>div:nth-of-type(1) button,
.kakao_join_detail_header>div:nth-of-type(3) button {
    border: none;
    background-color: inherit;
}
.kakao_join_detail_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.kakao_join_detail_header>div:nth-of-type(3) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.kakao_nick_name_box {
    width: 100%;
    padding: 0 20px;
}
.kakao_nick_name_box>div:nth-of-type(2)>div:nth-of-type(1) span {
    font-size: 0.8em;
}
.kakao_nick_name_box>div:nth-of-type(2)>div:nth-of-type(2) {
    font-size: 0.8em;
    color: rgba(115, 122, 130, 1);
}
.line_join_detail_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.line_join_detail_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
}
.line_join_detail_header>div:nth-of-type(1) button,
.line_join_detail_header>div:nth-of-type(3) button {
    border: none;
    background-color: inherit;
}
.line_join_detail_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.line_join_detail_header>div:nth-of-type(3) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.line_nick_name_box {
    width: 100%;
    padding: 0 20px;
}
.line_nick_name_box>div:nth-of-type(2)>div:nth-of-type(1) span {
    font-size: 0.8em;
}
.line_nick_name_box>div:nth-of-type(2)>div:nth-of-type(2) {
    font-size: 0.8em;
    color: rgba(115, 122, 130, 1);
}
.apple_join_detail_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.apple_join_detail_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
}
.apple_join_detail_header>div:nth-of-type(1) button,
.apple_join_detail_header>div:nth-of-type(3) button {
    border: none;
    background-color: inherit;
}
.apple_join_detail_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.apple_join_detail_header>div:nth-of-type(3) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.apple_nick_name_box {
    width: 100%;
    padding: 0 20px;
}
.apple_nick_name_box>div:nth-of-type(2)>div:nth-of-type(1) span {
    font-size: 0.8em;
}
.apple_nick_name_box>div:nth-of-type(2)>div:nth-of-type(2) {
    font-size: 0.8em;
    color: rgba(115, 122, 130, 1);
}
.height_initialized {
    width: 100%;
    padding-top: 10px;
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding-bottom: 50px
}
.join_input_tag{
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: 1px solid rgba(169, 173, 178, 1);
    padding-left: 10px;
    outline: none;
}
.join_input_tag:focus {
    border: 1px solid rgba(255, 3, 102, 1) !important;
}
.join_input_duplicate {
    border: 1px solid rgba(255, 92, 92, 1) !important;
}
.join_input button {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: 1px solid rgba(169, 173, 178, 1);
    background-color: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}
.join_input.phone_number_box {
    position: relative;
}
.join_input.phone_number_box input {
    padding-left: 50px;
}
.join_input.phone_number_box span {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}
.join_phone_auth {
    width: 100%;
}
.join_phone_auth button {
    border: none;
    background-color: rgba(241, 242, 243, 1);
    width: 100%;
    height: 40px;
    border-radius: 8px;
    margin-top: 5px;
    color: rgba(169, 173, 178, 1);
}
.join_phone_auth_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}
.join_btn_box {
    position: absolute;
    bottom: 20px;
    padding: 0 20px;
    width: 100%;
}
.join_btn_box button {
    width: 100%;
    height: 60px;
    background-color: rgba(241, 242, 243, 1);
    border: none;
    color: rgba(169, 173, 178, 1);
    border-radius: 8px;
}
.join_btn_box_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}
.password_eye_btn {
    position: absolute;
    right: 10px;
    top: 50%; 
    transform: translateY(-50%); 
    border: none !important;
    background: none !important;
    width: auto !important;
}
.country_container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.4);
}
.country_box {
    position: absolute;
    top: 50%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
    width: 80%;
    height: 80%;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
}
.country_box>div:nth-of-type(1)>div>div:nth-of-type(1) {
    font-size: 1.1em;
}
.country_search_box {
    position: relative;
    margin: 0 30px;
}
.country_search_box input {
    padding-left: 50px;
    width: 100%;
    height: 50px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid rgba(169, 173, 178, 1);
    font-size: 0.9em;
}
.country_search_box input::placeholder {
    color: rgba(169, 173, 178, 1);
}
.country_search_box input:focus {
    border-bottom: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.country_search_box span {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: rgba(169, 173, 178, 1);
}
.country_list {
    overflow-y: auto;
    font-size: 0.8em;
}
.height_auto {
    height: auto !important;
}
.country_list>button {
    width: 100%;
    border: none;
    background-color: inherit;
    height: 50px;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
}
.country_list>button:hover {
    background-color: rgba(255, 3, 102, 0.1);
}
.country_img{
    width: 10%;
}
.country_name{
    width: 70%;
}
.country_number{
    width: 20%;
}

.join_info_container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    background-color: white;
    overflow-x: hidden;
}

.join_info_detail_box {
    width: 100%;
    height: 100%;
    background-color: white;
}
.join_info_detail_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.join_info_detail_header button {
    border: none;
    background-color: inherit;
}
.join_info_detail_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
}
.join_info_detail_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.join_info_detail_header>div:nth-of-type(3) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}

.input_style {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: 1px solid rgba(169, 173, 178, 1);
    padding-left: 10px;
    outline: none;
}
.nickname_input:focus, .birth_input:focus { 
    border: 1px solid rgba(255, 3, 102, 1) !important;
}
.birth_input_div {
    width: 100%;
}
.birth_input {
    width: 85%;
    height: 60px;
    border-radius: 10px;
    border: 1px solid rgba(169, 173, 178, 1);
    padding-left: 10px;
    padding-right: 10px;
    outline: none;
}
.gender_box input[type="radio"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 50%;
    background: #fff;
    position: relative;
  }

.gender_box input[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 3, 102, 1);
    transition: width 0.2s ease, height 0.2s ease;
}

.gender_box input[type="radio"]:checked {
    border-color: rgba(255, 3, 102, 1);
}

.gender_box input[type="radio"]:checked::before {
    width: 10px;
    height: 10px;
}
.gender_box label {
    padding-left: 10px;
}
.gender_female_box, .gender_male_box, .gender_nonselect_box {
    display: flex;
    align-items: center;
    vertical-align: middle;
}
.info_btnbox {
    position: absolute;
    width: 100%;
    bottom: 20px;
    padding: 0 20px;
}
.info_btnbox button {
    width: 100%;
    height: 60px;
    border: none;
    background-color: rgba(241, 242, 243, 1);
    color: rgba(169, 173, 178, 1);
    font-weight: bold;
    border-radius: 8px;
}
.info_btn_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}

.join_favority_container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    background-color: white;
}
.join_favority_detail_box {
    width: 100%;
    height: 100%;
    background-color: white;
}
.join_favority_detail_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.join_favority_detail_header button {
    border: none;
    background-color: inherit;
}
.join_favority_detail_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
}
.join_favority_detail_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.join_favority_detail_header>div:nth-of-type(3) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.join_favority_search_box {
    width: 100%;
    padding: 20px;
    position: relative;
}
.join_favority_search_box span {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
    color: rgba(169, 173, 178, 1);
}
.join_favority_search_box input {
    width: 100%;
    height: 50px;
    border: 1px solid rgba(223, 224, 226, 1);
    border-radius: 8px;
    padding-left: 40px;
}
.join_favority_search_box input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.favority_search {
    width: 100%;
    height: 60px;
    border-radius: 12px;
    border: 1px solid rgba(169, 173, 178, 0.6);
    padding-left: 40px;
}
.favority_search:focus {
    border: 1px solid rgba(255, 3, 102, 1) !important;
}
.join_favority_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    height: 65vh;
    overflow: auto;
    padding: 0 20px;
    /* flex : 0 0 auto; */
    width: 100%;
}
.list_select {
    border: 1px solid rgba(255, 114, 153, 1);
}
  
.favority_list_detail {
    box-shadow: 0 0 4px 3px rgba(91, 97, 103, 0.1);
    height: 200px;
    margin: 10px; /* 열 사이의 간격을 조절합니다. */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
}
.favority_col_4 {
    flex: 0 0 auto;
    width: 30% !important;
}
.favotiry_col_6 {
    flex: 0 0 auto;
    width: 40% !important;
}
.favority_list_detail div:nth-of-type(1) {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
}
  
.favority_list_detail img {
    width: 80px;
    height: 80px;
    object-fit: cover;
}
.favority_btn_box {
    position: absolute;
    bottom: 20px;
    width: 100%;
    padding: 0 20px;
}
.favority_btn_box button {
    width: 100%;
    height: 60px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 8px;
}
.favority_select_span span {
    color: rgba(255, 3, 102, 1);
    font-weight: bold;
}
.skip_add_btnBox span {
    font-size: 0.8em;
    color: #999;
}
.cheae_add_btn {
    background-color: inherit  !important;
    color: rgba(255, 3, 102, 1) !important;
    text-decoration: underline;
    height: auto !important;
    padding: 0 0 12px 0;
}
.join_favority_searchBox_span{
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.input_form {
    width: 100%;
    height: 60px;
    border-radius: 8px;
    border: 1px solid rgba(169, 173, 178, 1);
    padding-left: 10px;
}
.textarea_form {
    width: 100%;
    height: 200px;
    border-radius: 8px;
    border: 1px solid rgba(169, 173, 178, 1);
    padding-left: 10px;
    padding-top: 10px;
}
.favority_add_btnBox {
    position: absolute;
    width: 100%;
    bottom: 5%;
}
.favority_add_btnBox button {
    width: 100%;
    height: 60px;
    border: none;
    background-color: rgba(223, 224, 226, 1);
    border-radius: 8px;
    color: rgba(169, 173, 178, 1);
}
.textarea-container {
    position: relative;
    display: inline-block;
    width: 100%;
}
.counter {
    position: absolute;
    bottom: 5px;
    right: 10px;
    color: #666;
    background-color: rgba(255, 255, 255, 0.7); /* 배경 투명도 조절 */
    padding: 0 5px;
    border-radius: 5px;
}
.favority_add_active {
    background-color: rgba(255, 3, 102, 1) !important;
    color: white !important;
}
.favority_day_detail_selected_item_box {
    width: 100%;
    max-height: 65vh;
    overflow-y: auto;
}
.favority_dday_container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    /* background-color: white; */
}
.favority_day_detail_box {
    width: 100%;
    height: 100%;
    background-color: white;
}
.favority_dday_selectBox {
    display: flex;
    padding: 12px 0;
    box-shadow: 1px 0 4px rgba(169, 173, 178, 0.6);
    border-radius: 8px;
}
.favority_day_detail_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.favority_day_detail_header button {
    border: none;
    background-color: inherit;
}
.favority_day_detail_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
}
.favority_day_detail_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.favority_day_detail_header>div:nth-of-type(3) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.favority_ddayImgBox {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 15px;
}
.favority_ddayImgBox >img {
    width: 60px;
    height: 60px;
    object-fit: cover;
}
.favority_ddayNumberBox {
    width: 75%;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.favority_dday_inputBox {
    padding-top: 12px;
}
/* .favority_add_save_popup {
    width: 30%;
    height: 20vh;
} */
.favority_add_save_title {
    font-size: 1em;
    padding: 5px 20px;
}
.favority_add_save_content {
    font-size: 0.8em;
    padding: 5px 20px;
}
.favority_add_save_btn button {
    width: 100%;
    height: 50px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    border-radius: 12px;
    color: white;
    font-size: 0.9em;
}
.dday_input {
    width: 100%;
    height: 60px;
    border-radius: 8px;
    border: 1px solid rgba(169, 173, 178, 0.6);
    padding-left: 35px;
}
.dday_input:focus {
    outline: none;
    border: 1px solid rgba(255, 3, 102, 1) !important;
}
.favority_dday_inputBox .dday_box>span {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: rgba(255, 3, 102, 1);
}
.favority_dday_btn_box {
    position: absolute;
    width: 100%;
    bottom: 20px;
    padding: 0 20px;
}
.favority_dday_info_box span {
    font-size: 0.8em;
    color: #999;
}
.favority_dday_info_box button {
    border: none;
    color: rgba(255, 3, 102, 1);
    text-decoration: underline;
    background-color: inherit;
}
.favority_dday_next_btnbox button {
    width: 100%;
    height: 60px;
    border: none;
    background-color: rgba(255, 3, 102, 1);
    color: white;
    border-radius: 8px;
}
.recommender_container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    /* background-color: white; */
}
.recommender_detail_box {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
}
.recommender_detail_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.recommender_detail_header button {
    border: none;
    background-color: inherit;
}
.recommender_detail_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
}
.recommender_detail_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.recommender_detail_header>div:nth-of-type(3) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}
.recommend_input_box input {
    width: 100%;
    height: 60px;
    border-radius: 8px;
    border: 1px solid #999;
    padding-left: 10px;
}
.recommend_input_box input:focus {
    border: 1px solid rgba(255, 3, 102, 1);
    outline: none;
}
.recommend_btn_box {
    position: absolute;
    bottom: 20px;
    width: 100%;
    padding: 0 20px;
}
.recommend_btn_box_gray {
    background-color: #c2c2c2 !important;
    color: #999;
    pointer-events: none;
}
.recommend_btn_box button {
    width: 100%;
    height: 60px;
    background-color: rgba(255, 3, 102, 1);
    border: none;
    color: white !important;
    border-radius: 8px;
}

.google_join_detail_header {
    width: 100%;
    height: 50px;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.google_join_detail_header>div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}
.google_join_detail_header>div:nth-of-type(1) button,
.google_join_detail_header>div:nth-of-type(3) button {
    border: none;
    background-color: inherit;
}
.google_join_detail_header>div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.google_join_detail_header>div:nth-of-type(3) {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
}

.user_division_select_container {
    width: 100%;
    position: absolute;
    height: 100%;
    background-color: white;
    top: 0;
    left: 0;
}
.user_division_select_box {
    width: 100%;
    height: 100%;
}
.user_division_select_header {
    width: 100%;
    height: 50px;
    overflow: hidden;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.user_division_select_header > div:nth-of-type(1) {
    padding-left: 19px;
    cursor: pointer;
}
.user_division_select_header > div:nth-of-type(2) {
    font-size: 1.1em;
    font-weight: 500;
}
.user_division_select_header > div:nth-of-type(3) {
    padding-right: 19px;
    cursor: pointer;
}
.user_division_select_content {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.user_division_select_content>div {
    width: 100%;
    padding: 20px;
}
.user_division_select_content>div button {
    width: 100%;
    height: 60px;
    border: 1px solid rgba(223, 224, 226, 1);
    background-color: inherit;
    font-size: 0.9em;
    border-radius: 6px;
    color: rgba(115, 122, 130, 1);
    transition: all 0.3s;
}
.user_division_select_content>div button:hover {
    background-color: rgba(255, 3, 102, 1);
    color: white;
    font-weight: 500;
}
.pw_check_point {
    font-size: 0.8em;
}

@media screen and (max-height: 600px) {
    .country_list {
        max-height: 350px;
    }
    .info_btnbox {
        bottom: -65%;
    }
}

@media screen and (min-height: 800px) {
    .country_list {
        max-height: 500px;
    }
}

@media screen and (min-height: 1000px) {
    .country_list {
        max-height: 650px;
    }
}
@media screen and (min-height: 1200px) {
    .country_list {
        max-height: 800px;
    }
}
@media screen and (min-height: 1300px) {
    .country_list {
        max-height: 880px;
    }
}