:root {
    --Neutral_Gray_10 : rgba(19, 20, 22, 1);
    --Neutral_Gray_9 : rgba(48, 51, 54, 1);
    --Neutral_Gray_8 : rgba(72, 76, 81, 1);
    --Neutral_Gray_7 : rgba(91, 97, 103, 1);
    --Neutral_Gray_6 : rgba(115, 122, 130, 1);
    --Neutral_Gray_5 : rgba(141, 147, 154, 1);
    --Neutral_Gray_4 : rgba(169, 173, 178, 1);
    --Neutral_Gray_3 : rgba(196, 199, 202, 1);
    --Neutral_Gray_2 : rgba(223, 224, 226, 1);
    --Neutral_Gray_1 : rgba(241, 242, 243, 1);

    --System_error : rgba(255, 92, 92, 1);
    --System_success : rgba(0, 189, 103, 1);
    --System_warning : rgba(255, 206, 0, 1);

    --Main_color_tone1 : rgba(255, 3, 102, 1);
    --Main_color_tone2 : rgba(255, 114, 153, 1);
    --Main_color_tone3 : rgba(255, 224, 232, 1);
    --Main_color_tone4 : rgba(255, 245, 248, 1);

    --Sub_color_tone : rgba(255, 239, 0, 1);
}
/* shop 정보 헤더 */
/* 스토어 상품 상세 */
.shop_info_container {
    position: relative;
    width: 100%;
    height: 100%;
}
.shop_info_header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    top: 0;
    z-index: 10;
    align-items: center;
    padding: 0 20px;
}
.shop_info_header_left {
    width: 100%;
    text-align: left;
}
.shop_info_header_left button{
    border: none;
    background-color: inherit;
    color: white;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 3px;
    border-radius: 4px;
}
.shop_info_header_right {
    width: 100%;
    text-align: right;
}
.shop_info_header_right button{
    border: none;
    background-color: inherit;
    color: white;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 3px;
    border-radius: 4px;
}

/* 배너 */
.shop_main_banner_box {
    position: relative;
    width: 100%;
    height: auto;
}
.shop_main_banner_img_box {
    width: 100%;
    max-height: 300px;
    box-shadow: 0 0 4px 1px rgba(241, 242, 243, 1);
    position: relative;
}
.shop_main_banner_img_box img {
    width: 100%;
    height: auto;
    aspect-ratio: 700 / 300;
    object-fit: contain;
}
.shop_main_banner_profile_box {
    position: absolute;
    bottom: 0;
    left: 1.5em;
    z-index: 10;
    border-radius: 50%;
    box-shadow: 0 0 4px 1px rgba(223, 224, 226, 1);
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    padding: 3px;
}
.shop_main_banner_profile_box img {
    width: 100%;
    height: auto;
    aspect-ratio: 100 / 100;
    object-fit: contain;
    border-radius: 50%;
}
.shop_info_content {
    width: 100%;
    height: 48vh;
    overflow-y: auto;
    padding: 0 10px;
}
.shop_main_banner_favority_box {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 10;
    display: flex;
}
.shop_main_banner_favority_box button {
    border: none;
    background-color: inherit;
}
.shop_main_banner_favority_box img {
    width: 20px;
    height: 20px;
    filter: drop-shadow(0px 0px 2px #666666);
}

/* shop 설명 */
.shop_info_content_box {
    padding: 1em 2em;
}
.shop_info_content_name {
    font-size: 1.3em;
}
.shop_info_content_name span:nth-of-type(2),
.shop_info_content_name span:nth-of-type(3) {
    font-size: 0.7em;
    color: rgba(169, 173, 178, 1);
}
.shop_info_content_name span:nth-of-type(3) {
    padding-left: 2px;
}
.shop_info_centent_message_btn {
    display: flex;
    align-items: center;
}
.shop_info_centent_message_btn button {
    border : 1px solid var(--Main_color_tone2);
    background-color: inherit;
    color: var(--Main_color_tone2);
    border-radius: 8px;
    width: 110px;
    padding: 3px 10px;
}
.shop_info_centent_message_btn button img {
    width: 25px;
    height: 25px;
}
.shop_info_centent_message_btn button div:nth-of-type(2) {
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translate(0, -40%);
}

/* 탭 버튼 */
.shop_tab_btn_box {
    width: 100%;
    display: flex;
}
.shop_tab_btn_box button {
    width: 100%;
    border: none;
    background-color: inherit;
    font-size: 1.1em;
    padding: 10px 0;
}
.shop_active {
    border-bottom: 3px solid var(--Main_color_tone1);
}

/* item list */
.shop_item_list_box {
    position: relative;
}
.shop_stockCnt_zero_box {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding-right: 2em;
    padding-bottom: 2em;
}
.shop_stockCnt_zero {
    width: 100%;
    height: 100%;
    background-color: rgba(115, 122, 130, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
    transition: all 0.3s;
}
.shop_stockCnt_zero:hover {
    background-color: rgba(115, 122, 130, 0.7);
}
.shop_stockCnt_zero span{
    color: white;
}
.shop_item_list_img_box {
    width: 100%;
    height: 220px;
    overflow: hidden;
    border-radius: 12px;
}
.shop_item_list_img_box img {
    width: 100%;
    height: 230px;
    object-fit: cover;
}
.shop_item_list_productname {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 두 줄로 제한 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* 원래는 nowrap을 사용하지만, 여러 줄 처리를 위해 normal로 설정 */
    line-height: 1.2em; /* 줄 높이 설정 */
    max-height: 2.4em; /* 줄 높이와 줄 수에 따라 최대 높이 설정 (예: 줄 높이 1.2em * 줄 수 2 = 2.4em) */
}
.shop_gray {
    color: rgba(115, 122, 130, 1);
}
.shop_item_list_discount_price {
    color: rgba(255, 3, 102, 1);
    font-weight: bold;
    font-size: 1.1em;
    padding-right: 1em;
}
.shop_item_list_price {
    color: rgba(141, 147, 154, 1);
    text-decoration: line-through;
    font-size: 0.9em;
}
.star_iconImg {
    width: 20px;
    height: 20px;
    overflow: hidden;
}
.star_iconImg img{
    width: 100%;
}
.shop_item_list_label {
    width: 70px;
    height: 25px;
    background-color: rgba(238, 225, 255, 1);
    font-size: 0.9em;
    border-radius: 3px;
    text-align: center;
    line-height: 25px;
    color: rgba(118, 19, 180, 1);
}
.shop_item_list {
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 8px;
}
.shop_item_list:hover {
    cursor: pointer;
    opacity: 0.8;
    box-shadow: 1px 1px 5px 4px rgba(238, 225, 255, 1);
}

/* 공유하기 */
.shop_detaile_shared_active {
    overflow: hidden !important;
    height: 100vh !important;
}
.shop_shared_background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    background-color: var(--Neutral_Gray_8);
}
.shop_shard_box {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 350px;
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.shop_shard_btn_box {
    width: 20%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1em;
    margin-right: 1em;
}
.shop_shard_btn_bottom_box {
    width: 19%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1em;
    margin-right: 1em;
}
.shop_shard_btn_box button, .shop_shard_btn_bottom_box button {
    border: none;
    background-color: inherit;
}
.shop_shard_btn_box span, .shop_shard_btn_bottom_box button {
    font-size: 0.9em;
}
.shop_clipboard_box {
    width: 220px;
    border-radius: 30px;
    background-color: rgba(241, 242, 243, 1);
    padding: 10px 5px;
    font-size: 0.8em;
    text-align: center;
}
.shop_shared_close {
    border: none;
    background-color: inherit;
}
